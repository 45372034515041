import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { YAxisOptions } from "highcharts";
import { difference as _difference, merge as _merge } from "lodash";
import { v4 as uuid } from "uuid";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { TimeRange } from "../../../core/models/time-range";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { DataSourceDto, SignalDataSourceDto } from "../../../data-connectivity";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { TimeSeriesDataPointDto } from "../../../data-connectivity/models/data-point";
import { ComponentMetadataService } from "../../../data-connectivity/services/component-metadata.service";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { BLACK_COLOR_HEX } from "../../models/colors.constants";
import { SignalData } from "../../models/dialogs/signal-data.model";
import { PRIMARY_X_AXIS_ID } from "../../services/highcharts/base-highcharts-options.helper";
import { CommonActions } from "../../store/common/common.actions";
import { DataConnectorActions } from "../../store/data-connector/data-connector.actions";
import {
  AxisDialogActions,
  PlotlineDialogActions,
  SignalDialogActions
} from "../../store/dialogs/actions";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ChartComponent } from "../chart/chart.component";
import { Roles } from "./roles";
import { TrendViewConfig } from "./view-config";

const AXIS_SPACING = 50;

@Component({
  selector: "c-trend",
  templateUrl: "./trend.component.html",
  styleUrls: ["./trend.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: TrendComponent }]
})
// @LayoutBuilder(ComponentCategory.Widgets, "TrendComponent", "Trend_1")
@ConnectorRoles(Roles)
@EditableWidget({ fullName: "TrendComponent", title: "trend" })
export class TrendComponent extends ChartComponent implements OnInit {
  private chartSeries: any[] = [];
  private chartYAxes: any[] = [];
  public timeRange: TimeRange = null;
  private minTimestamp: number;
  private maxTimestamp: number;
  private snackBarConfig: MatSnackBarConfig;
  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    private filterFactory: FilterFactory,
    private snackBar: MatSnackBar,
    private roleProvider: ComponentMetadataService,
    cdr: ChangeDetectorRef,
    private valueFormatter: ValueFormatterService
  ) {
    super(params, hostElementRef, cdr);
    this.snackBarConfig = { horizontalPosition: "center", verticalPosition: "bottom" };
  }

  ngOnInit(): void {
    super.ngOnInit();
    // this.Highcharts = Highstock;
    this.constructorType = "stockChart";
    this.addYAxis("Value", "unit");
    // TODO the following code is incompatible with ngrx8+ actions, and is anyway not the cleanest approach
    // this.actionsSubject.pipe(takeUntil(this.unsubscribeSubject$)).subscribe((action: Action) => {
    //   switch (action.type) {
    //     case AxisDialogActions.addAxisDialogSuccess.type: {
    //       const { result } = action;
    //       const { title, unit } = result as AxisData;

    //       this.addYAxis(title, unit);
    //       break;
    //     }
    //     case fromDialog.ADD_SIGNAL_DIALOG_SUCCESS: {
    //       const { result } = action as fromDialog.AddSignalDialogSucess;
    //       this.addSignalsToAxis(result as SignalData);
    //       break;
    //     }
    //     case fromDialog.ADD_PLOTLINE_DIALOG_SUCCESS: {
    //       const { result } = action as fromDialog.AddPlotlineDialogSuccess;
    //       const { plotlineName, plotlineValue, yAxis, plotlineColor } = result as PlotlineData;
    //       this.addPlotline(plotlineName, plotlineValue, yAxis, plotlineColor);
    //       break;
    //     }
    //     case fromDialog.REMOVE_AXIS_DIALOG_SUCCESS: {
    //       const { axisIds } = action as fromDialog.RemoveAxisDialogSuccess;

    //       this.removeYAxes(axisIds);
    //       break;
    //     }
    //     case fromDialog.REMOVE_PLOTLINE_DIALOG_SUCCESS: {
    //       const { removePlotlines } = action as fromDialog.RemovePlotlineDialogSuccess;
    //       const { axisId, plotlineIds } = removePlotlines as RemovePlotlineData;
    //       this.removePlotlinesFromAxis(axisId, plotlineIds);
    //       break;
    //     }
    //     case fromDialog.REMOVE_SIGNAL_DIALOG_SUCCESS: {
    //       const { signalsToRemove } = action as fromDialog.RemoveSignalDialogSuccess;
    //       const { axisId } = signalsToRemove as SignalData;

    //       const indexOfAxis: number = this.chartYAxes.findIndex(
    //         (axisConfig) => axisConfig.id === axisId
    //       );
    //       if (indexOfAxis === -1) {
    //         return;
    //       }

    //       this.chartYAxes[indexOfAxis].signals = this.removeSignalsFromAxis(
    //         signalsToRemove as SignalData,
    //         indexOfAxis
    //       );

    //       break;
    //     }
    //     case fromDialog.CLOSE_DIALOG: {
    //       const { dialogRef } = action as fromDialog.CloseDialog;
    //       dialogRef.close();
    //     }
    //   }
    // });

    this.componentStatePropertySelector.subscribeOnFilter((filter: FilterConfigurationDto) => {
      this.filter = filter;
      const validatedFilter = this.filterFactory.createFilterFromConfiguration(this.filter);
      this.timeRange = validatedFilter ? validatedFilter.timeRange : null;
      this.setChartOptions();
    });
  }

  @View(TrendViewConfig)
  private get view() {
    return this.currentState.view as TrendViewConfig;
  }

  public openAddAxisDialog() {
    if (this.chartYAxes.length < this.view.maxAxes) {
      this.dispatch(AxisDialogActions.addAxisDialog());
    } else {
      this.snackBar.open(
        "Cannot have more than " + this.view.maxAxes + " Y-axes. Remove an existing axis first.",
        "OK",
        this.snackBarConfig
      );
    }
  }

  private addYAxis(title: string, unit: string) {
    const component = this;
    const axisOptions: YAxisOptions = {
      id: uuid(),
      title: {
        useHTML: true,
        text: "<div style='text-align: center'>" + title + "<br>(" + unit + ")</div>",
        align: "high",
        offset: 0,
        rotation: 0,
        y: 0,
        x: 15,
        style: {
          color: BLACK_COLOR_HEX
        }
      },
      labels: {
        formatter: function () {
          return component.valueFormatter.formatValue(this.value, component.view.displayFormat);
        },
        style: {
          color: BLACK_COLOR_HEX
        }
      },
      plotLines: [],
      scrollbar: {
        enabled: true
      },
      offset: AXIS_SPACING * (this.chartOptions.yAxis as YAxisOptions[]).length,
      gridLineWidth: 0,
      lineWidth: 2,
      tickWidth: 1,
      opposite: false
    };

    if (this.chartObject != null) {
      this.chartObject.addAxis(axisOptions);
    }
    this.chartYAxes.push(axisOptions);
    this.setChartOptions();
  }

  public openRemoveAxisDialog() {
    this.dispatch(AxisDialogActions.removeAxisDialog({ chartYAxes: this.chartYAxes }));
  }

  private removeYAxes(axisIds: string[]) {
    axisIds.forEach((id) => {
      const index = this.chartYAxes.findIndex((axisConfig) => {
        return axisConfig.id === id;
      });
      if (index > -1) {
        this.chartYAxes.splice(index, 1);
      }
      this.chartObject.get(id).remove(false);
    });
    this.chartYAxes = this.chartYAxes.reduce((acc, axisConfig, index) => {
      axisConfig.offset = AXIS_SPACING * index;
      acc.push(axisConfig);
      (this.chartObject as any).get(axisConfig.id).update(axisConfig);
      return acc;
    }, []);
  }

  public openAddSignalDialog() {
    this.dispatch(SignalDialogActions.addSignalDialog({ chartYAxes: this.chartYAxes }));
  }

  // obsolete, never called
  public addSignalsToAxis(signalData: SignalData) {
    const { axisId, signalIds } = signalData;
    signalIds.forEach((signalId) => {
      let i = 0;
      let connectorRole;
      const axisIndex = this.chartYAxes.findIndex((yAxis) => yAxis.id === axisId);
      do {
        connectorRole = "Signal-" + axisIndex + "-" + i++;
      } while (this.findConnectorIndex(connectorRole) >= 0);
      // FIXME 10 This is bad
      this.addConnector(signalId, " ", connectorRole); // TODO signalId is now signalInfo and not EntityId
      // NOTE investigate is it correct to use upsertEntities for this
      const connectorToAdd: Maybe<DataConnectorDto> = this.dataConnectors.find(
        (dataConnector) => (dataConnector.dataSource as SignalDataSourceDto).signal.id === signalId
      );
      this.dispatch(
        CommonActions.upsertEntities({
          reportEntities: {
            componentStates: [],
            filters: [],
            dataConnectors: connectorToAdd ? [connectorToAdd] : [],
            dataConnectorViews: [] //FIXME: check what needs to be set here
          }
        })
      );
      setTimeout(() => this.updateDisplay(), 10000);
    });
    // TODO Update subscription to include new data connectors
    this.addOrUpdateChartSeries(this.dataConnectors, axisId);
  }

  private findConnectorIndex(role: string): number {
    return this.dataConnectors.findIndex((dataConnector) => dataConnector.role === role);
  }

  private addConnector(
    logId: number | string,
    name: string,
    roleName?: string,
    dataSource?: DataSourceDto,
    additionalProperties?: Partial<DataConnectorDto>
  ): void {
    if (!roleName) {
      roleName = this.roleProvider.getDefaultRole(this.currentState.type).name;
    }
    if (additionalProperties == null) {
      additionalProperties = {};
    }

    const newConnector = new DataConnectorDto({
      dataSource:
        dataSource ?? new SignalDataSourceDto({ signal: { id: logId, name: logId.toString() } }),
      role: roleName,
      title: name
    });
    _merge(newConnector, additionalProperties);
    this.dispatch(
      DataConnectorActions.addOne({
        componentId: this.id.toString(),
        connector: newConnector
      })
    );
  }

  public openRemoveSignalDialog() {
    this.dispatch(
      SignalDialogActions.removeSignalDialog({
        chartYAxes: this.chartYAxes,
        chartSeries: this.chartSeries
      })
    );
  }

  public removeSignalsFromAxis(signalsOfAxis: SignalData, indexOfAxis: number): string[] {
    const { signalIds } = signalsOfAxis;

    // TODO: incorrect logic
    const arrayOfSignals: string[] = this.chartYAxes[indexOfAxis].signals;
    const newArrayOfSignals: string[] = _difference(arrayOfSignals, signalIds);

    return newArrayOfSignals;
  }

  public openAddPlotlineDialog() {
    this.dispatch(PlotlineDialogActions.addPlotlineDialog({ chartYAxes: this.chartYAxes }));
  }

  private addPlotline(id: string, value: string, yAxis: string, plotlineColor: string) {
    const index: number = this.chartYAxes.findIndex((axisConfig) => {
      return axisConfig.id === yAxis;
    });
    if (index > -1) {
      if (!this.chartYAxes[index].plotLines.find((plotlineConfig) => plotlineConfig.id === id)) {
        if (isNaN(Number.parseFloat(value))) {
          this.snackBar.open("Invalid value", "OK", this.snackBarConfig);
          return;
        }

        this.chartYAxes[index].plotLines.push({
          id: id,
          value: Number.parseFloat(value),
          color: plotlineColor,
          width: 1
        });
        this.setChartOptions();
      } else {
        this.snackBar.open("Plotline with provided id already exists.", "OK", this.snackBarConfig);
      }
    } else {
      this.snackBar.open("Selected axis not found.", "OK", this.snackBarConfig);
    }
  }

  public openRemovePlotlineDialog() {
    this.dispatch(PlotlineDialogActions.removePlotlineDialog({ chartYAxes: this.chartYAxes }));
  }

  private removePlotlinesFromAxis(axisId: string, plotlineIds: string[]) {
    const axisIndex = this.chartYAxes.findIndex((axisConfig) => {
      return axisConfig.id === axisId;
    });

    plotlineIds.forEach((plotlineId) => {
      const plotlineindex = this.chartYAxes[axisIndex].plotLines.findIndex(
        (plotlineConfig) => plotlineConfig.id === plotlineId
      );
      this.chartYAxes[axisIndex].plotLines.splice(plotlineindex, 1);
    });

    this.setChartOptions();
  }

  protected updateChartData(): void {
    this.addOrUpdateChartSeries(this.dataConnectors, 0);
  }

  private addOrUpdateChartSeries(dataConnectors: DataConnectorDto[], yAxisId: string | number) {
    dataConnectors.forEach((dataConnector) => {
      const seriesIndex = this.chartSeries.findIndex((series) => series.id === dataConnector.role);
      if (seriesIndex >= 0) {
        this.chartSeries[seriesIndex].data = this.getSeriesData(dataConnector);
      } else {
        this.chartSeries.push({
          id: dataConnector.role,
          yAxis: yAxisId,
          data: this.getSeriesData(dataConnector)
          // _colorIndex: seriesIndex % 10
        });
      }
    });
  }

  private getSeriesData(dataConnector: DataConnectorDto): any[][] {
    return this.dataAccessor
      .getAllDataPointsForConnector(dataConnector)
      .map((dataPoint: TimeSeriesDataPointDto) => [dataPoint.x.getTime(), dataPoint.y]);
  }

  public onTimeRangeChanged(newTimeRange: TimeRange) {}

  protected setChartOptions(): void {
    const component = this;
    const colors: string[] = [
      "#2f7ed8",
      "#8bbc21",
      "#910000",
      "#1aadce",
      "#492970",
      "#f28f43",
      "#77a1e5",
      "#c42525",
      "#a6c96a",
      "#0d233a"
    ];

    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        zoomType: "xy",
        events: {
          load: function () {
            component.chartObject = this; // expose chart object to component in order to properly add and remove axes
          }
        }
      },
      tooltip: {
        formatter: function () {
          let labelHTML = "";

          this.points.forEach((point, i) => {
            labelHTML +=
              '<br/><span style="color:' +
              colors[i] +
              '">\u25CF</span><span class="plotline-log">' +
              point.series.name +
              ': </span><span class="plotline-value">' +
              point.y +
              "</span>";
          });

          return labelHTML;
        },
        shared: true
      },
      colors: colors,
      rangeSelector: {
        enabled: false,
        buttons: [
          {
            type: "hour",
            count: 1,
            text: "1 HR"
          },
          {
            type: "day",
            count: 1,
            text: "1 D"
          },
          {
            type: "month",
            count: 1,
            text: "1 MT"
          }
        ]
      },
      plotOptions: {
        series: {
          gapSize: 10,
          showInNavigator: true
        }
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      series: this.chartSeries,
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          type: "datetime",
          ordinal: false,
          minRange: 1,
          min: /*this.timeRange.from,*/ this.minTimestamp,
          max: /*this.timeRange.to,*/ this.maxTimestamp
        }
      ],
      yAxis: this.chartYAxes
    };
  }
}
