import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { concatLatestFrom } from "@ngrx/operators";
import { Action, Store } from "@ngrx/store";
import { cloneDeep as _cloneDeep, isEqual as _isEqual, sortBy as _sortBy } from "lodash";
import { Observable, combineLatest, interval, of } from "rxjs";
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  first,
  groupBy,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { EquipmentSelector } from "../../../browsing/services/equipment.selector";
import { LinkResolver } from "../../../browsing/services/link-resolver";
import { EquipmentActions } from "../../../browsing/store/equipment/equipment.actions";
import {
  REPORT_FILTER_ID,
  RUNTIME_FILTER_ID,
  alreadyHasEventRuntimeFilter,
  getDefaultFilterIdForEntity,
  isEventRuntimeFilter
} from "../../../core/helpers/filter/filter-id.helper";
import { DisplayMode, getDisplayMode } from "../../../core/models/display-mode";
import { Equipment } from "../../../core/models/equipment";
import { CustomFilterDescriptorDto } from "../../../core/models/filter/custom-filter-descriptor";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { QueryFilter } from "../../../core/models/filter/query-filter";
import { GeneralSettingsDto } from "../../../core/models/general-settings";
import { ReportLinkDto } from "../../../core/models/link";
import { ReportTag } from "../../../core/models/report-id";
import { RuntimeSettings } from "../../../core/models/runtime-settings";
import { TimeRange } from "../../../core/models/time-range";
import { TimeRangeConfigurationDto } from "../../../core/models/time-range-configuration";
import {
  EventSubscription,
  WidgetEventType
} from "../../../core/models/widget-eventing/widget-event";
import { DateExpressionParser } from "../../../core/services/filter/date-expression-parser";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { QueryStringService } from "../../../core/services/query-string.service";
import { TimeService } from "../../../core/services/time.service";
import { ErrorCatchingActions } from "../../../core/store/error-catching/error-catching.actions";
import { getConnectorViewId } from "../../../data-connectivity/helpers/connector-view-id.helper";
import { shouldUpdateConnectorViewTitle } from "../../../data-connectivity/helpers/data-connector-view.helper";
import { isSignalBased, isValue } from "../../../data-connectivity/helpers/data-source-type.helper";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { ConnectorContextService } from "../../../data-connectivity/services/connector-context.service";
import { DataService } from "../../../data-connectivity/services/data.service";
import { selectPeriodTypes } from "../../../data-connectivity/store/feature.selector";
import { FeatureAvailabilityService } from "../../../environment/services/feature-availability.service";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { TimeInfoActions } from "../../../environment/store/time-info/time-info.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ActionType } from "../../../meta/models/action-type";
import { EntityId } from "../../../meta/models/entity";
import { CreateReportDialogActions } from "../../../shared/dialogs/actions/create-report-dialog.actions";
import {
  DeepPartial,
  Dictionary,
  Maybe,
  assertIsDefined,
  assignDeep,
  flattenArrayDict,
  isDefined,
  isEmpty,
  isEmptyOrNotDefined,
  isNotDefined,
  toDictionary
} from "../../../ts-utils";
import { debugLog } from "../../../ts-utils/helpers/conditional-logging";
import { combineAllConnectors, isDataSourceNotEmpty } from "../../helpers/connectors.helper";
import { getFullRequestActions } from "../../helpers/full-range-request-actions.helper";
import {
  getComponentsWithGlobalPtype,
  getConnectorsWithGlobalPType
} from "../../helpers/global-period-type.helper";
import { LIVE_MODE_QUERY_INTERVAL_IN_SECONDS } from "../../helpers/heartbeat.helper";
import { getIncrementalRequestActions } from "../../helpers/incremental-request-actions.helper";
import { mergeConnectors } from "../../helpers/merge-connector.helper";
import {
  getAllRequestParams,
  getAllRequestParamsByFilters,
  getRequestParamsForConnectors
} from "../../helpers/request-params-creation.helper";
import { setRuntimeViewOnLoad } from "../../helpers/runtime-view.helper";
import {
  getCustomFiltersFromUrl,
  getNoAnimationFromUrl,
  getPeriodTypeFromUrl,
  getTimeRangeConfigBasedOnUrl
} from "../../helpers/url-params-extraction.helper";
import { DataStatus, ReportConfiguration } from "../../models";
import { ComponentStateDto } from "../../models/component-state";
import { ConnectorRequestParams, DataRequestParams } from "../../models/data-request-params";
import { FILTER_CONFIGURATION_DTO } from "../../models/entity-type.constants";
import { ReportEntities } from "../../models/report-entities";
import { RequestScope } from "../../models/request-scope";
import {
  addImageDataToComponents,
  getImageDataByComponent
} from "../../services/background-image.helper";
import { ConfigurationService } from "../../services/configuration.service";
import { getReportConnectorsWithContext } from "../../services/connector-context.helper";
import { ConnectorResolverService } from "../../services/connector-resolver.service";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../../services/entity-selectors/data-connector.selector";
import { GeneralSettingsSelector } from "../../services/entity-selectors/general-settings.selector";
import { RuntimeSettingsSelector } from "../../services/entity-selectors/runtime-settings.selector";
import { removeRuntimeFilter } from "../../services/global-filter.helper";
import { QueryParamsResolverService } from "../../services/query-params-resolver.service";
import { deserializeReportContent } from "../../services/report-converter.helper";
import { RuntimeViewService } from "../../services/runtime-view.service";
import { ComponentStateActions } from "../component-state/component-state.actions";
import { DataConnectorActions } from "../data-connector/data-connector.actions";
import { HistoryViewDialogActions } from "../dialogs/actions/history-view-dialog.actions";
import { getReportFeature } from "../feature.selector";
import { FilterActions } from "../filter/filter.actions";
import { selectGeneralSettings } from "../general-settings/general-settings.selectors";
import { selectReportId } from "../report-info/report-info.selectors";
import { RuntimeSettingsActions } from "../runtime-settings/runtime-settings.actions";
import { selectRuntimeSettings } from "../runtime-settings/runtime-settings.selectors";
import { ReportContents } from "../state";
import { CommonActions } from "./common.actions";

@Injectable()
export class CommonEffects {
  private loadedReportTag: Maybe<ReportTag> = null;
  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private configurationService: ConfigurationService,
    private dataService: DataService,
    private dataConnectorSelector: DataConnectorSelector,
    private componentStateSelector: ComponentStateSelector,
    private filterSelector: IFilterSelector,
    private translationService: LocalizationService,
    private connectorResolver: ConnectorResolverService,
    private runtimeViewService: RuntimeViewService,
    private linkResolver: LinkResolver,
    private queryStringService: QueryStringService,
    private filterFactory: FilterFactory,
    private generalSettingsSelector: GeneralSettingsSelector,
    private equipmentSelector: EquipmentSelector,
    private connectorContextService: ConnectorContextService,
    private timeService: TimeService,
    private runtimeSettingsSelector: RuntimeSettingsSelector,
    private queryParamsResolverService: QueryParamsResolverService,
    private featureAvailabilityService: FeatureAvailabilityService
  ) {}

  // #region INITIAL LOAD

  //TODO: Move all loading actions into ReportLoadingActions namespace
  loadLanguages$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonActions.loadLanguages),
        map(() => {
          const supportedLanguages = this.translationService.fetchSupportedLanguages();
          this.translationService.setSupportedLanguages(supportedLanguages);
        })
      ),
    { dispatch: false }
  );

  startLoadingReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppStatusActions.startLoadingReport),
      switchMap(({ reportTag, queryParams }) => {
        this.loadedReportTag = reportTag;
        return combineLatest([
          of(reportTag),
          this.configurationService.load(reportTag, queryParams)
        ]);
      }),
      switchMap(([reportTag, report]) => {
        this.updateReportConfigWithUrlParams(report);
        const displayMode: DisplayMode = getDisplayMode();
        const actions: Action[] = [
          CommonActions.resetStore({
            reportTag,
            reportConfiguration: report
          }),
          AppStatusActions.changeDisplayMode({ displayMode: displayMode })
        ];
        const equipmentTree: Maybe<Equipment> = this.equipmentSelector.getEquipmentTree();
        if (isNotDefined(equipmentTree) || !!equipmentTree) {
          actions.unshift(
            EquipmentActions.loadEquipmentModel({
              newRootPath: report.runtimeSettings?.currentRootPath || ""
            })
          );
        }
        return actions;
      }),
      catchError((error) => {
        const errorMessage = this.translationService.get(
          LOCALIZATION_DICTIONARY.snackBarMessages.LoadingReportConfigError
        );
        return of(
          ErrorCatchingActions.catchError({
            messageToDisplay: errorMessage,
            error: error,
            autoClose: true
          })
        );
      })
    )
  );

  updateReportConfigWithUrlParams(report: ReportConfiguration): void {
    if (isNotDefined(report.content)) {
      return;
    }
    const queryParams = this.queryStringService.getParams();
    if (isDefined(report.content?.filters.entities[RUNTIME_FILTER_ID])) {
      const runtimeFilter = report.content?.filters.entities[RUNTIME_FILTER_ID];
      const convertedParamsFromUrl = getFilterParamsFromUrl(
        runtimeFilter,
        report.content?.generalSettings?.customFilterDeclarations,
        queryParams,
        this.filterFactory
      );
      report.content.filters.entities[RUNTIME_FILTER_ID] = assignDeep(
        runtimeFilter,
        convertedParamsFromUrl
      );
    }
    const pTypeFromUrl = getPeriodTypeFromUrl(queryParams, getAllPeriodTypeKeys(this.store$));

    const queryRootPath = this.queryStringService.getRootPath();
    const currentRootPath = !isEmptyOrNotDefined(queryRootPath)
      ? queryRootPath
      : report.content.generalSettings.rootPath;

    const noAnimation = getNoAnimationFromUrl(queryParams);

    report.runtimeSettings = {
      currentRootPath,
      periodType: pTypeFromUrl ?? report.content.generalSettings.periodType,
      noAnimation,
      ...report.runtimeSettings
    } as RuntimeSettings;
  }

  resetStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.resetStore),
      map(({ reportTag, reportConfiguration }) => {
        if (isNotDefined(reportConfiguration?.content) || !this.isLoadedReport(reportTag)) {
          return CommonActions.doNothing();
        }
        const reportEntities = convertStateToReportEntities(
          reportConfiguration.content,
          this.runtimeViewService
        );
        return CommonActions.resolveBackgroundImages({
          reportTag: reportTag,
          reportEntities
        });
      })
    )
  );

  resolvedBackgroundImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.resolveBackgroundImages),
      switchMap(({ reportTag, reportEntities }) => {
        const imagesByComponents$ = this.isLoadedReport(reportTag)
          ? getImageDataByComponent(reportEntities.componentStates, this.dataService)
          : of(null);
        return combineLatest([of(reportTag), of(reportEntities), imagesByComponents$]);
      }),
      map(([reportTag, reportEntities, imagesByComponents]) => {
        if (isNotDefined(imagesByComponents)) {
          return CommonActions.doNothing();
        }
        const reportEntitiesWithImageData = addImageDataToComponents(
          reportEntities,
          imagesByComponents
        );
        return CommonActions.initDynamicConnectors({
          reportTag: reportTag,
          reportEntities: reportEntitiesWithImageData
        });
      })
    )
  );

  initDynamicConnectors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.initDynamicConnectors),
      tap((x) => debugLog("initDynamicConnectors$", x)),
      switchMap(({ reportTag, reportEntities }) => {
        const currentRootPath = this.runtimeSettingsSelector.getCurrentRootPath();
        if (this.isLoadedReport(reportTag)) {
          const { query$: resolvedReportEntities$ } = resolveAndWrapAllConnectors(
            reportEntities,
            currentRootPath,
            this.connectorResolver
          );

          return resolvedReportEntities$.pipe(
            map((newReportEntities) => {
              return DataConnectorActions.setContextOnLoad({
                reportTag: reportTag,
                reportEntities: newReportEntities
              });
            }),
            tap((x) => debugLog("initDynamicConnectors$ sending ", x))
          );
        } else {
          return [];
        }
      })
    )
  );

  $setContextOnLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.setContextOnLoad),
      tap((x) => debugLog("$setContextOnLoad", x)),
      map(({ reportTag, reportEntities }) => {
        if (!this.isLoadedReport(reportTag)) {
          return null;
        }
        const connectorsWithContext = getReportConnectorsWithContext(
          reportEntities,
          this.connectorContextService
        );

        return {
          ...reportEntities,
          dataConnectors: this.updateConnectorsWithTitle(
            connectorsWithContext,
            reportEntities.dataConnectorViews
          )
        };
      }),
      map((reportEntities: Maybe<ReportEntities>) => {
        if (isNotDefined(reportEntities)) {
          return CommonActions.doNothing();
        }
        return CommonActions.upsertEntitiesOnLoad({ reportEntities });
      })
    )
  );

  updateConnectorsWithTitle(
    connectors: DataConnectorDto[],
    connectorsView: DataConnectorViewDto[]
  ): DataConnectorDto[] {
    return connectors.map((connector) => {
      if (isDefined(connector) && connector.isDynamicallyCreated) {
        const connectorView: Maybe<DataConnectorViewDto> = connectorsView.find(
          (connectorView: DataConnectorViewDto) =>
            connectorView.id === getConnectorViewId(connector.id)
        );

        if (shouldUpdateConnectorViewTitle(connectorView)) {
          connector = { ...connector, title: connectorView.title };
        }
      }
      return connector;
    });
  }

  upsertEntitiesOnLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.upsertEntitiesOnLoad),
      switchMap(({ reportEntities }) => {
        return [
          AppStatusActions.finishLoadingReport({ reportEntities }),
          CommonActions.getFullRangeSignalData({ connectors: reportEntities.dataConnectors })
        ];
      })
    )
  );

  finishLoadingReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppStatusActions.finishLoadingReport),
      switchMap(({ reportEntities }) => {
        const actions: Action[] = [ComponentStateActions.calculateRuntimeViewProps()];
        return this.processAditionalUrlParams(reportEntities.componentStates, actions);
      })
    )
  );

  processAditionalUrlParams(componentStates: ComponentStateDto[], actions: Action[]): Action[] {
    const componentIdToExpand = this.queryStringService.getComponentIdToExpand();
    if (isEmptyOrNotDefined(componentIdToExpand)) {
      return actions;
    }

    const component = componentStates.find(
      (componentState) => componentState.id === componentIdToExpand
    );
    if (isDefined(component)) {
      actions.push(HistoryViewDialogActions.openHistoryViewDialog({ component }));
    }

    return actions;
  }

  // #endregion

  // #region HEARTBEAT

  heartbeat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.upsertEntitiesOnLoad),
      switchMap(() => {
        if (this.featureAvailabilityService.allowLiveModeUpdate) {
          return interval(LIVE_MODE_QUERY_INTERVAL_IN_SECONDS * 1000).pipe(
            switchMap((intervalNumber: number) => {
              const actions: Action[] = [];
              const liveModeFiltersDic = this.filterSelector.getAllInLiveMode();
              const liveModeFilters = Object.values(liveModeFiltersDic);
              if (liveModeFilters.length > 0) {
                // FIXME LiveModeUpdate is dispatched even if no report is opened
                actions.push(
                  CommonActions.liveModeUpdate({ liveModeFilters, heartbeatNumber: intervalNumber })
                );
              }
              actions.push(TimeInfoActions.updateCurrentTime({ now: new Date() }));

              return actions;
            })
          );
        } else {
          return [];
        }
      })
    )
  );

  liveModeUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.liveModeUpdate),
      switchMap(({ liveModeFilters, heartbeatNumber }) => {
        const requestParams = getAllRequestParamsByFilters(
          liveModeFilters,
          this.filterFactory,
          this.componentStateSelector,
          this.dataConnectorSelector
        );
        const currentTime = this.timeService.currentTime;
        return getIncrementalRequestActions(
          requestParams,
          heartbeatNumber,
          currentTime,
          this.queryParamsResolverService
        );
      })
    )
  );

  // #endregion

  // #region FULL SIGNAL DATA FETCH

  getFullRangeSignalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.getFullRangeSignalData),
      concatMap(({ connectors, timeRange }) => {
        if (connectors.length === 0) {
          return [CommonActions.doNothing()];
        }

        const connectorsToRequest = connectors.filter(
          (connector) => isSignalBased(connector.dataSource) || isValue(connector.dataSource)
        );
        let requestParams: ConnectorRequestParams[] = getRequestParamsForConnectors(
          connectorsToRequest,
          this.componentStateSelector,
          this.filterFactory
        );
        let isAdditionalDataRequest: boolean = false;
        if (isDefined(timeRange)) {
          isAdditionalDataRequest = true;
          requestParams = updateTimeRangeInRequestParams(requestParams, timeRange);
        }

        if (requestParams.length > 0) {
          return getFullRequestActions(requestParams, isAdditionalDataRequest);
        } else {
          return [];
        }
      })
    )
  );

  getFullFilterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.getFullRangeFilterData),
      groupBy(({ connectorsByComponent: connectors }) => getUniqueSetOfConnectors(connectors)),
      mergeMap((group) =>
        group.pipe(
          concatMap(({ connectorsByComponent, queryFilter, isAdditionalDataRequest }) => {
            const allConnectors = flattenArrayDict(connectorsByComponent);
            const setLoadingStatusDict = toDictionary(
              allConnectors,
              (conn) => conn.id,
              (_conn) => getWaitingDataStatus(queryFilter.id)
            );
            const setLoadingStatus = DataConnectorActions.updateDataStatusMany({
              dataConnectorStatusDic: setLoadingStatusDict,
              dataConnectorQueryStatusDic: this.getDataConnectorQueryStatus(connectorsByComponent)
            });
            return this.dataService
              .get(queryFilter, connectorsByComponent, RequestScope.FullRange)
              .pipe(
                map((connectorData) => {
                  const connectorDict = combineAllConnectors(connectorData, connectorsByComponent);
                  return isAdditionalDataRequest
                    ? DataConnectorActions.appendAdditionalDataPoints({ connectorDict })
                    : DataConnectorActions.addOrReplaceData({
                        connectorDict
                      });
                }),
                take(1), // dataService.get does not end the observable after returning a response
                // todo: simplify this effect. Add test that dataService.get is cancelled if same DataConnector is re-queried
                startWith(setLoadingStatus),
                tap((x) => debugLog("getFullFilterData$ sending", x)),
                takeUntil(
                  this.actions$.pipe(
                    ofType(CommonActions.getFullRangeFilterData),
                    filter((newQueryParams) =>
                      isSameFullRangeFilterQuery(
                        { connectorsByComponent, queryFilter },
                        newQueryParams
                      )
                    )
                  )
                )
              );
          })
        )
      )
    )
  );

  private getDataConnectorQueryStatus(
    connectorsByComponent: Dictionary<DataConnectorDto[]>
  ): Dictionary<DataStatus> {
    const componentIds: string[] = Object.keys(connectorsByComponent);
    const componentsWithEquipmentQuery: ComponentStateDto[] = this.componentStateSelector
      .getManyById(componentIds)
      .filter(
        (component: Maybe<ComponentStateDto>) =>
          isDefined(component) && isDataSourceNotEmpty(component.dataConnectorQuery)
      );
    if (isEmpty(componentsWithEquipmentQuery)) {
      return {};
    }

    return toDictionary(
      componentsWithEquipmentQuery,
      (component) => component.id,
      (component) =>
        isEmptyOrNotDefined(connectorsByComponent[component.id])
          ? DataStatus.NoDataReceived
          : DataStatus.DataReceived
    );
  }

  // #endregion

  // #region INCREMENTAL DATA FETCH

  getIncrementalFilterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.getIncrementalFilterData),
      groupBy(({ connectorsByComponent }) => getUniqueSetOfConnectors(connectorsByComponent)),
      mergeMap((group) =>
        group.pipe(
          concatMap(({ connectorsByComponent, queryFilter }) => {
            const connectorData$ = this.dataService.get(
              queryFilter,
              connectorsByComponent,
              RequestScope.Incremental
            );
            return combineLatest([connectorData$, of(queryFilter.timeRange.from)]);
          })
        )
      ),
      map(([connectorDict, startCutoffDate]) =>
        DataConnectorActions.updateData({ connectorDict, startCutoffDate })
      )
    )
  );

  //#endregion

  //#region UPSERT ENTITIES
  upsertEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.upsertEntities),
      mergeMap(({ reportEntities }) => [
        CommonActions.getFullRangeSignalData({ connectors: reportEntities.dataConnectors }),
        DataConnectorActions.resolveFullGenericQueries({
          componentsWithQuery: reportEntities.componentStates,
          queryFilter: null
        })
      ])
    )
  );

  //#endregion

  //#region UPSERT FROM SNAPSHOT
  upsertFromSnapshot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.upsertFromSnapshot),
      concatMap(({ reportSnapshot }) => {
        const { entities, context } = reportSnapshot;
        const { actionType } = context;
        const filters: FilterConfigurationDto[] = this.mergeSnapshotWithCurrentFilters(
          _cloneDeep(entities.filters),
          actionType
        );
        const generalSettings: GeneralSettingsDto = this.mergeSnapshotWithCurrentGeneralSettings(
          entities.generalSettings
        );
        const runtimeSettings: RuntimeSettings = this.mergeSnapshotWithCurrentRuntimeSettings(
          entities?.generalSettings?.periodType,
          actionType
        );
        const newEntities: ReportEntities = {
          ...entities,
          filters,
          generalSettings,
          runtimeSettings
        };
        return [
          CommonActions.replaceAll({
            entities: newEntities
          }),
          CommonActions.getFullRangeSignalData({
            connectors: entities.dataConnectors
          }),
          DataConnectorActions.resolveFullGenericQueries({
            componentsWithQuery: entities.componentStates,
            queryFilter: null
          }),
          ComponentStateActions.calculateRuntimeViewProps()
        ];
      })
    )
  );

  private mergeSnapshotWithCurrentFilters(
    snapshotFilters: FilterConfigurationDto[],
    actionType: Maybe<ActionType>
  ): FilterConfigurationDto[] {
    const currentRuntimeFilter: FilterConfigurationDto = this.filterSelector.getRuntime();
    if (actionType === ActionType.GlobalFilterChanged) {
      return getFiltersOnGlobalFilterChanged(snapshotFilters, currentRuntimeFilter);
    } else {
      return replaceSnapshotRuntimeFilter(snapshotFilters, currentRuntimeFilter);
    }
  }

  private mergeSnapshotWithCurrentGeneralSettings(
    snapshotGeneralSettings: Maybe<Partial<GeneralSettingsDto>>
  ): GeneralSettingsDto {
    const currentGeneralSettings = this.generalSettingsSelector.getGeneralSettings();
    return assignDeep(currentGeneralSettings, snapshotGeneralSettings);
  }

  private mergeSnapshotWithCurrentRuntimeSettings(
    snapshotPType: string,
    actionType: Maybe<ActionType>
  ): RuntimeSettings {
    const currentRuntimeSettings: RuntimeSettings =
      this.runtimeSettingsSelector.getRuntimeSettings();
    return actionType === ActionType.PeriodTypeChanged
      ? {
          ...currentRuntimeSettings,
          periodType: snapshotPType
        }
      : currentRuntimeSettings;
  }

  //#endregion

  // #region CREATE/SAVE REPORT

  createReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateReportDialogActions.onCreateReportDialogClosed),
      map(({ reportCreationInfo }) => {
        if (isNotDefined(reportCreationInfo)) {
          return CommonActions.doNothing();
        }
        const typedReportContents: ReportContents = deserializeReportContent(
          this.configurationService.getContentForNewReport(reportCreationInfo)
        );
        return CommonActions.startCreatingReport({
          reportCreationInfo: reportCreationInfo,
          state: typedReportContents
        });
      })
    )
  );

  startReportCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommonActions.startCreatingReport),
        withLatestFrom(this.store$.select(selectReportId)),
        switchMap(([{ reportCreationInfo, state }, currentReportId]) => {
          return this.configurationService.create(reportCreationInfo, state, currentReportId);
        }),
        map((reportConfiguration: Maybe<ReportConfiguration>) => {
          if (isDefined(reportConfiguration)) {
            this.linkResolver.navigateToNewReport(
              new ReportLinkDto({
                info: {
                  reportId: reportConfiguration.id,
                  reportName: reportConfiguration.name ?? ""
                }
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  saveReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.saveReport),
      withLatestFrom(this.store$.select(getReportFeature)),
      map(([{ reportId, hideConfirmation }, reportFeature]) => {
        const typedReportContents = deserializeReportContent(reportFeature);
        removeRuntimeFilter(typedReportContents);
        return CommonActions.startSavingReport({
          reportId,
          state: typedReportContents,
          hideConfirmation: hideConfirmation ?? false
        });
      })
    )
  );

  startConfigurationSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.startSavingReport),
      switchMap(({ reportId, state, hideConfirmation }) =>
        this.configurationService.save(reportId, state, hideConfirmation)
      ),
      switchMap((savedReport) => {
        const actions: Action[] = [CommonActions.finishSavingReport()];
        if (isDefined(savedReport)) {
          actions.push(CommonActions.saveReportSuccess());
        }
        return actions;
      })
    )
  );
  // #endregion

  ///#region ENTER EDIT MODE

  exitPreviewMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppStatusActions.exitPreviewMode),
      withLatestFrom(
        this.store$.select(selectGeneralSettings),
        this.store$.select(selectRuntimeSettings)
      ),
      concatMap(
        ([_, generalSettings, runtimeSettings]: [any, GeneralSettingsDto, RuntimeSettings]) => {
          const actions$: Action[] = [ComponentStateActions.calculateRuntimeViewProps()];
          const reportFilter: FilterConfigurationDto = this.filterSelector.getGlobal();
          const runtimeFilter: FilterConfigurationDto = this.filterSelector.getRuntime();
          const queryRootPath: string = this.queryStringService.getRootPath();
          if (
            shouldDiscardRuntimeSettings(
              queryRootPath,
              runtimeSettings,
              generalSettings,
              reportFilter,
              runtimeFilter
            )
          ) {
            actions$.push(
              CommonActions.discardRuntimeParameters({
                reportSettings: {
                  periodType: generalSettings.periodType,
                  rootPath: isEmptyOrNotDefined(queryRootPath)
                    ? generalSettings.rootPath
                    : queryRootPath
                }
              })
            );
          }
          return actions$;
        }
      )
    )
  );

  discardRuntimeParameters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.discardRuntimeParameters),
      concatMap(() => {
        const dataConnectors = this.dataConnectorSelector.getAllAsArray();
        const componentStates = this.componentStateSelector.getAllAsArray();
        return [
          CommonActions.getFullRangeSignalData({
            connectors: dataConnectors
          }),
          DataConnectorActions.resolveFullGenericQueries({
            componentsWithQuery: componentStates,
            queryFilter: null
          })
        ];
      })
    )
  );
  //#endregion

  //#region GLOBAL PERIOD TYPE CHANGE
  setGlobalPeriodType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RuntimeSettingsActions.setPeriodType),
      switchMap(() => {
        const components = this.componentStateSelector.getAllAsArray();
        const componentsWithGlobalPType = getComponentsWithGlobalPtype(components);
        const signalBasedConnectors = getConnectorsWithGlobalPType(
          componentsWithGlobalPType,
          this.dataConnectorSelector
        );
        const requestParams: DataRequestParams[] = getAllRequestParams(
          componentsWithGlobalPType,
          signalBasedConnectors,
          this.componentStateSelector,
          this.filterFactory
        );
        return getFullRequestActions(requestParams);
      })
    )
  );
  //#endregion

  //#region QUERY STRING CHANGE
  $setRootPathOnQueryStringChange = createEffect(() =>
    this.queryStringService.rootPathChanged.pipe(
      distinctUntilChanged(),
      switchMap((newRootPath) => {
        return [
          EquipmentActions.loadEquipmentModel({ newRootPath }),
          RuntimeSettingsActions.setCurrentRootPath({ currentRootPath: newRootPath })
        ];
      })
    )
  );

  $onUrlChange = createEffect(() =>
    this.queryStringService.filterParamsChanged.pipe(
      withLatestFrom(this.generalSettingsSelector.selectCustomFilterDescriptors()),
      switchMap(([_filterParamsChanged, customFilterDescriptors]) => {
        return this.getUpdateActionsOnUrlChange(customFilterDescriptors);
      })
    )
  );

  getUpdateActionsOnUrlChange(customFilterDescriptors: CustomFilterDescriptorDto[]): Action[] {
    const actions = [];
    const queryParams = this.queryStringService.getParams();
    const runtimeFilter = this.filterSelector.getRuntime();
    const filterPartialUpdate = getFilterParamsFromUrl(
      runtimeFilter,
      customFilterDescriptors,
      queryParams,
      this.filterFactory
    );
    actions.push(
      FilterActions.upsertOne({
        filterUpdate: {
          id: RUNTIME_FILTER_ID,
          changes: filterPartialUpdate
        }
      })
    );
    const pTypeFromUrl = getPeriodTypeFromUrl(queryParams, getAllPeriodTypeKeys(this.store$));
    if (isDefined(pTypeFromUrl)) {
      actions.push(RuntimeSettingsActions.setPeriodType({ pType: pTypeFromUrl }));
    }
    const noAnimation = getNoAnimationFromUrl(queryParams) ?? false;
    actions.push(RuntimeSettingsActions.setNoAnimation({ noAnimation }));
    return actions;
  }

  //#endregion
  private isLoadedReport(reportTag: ReportTag): boolean {
    return isDefined(this.loadedReportTag) && this.loadedReportTag === reportTag;
  }

  distributeEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommonActions.raiseWidgetSelectTimeRangeEvent,
        CommonActions.raiseWidgetSelectEquipmentPathEvent
      ),
      // tap((x) => console.log("distributeEvent$", x)),
      concatLatestFrom(() => this.componentStateSelector.selectAllComponentStates()),
      mergeMap(
        ([raiseEventAction, allComponentStates]: [
          CommonActions.RaiseWidgetEventActions,
          Dictionary<Maybe<ComponentStateDto>>
        ]) => {
          assertIsDefined(allComponentStates);
          return Object.values(allComponentStates)
            .filter(isDefined)
            .filter((component: ComponentStateDto) => shouldRaiseEvent(raiseEventAction, component))
            .flatMap((component: ComponentStateDto) =>
              handleWidgetEvent(raiseEventAction, component)
            );
        }
      )
    )
  );

  raiseWidgetResetTimeRangeEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonActions.raiseWidgetResetTimeRangeEvent),
      concatMap(({ source }) => {
        const componentIds: EntityId[] = [source].concat(
          this.componentStateSelector.getLinkedComponents(source)
        );
        const componentUpdates: Update<ComponentStateDto>[] = getComponentUpdatesOnResetEvent(
          componentIds,
          this.filterSelector
        );
        const eventRuntimeFilterIds = componentIds.map((componentId) =>
          getDefaultFilterIdForEntity(componentId, true)
        );
        const connectors: DataConnectorDto[] =
          this.dataConnectorSelector.getForComponents(componentIds);
        return [
          FilterActions.deleteMany({
            filterIds: eventRuntimeFilterIds
          }),
          ComponentStateActions.updateMany({ componentUpdates }),
          CommonActions.getFullRangeSignalData({ connectors })
        ];
      })
    )
  );
}

function getComponentUpdatesOnResetEvent(
  componentIds: EntityId[],
  filterSelector: IFilterSelector
): Update<ComponentStateDto>[] {
  return componentIds.reduce((acc: Update<ComponentStateDto>[], componentId: EntityId) => {
    const componentFilter = filterSelector.getById(getDefaultFilterIdForEntity(componentId));
    return acc.concat({
      id: componentId.toString(),
      changes: { filterId: componentFilter?.id ?? null }
    });
  }, []);
}

function shouldRaiseEvent(
  raiseEventAction: CommonActions.RaiseWidgetEventActions,
  component: ComponentStateDto
): boolean {
  return (
    isSourceComponent(raiseEventAction.source, component.id) ||
    isSubscribed(raiseEventAction, component.id, component.view.subscribedEvents ?? [])
  );
}

function isSourceComponent(sourceId: EntityId, componentId: EntityId): boolean {
  return sourceId === componentId;
}

function handleWidgetEvent(
  raiseEventAction: CommonActions.RaiseWidgetEventActions,
  target: ComponentStateDto
): Action[] {
  if (raiseEventAction.raisedEvent.type === WidgetEventType.SelectTimeRangeEvent) {
    const dateParser = new DateExpressionParser();
    const runtimeFilterId = getDefaultFilterIdForEntity(target.id, true);
    const alreadyHasRuntimeFilter = alreadyHasEventRuntimeFilter(target.id, target.filterId);
    if (
      isDefined(raiseEventAction.raisedEvent.start) &&
      isDefined(raiseEventAction.raisedEvent.end)
    ) {
      const filter: FilterConfigurationDto = {
        typeName: FILTER_CONFIGURATION_DTO,
        id: runtimeFilterId,
        isRuntimeOnly: true,
        sourceFilterId: alreadyHasRuntimeFilter ? undefined : target.filterId ?? RUNTIME_FILTER_ID,
        timeRange: new TimeRangeConfigurationDto({
          fromExpression: dateParser.createExpressionFromDate(
            raiseEventAction.raisedEvent.start,
            false
          ),
          toExpression: dateParser.createExpressionFromDate(raiseEventAction.raisedEvent.end, false)
        }),
        customFilters: {}
      };
      return [
        FilterActions.reactToSelectTimeRange({
          filterUpdate: {
            id: filter.id as string,
            changes: filter
          },
          componentId: target.id
        })
      ];
    } else {
      return [FilterActions.deleteOne({ filterId: runtimeFilterId })];
    }
  }
  return [];
}

function isSubscribed(
  raiseEventAction: CommonActions.RaiseWidgetEventActions,
  componentId: EntityId,
  subscriptions: EventSubscription[]
): boolean {
  const matchingSubscription = subscriptions.find(
    (subscription) =>
      subscription.eventType === raiseEventAction.raisedEvent.type &&
      (isEmptyOrNotDefined(subscription.sourceWidgetId)
        ? raiseEventAction.source !== componentId
        : raiseEventAction.source === subscription.sourceWidgetId)
  );
  return matchingSubscription != null;
}

function convertStateToReportEntities(
  reportState: ReportContents,
  runtimeViewService: RuntimeViewService
): ReportEntities {
  const updatedDict = setRuntimeViewOnLoad(
    _cloneDeep(reportState.componentStates.entities),
    runtimeViewService
  );
  const componentStates: ComponentStateDto[] = Object.values(updatedDict)
    .filter(isDefined)
    .map((component) => new ComponentStateDto(component));

  const dataConnectors: DataConnectorDto[] = Object.values(reportState.dataConnectors.entities)
    .filter(isDefined)
    .map((connector) => new DataConnectorDto(connector));

  const filters: FilterConfigurationDto[] = Object.values(reportState.filters.entities)
    .filter(isDefined)
    .map((filter) => new FilterConfigurationDto(filter));

  const connectorViews: DataConnectorViewDto[] = Object.values(
    reportState.dataConnectorViews.entities
  )
    .filter(isDefined)
    .map((connectorView) => new DataConnectorViewDto(connectorView));

  const generalSettings: GeneralSettingsDto = new GeneralSettingsDto(reportState.generalSettings);

  const reportEntities: ReportEntities = {
    componentStates: componentStates,
    dataConnectors: dataConnectors,
    filters: filters,
    dataConnectorViews: connectorViews,
    generalSettings
  };
  return reportEntities;
}

function resolveAndWrapAllConnectors(
  reportEntities: ReportEntities,
  rootPath: string,
  connectorResolver: ConnectorResolverService
): { setLoadingStatus: Action; query$: Observable<ReportEntities> } {
  const filterConfigs = reportEntities.filters;
  const resolvingEquipmentConnectors = connectorResolver.resolveEquipmentConnectors(
    reportEntities.dataConnectors,
    rootPath
  );
  const resolvingDynamicEquipmentConnectors = connectorResolver.resolveEquipmentQueries(
    reportEntities.componentStates,
    rootPath
  );
  const resolvingDynamicGenericConnectors = connectorResolver.resolveGenericQueries(
    reportEntities.componentStates,
    filterConfigs,
    RequestScope.FullRange
  );

  const setLoadingStatus = DataConnectorActions.updateDataStatusMany({
    dataConnectorStatusDic: toDictionary(
      resolvingEquipmentConnectors.beingQueried,
      (conn) => conn.id,
      (_conn) => DataStatus.WaitingForData
    ),
    dataConnectorQueryStatusDic: {}
  });

  const query$ = combineLatest([
    tapToConsole(resolvingEquipmentConnectors.queries$, "resolvingEquipmentConnectors"),
    tapToConsole(
      resolvingDynamicEquipmentConnectors.queries$,
      "resolvingDynamicEquipmentConnectors"
    ),
    tapToConsole(resolvingDynamicGenericConnectors.queries$, "resolvingDynamicGenericConnectors")
  ]).pipe(
    map(([equipmentConnectors, dynamicEquipmentConnectorsDict, dynamicGenericConnectorsDict]) =>
      mergeConnectors(
        reportEntities,
        equipmentConnectors,
        dynamicEquipmentConnectorsDict,
        dynamicGenericConnectorsDict
      )
    ),
    tap((x) => {
      debugLog("resolveAndWrapAllConnectors merged", x);
    })
  );
  return {
    setLoadingStatus,
    query$
  };
}

function tapToConsole<T>(obs$: Observable<T>, msg: string): Observable<T> {
  return obs$.pipe(tap((x) => debugLog(msg, x)));
}

function updateTimeRangeInRequestParams(
  requestParams: ConnectorRequestParams[],
  newTimeRange: Partial<TimeRange>
): ConnectorRequestParams[] {
  const { from: newStartTime } = newTimeRange;
  return requestParams.map((params) => {
    if (shouldSetNewStartTime(params.queryFilter.timeRange, newStartTime)) {
      params.queryFilter.timeRange.from = newStartTime;
    }
    return params;
  });
}

function shouldSetNewStartTime(timeRange: TimeRange, newStartTime: Maybe<Date>): boolean {
  const { from, to } = timeRange;
  return (
    isDefined(newStartTime) && ((isNotDefined(to) && from < newStartTime) || to > newStartTime)
  );
}

function getUniqueSetOfConnectors(connectorsDict: Dictionary<DataConnectorDto[]>): string {
  return Object.values(connectorsDict)
    .flat()
    .map((connector) => connector.id)
    .join(" ");
}

function getFilterParamsFromUrl(
  runtimeFilter: Maybe<FilterConfigurationDto>,
  customFilterDescriptors: Maybe<CustomFilterDescriptorDto[]>,
  queryParams: URLSearchParams,
  filterFactory: FilterFactory
): DeepPartial<FilterConfigurationDto> {
  const runtimeFilterUpdate: DeepPartial<FilterConfigurationDto> = {};
  if (isNotDefined(runtimeFilter)) {
    return runtimeFilterUpdate;
  }
  const customFiltersFromUrl = getCustomFiltersFromUrl(customFilterDescriptors, queryParams);
  runtimeFilterUpdate.customFilters = customFiltersFromUrl;
  const timeRangeFromUrl = getTimeRangeConfigBasedOnUrl(queryParams, runtimeFilter, filterFactory);
  if (isDefined(timeRangeFromUrl)) {
    runtimeFilterUpdate.timeRange = timeRangeFromUrl;
  }
  return runtimeFilterUpdate;
}

//TODO: Create injectable service for this slice if it needs to be used somewhere else too.
function getAllPeriodTypeKeys(store$: Store<any>): string[] {
  let periodTypeKeys: string[] = [];
  store$
    .select(selectPeriodTypes)
    .pipe(first())
    .subscribe((periodTypes) => (periodTypeKeys = Object.keys(periodTypes)));
  return periodTypeKeys;
}

function getWaitingDataStatus(filterId: EntityId): DataStatus {
  return isEventRuntimeFilter(filterId)
    ? DataStatus.WaitingForMorePreciseData
    : DataStatus.WaitingForData;
}

function isSameFullRangeFilterQuery(
  existingQueryParams: {
    connectorsByComponent: Dictionary<DataConnectorDto[]>;
    queryFilter: QueryFilter;
  },
  newQueryParams: {
    connectorsByComponent: Dictionary<DataConnectorDto[]>;
    queryFilter: QueryFilter;
  }
): boolean {
  return (
    existingQueryParams.queryFilter.id === newQueryParams.queryFilter.id &&
    _isEqual(
      _sortBy(
        Object.values(existingQueryParams.connectorsByComponent)
          .flat()
          .map((connector) => connector.id)
      ),
      _sortBy(
        Object.values(newQueryParams.connectorsByComponent)
          .flat()
          .map((connector) => connector.id)
      )
    )
  );
}

function getFiltersOnGlobalFilterChanged(
  snapshotFilters: FilterConfigurationDto[],
  currentRuntimeFilter: FilterConfigurationDto
): FilterConfigurationDto[] {
  const snapshotGlobalFilter = _cloneDeep(
    snapshotFilters.find((filter) => filter.id === REPORT_FILTER_ID)
  );
  if (isNotDefined(snapshotGlobalFilter)) {
    return replaceSnapshotRuntimeFilter(snapshotFilters, currentRuntimeFilter);
  }
  const newRuntimeFilter: FilterConfigurationDto = {
    ...currentRuntimeFilter,
    customFilters: snapshotGlobalFilter.customFilters,
    timeRange: snapshotGlobalFilter.timeRange
  };
  return replaceSnapshotRuntimeFilter(snapshotFilters, newRuntimeFilter);
}

function replaceSnapshotRuntimeFilter(
  snapshotFilters: FilterConfigurationDto[],
  newRuntimeFilter: FilterConfigurationDto
): FilterConfigurationDto[] {
  const snapshotRuntimeFilter = snapshotFilters.find((filter) => filter.id === RUNTIME_FILTER_ID);
  if (isNotDefined(snapshotRuntimeFilter)) {
    return snapshotFilters;
  }
  Object.assign(snapshotRuntimeFilter, newRuntimeFilter);
  return snapshotFilters;
}

function shouldDiscardRuntimeSettings(
  queryRootPath: string,
  runtimeSettings: RuntimeSettings,
  generalSettings: GeneralSettingsDto,
  reportFilter: FilterConfigurationDto,
  runtimeFilter: FilterConfigurationDto
): boolean {
  return (
    (runtimeSettings.currentRootPath !== queryRootPath &&
      generalSettings.rootPath !== runtimeSettings.currentRootPath) ||
    generalSettings.periodType !== runtimeSettings.periodType ||
    !_isEqual(reportFilter.customFilters, runtimeFilter.customFilters) ||
    !_isEqual(reportFilter.timeRange, runtimeFilter.timeRange)
  );
}
