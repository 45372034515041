import { DataTransferObject } from "../../core/models/data-transfer-object";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { DynamicallyVisible } from "../../meta/decorators/dynamically-visible.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { OnPropertyChange } from "../../meta/decorators/on-property-change.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyInfo } from "../../meta/models/property-info";
import { selectDisplayStrategyByComponentId } from "../helpers/dynamically-visible-properties.helper";
import { createLimitsMode, getLimitsDisplayModes } from "../helpers/limit-modes.helper";
import {
  GaugeSingleValueDisplayStrategies,
  SingleValueDisplayStrategy
} from "./display-strategies/single-value-display-strategies";

export type LimitsDisplayMode =
  | "LimitsModeDto"
  | "PrimaryLimitsModeDto"
  | "OnlyLimitColorsModeDto"
  | "NoLimitsModeDto";

export interface LimitsDisplayOptions {
  showLimitValues?: boolean;
  showLimitsAsBars?: boolean;
}

export const LIMITS_MODE = "LimitsModeDto";
export const PRIMARY_LIMITS_MODE = "PrimaryLimitsModeDto";
export const ONLY_LIMIT_COLORS_MODE = "OnlyLimitColorsModeDto";
export const NO_LIMITS_MODE = "NoLimitsModeDto";

@EditableType({ fullName: LIMITS_MODE, title: "limits-mode-dto", virtual: true })
export abstract class LimitsModeDto implements DataTransferObject {
  @ConfigurableEnum({
    enumSource: getLimitsDisplayModes,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LimitsDisplayMode
  })
  @OnPropertyChange<any, LimitsModeDto, LimitsDisplayMode>(onLimitsDisplayModeChange)
  @Serializable(PRIMARY_LIMITS_MODE)
  typeName: LimitsDisplayMode = LIMITS_MODE;
}

@EditableType({ fullName: PRIMARY_LIMITS_MODE, title: "primary-limits-mode-dto" })
export class PrimaryLimitsModeDto extends LimitsModeDto {
  typeName: "PrimaryLimitsModeDto" = PRIMARY_LIMITS_MODE;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowLimitValues,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, GaugeSingleValueDisplayStrategies)
  showLimitValues!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowLimitsAsBars,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [
    SingleValueDisplayStrategy.HorizontalGauge,
    SingleValueDisplayStrategy.VerticalGauge
  ])
  showLimitsAsBars!: boolean;

  constructor(primaryLimitsModeDto: Partial<PrimaryLimitsModeDto>) {
    super();
    this.showLimitValues = primaryLimitsModeDto.showLimitValues ?? true;
    this.showLimitsAsBars = primaryLimitsModeDto.showLimitsAsBars ?? true;
  }
}

@EditableType({ fullName: ONLY_LIMIT_COLORS_MODE, title: "only-limit-colors-mode-dto" })
export class OnlyLimitColorsModeDto extends LimitsModeDto {
  typeName: "OnlyLimitColorsModeDto" = ONLY_LIMIT_COLORS_MODE;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowLimitValues,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, GaugeSingleValueDisplayStrategies)
  showLimitValues!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowLimitsAsBars,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [
    SingleValueDisplayStrategy.HorizontalGauge,
    SingleValueDisplayStrategy.VerticalGauge
  ])
  showLimitsAsBars!: boolean;

  constructor(onlyLimitColorsModeDto: Partial<OnlyLimitColorsModeDto>) {
    super();
    this.showLimitValues = onlyLimitColorsModeDto.showLimitValues ?? false;
    this.showLimitsAsBars = onlyLimitColorsModeDto.showLimitsAsBars ?? false;
  }
}

@EditableType({ fullName: NO_LIMITS_MODE, title: "no-limits-mode-dto" })
export class NoLimitsModeDto extends LimitsModeDto {
  typeName: "NoLimitsModeDto" = NO_LIMITS_MODE;
}

function onLimitsDisplayModeChange(
  _context: any,
  _owner: LimitsModeDto,
  propertyChange: PropertyInfo<LimitsDisplayMode>
): LimitsModeDto {
  return createLimitsMode({ typeName: propertyChange.value });
}
