import { KeyValue } from "@angular/common";
import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  ViewChildren
} from "@angular/core";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ObjectDescriptor } from "../../../meta/models/object-descriptor";
import { PropertyInfo } from "../../../meta/models/property-info";
import { ItemInfo } from "../../../meta/models/property-sheet-config";
import { first } from "../../../ts-utils/helpers";
import { PropertySheetEditorWrapperComponent } from "../property-sheet-editor-wrapper/property-sheet-editor-wrapper.component";

@Component({
  selector: "property-sheet-subcategory",
  templateUrl: "property-sheet-subcategory.component.html",
  styleUrls: ["property-sheet-subcategory.component.scss"]
})
export class PropertySheetSubcategoryComponent implements AfterViewInit, OnDestroy {
  @Input() subCategory: KeyValue<string, ItemInfo[]>;
  @Input() targetInfo: ObjectDescriptor<any>;
  @ViewChildren(PropertySheetEditorWrapperComponent)
  subcategoryEditors: QueryList<PropertySheetEditorWrapperComponent>;
  @HostBinding(`class.element--hidden`) isHidden = false;
  protected unsubscribeSubject$: Subject<void> = new Subject<void>();

  ngAfterViewInit(): void {
    this.subscribeToEditorsVisibility();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private subscribeToEditorsVisibility(): void {
    combineLatest(this.subcategoryEditors.map((editor) => editor.isHiddenSubject))
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((editorsVisibility: boolean[]) => {
        setTimeout(() => {
          this.isHidden = editorsVisibility.every((isHidden) => isHidden);
        });
      });
  }

  public sectionPropNames(_index: number, item: ItemInfo): string {
    return item.propertyInfo.descriptor.name ?? "";
  }

  public getTargetInfo(propInfo: PropertyInfo<any>): ObjectDescriptor<unknown> {
    return propInfo.targetInfo ?? this.targetInfo;
  }

  trackByIndex(index: number, _props: KeyValue<string, ItemInfo[]>): number {
    return index;
  }

  sortGroups(group1: KeyValue<string, ItemInfo[]>, group2: KeyValue<string, ItemInfo[]>): number {
    return (
      first(group1.value)?.propertyInfo.descriptor.order -
      first(group2.value)?.propertyInfo.descriptor.order
    );
  }
}
