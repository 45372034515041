<select
  #selectElement
  (change)="onValueChange(selectElement.value)"
  [value]="selectedItem"
  [class.editor--value-configured]="distinctDefault"
>
  <option
    *ngFor="let item of items"
    [class.item--selected]="item.key === selectedItem"
    [value]="item.key"
    [selected]="item.key == selectedItem"
  >
    {{ item.title | translate }}
  </option>
</select>
