import { construct } from "../../../core";
import { EditorType } from "../../../meta";
import {
  Configurable,
  EditableType,
  NotSerializable,
  Serializable
} from "../../../meta/decorators";
import { HideChildProperties } from "../../../meta/decorators/hide-child-properties.decorator";
import { DeepPartial } from "../../../ts-utils";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";
import { DisplayFormatDto } from "../../models/display-format";
import { TitleFormat } from "../../models/title-format";

const TYPE_NAME = "ImageViewConfig";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class ImageViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({ displayName: "Image", editorType: EditorType.ImageSelection })
  @Serializable("")
  image!: string;

  @NotSerializable()
  title: string;

  @NotSerializable()
  titleFormat: TitleFormat;

  @NotSerializable()
  @HideChildProperties()
  displayFormat: DisplayFormatDto;

  constructor(viewConfigDto: DeepPartial<ImageViewConfig>) {
    super();
    const defaultOverrides: Partial<ImageViewConfig> = {
      size: getDefaultSize(),
      hasRuntimeData: false
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("250", "150");
}
