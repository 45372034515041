import { Observable } from "rxjs";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function DynamicallyVisible(
  propertySelector: (context: DecoratorDelegateContext) => Observable<any>,
  displayValues: any[],
  invert: boolean = false
) {
  return function (proto: Object, key: string) {
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      visibilitySelector: propertySelector,
      displayValues,
      dynamicallyVisibleInvert: invert
    };
    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}
