import { AfterViewChecked, Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { EntityId, EnvironmentName, IName } from "ui-core";
import { SignalRService } from "../../services/api/signalR.service";
import { RoleService } from "../../services/auth/role.service";

@Component({
  selector: "rds-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"]
})
export class MainComponent implements OnInit, AfterViewChecked {
  environmentName = EnvironmentName.Rds;
  projectName: IName = {
    fullName: "ABB Ability™ Predictive Maintenance for Grinding",
    shortName: "PMG"
  };
  activeReport: { reportId: EntityId } = { reportId: "dynamic-report" }; // FIXME This is weird and present throughout related code
  hasAccessToEdit: boolean = false;
  @ViewChild("sidenav") public sidenav!: MatSidenav;

  constructor(private roleService: RoleService, private signalRService: SignalRService) {}

  ngAfterViewChecked(): void {}

  ngOnInit(): void {
    this.fetchData$().subscribe();
  }

  fetchData$(): Observable<boolean> {
    return this.roleService
      .getEditorPermission()
      .pipe(tap((res: boolean) => (this.hasAccessToEdit = res)));
  }

  isMobileUserAgent(): boolean {
    return !!navigator.userAgent.match(/iPad|iPhone|Android|BlackBerry|Windows Phone|webOS/i);
  }

  public toggleSidenav(): void {
    this.sidenav.toggle();
  }

  public setActiveReport(report: { reportId: EntityId }): void {
    if (report && report.reportId) {
      this.activeReport = report;
    }
  }
}

