import { Options, YAxisLabelsOptions } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { Maybe, first, isDefined, tryConvertToNumber } from "../../../ts-utils";
import { isEmpty } from "../../../ts-utils/helpers/is-empty.helper";
import {
  getConfiguredOrForegroundColor,
  getNeutralColorForText,
  getTextColorForNoDataStatus
} from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { getLimitsDisplayOptions, isPrimaryLimitsMode } from "../../helpers/limit-modes.helper";
import {
  DEFAULT_BULLET_PLOT_BAND_COLOR,
  DEFAULT_CHART_TEXT_COLOR_HEX
} from "../../models/colors.constants";
import { DataStatus } from "../../models/data-status";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { LimitMarkerHelper } from "../limit.helper";
import { PRIMARY_X_AXIS_ID, PRIMARY_Y_AXIS_ID } from "./base-highcharts-options.helper";
import { GaugeDisplayService } from "./gauge-display.service";
import { getPrimaryColor, getYAxisLabelsOptions } from "./single-value-display.helper";

export class BulletChartDisplayService extends GaugeDisplayService {
  protected colorListService: ColorListService;
  constructor(protected valueFormatter: ValueFormatterService, colorListService: ColorListService) {
    super(valueFormatter);
    this.chartType = "bullet";
    this.isTargetAsAxis = false;
    this.colorListService = colorListService;
  }

  protected getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const component = this;
    const plotBandColor = getNeutralColorForText(
      isEmpty(viewConfig.neutralColor) ? DEFAULT_BULLET_PLOT_BAND_COLOR : viewConfig.neutralColor,
      null
    );
    const primaryColor = getPrimaryColor(viewConfig, this.colorListService);
    return {
      chart: {
        inverted: true,
        events: {
          render: function () {
            const chart = this;
            chart.series.forEach(function (s) {
              s.points.forEach(function (point: Highcharts.Point) {
                // @ts-ignore
                if (point.dataLabel) {
                  component.setDataLabelColor(chart.plotWidth, point, primaryColor);
                }
              });
            });
          }
        }
      },
      title: {
        text: viewConfig.showTitle ? viewConfig?.title : ""
      },
      noData: {
        style: {
          color: getTextColorForNoDataStatus(dataStatus, DEFAULT_CHART_TEXT_COLOR_HEX)
        }
      },
      plotOptions: {
        bullet: {
          color: primaryColor,
          targetOptions: {
            width: "200%",
            color: viewConfig.limits.targetColor
          }
        },
        series: {
          dataLabels: {
            style: {
              color: getConfiguredOrForegroundColor(primaryColor, plotBandColor)
            }
          }
        }
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          labels: { enabled: false },
          tickLength: 0
        }
      ],
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          gridLineWidth: 0,
          title: { text: null },
          tickWidth: 1,
          tickLength: 7,
          tickColor: "#E6E6E6",
          startOnTick: false,
          endOnTick: false,
          tickPositions: this.getTickPositions(viewConfig),
          labels: this.getYAxisLabels(viewConfig),
          plotBands: this.getPlotBands(viewConfig)
        }
      ],
      series: [
        {
          id: "value_bullet",
          type: undefined,
          data:
            value != null
              ? [
                  {
                    y: value,
                    target: this.getTarget(viewConfig)
                  }
                ]
              : []
        }
      ]
    };
  }

  private setDataLabelColor(
    plotBandWidth: number,
    point: Highcharts.Point,
    primaryColor: string
  ): void {
    // @ts-ignore
    const invertedBarWidth = Number(point.shapeArgs.height);
    // @ts-ignore
    const dataLabelWidth = Number(point.dataLabel.width);
    if (plotBandWidth - invertedBarWidth < dataLabelWidth) {
      // @ts-ignore
      point.dataLabel.css({
        color: getNeutralColorForText(primaryColor, null)
      });
    }
  }

  protected getTickPositions(viewConfig: IGaugeDisplayConfig): Maybe<number[]> {
    const extremeValues = this.getExtremeValues(viewConfig);
    const limitValues = this.getLimitValues(viewConfig);
    return extremeValues.concat(limitValues);
  }

  private getExtremeValues(viewConfig: IGaugeDisplayConfig): number[] {
    const { showYAxisLabels, min, max } = viewConfig;
    const extremeValues = [tryConvertToNumber(min), tryConvertToNumber(max)];
    return showYAxisLabels ? extremeValues.filter((value) => isDefined(value)) : [];
  }

  private getLimitValues(viewConfig: IGaugeDisplayConfig): number[] {
    const { limitsMode, limits } = viewConfig;
    const { showLimitValues } = getLimitsDisplayOptions(limitsMode);
    if (showLimitValues) {
      const markerHelper = new LimitMarkerHelper(limits);
      const markers = markerHelper.getMarkers(6, 6);
      return markers.map((marker) => marker.value);
    }
    return [];
  }

  private getYAxisLabels(viewConfig: IGaugeDisplayConfig): YAxisLabelsOptions {
    const { showYAxisLabels, limitsMode } = viewConfig;
    const { showLimitValues } = getLimitsDisplayOptions(limitsMode);
    const shouldEnableLabels = showYAxisLabels || showLimitValues;
    return getYAxisLabelsOptions(viewConfig, shouldEnableLabels, this.valueFormatter);
  }

  private getTarget(viewConfig: IGaugeDisplayConfig): Maybe<number> {
    return isPrimaryLimitsMode(viewConfig.limitsMode) ? viewConfig.limits.target : undefined;
  }

  protected getDataLabelFormat(value: number, viewConfig: IGaugeDisplayConfig): string {
    return super.getDataLabelFormat(value, viewConfig).replace("<br />", "").replace("color", "");
  }
}
