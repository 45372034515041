import {
  getEquipmentClasses,
  getEquipmentProperties
} from "../../../core/helpers/equipment-data-source.helper";
import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { Title } from "../../../meta/decorators/title.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyCategory } from "../../../meta/models/property-category";
import { Maybe, isDefined, isEmptyOrNotDefined, isWhiteSpaceOrNotDefined } from "../../../ts-utils";
import { EQUIPMENT_DATA_SOURCE, EquipmentDataSourceType } from "./data-source.type";
import { SignalDataSourceDto } from "./signal-data-source";

// @dynamic
@EditableType({ fullName: EQUIPMENT_DATA_SOURCE, title: "equipment-data-source-dto" })
export class EquipmentDataSourceDto extends SignalDataSourceDto {
  typeName: EquipmentDataSourceType = EQUIPMENT_DATA_SOURCE;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Path,
    editorType: EditorType.EquipmentBrowser
  })
  @Title()
  @Serializable("")
  path!: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: getEquipmentClasses,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Class,
    localizationPrefix: LOCALIZATION_DICTIONARY.propertySheet.DataSource,
    editorType: EditorType.EditableSelectList,
    allowMultipleSelection: true
  })
  @Serializable([], String)
  class!: string[];

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: getEquipmentProperties,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.PropertyName,
    editorType: EditorType.EditableSelectList,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.EquipmentPropertyTooltip
  })
  @Serializable("")
  property!: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.SearchDepth,
    editorType: EditorType.Number
  })
  @Serializable()
  searchDepth: Maybe<number>;

  // for equipment properties that resolve to constants
  // do not store or make editable
  value: any;

  constructor(params: Partial<EquipmentDataSourceDto> = {}) {
    super();
    construct(this, params, EQUIPMENT_DATA_SOURCE);
  }
}

export function isEmptyEquipmentSource(dataSource: EquipmentDataSourceDto): boolean {
  if (
    !isDefined(dataSource) ||
    (isWhiteSpaceOrNotDefined(dataSource.path) &&
      isEmptyOrNotDefined(dataSource.class) &&
      isWhiteSpaceOrNotDefined(dataSource.property))
  ) {
    return true;
  }
  return false;
}
