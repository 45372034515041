import { fontSize16 } from "../../../../_typography";
import { IconId } from "../../../browsing/models/icon-id";
import { construct } from "../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  ConfigurableEnum,
  ConfigurationCategory,
  EditableType,
  NotSerializable,
  Serializable,
  Title
} from "../../../meta/decorators";
import { DynamicallyVisible } from "../../../meta/decorators/dynamically-visible.decorator";
import { HideChildProperties } from "../../../meta/decorators/hide-child-properties.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyCategory } from "../../../meta/models/property-category";
import { DeepPartial } from "../../../ts-utils";
import { selectDisplayStrategyByComponentId } from "../../helpers/dynamically-visible-properties.helper";
import { HorizontalAlignment } from "../../models/alignment/horizontal-alignment";
import { VerticalAlignment } from "../../models/alignment/vertical-alignment";
import { BLACK_COLOR_HEX } from "../../models/colors.constants";
import { ComponentCssSize } from "../../models/component-size";
import { DisplayFormatDto } from "../../models/display-format";
import { DisplayStrategies } from "../../models/display-strategies/label-display-strategies";
import { TitleFormat } from "../../models/title-format";
import { LABEL_VIEW_CONFIG } from "../../models/view-config-type.constants";
import { StrategizedChartViewConfig } from "../strategized-chart/view-config";

const DEFAULT_TITLE = "LABEL";
const LABEL_FONT_SIZE = fontSize16;

// @dynamic
@EditableType({ fullName: LABEL_VIEW_CONFIG })
export class LabelViewConfig extends StrategizedChartViewConfig {
  typeName = LABEL_VIEW_CONFIG;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.PropertyInterpolationTooltip,
    editorType: EditorType.TextBox
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Label])
  @AllowInterpolation()
  @Title()
  @Serializable(DEFAULT_TITLE)
  title!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General)
  @ConfigurableEnum({
    enumSource: TitleFormat,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TitleFormat,
    advancedMode: true
  })
  @Serializable(TitleFormat.Truncate)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Label])
  titleFormat!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @ConfigurableEnum({
    enumSource: DisplayStrategies,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display
  })
  @Serializable(DisplayStrategies.Label)
  displayStrategy!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Bold,
    editorType: EditorType.CheckBox
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Label])
  @Serializable(true)
  bold!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FontSize,
    editorType: EditorType.Number
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Label])
  @Serializable(LABEL_FONT_SIZE)
  fontSize!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.PrimaryColor,
    editorType: EditorType.ColorPicker,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.TextColorTooltip
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Label])
  @Serializable(BLACK_COLOR_HEX)
  primaryColor!: string;

  // FIXME Needs default or Maybe<>
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Icon,
    editorType: EditorType.IconPicker
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Label])
  @Serializable()
  icon!: IconId;

  @NotSerializable()
  @HideChildProperties()
  displayFormat!: DisplayFormatDto;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.CSSOverride
  )
  @ConfigurableEnum({
    enumSource: HorizontalAlignment,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.HorizontalAlignment
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Label])
  @Serializable(HorizontalAlignment.Center)
  horizontalAlignment!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.CSSOverride
  )
  @ConfigurableEnum({
    enumSource: VerticalAlignment,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.VerticalAlignment
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.Label])
  @Serializable(VerticalAlignment.Middle)
  verticalAlignment!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MultilineText,
    editorType: EditorType.TextArea,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.MultilineLabelTooltip
  })
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [DisplayStrategies.MultilineLabel])
  @Serializable(DEFAULT_TITLE)
  @AllowInterpolation()
  htmlText!: string;

  constructor(viewConfigDto: DeepPartial<LabelViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<LabelViewConfig> = {
      size: getDefaultSize(),
      hasRuntimeData: false
    };
    construct(this, viewConfigDto, LABEL_VIEW_CONFIG, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("200", "50");
}
