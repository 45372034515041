import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild
} from "@angular/core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { View } from "../../decorators/view.decorator";
import { resolveElementBorderStyle } from "../../helpers/border.helper";
import { KEEP_TOGETHER_CLASS } from "../../helpers/template-builder.helper";
import { ComponentStyleDto } from "../../models/component-style";
import { GENERIC_IFRAME } from "../../models/element-type.constants";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { IframeViewConfig, TextAlignment } from "./view-config";

@LayoutBuilder(
  ComponentCategory.Glitter,
  GENERIC_IFRAME,
  "Dashboard_1",
  "abb-icon-default",
  "Generic",
  LOCALIZATION_DICTIONARY.propertySheet.Display_IFrame
)
@Component({
  selector: "c-generic-iframe",
  templateUrl: "./generic-iframe.component.html",
  styleUrls: ["./generic-iframe.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: GenericIframe }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@EditableWidget({ fullName: GENERIC_IFRAME, title: GENERIC_IFRAME })
export class GenericIframe extends BaseComponent {
  @View(IframeViewConfig)
  public get view(): IframeViewConfig {
    return this.currentState.view as IframeViewConfig;
  }
  interpolatedViewConfig!: IframeViewConfig;

  @ViewChild("iframe") protected iframe: ElementRef<HTMLIFrameElement>;

  protected applyBorderStyleToHostElement(css: Partial<ComponentStyleDto>): void {
    if (isNotDefined(this.iframe)) return;
    const elementStyle = resolveElementBorderStyle(css);
    Object.assign(this.iframe.nativeElement.style, elementStyle);
  }

  protected updateDisplay(): void {
    if (isDefined(this.iframe?.nativeElement)) {
      this.iframe.nativeElement.style.overflow = this.view.overflow;
      this.iframe.nativeElement.style.textAlign =
        this.view.textAlignment === TextAlignment.None ? "" : this.view.textAlignment;
      this.iframe.nativeElement.style.padding = this.view.css.padding;
      this.iframe.nativeElement.style.margin = this.view.css.margin;
      this.iframe.nativeElement.style.float = this.view.dock;
      this.iframe.nativeElement.style.clear = this.view.clear;
      this.hostElement.style.padding = "";
      this.hostElement.style.margin = "";

      if (this.view.keepTogether) {
        this.iframe.nativeElement.classList.add(KEEP_TOGETHER_CLASS);
      } else {
        this.iframe.nativeElement.classList.remove(KEEP_TOGETHER_CLASS);
      }
    }
  }

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, cdr);
  }
}
