export class FilterComponentLocalizationDictionary {
  From: string = "From";
  To: string = "EndDate";
  PeriodType: string = "PeriodType";
  LiveModeOn: string = "LiveModeOn";
  Custom: string = "Custom";
  Last: string = "Last";
  DateTimePicker: string = "DateTimePicker";
  Apply: string = "Apply";
  location: string = "location";
  material: string = "material";
  array: string = "array";
  LiveModeFilterTooltip: string = "LiveModeFilterTooltip";
  Today: string = "Today";
  Yesterday: string = "Yesterday";
  ThisWeek: string = "ThisWeek";
  ThisMonth: string = "ThisMonth";
  Last4hours: string = "Last4hours";
  Last24hours: string = "Last24hours";
  Interval: string = "Interval";

  constructor() {
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof FilterComponentLocalizationDictionary;
      this[thisKey] = `FilterComponent.${thisKey}`;
    });
  }
}
