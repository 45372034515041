import "reflect-metadata";
import { HELP_LANDING_PAGE } from "../../elements/models/help-constants";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ComponentCategory } from "../models/component-category";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();
export const LAYOUT_BUILDER_ICON = "icon";
export const LAYOUT_BUILDER_ICON_GROUP = "icon-group";
/* Purpose of component descriptor is to specify component name, as function and
 class names will be changed in production build
 */

// FIXME 25 change to layout builder specific name
export function LayoutBuilder(
  categoryEnumValue: ComponentCategory | string,
  componentName: string,
  iconClassName: string,
  iconClassGroup: string = "abb-icon ",
  alias: Maybe<string> = null,
  aliasDisplayName: Maybe<string> = null,
  userHelp: string = HELP_LANDING_PAGE
): any {
  return function (_proto: any, _key: string) {
    const typeDescriptor = TYPE_PROVIDER.getType(componentName);

    typeDescriptor.aliases.push({
      name: alias || componentName,
      displayName: aliasDisplayName || alias || componentName,
      icon: iconClassName,
      iconGroup: iconClassGroup,
      category: categoryEnumValue,
      userHelp: userHelp
    });
  };
}
