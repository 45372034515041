import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

// FIXME: Use the store for this kind of scenario
@Injectable()
export class FleetMapSidebarBridgeService implements OnDestroy {
  public fleetMapRegionZoomed$: Subject<void> = new Subject<void>();
  public sidebarNavigated$: Subject<void> = new Subject<void>();
  public motorExpandTrigger$: Subject<void> = new Subject<void>();
  constructor() {}

  ngOnDestroy(): void {
    this.fleetMapRegionZoomed$.complete();
    this.sidebarNavigated$.complete();
    this.motorExpandTrigger$.complete();
  }
}
