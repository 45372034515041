import { Injectable } from "@angular/core";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RemoveDefaultConnectorTitleAndSignalName implements UpgradeStep {
  name = "RemoveDefaultConnectorTitleAndSignalName";
  fromVersion = new Version(4, 0, 13);

  perform(oldConfig: any): UpgradeStepResult {
    const dataConnectors = oldConfig.dataConnectors;
    let modified = false;
    if (isDefined(dataConnectors)) {
      Object.values(dataConnectors.entities).forEach((dataConnector: any) => {
        const title = dataConnector.title;
        const signalName = dataConnector.dataSource?.signal?.name;
        if (isDefined(signalName)) {
          delete dataConnector.dataSource.signal.name;
          if (title === signalName) {
            delete dataConnector.title;
          }
          modified = true;
        }
      });
    }
    return {
      result: oldConfig,
      modified: modified,
      warning: null
    };
  }
}
