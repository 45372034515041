import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { CUSTOM_FILTER_PROPERTY_NAME } from "../../../core/helpers/filter/expand-custom-filters";
import { RUNTIME_FILTER_ID } from "../../../core/helpers/filter/filter-id.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { CustomFilterValue } from "../../../core/models/filter/filter-type-descriptor";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { Dispatcher } from "../../../dispatcher";
import { FilterActions } from "../../../elements/store/filter/filter.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyInfo } from "../../../meta/models/property-info";
import { TypeProvider } from "../../../meta/services/type-provider";
import { isEmpty } from "../../../ts-utils/helpers/is-empty.helper";
import { FilterToolbarElementConfig } from "../../models/button/filter-toolbar-element.config";
import { FilterType } from "../../models/filter-type";

interface ICustomFilterValueUpdate {
  key: string;
  value: CustomFilterValue;
  editorType: EditorType;
}

@Component({
  selector: "runtime-custom-filters",
  templateUrl: "runtime-custom-filters.component.html",
  styleUrls: ["./runtime-custom-filters.component.scss"]
})
export class RuntimeCustomFiltersComponent implements OnInit, OnDestroy {
  @Input() isFilterBarSelected: boolean = false;
  @Input() canExpandHorizontally: boolean = false;
  @Output() showFilterBar: EventEmitter<any> = new EventEmitter();
  public icon: string = "Resistor";
  public toggleButton: FilterToolbarElementConfig;
  customFilterUpdate$: Subject<ICustomFilterValueUpdate> = new Subject<ICustomFilterValueUpdate>();
  runtimeFilterConfiguration: FilterConfigurationDto;
  customFilters: PropertyInfo<unknown>[];
  shouldHide: boolean = false;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private dispatcher: Dispatcher,
    public localizer: LocalizationService,
    private filterSelector: IFilterSelector,
    private typeProvider: TypeProvider
  ) {}

  ngOnInit(): void {
    this.toggleButton = this.createToggleButtonConfig();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private createToggleButtonConfig(): FilterToolbarElementConfig {
    return new FilterToolbarElementConfig({
      icon: this.icon,
      clickFunction: () => {
        return this.toggleFilterBar();
      },
      class: "medium-label custom-filter__label--responsive"
    });
  }

  private initSubscriptions(): void {
    this.customFilterUpdate$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((valueUpdate) => {
        this.runtimeFilterConfiguration.customFilters[valueUpdate.key] =
          parseInputValueToSpecificType(valueUpdate.editorType, valueUpdate.value);
        this.updateRuntimeFilterConfiguration();
      });

    this.filterSelector
      .selectById(RUNTIME_FILTER_ID)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((filterConfig) => {
        this.runtimeFilterConfiguration = _cloneDeep(filterConfig);
        const type = this.typeProvider.getType(this.runtimeFilterConfiguration.typeName);
        this.customFilters = this.typeProvider
          .getAllPropertyItemsDeep(type, this.runtimeFilterConfiguration)
          .filter((propInfo) => isCustomFilterPropertyInfo(propInfo));
        this.shouldHide = areAllFiltersHidden(this.customFilters);
      });
  }

  updateRuntimeFilterConfiguration(): void {
    const filterForStore = _cloneDeep(this.runtimeFilterConfiguration);
    this.dispatcher.dispatch(
      FilterActions.upsertOne({
        filterUpdate: {
          id: RUNTIME_FILTER_ID,
          changes: {
            customFilters: filterForStore.customFilters
          }
        }
      })
    );
  }

  getFilterType(editorType: EditorType): string {
    switch (editorType) {
      case EditorType.TextBox: {
        return "string";
      }
      case EditorType.Number: {
        return "number";
      }
      default: {
        return "string";
      }
    }
  }

  closeBar(event: Event): void {
    this.toggleFilterBar();
    event.stopPropagation();
  }

  closeIfDropdown(): void {
    if (!this.canExpandHorizontally && this.isFilterBarSelected) {
      this.toggleFilterBar();
    }
  }

  public toggleFilterBar(): void {
    this.showFilterBar.emit(FilterType.CustomFilters);
  }

  public trackByTitle(_index: number, filter: PropertyInfo<unknown>): string {
    return filter.descriptor.displayName;
  }

  resolveCustomFilterKey(filter: PropertyInfo<unknown>): string {
    return isEmpty(filter.descriptor.name) ? filter.descriptor.displayName : filter.descriptor.name;
  }
}

function areAllFiltersHidden(customFilters: PropertyInfo<unknown>[]): boolean {
  return customFilters.every((customFilter) => customFilter.descriptor.isHidden);
}

function isCustomFilterPropertyInfo(propInfo: PropertyInfo<unknown>): boolean {
  return propInfo.descriptor.dynamicallyCreatedFrom === CUSTOM_FILTER_PROPERTY_NAME;
}

function parseInputValueToSpecificType(
  editorType: EditorType,
  value: CustomFilterValue
): CustomFilterValue {
  switch (editorType) {
    case EditorType.TextBox:
      return value;
    case EditorType.Number:
      return isEmpty(value as string) ? null : Number(value);
    default:
      return value;
  }
}
