import "reflect-metadata";
import { Access } from "../models/access";
import { EditorType } from "../models/editor-type";
import { PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";
import { ConfigurableDecoratorParams } from "./configurable.decorator";

const TYPE_PROVIDER = TypeProvider.getInstance();

export class ConfigurableArrayWithCheckboxParams extends ConfigurableDecoratorParams {
  typeConstructor: Function;
  arrayItemEditorType?: EditorType;
  enableCustomItems?: boolean;
}

export function ConfigurableArrayWithCheckbox(params: ConfigurableArrayWithCheckboxParams) {
  return function (proto: Object, key: string) {
    const typeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: proto.constructor as new () => any
    });

    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      displayName: params.displayName || key,
      editorType: params.editorType,
      access: Access.Write,
      tooltipKey: params.tooltipKey || "",
      arrayItemEditorType: params.arrayItemEditorType,
      isArray: true,
      isHidden: false,
      enableCustomItems: params.enableCustomItems ?? false,
      showLabel: false
    };

    typeDescriptor.upsertProperty(key, params.typeConstructor, partialPropertyDescriptor);
  };
}
