import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  ConfigurableEnum,
  ConfigurationCategory,
  CutOffStrategy,
  EditableType,
  EditorType,
  PropertyCategory,
  Serializable
} from "../../../meta";
import { validateNumberOrInterpolatedString } from "../../../property-sheet/helpers/number-validation.helper";
import { Maybe } from "../../../ts-utils";
import { DefaultValue } from "../../decorators/default-value.decorator";
import { BaseViewConfigDto, ComponentCssSize } from "../../models";
import { FILTERING_AND_AGGREGATION__CUT_OFF } from "../../models/help-constants";

const TYPE_NAME = "ScatterChartViewConfig";
const GROUP_NAME = "Scatter";

@EditableType({ fullName: TYPE_NAME })
export class ScatterChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataAggregation,
    11
  )
  @ConfigurableEnum({
    enumSource: CutOffStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategy,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategyTooltip,
    userHelp: FILTERING_AND_AGGREGATION__CUT_OFF
  })
  @Serializable(CutOffStrategy.Downsample)
  cutOffStrategy!: CutOffStrategy;

  @DefaultValue(true)
  enableGroups: boolean = true;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayLegend,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  showLegend!: boolean;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowRegression,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ShowRegressionTooltip,
    editorType: EditorType.CheckBox,
    advancedMode: true
  })
  @Serializable(false)
  showTrendLine: boolean = false;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.General,
    40
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitle,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.XaxisTitleTooltip,
    editorType: EditorType.TextBox,
    advancedMode: false
  })
  @Serializable()
  xAxisTitle: Maybe<string>;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.General,
    40
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YaxisTitle,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.YaxisTitleTooltip,
    editorType: EditorType.TextBox,
    advancedMode: false
  })
  @Serializable()
  yAxisTitle: Maybe<string>;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.General,
    50
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XAxisMinimumValue,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateNumberOrInterpolatedString
  })
  @Serializable()
  @AllowInterpolation()
  xMin: Maybe<string>;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.General,
    51
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.XAxisMaximumValue,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateNumberOrInterpolatedString
  })
  @Serializable()
  @AllowInterpolation()
  xMax: Maybe<string>;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.General,
    52
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YAxisMinimumValue,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateNumberOrInterpolatedString
  })
  @Serializable()
  @AllowInterpolation()
  yMin: Maybe<string>;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.General,
    53
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YAxisMaximumValue,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateNumberOrInterpolatedString
  })
  @Serializable()
  @AllowInterpolation()
  yMax: Maybe<string>;

  constructor(viewConfigDto: Partial<ScatterChartViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<ScatterChartViewConfig> = {
      size: getDefaultSize(),
      groupName: GROUP_NAME
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("550", "200");
}
