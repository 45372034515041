import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TimeRange } from "../../../core/models/time-range";
import { LocalizationService } from "../../../i18n/localization.service";
import { SelectionOption } from "../../../meta/models/selection";
import { getTimePresetOptions } from "../../helpers/time-filter.helper";
import { LiveModeFilter } from "../../models/live-mode-filter";
import { TimePreset, TimePresetType } from "../../models/time-preset-filter";
import { HistoricRangeComponent } from "../historic-range/historic-range.component";
import { LiveModeFilterComponent } from "../live-mode-filter/live-mode-filter.component";
import { SelectorComponent } from "../selector/selector.component";

@Component({
  selector: "time-preset-filter",
  templateUrl: "time-preset-filter.component.html",
  styleUrls: ["./time-preset-filter.component.scss"]
})
export class TimePresetFilterComponent implements OnInit {
  @Input() timePreset!: TimePresetType;
  @Input() liveModeFilter!: LiveModeFilter;
  @Input() historicTimeRange!: TimeRange;
  @Input() horizontalLayout: boolean = false;
  @Output() onTimePresetChange: EventEmitter<TimePresetType> = new EventEmitter<TimePresetType>();
  @Output() onIntervalParamsChange: EventEmitter<LiveModeFilter> =
    new EventEmitter<LiveModeFilter>();
  @Output() onCustomParamsChange: EventEmitter<TimeRange> = new EventEmitter<TimeRange>();

  @ViewChild(LiveModeFilterComponent) liveModeFilterComponent?: LiveModeFilterComponent;
  @ViewChild(HistoricRangeComponent) historicRangeComponent?: HistoricRangeComponent;
  @ViewChild(SelectorComponent) selectorComponent!: SelectorComponent;

  presetOptions: SelectionOption[] = [];
  TimePreset = TimePreset;

  constructor(public localizer: LocalizationService) {}

  ngOnInit(): void {
    this.presetOptions = getTimePresetOptions(this.localizer);
  }

  updateTimePreset(newTimePreset: TimePresetType): void {
    this.onTimePresetChange.emit(newTimePreset);
  }

  updateIntervalParams(newIntervalParams: LiveModeFilter): void {
    this.onIntervalParamsChange.emit(newIntervalParams);
  }

  updateCustomParams(newTimeRange: TimeRange): void {
    this.onCustomParamsChange.emit(newTimeRange);
  }
}
