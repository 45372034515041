export interface ICommonDataProperties {
  description?: string;
  unit?: string;
  min?: number;
  max?: number;
  aliasName?: string;
  defaultTitle?: string;
}

export enum CommonDataPropertyNames {
  description = "description",
  unit = "unit",

  equipmentId = "equipmentId",
  equipmentName = "equipmentName",
  equipmentPath = "equipmentPath",
  equipmentDescription = "equipmentDescription",

  color = "color",

  aliasName = "aliasName",
  defaultTitle = "defaultTitle"
}

export interface ICommonDataPointProperties {
  sort?: string;
  hiLimit?: number;
  hiHiLimit?: number;
  hiHiHiLimit?: number;
  loLimit?: number;
  loLoLimit?: number;
  loLoLoLimit?: number;

  min?: number;
  max?: number;

  target?: number;
}

export enum CommonDataPointPropertyNames {
  sort = "sort",
  hiLimit = "hiLimit",
  hiHiLimit = "hiHiLimit",
  hiHiHiLimit = "hiHiHiLimit",
  loLimit = "loLimit",
  loLoLimit = "loLoLimit",
  loLoLoLimit = "loLoLoLimit",

  min = "min",
  max = "max",

  target = "target"
}
