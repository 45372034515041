import { Component, OnDestroy, OnInit } from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { DisplayMode } from "../../../core/models/display-mode";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { Y_KEY, Z_KEY } from "../../../keyboard.constants";
import { isShortcutPressed } from "../../helpers/undo-redo.helper";
import { HeaderButtonConfig } from "../../models/button/header-button.config";
import { UndoRedoService } from "../../services/undo-redo.service";

@Component({
  selector: "undo-redo",
  templateUrl: "undo-redo.component.html",
  styleUrls: ["./undo-redo.component.scss"]
})
export class UndoRedoComponent implements OnInit, OnDestroy {
  undoButtonConfig!: HeaderButtonConfig;
  redoButtonConfig!: HeaderButtonConfig;
  dropdownButtonConfig!: HeaderButtonConfig;
  isMenuOpened: boolean = false;
  isCollapsed: boolean = false;
  private unsubscribeSubject$: Subject<void> = new Subject();

  constructor(
    private localizer: LocalizationService,
    private environmentSelector: EnvironmentSelector,
    private undoRedoService: UndoRedoService
  ) {}

  ngOnInit(): void {
    this.undoButtonConfig = this.createUndoButtonConfig();
    this.redoButtonConfig = this.createRedoButtonConfig();
    this.dropdownButtonConfig = this.createDropDownButtonConfig();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private initSubscriptions(): void {
    this.subscribeToDisplayMode();
    this.subscribeToCanUndoRedo();
    this.subscribeToKeyboardEvent();
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.isCollapsed = displayMode !== DisplayMode.Desktop;
      });
  }

  private subscribeToCanUndoRedo(): void {
    this.undoRedoService.snapshotPointerChanged$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(() => {
        this.undoButtonConfig.isDisabled = !this.undoRedoService.canUndo();
        this.redoButtonConfig.isDisabled = !this.undoRedoService.canRedo();
      });
  }

  private subscribeToKeyboardEvent(): void {
    fromEvent<KeyboardEvent>(document, "keydown")
      .pipe(
        filter((event) => isShortcutPressed(event.ctrlKey, event.key.toLowerCase())),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((event) => {
        event.preventDefault();
        const key: string = event.key.toLowerCase();
        if (key === Z_KEY && !this.undoButtonConfig.isDisabled) {
          this.undoRedoService.undo();
        } else if (key === Y_KEY && !this.redoButtonConfig.isDisabled) {
          this.undoRedoService.redo();
        }
      });
  }

  private createUndoButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Undo,
      clickFunction: () => this.undoRedoService.undo(),
      isDisabled: !this.undoRedoService.canUndo()
    });
  }

  private createRedoButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Redo,
      clickFunction: () => this.undoRedoService.redo(),
      isDisabled: !this.undoRedoService.canRedo()
    });
  }

  private createDropDownButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.UndoRedo,
      hasDropdown: true,
      clickFunction: () => this.showOrHideMenu()
    });
  }

  private showOrHideMenu(): void {
    this.isMenuOpened ? this.closeMenu() : this.openMenu();
  }

  closeMenu(): void {
    this.isMenuOpened = false;
  }

  private openMenu(): void {
    this.isMenuOpened = true;
  }
}
