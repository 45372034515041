import {
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { HighchartsChartComponent } from "highcharts-angular";
import { Subject, fromEvent } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ChartRedrawInitiatorService } from "../../../core/services/chart-redraw-initiator.service";

@Component({
  selector: "highcharts-chart-extended",
  template: ""
})
export class HighchartsChartExtendedComponent
  extends HighchartsChartComponent
  implements OnInit, OnDestroy
{
  @Output()
  errorCaught = new EventEmitter<any>();
  unsubscribeSubject$ = new Subject<void>();

  constructor(
    private chartRedrawService: ChartRedrawInitiatorService,
    elementRef: ElementRef,
    zone: NgZone
  ) {
    super(elementRef, zone);
  }

  ngOnInit(): void {
    fromEvent(window, "resize")
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(() => {
        this.wrappedUpdateOrCreateChart();
      });

    this.chartRedrawService.redrawCharts$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(() => {
        this.wrappedUpdateOrCreateChart();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  updateOrCreateChart(): void {
    try {
      super.updateOrCreateChart();
    } catch (ex) {
      console.error(ex);
      this.errorCaught.emit(ex);
    }
  }

  private get chartInst(): Highcharts.Chart {
    return (this as any).chart;
  }
  private set chartInst(value: Highcharts.Chart) {
    (this as any).chart = value;
  }

  forceFullRedraw(): void {
    if (this.chartInst != null) {
      // console.log("recreating chart");
      this.chartInst.destroy();
      this.chartInst = null;
    }
  }
}
