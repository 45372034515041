import { Pipe, PipeTransform } from "@angular/core";
import { sortGroups } from "../../property-sheet/helpers/groups.helper";
import { Maybe, isEmptyOrNotDefined } from "../../ts-utils";
import { ConnectorGroupDto } from "../models/connector-group";

@Pipe({ name: "sortAlphabetically" })
export class SortAlphabeticallyPipe implements PipeTransform {
  public transform(
    items: Maybe<string[]> | ConnectorGroupDto[],
    sortBy: "items" | "groups" = "items"
  ) {
    if (isEmptyOrNotDefined(items)) {
      return [];
    }

    if (sortBy === "items") {
      return this.sortItems(items as string[]);
    } else {
      return sortGroups(items as ConnectorGroupDto[]);
    }
  }

  private sortItems(items: string[]): string[] {
    const res = items.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return res;
  }
}
