import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { first } from "rxjs/operators";
import { WebServicesConfiguration } from "ui-core";
import { ApiResponse } from "../models/api/api-response";
import { Hierarchy } from "../models/api/hierarchy";
import { IProjectHierarchyWithStatus } from "../models/motor-response.interface";
import {
  clientDataSelector,
  hierarchySelector
} from "../store/selectors/hierarchy-collection.selector";
@Injectable({
  providedIn: "root"
})
export class HierarchyService implements OnDestroy {
  hierarchyTree: Subject<Hierarchy> = new Subject();
  unsubscribeMotors$: Subject<void> = new Subject<void>();
  unsubscribeHierarchy$: Subject<void> = new Subject<void>();

  unsubscribeSubject$ = new Subject();

  constructor(
    private httpService: HttpClient,
    private apiConfig: WebServicesConfiguration,
    private store$: Store<any>
  ) {}

  getHierarchyObservable(): Observable<Hierarchy> {
    return this.httpService.get<Hierarchy>(
      this.apiConfig.itemsServiceUrl + "/customers/all/root-equipment/all/hierarchy"
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.complete();
  }

  getHierarchyState(): IProjectHierarchyWithStatus {
    let hierarchyState;
    this.store$
      .select(hierarchySelector)
      .pipe(first())
      .subscribe((state: IProjectHierarchyWithStatus) => (hierarchyState = state));
    return hierarchyState;
  }

  getclientDataState(): ApiResponse[] {
    let clientDataState;
    this.store$
      .select(clientDataSelector)
      .pipe(first())
      .subscribe((state: ApiResponse[]) => (clientDataState = state));
    return clientDataState;
  }
}
