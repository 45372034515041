<div #sidebar [@enterAnimation]>
  <div [ngSwitch]="componentToShow" class="sidebar__container" [ngClass]="{ 'sidebar__container--rounded': !templateBuilderMode }">
    <div *ngSwitchCase="SidemenuComponents.LayoutBuilderMenu">
      <layout-builder-menu
        (collapseSidebar)="checkIfSidebarShouldCollapse()"
        (expandSidebar)="expandSidebar()"
      ></layout-builder-menu>
    </div>
    <div *ngSwitchCase="SidemenuComponents.DataExplorer">
      <data-explorer
        (collapseSidebar)="checkIfSidebarShouldCollapse()"
        (expandSidebar)="expandSidebar()"
      ></data-explorer>
    </div>
    <div *ngSwitchCase="SidemenuComponents.TreeExplorer">
      <ng-content></ng-content>
    </div>
  </div>
</div>
<div *ngIf="!isSignalTabSelected">
<equipment-property-browser
  *ngIf="isPropertyBrowserExpanded"
  (collapseSidebar)="checkIfSidebarShouldCollapse()"
  (collapse)="collapseEquipmentPropertyBrowser()"
  (expandSidebar)="expandSidebar()"
></equipment-property-browser>
</div>
