import { Serializable } from "../../meta/decorators/serializable.decorator";

export enum BindingOperator {
  And = "And",
  Or = "Or"
}

export enum ComparisonOperator {
  Equal = "=",
  NotEqual = "<>",
  GreaterThan = ">",
  GreaterOrEqual = ">=",
  LessThan = "<",
  LessOrEqual = "<=",
  Contains = "Contains",
  DoesntContain = "Does not cantain"
}

// @EditableType("FilterWhere", "Where")
export class FilterWhere {
  id: any;

  // @ConfigurationCategory(PropertyCategory.Data, localizationDictionary.PropertySheet.DataSource)
  // @ConfigurableEnum(localizationDictionary.PropertySheet.Bind, BindingOperator)
  // @Serializable(DEFAULTS.bindingOperator)
  bindingOperator?: BindingOperator;

  // @ConfigurableEnum("Column", getSelectedEntityColumns)
  @Serializable()
  name: string;

  // @ConfigurableEnum(localizationDictionary.PropertySheet.Comparer, ComparisonOperator)
  @Serializable()
  compareOperator?: ComparisonOperator;

  // @Configurable({ displayName: localizationDictionary.PropertySheet.Value, editorType: EditorType.TextBox })
  @Serializable()
  value: any;

  // TODO-for Dominic: is this intentionally left not serializable?
  items: FilterWhere[];
}

export function areEqualFilterWhere(source: FilterWhere, target: FilterWhere): boolean {
  return (
    source.bindingOperator === target.bindingOperator &&
    source.name === target.name &&
    source.compareOperator === target.compareOperator &&
    source.value === target.value &&
    areEqualFilterWhereArrays(source.items, target.items)
  );
}

function areEqualFilterWhereArrays(
  sourceArray: FilterWhere[],
  targetArray: FilterWhere[]
): boolean {
  if (!sourceArray && !targetArray) {
    return true;
  }
  if (!sourceArray || !targetArray || sourceArray.length !== targetArray.length) {
    return false;
  }
  let areEqual = true;
  sourceArray.forEach((sourceFilter, index) => {
    if (!areEqualFilterWhere(sourceFilter, targetArray[index])) {
      areEqual = false;
    }
  });
  return areEqual;
}
