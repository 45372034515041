import { replaceAggregationDefaultValue } from "../../core/helpers/replace-aggregation-default-value.helper";
import { DataTransferObject } from "../../core/models/data-transfer-object";
import {
  COMPONENT_DATA_AGGREGATION_DTO,
  CONNECTOR_DATA_AGGREGATION_DTO
} from "../../core/models/dto-type.constants";
import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../meta/decorators/configurable-enum.decorator";
import { ConfigurationCategory } from "../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { ValueOverrider } from "../../meta/decorators/value-overrider.decorator";
import { PropertyCategory } from "../../meta/models/property-category";
import { getPeriodTypes } from "../helpers/period-type-enum.helper";
import { AggregationType } from "./data-aggregation-function";

const TYPE_NAME = "DataAggregationConfigDto";

// @dynamic
@EditableType({ fullName: TYPE_NAME, title: "Data Aggregation Config", virtual: true })
export abstract class DataAggregationConfigDto implements DataTransferObject {
  typeName = TYPE_NAME;

  @ValueOverrider(replaceAggregationDefaultValue)
  materialAggregationFunction!: AggregationType;

  @ValueOverrider(replaceAggregationDefaultValue)
  locationAggregationFunction!: AggregationType;

  @ValueOverrider(replaceAggregationDefaultValue)
  timeAggregationFunction!: AggregationType;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataAggregation
  )
  @ConfigurableEnum({
    enumSource: getPeriodTypes,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.PeriodType
  })
  @Serializable("")
  periodType!: string;

  @ValueOverrider(replaceAggregationDefaultValue)
  arrayAggregationFunction!: AggregationType;

  static areEqual(source: DataAggregationConfigDto, target: DataAggregationConfigDto): boolean {
    return (
      source.arrayAggregationFunction === target.arrayAggregationFunction &&
      source.materialAggregationFunction === target.materialAggregationFunction &&
      source.locationAggregationFunction === target.locationAggregationFunction &&
      source.timeAggregationFunction === target.timeAggregationFunction &&
      source.periodType === target.periodType
    );
  }
}

const CONNECTOR_TYPE_NAME = CONNECTOR_DATA_AGGREGATION_DTO;

@EditableType({
  fullName: CONNECTOR_TYPE_NAME,
  title: "Connector Data Aggregation Config",
  overridePropertyValues: true
})
export class ConnectorDataAggregationConfigDto extends DataAggregationConfigDto {
  typeName = CONNECTOR_TYPE_NAME;

  constructor(aggregationConfig: Partial<ConnectorDataAggregationConfigDto> = {}) {
    super();
    construct(this, aggregationConfig, CONNECTOR_TYPE_NAME);
  }
}

const COMPONENT_TYPE_NAME = COMPONENT_DATA_AGGREGATION_DTO;

@EditableType({
  fullName: COMPONENT_TYPE_NAME,
  title: "Component Data Aggregation Config",
  overridePropertyValues: true
})
export class ComponentDataAggregationConfigDto extends DataAggregationConfigDto {
  typeName = COMPONENT_TYPE_NAME;

  constructor(aggregationConfig: Partial<ComponentDataAggregationConfigDto> = {}) {
    super();
    construct(this, aggregationConfig, COMPONENT_TYPE_NAME);
  }
}
