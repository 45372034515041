import { TimeRangeConfigurationDto } from "../../core/models/time-range-configuration";
import {
  extractLiveModeParams,
  isUnitBiggerThanHours
} from "../../elements/services/global-filter.helper";
import { LocalizationService } from "../../i18n/localization.service";
import { SelectionOption } from "../../meta/models/selection";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { LiveModeFilter } from "../models/live-mode-filter";
import {
  StandardPresets,
  TimeModeSpecificPresets,
  TimePreset,
  TimePresetType,
  TimeRangeMode
} from "../models/time-preset-filter";

export function deriveTimeModeFromPreset(timePreset: TimePresetType): TimeRangeMode {
  switch (timePreset) {
    case TimeModeSpecificPresets.Interval:
      return TimeRangeMode.Live;
    case TimeModeSpecificPresets.Custom:
      return TimeRangeMode.Historical;
    default:
      return TimeRangeMode.Other;
  }
}

export function getTimePresetOptions(localizer: LocalizationService): SelectionOption[] {
  return Object.keys(TimePreset).map((preset) => ({
    key: preset,
    title: localizer.filterComponent[preset]
  }));
}

export function shouldShowDayStart(timeRange: TimeRangeConfigurationDto): boolean {
  if (
    timeRange.timePreset === StandardPresets.Last4hours ||
    timeRange.timePreset === StandardPresets.Last24hours
  ) {
    return false;
  }
  if (timeRange.timePreset === TimeModeSpecificPresets.Interval) {
    const liveModeFilterParams: Maybe<LiveModeFilter> = extractLiveModeParams(
      timeRange.fromExpression
    );
    if (isNotDefined(liveModeFilterParams)) {
      return false;
    }
    return isUnitBiggerThanHours(liveModeFilterParams.unit);
  }
  return true;
}

export function shouldClearEndOfTimeRange(timePreset: Maybe<TimePresetType>): boolean {
  return (
    isDefined(timePreset) &&
    timePreset !== TimeModeSpecificPresets.Custom &&
    timePreset !== StandardPresets.Yesterday
  );
}
