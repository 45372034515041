import { Action, createReducer, on } from "@ngrx/store";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ComponentStateDto } from "../../models";
import { PAGE } from "../../models/element-type.constants";
import { getDefaultReportContent } from "../../services";
import { CommonActions } from "../common";
import { ComponentStateActions } from "../component-state";
import { ReportContents } from "../state";
import { ComponentCounterActions } from "./component-counter.actions";

const initialState = 0;

export function reducer(state: number, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(ComponentCounterActions.increment, (state, { incrementValue }) =>
    increment(state, incrementValue)
  ),
  on(ComponentCounterActions.decrement, (state, { decrementValue }) =>
    decrement(state, decrementValue)
  ),
  on(ComponentCounterActions.setCounter, (_, { value }) => value),
  on(ComponentStateActions.addOne, (state, { newComponent }) =>
    onComponentAdd(state, newComponent)
  ),
  on(ComponentStateActions.addMany, (state, { newComponents }) =>
    onComponentAddMany(state, newComponents)
  ),
  on(CommonActions.resetStore, (_, { reportConfiguration }) =>
    getCounterOrDefault(reportConfiguration.content)
  )
);

function increment(state: number, incrementValue: number): number {
  state += incrementValue;
  return state;
}

function decrement(state: number, decrementValue: number): number {
  state -= decrementValue;
  return state;
}

function onComponentAdd(state: number, newComponent: ComponentStateDto): number {
  if (newComponent.type !== PAGE) {
    state++;
  }
  return state;
}

function onComponentAddMany(state: number, newComponents: ComponentStateDto[]): number {
  const addedComponentsCount = newComponents.length;
  state += addedComponentsCount;
  return state;
}

function getCounterOrDefault(reportContents: Maybe<ReportContents>): number {
  if (reportContents != null) {
    return reportContents.componentsCounter;
  } else {
    return getDefaultReportContent().componentsCounter;
  }
}
