import { createSelector } from "@ngrx/store";
import { ReportState } from "..";
import { GeneralSettingsDto } from "../../../core/models/general-settings";
import { getReportFeature } from "../feature.selector";

export const selectGeneralSettings = createSelector(
  getReportFeature,
  (report: ReportState) => report.generalSettings
);

export const selectCustomFiltersDeclaration = createSelector(
  selectGeneralSettings,
  (pageConfig) => pageConfig.customFilterDeclarations
);

export const selectRootPath = createSelector(
  selectGeneralSettings,
  (generalSettings: GeneralSettingsDto) => generalSettings.rootPath
);

export const selectRootClass = createSelector(
  selectGeneralSettings,
  (generalSettings: GeneralSettingsDto) => generalSettings.rootClass
);

export const selectPeriodType = createSelector(
  selectGeneralSettings,
  (generalSettings: GeneralSettingsDto) => generalSettings.periodType
);

export const selectUseServerTime = createSelector(
  selectGeneralSettings,
  (generalSettings: GeneralSettingsDto) => generalSettings.useServerTime
);

export const selectWorkDayStartTime = createSelector(
  selectGeneralSettings,
  (generalSettings: GeneralSettingsDto) => generalSettings.workDayStartTime
);
