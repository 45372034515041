import { Dictionary as ngrxDictionary } from "@ngrx/entity";
import { createSelector, MemoizedSelector } from "@ngrx/store";
import { RUNTIME_FILTER_ID } from "../../../core/helpers/filter/filter-id.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { EquipmentDataSourceDto } from "../../../data-connectivity";
import { EntityId } from "../../../meta/models/entity";
import { Dictionary, isDefined, isNotDefined, Maybe } from "../../../ts-utils";
import { BasicCardViewConfig } from "../../components/basic-card/view-config";
import { CategoryViewConfig } from "../../components/category/view-config";
import { ContainerComponentViewConfig } from "../../components/container/view-config";
import { NavigationBarViewConfig } from "../../components/navigation-bar/view-config";
import { StrategizedChartViewConfig } from "../../components/strategized-chart/view-config";
import { TimeSeriesViewConfig } from "../../components/time-series/view-config";
import { BaseViewConfigDto } from "../../models";
import { CardImageProps } from "../../models/card-image-props";
import { ComponentStateDto } from "../../models/component-state";
import { CardRuntimeViewProperties } from "../../models/runtime-view-properties";
import { selectFilterEntities } from "../../store/filter/filter.selectors";
import { getReportFeature } from "../feature.selector";
import { ReportContents } from "../state";

export const ROOT_COMPONENT_ID = "Root";

export const selectComponentStates = createSelector(
  getReportFeature,
  (report: ReportContents) => report.componentStates
);

export const selectComponentStateEntities = createSelector(
  selectComponentStates,
  (state) => state.entities
);

export const selectComponentStateIds = createSelector(selectComponentStates, (state) => state.ids);

export const selectRootComponentState = createSelector(
  selectComponentStateEntities,
  (entities) => entities[ROOT_COMPONENT_ID]
);

export const selectComponentStateById = (
  id: EntityId
): MemoizedSelector<
  object,
  ComponentStateDto | undefined,
  (s1: ngrxDictionary<ComponentStateDto>) => ComponentStateDto | undefined
> =>
  createSelector(selectComponentStateEntities, (entities: ngrxDictionary<ComponentStateDto>) => {
    if (!isDefined(entities) || !entities[id]) {
      console.warn(`Component state with id ${id} not found`);
    } else {
      return entities[id];
    }
  });

export const componentsByFilterIdSelector = (
  filterId: EntityId
): MemoizedSelector<
  object,
  ComponentStateDto[],
  (s1: ngrxDictionary<ComponentStateDto>) => ComponentStateDto[]
> =>
  createSelector(selectComponentStateEntities, (entities: ngrxDictionary<ComponentStateDto>) =>
    Object.values(entities)
      .filter(isDefined)
      .filter((component) => component.filterId === filterId)
  );

export const selectComponentStateAvailability = (id: EntityId) =>
  createSelector(selectComponentStateEntities, (entities: Dictionary<ComponentStateDto>) => {
    return isDefined(entities[id]);
  });

export const selectComponentDataConnectorIds = (id: EntityId) =>
  createSelector(selectComponentStateById(id), (component: Maybe<ComponentStateDto>) =>
    component ? component.dataConnectorIds : []
  );

export const selectComponentView = (id: EntityId) =>
  createSelector(
    selectComponentStateById(id),
    (component: Maybe<ComponentStateDto>) => component.view
  );

export const selectComponentSize = (id: EntityId) =>
  createSelector(selectComponentView(id), (view: Partial<BaseViewConfigDto>) => view.size);

export const selectComponentRuntimeSize = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<BaseViewConfigDto>) => view.runtimeView.runtimeSize
  );

export const selectComponentFilterId = (componentId: EntityId) =>
  createSelector(
    selectComponentStateById(componentId),
    (state: Maybe<ComponentStateDto>) => state.filterId
  );

export const selectParent = (componentId: EntityId) =>
  createSelector(selectComponentStateEntities, (entities) => {
    const foundComponent = Object.values(entities) //
      .filter((entity) => entity.childrenIds.includes(componentId))[0];
    return foundComponent || null;
  });

export const selectChildren = (componentId: EntityId) =>
  createSelector(selectComponentStateEntities, (entities) => {
    const parent = entities[componentId];
    if (!parent) {
      return []; // or undefined?
    }
    return parent.childrenIds.map((id) => entities[id]);
  });

export function createComponentStateDtoSliceSelector<T>(
  componentId: EntityId,
  sliceSelector: (entity: ComponentStateDto) => T
) {
  // IP specify return type
  return createSelector(selectComponentStateById(componentId), (entity) => sliceSelector(entity));
}

export const selectSiblings = (componentId: EntityId) =>
  createSelector(selectComponentStateEntities, (entities) => {
    const parent = Object.values(entities) //
      .filter((entity) => entity.childrenIds.includes(componentId))[0];
    if (!parent) {
      return [];
    }
    return findSiblings(componentId, parent, entities);
  });

export const selectAllComponentsForFilter = (filterId: EntityId) =>
  createSelector(selectComponentStateEntities, (entities) => {
    return Object.values(entities) //
      .filter(
        (component) =>
          component.filterId === filterId ||
          (component.filterId == null && filterId === RUNTIME_FILTER_ID)
      );
  });

export const selectComponentByFilterId = (filterId: EntityId) =>
  createSelector(selectComponentStateEntities, (entities) => {
    return Object.values(entities).find((component) => component?.filterId === filterId);
  });

export const selectComponentDataStatus = (componentId: EntityId) =>
  createSelector(
    selectComponentStateById(componentId),
    (state: Maybe<ComponentStateDto>) => state.componentDataStatus
  );

export const selectRootPath = () =>
  createSelector(selectRootComponentState, (rootComponent) => {
    if (rootComponent == null || rootComponent.dataConnectorQuery == null) {
      return "";
    }
    const rootPath = (rootComponent.dataConnectorQuery as EquipmentDataSourceDto).path;
    return rootPath ?? "";
  });

export const selectComponentCacheOptions = (id: EntityId) =>
  createSelector(selectComponentStateById(id), (component: ComponentStateDto) => component.cache);

export const selectBackgroundImage = (id: EntityId) =>
  createSelector(selectComponentView(id), (view: BaseViewConfigDto) => {
    return view.backgroundImage;
  });

export const selectBackgroundImageData = (id: EntityId) =>
  createSelector(selectComponentView(id), (view: BaseViewConfigDto) => view.backgroundImageData);

export const selectLink = (id: EntityId) =>
  createSelector(selectComponentView(id), (view: Partial<BaseViewConfigDto>) => view.link);

//FIXME: function is also used in component-state.reducer; move it to proper place
export function findSiblings(
  componentId: EntityId,
  parentComponentState: ComponentStateDto,
  componentStatesDict: Dictionary<ComponentStateDto>
): ComponentStateDto[] {
  if (parentComponentState == null) {
    return [];
  }
  return parentComponentState.childrenIds
    .filter((childId) => childId !== componentId)
    .map((childId) => componentStatesDict[childId]);
}

export const selectRuntimeView = (id: EntityId) =>
  createSelector(selectComponentView(id), (view: BaseViewConfigDto) => view.runtimeView);

// Basic card selectors
// FIXME: move into separate file
export const selectCardHeaderInfo = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<BaseViewConfigDto>) => (view as BasicCardViewConfig).showHeader
  );

export const selectCardFooterInfo = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<BaseViewConfigDto>) => (view as BasicCardViewConfig).showFooter
  );

export const selectCardImageProps = (id: EntityId) =>
  createSelector(
    selectBackgroundImageData(id),
    selectCardHeaderInfo(id),
    selectCardFooterInfo(id),
    selectRuntimeView(id),
    (imageData, showHeader, showFooter, runtimeView) => {
      const imageProps: CardImageProps = {
        imageData: imageData,
        showHeader: showHeader,
        showFooter: showFooter,
        runtimeView: runtimeView as CardRuntimeViewProperties
      };
      return imageProps;
    }
  );

export const selectNavigationBarLinks = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<BaseViewConfigDto>) => (view as NavigationBarViewConfig).links
  );

export const selectContainerPositioningType = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<ContainerComponentViewConfig>) =>
      (view as ContainerComponentViewConfig).positioningType
  );

export const selectContainerSnapToGrid = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<ContainerComponentViewConfig>) =>
      (view as ContainerComponentViewConfig).snapToGrid
  );

export const selectLimits = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<BaseViewConfigDto>) => (view as TimeSeriesViewConfig).limits
  );

export const selectYAxes = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<BaseViewConfigDto>) => (view as TimeSeriesViewConfig | CategoryViewConfig).yAxes
  );

export const selectSubscribedEvents = (id: EntityId) =>
  createSelector(selectComponentView(id), (view) => view.subscribedEvents);

export const selectComponentsById = (componentIds: EntityId[]) =>
  createSelector(selectComponentStates, (state) =>
    componentIds.map((componentId) => state.entities[componentId]).filter(isDefined)
  );

export const selectComponentWithFilter = (componentId: EntityId) => {
  return createSelector(
    selectComponentStateById(componentId),
    selectFilterEntities,
    (componentState: Maybe<ComponentStateDto>, filters: ngrxDictionary<FilterConfigurationDto>) => {
      return {
        componentState,
        componentFilter:
          isDefined(filters) && isDefined(componentState?.filterId)
            ? filters[componentState.filterId]
            : undefined
      };
    }
  );
};

export const selectRootRuntimeSize = createSelector(
  selectRootComponentState,
  (root) => root?.view?.runtimeView?.runtimeSize
);

export const selectComponentByConnectorId = (connectorId: EntityId) =>
  createSelector(selectComponentStateEntities, (entities: ngrxDictionary<ComponentStateDto>) => {
    return Object.values(entities).find((component: Maybe<ComponentStateDto>) =>
      component.dataConnectorIds.some((id: EntityId) => connectorId === id)
    );
  });

export const selectDisplayStrategy = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<BaseViewConfigDto>) => (view as StrategizedChartViewConfig).displayStrategy
  );

export const selectEquipmentPathByComponent = (id: EntityId) =>
  createSelector(selectComponentStateById(id), (component) => {
    if (isNotDefined(component) || isNotDefined(component.dataConnectorQuery)) {
      return "";
    }
    const rootPath = (component.dataConnectorQuery as EquipmentDataSourceDto).path;
    return rootPath ?? "";
  });

export const selectGroupsForComponent = (id: EntityId) =>
  createSelector(selectComponentView(id), (view: Partial<BaseViewConfigDto>) => view.groups ?? []);

export const selectHeaderVisibility = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<BaseViewConfigDto>) => (view as TimeSeriesViewConfig).showHeader
  );

export const selectFooterVisibility = (id: EntityId) =>
  createSelector(
    selectComponentView(id),
    (view: Partial<BaseViewConfigDto>) => (view as TimeSeriesViewConfig).showFooter
  );
