import { Injectable } from "@angular/core";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { ConnectorContextService } from "../../data-connectivity/services/connector-context.service";
import { EntityId } from "../../meta/models/entity";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { DataStatus } from "../models/data-status";
import { ConnectorsReplaceInfo } from "../models/store/connectors-replace-info";
import { getConnectorsWithContext } from "./connector-context.helper";
import { ComponentStateSelector } from "./entity-selectors/component-state.selector";
import { DataConnectorSelector } from "./entity-selectors/data-connector.selector";

@Injectable()
export class DataConnectorReplacementService {
  constructor(
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector
  ) {}

  createDynamicConnectorReplacementInfo(
    newDynamicConnectors: Dictionary<DataConnectorDto[]>,
    contextService: ConnectorContextService
  ): Dictionary<ConnectorsReplaceInfo> {
    if (isNotDefined(newDynamicConnectors) || isNotDefined(contextService)) {
      return null;
    }
    return Object.keys(newDynamicConnectors).reduce((acc, componentId) => {
      acc[componentId] = this.createComponentDynamicConnectorReplacementInfo(
        componentId,
        newDynamicConnectors[componentId],
        contextService
      );

      return acc;
    }, {} as Dictionary<ConnectorsReplaceInfo>);
  }

  createComponentDynamicConnectorReplacementInfo(
    componentId: EntityId,
    newDynamicConnectors: DataConnectorDto[],
    contextService: ConnectorContextService
  ): ConnectorsReplaceInfo {
    if (
      isNotDefined(componentId) ||
      isNotDefined(newDynamicConnectors) ||
      isNotDefined(contextService)
    ) {
      return getEmptyReplacementInfo();
    }
    const ownerComponent = this.componentStateSelector.getById(componentId);
    if (isNotDefined(ownerComponent)) {
      return getEmptyReplacementInfo();
    }
    const componentOldConnectors = this.dataConnectorSelector.getForComponent(componentId);

    const connectorsReplacementInfo: ConnectorsReplaceInfo = this.getReplaceAllConnectorsUpdateInfo(
      componentOldConnectors,
      newDynamicConnectors
    );

    connectorsReplacementInfo.newConnectors = getConnectorsWithContext(
      connectorsReplacementInfo.newConnectors,
      ownerComponent,
      contextService
    );
    connectorsReplacementInfo.dcqDataStatus =
      newDynamicConnectors.length > 0 ? DataStatus.DataReceived : DataStatus.NoDataReceived;
    return connectorsReplacementInfo;
  }

  private getReplaceAllConnectorsUpdateInfo(
    oldConnectors: DataConnectorDto[],
    newConnectors: DataConnectorDto[]
  ): ConnectorsReplaceInfo {
    return {
      obsoleteConnectors: oldConnectors,
      newConnectors: newConnectors,
      connectorsToUpdate: []
    } as ConnectorsReplaceInfo;
  }
}

function getEmptyReplacementInfo(): ConnectorsReplaceInfo {
  return {
    obsoleteConnectors: [],
    newConnectors: [],
    connectorsToUpdate: []
  };
}
