import { getPeriodTypes } from "../../data-connectivity/helpers/period-type-enum.helper";
import { getLiveMode$ } from "../../elements/helpers/dynamically-visible-properties.helper";
import { TIME_AM_PM_FORMAT } from "../../environment/helpers/date-formatter.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurableArray,
  ConfigurableEnum,
  ConfigurationCategory,
  DynamicallyVisible,
  EditableType,
  EditorType,
  PropertyCategory,
  Serializable
} from "../../meta";
import { Placeholder } from "../../meta/decorators/placeholder.decorator";
import { EditorSize } from "../../meta/models/editor-size";
import { DeepPartial, isNotDefined, Maybe } from "../../ts-utils";
import { validateTimeFormat } from "../helpers/filter/filter-validation.helper";
import { construct } from "../services/construct.helper";
import { DecoratorDelegateContext } from "./decorator-delegate-context";
import { CustomFilterDescriptorDto } from "./filter/custom-filter-descriptor";

export const GENERAL_SETTINGS_TYPE_NAME = "GeneralSettingsDto";

// @dynamic
@EditableType({ fullName: GENERAL_SETTINGS_TYPE_NAME, title: "general-settings-dto" })
export class GeneralSettingsDto {
  typeName = GENERAL_SETTINGS_TYPE_NAME;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.CustomFilters)
  @ConfigurableArray({
    editorType: EditorType.Array,
    typeConstructor: CustomFilterDescriptorDto,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    arrayEditorSize: EditorSize.Small,
    canBeHidden: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CustomFiltersTooltip
  })
  @Serializable([], CustomFilterDescriptorDto)
  customFilterDeclarations!: CustomFilterDescriptorDto[];

  @Serializable("")
  rootPath!: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.General, 2)
  @ConfigurableEnum({
    enumSource: getPeriodTypes,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.PeriodType
  })
  @Serializable("")
  periodType!: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.UseServerTime,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  useServerTime!: boolean;

  @Serializable("")
  rootClass!: string;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.FilterTimeRange,
    3
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.WorkDayStartTime,
    editorType: EditorType.TextBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.WorkDayStartTimeTooltip,
    validationFunction: validateTimeFormat
  })
  @Serializable(null)
  @DynamicallyVisible(getLiveMode$, [true])
  @Placeholder({
    text: "",
    combineWithRuntimeValue: false,
    placeholderFunction: getDefaultStartTime
  })
  workDayStartTime!: Maybe<string>;

  constructor(generalSettings: DeepPartial<GeneralSettingsDto> = {}) {
    generalSettings = {
      ...generalSettings,
      customFilterDeclarations: getCustomFilters(generalSettings)
    };

    construct(this, generalSettings, GENERAL_SETTINGS_TYPE_NAME);
  }
}

function getCustomFilters(
  generalSettings: DeepPartial<GeneralSettingsDto>
): CustomFilterDescriptorDto[] {
  if (isNotDefined(generalSettings.customFilterDeclarations)) {
    return [];
  }
  return generalSettings.customFilterDeclarations.map(
    (customFilter) => new CustomFilterDescriptorDto(customFilter)
  );
}

function getDefaultStartTime(context: DecoratorDelegateContext): string {
  return context.services.timeService.determineDayStart().format(TIME_AM_PM_FORMAT);
}
