<div class="date-time-picker__container" (clickOutside)="closeDateTimePicker()">
  <div class="date-picker">
    <mat-calendar
      #calendar
      [selected]="date"
      [startAt]="date"
      (selectedChange)="changeDate($event)"
    >
    </mat-calendar>
  </div>
  <div class="time-picker">
    <ngx-mat-timepicker
      [(ngModel)]="time"
      [showSeconds]="formatIncludesSecond"
      [enableMeridian]="formatIncludesMeridiem"
      (ngModelChange)="changeTime($event)"
    >
    </ngx-mat-timepicker>
    <div
      class="date-time-picker__reset-icon"
      (click)="resetTime()"
      [title]="getResetButtonTooltip()"
    >
      <i class="abb-icon abb-icon--medium Turn_counter_clockwise"></i>
    </div>
  </div>
</div>
