import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { IClientsGroup } from "../../../models/side-nav/sidebar/client-groups.interface";
import { FleetMapSidebarBridgeService } from "../../../services/sidebar/fleet-map-sidebar-bridge.service";
import { _filter } from "../utils";

@Component({
  selector: "customer-search",
  templateUrl: "customer-search.component.html",
  styleUrls: ["./customer-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerSearchComponent implements OnDestroy, OnInit {
  unsubscribeSubject$ = new Subject<void>();
  clientNameForm: UntypedFormGroup = new UntypedFormGroup({});

  @Input() countryGroupOptions: IClientsGroup[] = [];
  @Input() clientsCollection: IClientsGroup[] = [];
  @Input() clientsCountriesMap = new Map<string, string>();

  constructor(
    private fb: UntypedFormBuilder,
    private fleetMapBridgeService: FleetMapSidebarBridgeService
  ) {
    this.clientNameForm = this.fb.group({
      clientName: ""
    });
  }

  ngOnInit(): void {
    const clientNameInput = this.clientNameForm.get("clientName");

    if (clientNameInput) {
      clientNameInput.valueChanges
        .pipe(
          tap((value: string) => {
            this.countryGroupOptions = this._filterGroup(value);
          }),
          takeUntil(this.unsubscribeSubject$)
        )
        .subscribe();
    }
  }

  private _filterGroup(value: string): IClientsGroup[] {
    if (value) {
      return this.clientsCollection
        .map((group: IClientsGroup) => ({
          country: group.country,
          clientsNames: _filter(value, group.clientsNames ?? [])
        }))
        .filter((group) => group.clientsNames.length > 0);
    }

    return this.clientsCollection;
  }

  getSelectedClient(event: MatAutocompleteSelectedEvent): void {
    const projectData = event.option;
    this.redirectToSelectedProject(projectData);
  }

  redirectToSelectedProject(projectData: { value: string }): void {
    const region = {
      regionName: this.clientsCountriesMap.get(projectData.value),
      projectName: projectData.value
    };
    this.fleetMapBridgeService.sidebarNavigated$.next();
    this.fleetMapBridgeService.fleetMapRegionZoomed$.next();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }
}
