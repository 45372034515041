import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { from, fromEvent, of, Subject } from "rxjs";
import { debounceTime, map, switchMap, takeUntil } from "rxjs/operators";
import { BaseDialogComponent } from "../../../../../shared/components/base-dialog/base-dialog.component";
import { PlotlineData } from "../../../../models/dialogs/plotline-data.model";

@Component({
  selector: "c-add-plotline-dialog",
  templateUrl: "./add-plotline-dialog.component.html",
  styleUrls: ["./add-plotline-dialog.component.scss"]
})
export class AddPlotlineDialogComponent
  extends BaseDialogComponent
  implements AfterViewInit, OnDestroy
{
  public axisArray: any[] = [];

  @ViewChild("axisList", { static: true }) axisList: MatSelect;
  @ViewChild("nameInput", { static: true }) nameInput: ElementRef;
  @ViewChild("valueInput", { static: true }) valueInput: ElementRef;
  public plotlineColor = "#FF0000";
  public inputInvalid: boolean;
  private noAxisSelected: boolean;
  private nameInputEmpty: boolean;
  private valueInputEmpty: boolean;

  unsubscribeSubject$: Subject<void> = new Subject<void>();
  onDialogClosed(): void {
    const plotlineData: PlotlineData = {
      plotlineColor: this.plotlineColor,
      plotlineName: this.nameInput.nativeElement.value,
      plotlineValue: this.valueInput.nativeElement.value,
      yAxis: this.getValueFromSelect(this.axisList)
    };
    this.dialogRef.close(plotlineData);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  ngAfterViewInit(): void {
    fromEvent(this.nameInput.nativeElement, "keyup")
      .pipe(
        debounceTime(100),
        map((event: any) => event.target.value),
        switchMap((value: string) => {
          if (value) {
            return of(true);
          } else {
            return of(false);
          }
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((nameInputFull: boolean) => {
        this.nameInputEmpty = !nameInputFull;

        if (this.noAxisSelected || this.valueInputEmpty) {
          this.inputInvalid = true;
          return;
        }

        this.inputInvalid = this.nameInputEmpty;
      });

    fromEvent(this.valueInput.nativeElement, "keyup")
      .pipe(
        debounceTime(100),
        map((event: any) => event.target.value),
        switchMap((value: string) => {
          if (value) {
            return of(true);
          } else {
            return of(false);
          }
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((valueInputFull: boolean) => {
        this.valueInputEmpty = !valueInputFull;

        if (this.noAxisSelected || this.nameInputEmpty) {
          this.inputInvalid = true;
          return;
        }

        this.inputInvalid = this.valueInputEmpty;
      });

    from(this.axisList.selectionChange)
      .pipe(
        debounceTime(100),
        map(() => true),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((axisSelected: boolean) => {
        this.noAxisSelected = !axisSelected;

        if (this.valueInputEmpty || this.nameInputEmpty) {
          this.inputInvalid = true;
          return;
        }

        this.inputInvalid = this.noAxisSelected;
      });
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<AddPlotlineDialogComponent>
  ) {
    super();
    this.inputInvalid = true;
    this.nameInputEmpty = true;
    this.valueInputEmpty = true;
    this.noAxisSelected = true;
    this.axisArray = data.axisArray;
  }

  public getValueFromSelect(comboBox: MatSelect) {
    if (comboBox.selected) {
      return (comboBox.selected as MatOption).value;
    } else {
      return null;
    }
  }
}
