import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { Dictionary, cloneDeep as _cloneDeep } from "lodash";
import { Subject, combineLatest, fromEvent } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import {
  nonCustomAndIntervalExpandedWidth,
  rootPathExpandedWidth,
  timeRangeExpandedWidth
} from "../../../../style-variables";
import { RUNTIME_FILTER_ID } from "../../../core/helpers/filter/filter-id.helper";
import { DisplayMode } from "../../../core/models/display-mode";
import { CustomFilterDescriptorDto } from "../../../core/models/filter/custom-filter-descriptor";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { CustomFilterValue } from "../../../core/models/filter/filter-type-descriptor";
import { TimeRange } from "../../../core/models/time-range";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { QueryStringService } from "../../../core/services/query-string.service";
import { ConnectivitySelector } from "../../../data-connectivity/services/connectivity.selector";
import { Dispatcher } from "../../../dispatcher";
import { GeneralSettingsSelector } from "../../../elements/services/entity-selectors/general-settings.selector";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { PropertySheetService } from "../../../property-sheet/services/property-sheet.service";
import { Maybe, isDefined, isEmptyOrNotDefined, isNotDefined } from "../../../ts-utils";
import {
  calculatePTypeFilterWidth,
  canExpandHorizontally,
  findOpenedItems,
  findOpenedItemsVertically,
  getCollapsedWidth,
  resolveCustomFilterWidth,
  resolveExpansionConfiguration
} from "../../helpers/filter-toolbar.helper";
import { shouldClearEndOfTimeRange } from "../../helpers/time-filter.helper";
import {
  RuntimeSessionConfig,
  SessionTimeRange
} from "../../models/copy-runtime-to-clipboard-info";
import { getInitialValueOrDefault } from "../../models/custom-filter-value-type";
import { FilterItem } from "../../models/filter-item";
import { FilterType } from "../../models/filter-type";
import { TimeModeSpecificPresets, TimePresetType } from "../../models/time-preset-filter";
import { RuntimePeriodTypeComponent } from "../period-type/runtime-period-type.component";
import { RootPathComponent } from "../root-path/root-path.component";
import { RuntimeCustomFiltersComponent } from "../runtime-custom-filters/runtime-custom-filters.component";

@Component({
  selector: "runtime-filter-toolbar",
  templateUrl: "runtime-filter-toolbar.component.html",
  styleUrls: ["./runtime-filter-toolbar.component.scss"]
})
export class RuntimeFilterToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("filterToolbar") filterToolbar!: ElementRef;
  @ViewChild(RuntimeCustomFiltersComponent)
  runtimeCustomFiltersComponent!: RuntimeCustomFiltersComponent;
  @ViewChild(RootPathComponent) rootPathComponent!: RootPathComponent;
  @ViewChild(RuntimePeriodTypeComponent) runtimePeriodTypeComponent!: RuntimePeriodTypeComponent;
  toolbarPinned: boolean = false;
  displayMode!: string;
  DisplayMode = DisplayMode;
  filterItems: Dictionary<FilterItem> = {};
  runtimeFilterConfiguration!: FilterConfigurationDto;
  periodTypes: Dictionary<string> = {};
  customFilters: CustomFilterDescriptorDto[] = [];
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private dispatcher: Dispatcher,
    public localizer: LocalizationService,
    private cdr: ChangeDetectorRef,
    private filterSelector: IFilterSelector,
    private environmentSelector: EnvironmentSelector,
    public propertySheetService: PropertySheetService,
    private connectivitySelector: ConnectivitySelector,
    private generalSettingsSelector: GeneralSettingsSelector,
    private queryStringService: QueryStringService,
    private filterFactory: FilterFactory
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.filterItems = this.resetToolbarConfiguration();
  }

  ngAfterViewInit(): void {
    this.onChangeFilterItemMode(FilterType.TimeRange);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private initSubscriptions(): void {
    this.subscribeToDisplayMode();
    this.subscribeToSidebarChanges();
    this.subscribeToPropertySheetChanges();
    this.subscribeToPeriodTypes();
    this.subscribeToCustomFilters();
    this.subscribeToRuntimeFilter();
    this.subscribeToWindowResize();
    this.subscribeToPinMode();
  }

  private subscribeToPeriodTypes(): void {
    this.connectivitySelector
      .selectPeriodTypes()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((periodTypesFromStore) => {
        this.periodTypes = periodTypesFromStore;
      });
  }

  private subscribeToCustomFilters(): void {
    this.generalSettingsSelector
      .selectCustomFilterDescriptors()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((customFilters) => {
        this.customFilters = customFilters;
        if (isDefined(this.filterItems) && isDefined(this.filterItems[FilterType.CustomFilters])) {
          this.filterItems[FilterType.CustomFilters].expandedWidth = this.getExpandedWidth(
            FilterType.CustomFilters
          );
          this.refreshExpansionConfiguration();
        }
      });
  }

  private subscribeToRuntimeFilter(): void {
    this.filterSelector
      .selectById(RUNTIME_FILTER_ID)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((filterConfig) => {
        this.runtimeFilterConfiguration = _cloneDeep(filterConfig);
        if (isDefined(this.filterItems[FilterType.TimeRange])) {
          this.filterItems[FilterType.TimeRange].expandedWidth = this.getExpandedWidth(
            FilterType.TimeRange
          );
          this.refreshExpansionConfiguration();
        }
      });
  }

  private subscribeToWindowResize(): void {
    fromEvent(window, "resize")
      .pipe(debounceTime(250), takeUntil(this.unsubscribeSubject$))
      .subscribe(() => {
        if (isDefined(this.filterToolbar)) {
          const itemsOpenedVertically = findOpenedItemsVertically(this.filterItems);
          if (itemsOpenedVertically.length > 1) {
            itemsOpenedVertically.map((filter, index) => {
              if (index !== 0) {
                this.filterItems[filter].isExpanded = false;
              }
            });
          }
          this.refreshExpansionConfiguration();
        }
      });
  }

  private subscribeToPinMode(): void {
    this.environmentSelector
      .selectFilterToolbarPinMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((pinMode) => {
        this.toolbarPinned = pinMode;
      });
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
        if (this.toolbarPinned) {
          this.onChangeDisplayMode();
        }
      });
  }

  private subscribeToSidebarChanges(): void {
    this.environmentSelector
      .selectSidebarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isSidebarOpened) => {
        if (isSidebarOpened && this.toolbarPinned) {
          this.resetToolbarConfiguration();
        }
      });
  }

  private subscribeToPropertySheetChanges(): void {
    combineLatest([
      this.environmentSelector.selectPropertySheetVisibilityMode(),
      this.environmentSelector.selectPropertySheetPinMode()
    ])
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(([isPropertySheetOpened, isPropertySheetPinned]) => {
        if (isPropertySheetOpened && isPropertySheetPinned && this.toolbarPinned) {
          this.resetToolbarConfiguration();
        }
      });
  }

  private onChangeDisplayMode(): void {
    if (isNotDefined(this.filterToolbar)) {
      return;
    }
    this.refreshExpansionConfiguration();
  }

  clickOutside(): void {
    if (!this.toolbarPinned) {
      this.dispatcher.dispatch(AppStatusActions.closeFilterToolbar());
    }
  }

  private resetToolbarConfiguration(): Dictionary<FilterItem> {
    return Object.values(FilterType).reduce((result: Dictionary<FilterItem>, filter) => {
      result[filter] = new FilterItem({
        collapsedWidth: getCollapsedWidth(filter),
        expandedWidth: this.getExpandedWidth(filter),
        canExpandHorizontally: false,
        isExpanded: false
      });
      return result;
    }, this.filterItems);
  }

  onChangeFilterItemMode(filterType: FilterType): void {
    this.expandOrCollapseFilterItem(filterType);
    this.refreshExpansionConfiguration();
  }

  private expandOrCollapseFilterItem(filterType: FilterType): void {
    this.filterItems[filterType].isExpanded = !this.filterItems[filterType].isExpanded;
    this.shouldExpandHorizontally(filterType);
  }

  private shouldExpandHorizontally(filterType: FilterType): void {
    if (isNotDefined(this.filterToolbar)) {
      return;
    }
    const filterToolbarWidth = this.filterToolbar.nativeElement.offsetWidth;
    if (this.filterItems[filterType].isExpanded) {
      this.filterItems[filterType].canExpandHorizontally = canExpandHorizontally(
        filterType,
        this.displayMode,
        this.filterItems,
        filterToolbarWidth
      );
    } else {
      const openedFilters = findOpenedItems(this.filterItems);
      openedFilters.map((filter) => {
        this.filterItems[filter].canExpandHorizontally = canExpandHorizontally(
          filter as FilterType,
          this.displayMode,
          this.filterItems,
          filterToolbarWidth
        );
      });
    }
  }

  private refreshExpansionConfiguration(): void {
    if (isNotDefined(this.filterToolbar)) {
      return;
    }
    const filterToolbarWidth = this.filterToolbar.nativeElement.offsetWidth;
    this.filterItems = resolveExpansionConfiguration(
      this.filterItems,
      this.displayMode,
      filterToolbarWidth
    );
  }

  getExpandedWidth(filterType: FilterType): number {
    switch (filterType) {
      case FilterType.CustomFilters: {
        const visibleCustomFilters = this.customFilters.filter(
          (customFilter) => !customFilter.isHidden
        );
        return resolveCustomFilterWidth(visibleCustomFilters.length);
      }
      case FilterType.PeriodType: {
        return calculatePTypeFilterWidth(this.periodTypes);
      }
      case FilterType.TimeRange: {
        const timePreset: TimePresetType = this.runtimeFilterConfiguration.timeRange.timePreset;
        return Object.keys(TimeModeSpecificPresets).includes(timePreset)
          ? timeRangeExpandedWidth
          : nonCustomAndIntervalExpandedWidth;
      }
      case FilterType.RootPath: {
        return rootPathExpandedWidth;
      }
      default: {
        return timeRangeExpandedWidth;
      }
    }
  }

  onChangePinMode(): void {
    this.dispatcher.dispatch(
      AppStatusActions.changeFilterToolbarPinMode({ pinMode: !this.toolbarPinned })
    );
  }

  copyRuntimeConfigurationToClipboard(): void {
    let urlParams: Maybe<RuntimeSessionConfig> = { timeRange: this.generateTimeRangeParams() };
    const configuredPeriodType: Maybe<string> = this.getPeriodTypeIfConfigured();
    const configuredRootPath: Maybe<string> = this.getRootPathIfConfigured();
    const configuredCustomFilters: Dictionary<CustomFilterValue> = this.getConfiguredCustomFilters(
      this.customFilters
    );

    if (isDefined(configuredPeriodType)) {
      urlParams.periodType = configuredPeriodType;
    }
    if (isDefined(configuredRootPath)) {
      urlParams.rootPath = configuredRootPath;
    }
    if (!isEmptyOrNotDefined(configuredCustomFilters)) {
      urlParams = { ...urlParams, customFilters: configuredCustomFilters };
    }

    this.queryStringService.copyUrlSessionToClipboard(urlParams);
  }

  private generateTimeRangeParams(): SessionTimeRange {
    const runtimeTimeRange: Maybe<TimeRange> = this.filterFactory.createStandaloneFilterTimeRange(
      this.runtimeFilterConfiguration.timeRange
    );
    const timeRange: SessionTimeRange = {
      startTime: runtimeTimeRange?.from,
      endTime: runtimeTimeRange?.to
    };

    if (shouldClearEndOfTimeRange(this.runtimeFilterConfiguration.timeRange.timePreset)) {
      timeRange.endTime = null;
    }
    return timeRange;
  }

  private getPeriodTypeIfConfigured(): Maybe<string> {
    const runtimePeriodType = this.runtimePeriodTypeComponent.runtimePeriodType;
    const reportPeriodType = this.generalSettingsSelector.getPeriodType();
    if (runtimePeriodType !== reportPeriodType) {
      return runtimePeriodType;
    }
  }

  private getRootPathIfConfigured(): Maybe<string> {
    const runtimeRootPath = this.rootPathComponent.rootPath;
    const reportRootPath = this.generalSettingsSelector.getRootPath();
    if (runtimeRootPath !== reportRootPath) {
      return runtimeRootPath;
    }
  }

  private getConfiguredCustomFilters(
    customFilters: CustomFilterDescriptorDto[]
  ): Dictionary<CustomFilterValue> {
    return customFilters.reduce(
      (acc: Dictionary<CustomFilterValue>, customFilter: CustomFilterDescriptorDto) => {
        if (isRuntimeValueSet(customFilter, this.runtimeFilterConfiguration)) {
          acc[customFilter.key] = this.runtimeFilterConfiguration.customFilters[customFilter.key];
        }
        return acc;
      },
      {}
    );
  }
}

function isRuntimeValueSet(
  customFilterDescriptor: CustomFilterDescriptorDto,
  runtimeFilter: FilterConfigurationDto
): boolean {
  return (
    getInitialValueOrDefault(customFilterDescriptor) !==
    runtimeFilter.customFilters[customFilterDescriptor.key]
  );
}
