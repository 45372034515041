import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, Subject, combineLatest, fromEvent } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { DisplayMode } from "../../../core/models/display-mode";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { IName } from "../../../core/models/i-name";
import { ReportId } from "../../../core/models/report-id";
import { SidemenuComponents } from "../../../core/models/sidemenu-components.enum";
import { ViewMode } from "../../../core/models/view-mode";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { HelpService } from "../../../core/services/help.service";
import { Dispatcher } from "../../../dispatcher";
import { ComponentSelectionService } from "../../../elements/services/component-selection.service";
import { ComponentStateSelector } from "../../../elements/services/entity-selectors/component-state.selector";
import { ReportInfoSelector } from "../../../elements/services/entity-selectors/report-info.selector";
import { LinkingWidgetService } from "../../../elements/services/linking-widget.service";
import { SidebarService } from "../../../elements/services/sidebar.service";
import { CommonActions } from "../../../elements/store/common/common.actions";
import { ComponentStateActions } from "../../../elements/store/component-state/component-state.actions";
import { AppSettingsActions, selectConnectionStatus } from "../../../environment";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { FeatureAvailabilityService } from "../../../environment/services/feature-availability.service";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { DELETE_KEY, ESCAPE_KEY } from "../../../keyboard.constants";
import { createUpdatedComponentsInfo } from "../../../meta/helpers/updated-entities-info.helper";
import { PropertySheetService } from "../../../property-sheet/services/property-sheet.service";
import { Theme } from "../../../theme";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { CreateReportDialogActions } from "../../dialogs/actions/create-report-dialog.actions";
import { isInputElement } from "../../helpers/html-element-type.helper";
import { BuilderHeaderButtonConfig } from "../../models/button/builder-header-button.config";
import { HeaderButtonConfig } from "../../models/button/header-button.config";
import { ReportCreationType } from "../../models/report-creation-type";
import { HeaderButtonsForSidebarGenerator } from "../../services/header-buttons-for-sidebar-generator-service";

@Component({
  selector: "c-app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"]
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  @Input() hasAccessToEdit: boolean = true;

  appTitle: IName = { fullName: "", shortName: "" };
  public previewMode: boolean = false;
  standalone: boolean = false;
  toolbarOpened: boolean = false;
  public displayMode: string;
  public DisplayMode = DisplayMode;
  previewButtonConfig: HeaderButtonConfig;
  filterButton: HeaderButtonConfig;
  showSaveProgressBar = false;
  showReloadProgressBar = false;
  activeReportId: ReportId;
  isDarkTheme: boolean = true;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();
  public isOnline$: Observable<boolean>;
  public templateBuilderMode$: Observable<boolean>;
  explorerDisplayButtons$: Observable<BuilderHeaderButtonConfig[]>;
  headerButtons$: Observable<BuilderHeaderButtonConfig[]>;
  public componentToShow: Maybe<SidemenuComponents>;

  constructor(
    public store$: Store<any>,
    private titleService: Title,
    public dispatcher: Dispatcher,
    public helpService: HelpService,
    public localizer: LocalizationService,
    private filterSelector: IFilterSelector,
    private propertySheetService: PropertySheetService,
    private reportInfoSelector: ReportInfoSelector,
    private environmentSelector: EnvironmentSelector,
    private componentStateSelector: ComponentStateSelector,
    public componentSelectionService: ComponentSelectionService,
    private linkingWidgetService: LinkingWidgetService,
    private sidebarService: SidebarService,
    private headerButtonsForSidebarGenerator: HeaderButtonsForSidebarGenerator,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private featureAvailabilityService: FeatureAvailabilityService
  ) {
    this.templateBuilderMode$ = this.environmentSelector.selectTemplateBuilderMode();
  }

  ngOnInit(): void {
    this.previewButtonConfig = this.createPreviewButtonConfig();
    this.filterButton = this.createFilterButtonConfig();
    this.initSubscriptions();
    this.isOnline$ = this.store$.select(selectConnectionStatus);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  initSubscriptions(): void {
    this.subscribeToActiveReport();
    this.subscribeToAppTitle();
    this.subscribeToStandaloneMode();
    this.subscribeToViewMode();
    this.subscribeToFilterVisibilityMode();
    this.subscribeToDisplayMode();
    this.subscribeToViewMode();
    this.subscribeToKeyboardEvent();
    this.subscribeToLinkingWidget();
    this.subscribeToDisplayedComponent();
    this.subscribeToSidebarDisplayHeaderButtons();
  }

  private subscribeToSidebarDisplayHeaderButtons(): void {
    this.explorerDisplayButtons$ =
      this.headerButtonsForSidebarGenerator.generateExplorerHeaderButtonItems$();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.explorerDisplayButtons$ =
          this.headerButtonsForSidebarGenerator.generateExplorerHeaderButtonItems$();
        this.closeSidebar();
      });

    this.headerButtons$ = combineLatest([
      this.headerButtonsForSidebarGenerator.generateBuilderHeaderButtonItems$(),
      this.explorerDisplayButtons$
    ]).pipe(
      map(([widgetExplorerButtons, explorerDisplayButtons]) => {
        return [...widgetExplorerButtons, ...explorerDisplayButtons];
      })
    );
  }

  private subscribeToDisplayedComponent(): void {
    this.sidebarService.componentToShow.subscribe((value) => {
      this.componentToShow = value;
      this.cdr.detectChanges();
    });
  }

  subscribeToActiveReport(): void {
    this.reportInfoSelector
      .selectReportId()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((reportId: Maybe<ReportId>) => {
        if (isDefined(reportId)) {
          this.activeReportId = reportId;
        }
      });
  }

  private subscribeToAppTitle(): void {
    this.environmentSelector
      .selectAppliedAppTitle()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((title: IName) => this.applyTitle(title));
  }

  subscribeToStandaloneMode(): void {
    this.environmentSelector
      .selectStandalone()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((standalone: boolean) => {
        this.standalone = standalone;
      });
  }

  private subscribeToViewMode(): void {
    this.environmentSelector
      .selectViewMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        this.previewMode = viewMode === ViewMode.PreviewMode;
        this.filterButton.hasDropdown = this.previewMode;
      });
  }

  subscribeToFilterVisibilityMode(): void {
    this.environmentSelector
      .selectFilterToolbarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((toolbarOpened: boolean) => {
        this.toolbarOpened = toolbarOpened;
      });
  }

  subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
      });
  }

  private subscribeToKeyboardEvent(): void {
    fromEvent<KeyboardEvent>(document, "keydown")
      .pipe(
        filter((event) => this.isShortcutPressed(event.key)),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((event) => {
        const key: string = event.key;
        if (this.shouldDeleteSelectedWidgets(key)) {
          const selectedComponentsIds = this.componentSelectionService.selectedComponentsIds;
          this.dispatcher.dispatch(
            ComponentStateActions.deleteManyWithChildren({
              targetComponents: this.componentStateSelector.getManyById(selectedComponentsIds)
            }),
            {
              withSnapshot: true,
              updatedEntitiesInfo: createUpdatedComponentsInfo(selectedComponentsIds)
            }
          );
        } else if (this.shouldEnterEditMode(key)) {
          this.dispatcher.dispatch(AppStatusActions.exitPreviewMode());
        } else if (this.shouldDeactivateLinkingMode(key)) {
          this.linkingWidgetService.deactivateLinkingMode();
        }
      });
  }

  private isShortcutPressed(key: string): boolean {
    return (key === DELETE_KEY || key === ESCAPE_KEY) && !isInputElement(document.activeElement);
  }

  private shouldDeleteSelectedWidgets(key: string): boolean {
    return key === DELETE_KEY && !this.componentSelectionService.isSelectionEmpty;
  }

  private shouldEnterEditMode(key: string): boolean {
    return this.previewMode && key === ESCAPE_KEY && this.hasAccessToEdit;
  }

  private shouldDeactivateLinkingMode(key: string): boolean {
    return key === ESCAPE_KEY && this.linkingWidgetService.isLinkingModeActive();
  }

  private subscribeToLinkingWidget(): void {
    this.linkingWidgetService.linkingModeChanged$
      .pipe(
        filter((isActive) => isActive),
        switchMap(() =>
          fromEvent(document, "pointerdown").pipe(
            takeUntil(
              this.linkingWidgetService.linkingModeChanged$.pipe(filter((isActive) => !isActive))
            )
          )
        ),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe(() => this.linkingWidgetService.deactivateLinkingMode());
  }

  private createFilterButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Filters,
      hasDropdown: this.previewMode,
      clickFunction: () => {
        return this.editFilter();
      },
      clickEventFunction: (event: Event) => {
        return event.stopPropagation();
      }
    });
  }

  private createPreviewButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.Preview,
      clickFunction: () => {
        this.onChangePreviewMode();
      },
      clickEventFunction: (event: Event) => {
        return event.stopPropagation();
      }
    });
  }

  onChangePreviewMode(): void {
    if (this.previewMode) {
      this.enterPreviewMode();
    } else {
      this.exitPreviewMode();
    }
  }

  enterPreviewMode() {
    this.dispatcher.dispatch(AppStatusActions.exitPreviewMode());
    if (
      ![SidemenuComponents.DataExplorer, SidemenuComponents.LayoutBuilderMenu].includes(
        this.sidebarService.componentToShow.value as SidemenuComponents
      )
    ) {
      this.closeSidebar();
    }
  }

  exitPreviewMode() {
    this.sidebarService.setComponentToShow(null);
    this.dispatcher.dispatch(AppStatusActions.enterPreviewMode());
    if (
      !this.featureAvailabilityService.previewModeButtons.includes(
        this.sidebarService.componentToShow.value as SidemenuComponents
      )
    ) {
      this.closeSidebar();
    }
  }

  private closeSidebar() {
    this.sidebarService.setComponentToShow(null);
    this.dispatcher.dispatch(AppStatusActions.closeSidebar());
  }

  private editFilter(): void {
    this.previewMode ? this.editRuntimeFilter() : this.editGlobalReportFilter();
  }

  private editRuntimeFilter(): void {
    this.toolbarOpened
      ? this.dispatcher.dispatch(AppStatusActions.closeFilterToolbar())
      : this.dispatcher.dispatch(AppStatusActions.openFilterToolbar());
  }

  private editGlobalReportFilter(): void {
    const globalFilter: FilterConfigurationDto = this.filterSelector.getGlobal();
    this.propertySheetService.openOrReplaceTarget(globalFilter);
  }

  applyTitle(newTitle: IName): void {
    this.titleService.setTitle(newTitle.fullName);
    this.appTitle = newTitle;
  }

  save(): void {
    this.store$.dispatch(
      CommonActions.saveReport({
        reportId: this.activeReportId
      })
    );
  }

  saveAs(): void {
    this.dispatcher.dispatch(
      CreateReportDialogActions.openCreateReportDialog({
        reportDialogInfo: {
          creationType: ReportCreationType.Duplicate
        }
      })
    );
  }

  toggleTheme(): void {
    this.isDarkTheme = !this.isDarkTheme;
    const theme: Theme = this.isDarkTheme ? Theme.Dark : Theme.Light;
    this.dispatcher.dispatch(AppSettingsActions.updateTheme({ theme }));
  }
}
