import { Inject, Injectable } from "@angular/core";
import { ReportContents } from "../../elements/store/state";
import { isEmptyOrNotDefined } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import {
  UPGRADE_STEP,
  UpgradeResult,
  UpgradeStep,
  UpgradeStepResult
} from "../models/upgrade-step";
import { Version } from "../models/version";

const VERSION_IF_UNSPECIFIED = new Version(0, 0, 0);
export const CURRENT_VERSION = new Version(4, 0, 19);

@Injectable({ providedIn: "root" })
export class ReportUpgradeService {
  private readonly allStepsOrdered: UpgradeStep[];
  constructor(@Inject(UPGRADE_STEP) upgradeSteps: UpgradeStep[]) {
    this.allStepsOrdered = upgradeSteps.sort((a, b) =>
      Version.compare(a.fromVersion, b.fromVersion)
    );
  }

  public getAllSteps(): UpgradeStep[] {
    return this.allStepsOrdered;
  }

  public findVersion(reportContent: any): Version {
    if (reportContent == null) {
      return VERSION_IF_UNSPECIFIED;
    }
    const version = (reportContent as ReportContents).version;
    if (version == null) {
      return VERSION_IF_UNSPECIFIED;
    }
    return Version.fromArray(version as number[]);
  }

  public upgrade(reportContent: any): UpgradeResult {
    const reportVersion = this.findVersion(reportContent);

    const overallResult: UpgradeResult = {
      originalVersion: reportVersion,
      isModified: false,
      upgradedReportContent: reportContent,
      warning: "",
      performedSteps: []
    };
    if (isEmptyOrNotDefined(reportContent)) {
      return overallResult;
    }

    if (Version.compare(reportVersion, CURRENT_VERSION) > 0) {
      overallResult.warning =
        "Report is from a newer version of dashboard and may not work correctly on this version.";
      return overallResult;
    }
    const firstStepToExecute = this.allStepsOrdered.findIndex(
      (step) => Version.compare(reportVersion, step.fromVersion) <= 0
    );
    if (firstStepToExecute < 0) {
      return overallResult;
    }
    for (let index = firstStepToExecute; index < this.allStepsOrdered.length; index++) {
      const step = this.allStepsOrdered[index];

      const stepResult = step.perform(overallResult.upgradedReportContent);
      this.mergeResult(overallResult, stepResult);
      if (stepResult.modified) {
        overallResult.performedSteps.push(step.name);
      }
    }

    overallResult.upgradedReportContent["version"] = CURRENT_VERSION.asArray;
    return overallResult;
  }

  private mergeResult(overallResult: UpgradeResult, stepResult: UpgradeStepResult): void {
    overallResult.upgradedReportContent = stepResult.result;
    overallResult.isModified = overallResult.isModified || stepResult.modified;
    if (isDefined(stepResult.warning)) {
      overallResult.warning = stepResult.warning;
    }
  }
}
