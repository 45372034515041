import { animate, state, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { MapInfoWindow, MapMarker } from "@angular/google-maps";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import Highcharts from "highcharts";
import { Nominal } from "projects/ui-core/src/lib/ts-utils/models/nominal.type";
import { Subject } from "rxjs";
import { catchError, filter, switchMap, takeUntil, tap } from "rxjs/operators";
import {
  BaseComponent,
  ComponentConstructorParams,
  ConnectorRoles,
  CriticalError,
  EditableType,
  EntityId,
  LayoutBuilder,
  ReportLinkDto,
  View
} from "ui-core";
import { IGroupMarker, IPlantMarker, IRunningStatus } from "../../../models";
import { ApiResponse } from "../../../models/api/api-response";
import { Continent } from "../../../models/api/hierarchy";
import { MotorStatus } from "../../../models/enums/motor-status.enum";
import { IFleetDetails, IFleetStatus } from "../../../models/fleet-status.interface";
import { IProjectHierarchyWithStatus } from "../../../models/motor-response.interface";
import { MotorModel } from "../../../models/side-nav/motor.model";
import { FleetViewDataService } from "../../../services/sidebar/fleet-view.service";
import { ClientDataActions } from "../../../store/actions/client-data.actions";
import { HierarchyCollectionActions } from "../../../store/actions/hierarchy-collection.actions";
import {
  clientDataSelector,
  hierarchySelector
} from "../../../store/selectors/hierarchy-collection.selector";
import { RdsComponentCategory } from "../../rds-component-category";
import { baseChartOptions, mapStyles } from "../fleet-view.config";
import { FleetMapViewConfig } from "./view-config";

@Component({
  selector: "rds-fleet-map",
  templateUrl: "./fleet-map.component.html",
  styleUrls: ["./fleet-map.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: FleetMapComponent }],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})
@ConnectorRoles()
@LayoutBuilder(RdsComponentCategory.Fleet, "FleetMapComponent", "Dashboard_2")
@EditableType({ fullName: "FleetMapComponent", title: "fleet-map" })
export class FleetMapComponent extends BaseComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
  @ViewChildren(MapMarker) markerQueryList!: QueryList<MapMarker>;

  activeGroupMarker: IGroupMarker = {} as IGroupMarker;
  center: google.maps.LatLngLiteral = { lat: 30, lng: 0 };

  mapOptions: google.maps.MapOptions = {
    backgroundColor: "transparent",
    mapTypeId: google.maps.MapTypeId.TERRAIN,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    streetViewControl: false,
    zoom: 3,
    restriction: {
      latLngBounds: {
        east: 180,
        north: 80,
        south: -85,
        west: -180
      },
      strictBounds: true
    },
    styles: mapStyles
  };

  groupMarkerOptions: google.maps.MarkerOptions = {
    optimized: true,
    draggable: false,
    animation: google.maps.Animation.DROP
  };

  groupMarkers: IGroupMarker[] = [];

  motors: MotorModel[] = [];
  continents: Continent[] = [];
  tableViewLoading = true;
  projectName: string = "";
  unsubscribeMotors$ = new Subject<void>();

  colorStatusMap: Map<string, string> = new Map<string, string>([
    [MotorStatus.Running, "circle-green"],
    [MotorStatus.RunningAlarms, "circle-yellow"],
    [MotorStatus.StoppedNormal, "circle-violet"],
    [MotorStatus.Trip, "circle-red"],
    [MotorStatus.NoDataConnection, "circle-grey"]
  ]);

  columnsToDisplay = ["status", "mills"];
  detailsColumns: string[] = ["plantName", "mill"];
  expandedElement: IFleetStatus | null = null;
  dataSource = new MatTableDataSource<IFleetStatus>([]);
  detailsSource = new MatTableDataSource<IFleetDetails>([]);

  constructor(
    private fleetViewService: FleetViewDataService,
    private store$: Store<any>,
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, cdr);
  }

  @View(FleetMapViewConfig)
  public get view(): FleetMapViewConfig {
    return this.currentState.view as FleetMapViewConfig;
  }

  ngOnInit(): void {
    super.ngOnInit();
    // FIX ME: this strategy is quite odd
    this.store$.dispatch(HierarchyCollectionActions.getHierarchy());
    this.getMotorsData();
  }

  linkConstructor(projectName: string, productionArea: string, motor = ""): string {
    const reportId = `rootPath=${projectName}/${productionArea}/${motor}`;
    return reportId.replace(/\/$/, "");
  }

  getMotorsData(): void {
    this.store$
      .select(hierarchySelector)
      .pipe(
        filter((response: IProjectHierarchyWithStatus) => Boolean(response.continents.length)),
        switchMap((response) => {
          this.continents = response.continents;

          if (response.motorStatus) {
            this.store$.dispatch(
              ClientDataActions.getClientDataAction({ motorStatus: response.motorStatus })
            );
          }

          return this.store$.select(clientDataSelector);
        }),
        filter((response: ApiResponse[]) => response.length > 1),
        tap((response) => {
          this.motors = this.fleetViewService.flatMotors(this.continents, response);
          this.updateMarkers();
        }),
        catchError((error) => {
          throw new CriticalError(error.message);
        }),
        takeUntil(this.unsubscribeMotors$)
      )
      .subscribe();
  }

  onMarkerHover(marker: IGroupMarker, windowIndex: number): void {
    this.markerQueryList.forEach((mapMarker: MapMarker, index: number) => {
      if (windowIndex === index) {
        this.infoWindow.open(mapMarker);
        this.activeGroupMarker = marker;
      }
    });
  }

  onCustomerClick(marker: IPlantMarker): void {
    this.redirectToReport(
      `rootPath=${marker.plantData.projectShortName}/${marker.plantData.productionArea}`,
      marker.plantData.systemOverviewReport as string
    );
  }

  onMotorClick(motor: MotorModel): void {
    this.redirectToReport(`rootPath=${motor.rootPath}`, motor.defaultReport);
  }

  redirectToReport(query: string, report: string): void {
    this.linkResolver.openReportInSameWindow(
      new ReportLinkDto({
        info: {
          reportId: report as Nominal<EntityId, "ReportId">,
          reportName: report
        }
      }),
      query
    );
  }

  setExpandIcon(element: IFleetStatus): string {
    return this.expandedElement === element ? "Up" : " Down";
  }

  updateMarkers(): void {
    this.groupMarkers = this.fleetViewService.groupMotors(this.motors);

    const mappedStatuses: IFleetStatus[] = [];
    const statuses = this.getStatuses();

    statuses.forEach((type: string) => {
      mappedStatuses.push({
        status: type,
        mills: this.getCountByStatus(type),
        details: this.getMotorDetails(type)
      });
    });

    this.dataSource = new MatTableDataSource(mappedStatuses);
    this.tableViewLoading = false;

    this.getSummaryChart();
  }

  getSummaryChart(): void {
    const series = this.fleetViewService.getStatusSeries(this.motors);
    const { running, runningPercent, total } = this.getRunningStatus(this.motors);
    const { chart } = baseChartOptions;

    Highcharts.chart({
      ...baseChartOptions,
      chart: {
        ...chart,
        renderTo: "summary-chart",
        width: 240,
        height: 240
      },
      tooltip: {
        formatter: function () {
          return `${this.key}: ${this.y}`;
        }
      },
      title: {
        useHTML: true,
        text: `
          <div style="
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;"
          >
            <h2 style="color: ${this.calculateColor(runningPercent)}">
              ${runningPercent}%
            </h2>
            <span style="font-size: 1rem;">
              Running: ${running} of ${total}
            </span>
          </div
        `,
        verticalAlign: "middle",
        floating: true
      },
      series: series
    });
  }

  getMotorDetails(type: string): IFleetDetails[] {
    return this.groupMarkers.reduce((acc: IFleetDetails[], curr: IGroupMarker) => {
      curr.plantsMarker
        .flatMap((p) => p.motors)
        .forEach((motor: MotorModel) => {
          if (motor.systemStatus === type) {
            acc.push({
              plantName: motor.projectName,
              motor: motor.systemName
            });
          }
        });

      return acc;
    }, []);
  }

  setDetailsSource(data: IFleetDetails[]): void {
    this.detailsSource = new MatTableDataSource(data);
  }

  getCountByStatus(status: string): number | string {
    return this.motors.length === 0
      ? "-"
      : this.motors.filter((x) => x.systemStatus === status).length;
  }

  getColorByStatus(status: string): string {
    return `${this.colorStatusMap.get(status)}`;
  }

  getStatuses(): string[] {
    return Array.from(this.colorStatusMap.keys());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribeMotors$.next();
    this.unsubscribeMotors$.complete();
  }

  private getRunningStatus(motors: MotorModel[]): IRunningStatus {
    const statusMap = this.fleetViewService.getStatusMap(motors);

    const total = motors.length;
    const running = statusMap.get(MotorStatus.Running) ?? 0;
    const runningAlarms = statusMap.get(MotorStatus.RunningAlarms) ?? 0;

    const percent = ((running + runningAlarms) / total) * 100;

    return {
      running: running + runningAlarms,
      runningPercent: +percent.toFixed(1),
      total: total
    };
  }

  //FIXME magic number antipattern
  private calculateColor(percent: number): string {
    const red = Math.floor((12 * percent) / 100);
    const green = Math.floor((169 * percent) / 100);
    const blue = Math.floor((25 * percent) / 100);

    return `rgb(${red}, ${green}, ${blue})`;
  }
}
