import { formatEquipmentPropertyPath } from "../../core/services/equipment-path-formatter";
import { DataSourceDto } from "../../data-connectivity";
import { switchDataSource } from "../../data-connectivity/helpers/data-source-type.helper";
import { first, isDefined, isNotDefined, last, Maybe } from "../../ts-utils";
import { Entity, PropertyInfo, TypeDescriptor } from "../models";
import { TypeProvider } from "../services/type-provider";
import { getPropertyByPath } from "./property-extraction.helper";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function getEntityTitle<T extends Entity>(instance: T): string {
  const instanceType: TypeDescriptor = TYPE_PROVIDER.getType(instance.typeName);
  return getTitle(instance, instanceType);
}

// avoid Object and use Record<string, unknown> instead
export function getObjectTitle<T extends Record<string, unknown>>(instance: T): string {
  const instanceType: TypeDescriptor = TYPE_PROVIDER.getTypeByConstructor(instance.constructor);
  return getTitle(instance, instanceType);
}

// TODO Make nested prop order deterministic. Handle case of two title props on same level.
function getTitle<T extends Object>(instance: T, instanceType: TypeDescriptor): string {
  const titleProps: PropertyInfo<string>[] = TYPE_PROVIDER.getTitlePropertyItemsDeep(
    instanceType,
    instance
  );
  const validTitleProps = titleProps
    .map((titleProp: PropertyInfo<string>) => getPropertyByPath(instance, titleProp.localPath))
    .filter(isDefined);

  if (validTitleProps.length < 1) {
    return instanceType.name;
  } else {
    return first(validTitleProps);
  }
}

export function getDataConnectorTitle(dataSource: Maybe<DataSourceDto>): string {
  if (isNotDefined(dataSource)) {
    return "";
  }
  return switchDataSource<string>(dataSource, {
    ApiDataSourceDto: () => "",
    EmptyDataSourceDto: () => "",
    EquipmentDataSourceDto: (dataSource) =>
      getEquipmentTitle(dataSource.path, dataSource.property, dataSource.signal.name),
    GroupedDataSourceDto: () => "",
    SignalDataSourceDto: () => "",
    TabularDataSourceDto: () => "",
    ValueDataSourceDto: () => ""
  });
}

export function getEquipmentTitle(path: string, property: string, signalName: string): string {
  const splittedPath: string[] = path.split("/");
  const equipmentName: string = last(splittedPath);
  return formatEquipmentPropertyPath(equipmentName, property, signalName);
}
