import { Component } from "@angular/core";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseComponent,
  ChartComponent,
  ConnectorRoles,
  EditableType,
  formatNumberByStringFormat,
  HorizontalAlignment,
  LayoutBuilder,
  MaxConnectors,
  View
} from "ui-core";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID,
  SECONDARY_X_AXIS_ID
} from "../../../../../../ui-core/src/lib/elements/services/highcharts/base-highcharts-options.helper";
import { RdsComponentCategory } from "../../rds-component-category";
import { Roles } from "./roles";
import { RotorShapeChartViewConfig } from "./view-config";

@Component({
  selector: "rds-rotor-shape-chart",
  templateUrl: "./rotor-shape-chart.component.html",
  styleUrls: ["./rotor-shape-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: RotorShapeChartComponent }]
})
@LayoutBuilder(
  RdsComponentCategory.RDS,
  "RotorShapeChartComponent",
  "Plugin",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.RotorShapeChart
)
@ConnectorRoles(Roles)
@MaxConnectors(2)
@EditableType({ fullName: "RotorShapeChartComponent", title: "rotor-shape-chart" })
export class RotorShapeChartComponent extends ChartComponent {
  HorizontalAlignment = HorizontalAlignment;
  minimumProximity: number;

  smallestAirgap: number;
  averageAirgap: number;
  largestAirgap: number;

  calculatedRotorShape: number[] = [];
  calculatedRotorShapeOriginal: number[] = [];
  detectedPoleValues: number[] = [];
  detectedPoleValuesOriginal: number[] = [];

  @View(RotorShapeChartViewConfig)
  public get view() {
    return this.currentState.view as RotorShapeChartViewConfig;
  }

  protected updateChartData(): void {
    const detectedPoleValues: number[] = this.dataAccessor.getValueForRole(Roles.PoleValues.name);
    const rotorShapeValues: number[] = this.dataAccessor.getValueForRole(
      Roles.CalculatedRotorShape.name
    );

    if (!detectedPoleValues || !rotorShapeValues) {
      return;
    }

    // NOTE constant added for visual purposes
    const minRho = -Math.ceil(Math.max(...detectedPoleValues) + 2);

    this.populateViewWithAirgapValues(detectedPoleValues, minRho);

    this.calculatedRotorShapeOriginal = [...rotorShapeValues];
    this.detectedPoleValuesOriginal = [...detectedPoleValues];
    this.detectedPoleValues = this.calculateChartSeries(detectedPoleValues, minRho);
    this.detectedPoleValues.push(minRho);
    this.calculatedRotorShape = this.calculateChartSeries(rotorShapeValues, minRho);
  }

  private calculateChartSeries(seriesData: number[], minRho: number): number[] {
    return seriesData.reduce((acc: number[], value: number) => {
      acc.push(-value - minRho);
      return acc;
    }, []);
  }

  private populateViewWithAirgapValues(poleValues: number[], minRho: number) {
    const maxAirgap = -Math.max(...poleValues) - minRho;
    const minAirgap = -Math.min(...poleValues) - minRho;
    const sumOfPoleValues: number = poleValues.reduce((a, b) => a + b, 0);
    const avgAirgap = -(sumOfPoleValues / poleValues.length) - minRho;

    this.smallestAirgap = minAirgap;
    this.largestAirgap = maxAirgap;
    this.averageAirgap = avgAirgap;
  }
  protected setChartOptions(): void {
    const component = this;
    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        polar: true
      },
      title: {
        text: null
      },
      pane: {
        startAngle: 0,
        endAngle: 360
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },

      tooltip: {
        formatter: function (): string {
          if (this.series.index === 0) {
            return (
              "Pole: <b>" +
              (this.x + 1) +
              "</b> Rho: <b>" +
              formatNumberByStringFormat(
                component.calculatedRotorShapeOriginal[this.x],
                component.view.displayFormat.numberFormat
              ) +
              "</b>."
            );
          } else if (this.series.index === 1) {
            return (
              "Pole: <b>" +
              (this.x + 1) +
              "</b> Airgap: <b>" +
              formatNumberByStringFormat(
                component.detectedPoleValuesOriginal[this.x],
                component.view.displayFormat.numberFormat
              ) +
              "</b>."
            );
          }
          return "";
        }
      },

      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          tickInterval: 1,
          min: 1,
          max: this.detectedPoleValues.length,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          labels: {
            formatter: function () {
              return "";
            }
          }
        },
        {
          id: SECONDARY_X_AXIS_ID,
          showFirstLabel: false,
          tickInterval: 90,
          min: 0,
          max: 360,
          gridLineColor: "#c5c5c5",
          labels: {
            formatter: function () {
              return ""; // this.value + '°';z
            }
          },
          visible: true
        }
      ],

      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          min: 0,
          showFirstLabel: false,
          tickAmount: 0,
          gridLineWidth: 0,
          ceiling: Math.max(...this.detectedPoleValues),
          max: Math.max(...this.detectedPoleValues),
          plotLines: [
            {
              id: "Smallest Airgap",
              dashStyle: "longdash",
              color: this.view.smallestAirgapColor,
              width: 1,
              value: this.smallestAirgap
            },
            {
              id: "Average Airgap",
              dashStyle: "longdash",
              color: this.view.averageAirgapColor,
              width: 1,
              value: this.averageAirgap
            },
            {
              id: "Largest Airgap",
              dashStyle: "longdash",
              color: this.view.largestAirgapColor,
              width: 1,
              value: this.largestAirgap
            }
          ]
        }
      ],

      series: [
        {
          type: "line",
          name: "Calculated Rotor Shape",
          data: this.calculatedRotorShape,
          color: this.view.calculatedRotorShapeColor,
          marker: {
            enabled: false
          }
        },
        {
          type: "line",
          name: "Detected Pole Values",
          data: this.detectedPoleValues,
          color: this.view.detectedPoleValuesColor,
          lineWidth: 0,
          marker: {
            symbol: "circle",
            radius: 2
          },
          states: {
            hover: {
              lineWidthPlus: 0
            }
          }
        }
      ],
      subtitle: {
        text: "View From Feed Side",
        align: "center",
        verticalAlign: "bottom"
      }
    };
  }
}
