import { Options } from "highcharts";
import { ICountryCode } from "../../interfaces";

export const baseChartOptions: Options = {
  chart: {
    type: "pie",
    backgroundColor: "transparent",
    plotShadow: false,
    animation: false,
    reflow: false,
    borderRadius: 50,
    margin: [0, 0, 0, 0],
    spacingTop: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  plotOptions: {
    pie: {
      size: "100%",
      innerSize: "70%",
      slicedOffset: 5,
      animation: false,
      borderColor: undefined,
      dataLabels: {
        enabled: false
      }
    }
  },
  title: {
    text: ""
  },
  legend: {
    enabled: false
  },
  series: []
};

export const mapStyles: google.maps.MapTypeStyle[] = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "administrative.country",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#E9E9EB"
      },
      {
        visibility: "on"
      },
      {
        weight: 2
      }
    ]
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#ADD8E6"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  }
];

export const countryCodes: ICountryCode[] = [
  {
    name: "Ascension Island",
    code: "ac"
  },
  {
    name: "Andorra",
    code: "ad"
  },
  {
    name: "United Arab Emirates",
    code: "ae"
  },
  {
    name: "Afghanistan",
    code: "af"
  },
  {
    name: "Antigua & Barbuda",
    code: "ag"
  },
  {
    name: "Anguilla",
    code: "ai"
  },
  {
    name: "Albania",
    code: "al"
  },
  {
    name: "Armenia",
    code: "am"
  },
  {
    name: "Angola",
    code: "ad"
  },
  {
    name: "Antarctica",
    code: "aq"
  },
  {
    name: "Argentina",
    code: "ar"
  },
  {
    name: "American Samoa",
    code: "as"
  },
  {
    name: "Austria",
    code: "at"
  },
  {
    name: "Australia",
    code: "au"
  },
  {
    name: "Aruba",
    code: "aw"
  },
  {
    name: "Åland Islands",
    code: "ax"
  },
  {
    name: "Azerbaijan",
    code: "az"
  },
  {
    name: "Bosnia & Herzegovina",
    code: "ba"
  },
  {
    name: "Barbados",
    code: "bb"
  },
  {
    name: "Bangladesh",
    code: "bd"
  },
  {
    name: "Belgium",
    code: "be"
  },
  {
    name: "Burkina Faso",
    code: "bf"
  },
  {
    name: "Bulgaria",
    code: "bg"
  },
  {
    name: "Bahrain",
    code: "bh"
  },
  {
    name: "Burundi",
    code: "bi"
  },
  {
    name: "Benin",
    code: "bj"
  },
  {
    name: "St. Barthélemy",
    code: "bl"
  },
  {
    name: "Bermuda",
    code: "bm"
  },
  {
    name: "Brunei",
    code: "bn"
  },
  {
    name: "Bolivia",
    code: "bo"
  },
  {
    name: "Caribbean Netherlands",
    code: "bq"
  },
  {
    name: "Brazil",
    code: "br"
  },
  {
    name: "Bahamas",
    code: "bs"
  },
  {
    name: "Bhutan",
    code: "bt"
  },
  {
    name: "Bouvet Island",
    code: "bv"
  },
  {
    name: "Botswana",
    code: "bw"
  },
  {
    name: "Belarus",
    code: "by"
  },
  {
    name: "Belize",
    code: "bz"
  },
  {
    name: "Canada",
    code: "ca"
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "cc"
  },
  {
    name: "Congo - Kinshasa",
    code: "cg"
  },
  {
    name: "Central African Republic",
    code: "cf"
  },
  {
    name: "Congo - Brazzaville",
    code: "cd"
  },
  {
    name: "Switzerland",
    code: "ch"
  },
  {
    name: "Côte d’Ivoire",
    code: "ci"
  },
  {
    name: "Cook Islands",
    code: "ck"
  },
  {
    name: "Chile",
    code: "cl"
  },
  {
    name: "Cameroon",
    code: "cm"
  },
  {
    name: "China",
    code: "cn"
  },
  {
    name: "Colombia",
    code: "co"
  },
  {
    name: "Clipperton Island",
    code: "cp"
  },
  {
    name: "Costa Rica",
    code: "cr"
  },
  {
    name: "Cuba",
    code: "cu"
  },
  {
    name: "Cape Verde",
    code: "cv"
  },
  {
    name: "Curaçao",
    code: "cw"
  },
  {
    name: "Christmas Island",
    code: "cx"
  },
  {
    name: "Cyprus",
    code: "cy"
  },
  {
    name: "Czechia",
    code: "cz"
  },
  {
    name: "Germany",
    code: "de"
  },
  {
    name: "Diego Garcia",
    code: "dg"
  },
  {
    name: "Djibouti",
    code: "dj"
  },
  {
    name: "Denmark",
    code: "dk"
  },
  {
    name: "Dominica",
    code: "dm"
  },
  {
    name: "Dominican Republic",
    code: "do"
  },
  {
    name: "Algeria",
    code: "dz"
  },
  {
    name: "Ceuta & Melilla",
    code: "ea"
  },
  {
    name: "Ecuador",
    code: "ec"
  },
  {
    name: "Estonia",
    code: "ee"
  },
  {
    name: "Egypt",
    code: "eg"
  },
  {
    name: "Western Sahara",
    code: "eh"
  },
  {
    name: "Eritrea",
    code: "er"
  },
  {
    name: "Spain",
    code: "es"
  },
  {
    name: "Ethiopia",
    code: "et"
  },
  {
    name: "European Union",
    code: "eu"
  },
  {
    name: "Finland",
    code: "fi"
  },
  {
    name: "Fiji",
    code: "fj"
  },
  {
    name: "Falkland Islands",
    code: "fk"
  },
  {
    name: "Micronesia",
    code: "fm"
  },
  {
    name: "Faroe Islands",
    code: "fo"
  },
  {
    name: "France",
    code: "fr"
  },
  {
    name: "Gabon",
    code: "ga"
  },
  {
    name: "United Kingdom",
    code: "gb"
  },
  {
    name: "Grenada",
    code: "gd"
  },
  {
    name: "Georgia",
    code: "ge"
  },
  {
    name: "French Guiana",
    code: "gf"
  },
  {
    name: "Guernsey",
    code: "gg"
  },
  {
    name: "Ghana",
    code: "gh"
  },
  {
    name: "Gibraltar",
    code: "gi"
  },
  {
    name: "Greenland",
    code: "gl"
  },
  {
    name: "Gambia",
    code: "gm"
  },
  {
    name: "Guinea",
    code: "gn"
  },
  {
    name: "Guadeloupe",
    code: "gp"
  },
  {
    name: "Equatorial Guinea",
    code: "gq"
  },
  {
    name: "Greece",
    code: "gr"
  },
  {
    name: "South Georgia & South', Sandwich Islands",
    code: "gs"
  },
  {
    name: "Guatemala",
    code: "gt"
  },
  {
    name: "Guam",
    code: "gu"
  },
  {
    name: "Guinea-Bissau",
    code: "gw"
  },
  {
    name: "Guyana",
    code: "gy"
  },
  {
    name: "Hong Kong SAR China",
    code: "hk"
  },
  {
    name: "Heard & McDonald Islands",
    code: "hm"
  },
  {
    name: "Honduras",
    code: "hn"
  },
  {
    name: "Croatia",
    code: "hr"
  },
  {
    name: "Haiti",
    code: "ht"
  },
  {
    name: "Hungary",
    code: "hu"
  },
  {
    name: "Canary Islands",
    code: "ic"
  },
  {
    name: "Indonesia",
    code: "id"
  },
  {
    name: "Ireland",
    code: "ie"
  },
  {
    name: "Israel",
    code: "il"
  },
  {
    name: "Isle of Man",
    code: "im"
  },
  {
    name: "India",
    code: "in"
  },
  {
    name: "British Indian Ocean Territory",
    code: "io"
  },
  {
    name: "Iraq",
    code: "iq"
  },
  {
    name: "Iran",
    code: "ir"
  },
  {
    name: "Iceland",
    code: "is"
  },
  {
    name: "Italy",
    code: "it"
  },
  {
    name: "Jersey",
    code: "je"
  },
  {
    name: "Jamaica",
    code: "jm"
  },
  {
    name: "Jordan",
    code: "jo"
  },
  {
    name: "Japan",
    code: "jp"
  },
  {
    name: "Kenya",
    code: "ke"
  },
  {
    name: "Kyrgyzstan",
    code: "kg"
  },
  {
    name: "Cambodia",
    code: "kh"
  },
  {
    name: "Kiribati",
    code: "ki"
  },
  {
    name: "Comoros",
    code: "km"
  },
  {
    name: "St. Kitts & Nevis",
    code: "kn"
  },
  {
    name: "North Korea",
    code: "kp"
  },
  {
    name: "South Korea",
    code: "kr"
  },
  {
    name: "Kuwait",
    code: "kw"
  },
  {
    name: "Cayman Islands",
    code: "ky"
  },
  {
    name: "Kazakhstan",
    code: "kz"
  },
  {
    name: "Laos",
    code: "la"
  },
  {
    name: "Lebanon",
    code: "lb"
  },
  {
    name: "St. Lucia",
    code: "lc"
  },
  {
    name: "Liechtenstein",
    code: "li"
  },
  {
    name: "Sri Lanka",
    code: "lk"
  },
  {
    name: "Liberia",
    code: "lr"
  },
  {
    name: "Lesotho",
    code: "ls"
  },
  {
    name: "Lithuania",
    code: "lt"
  },
  {
    name: "Luxembourg",
    code: "lu"
  },
  {
    name: "Latvia",
    code: "lv"
  },
  {
    name: "Libya",
    code: "ly"
  },
  {
    name: "Morocco",
    code: "ma"
  },
  {
    name: "Monaco",
    code: "mc"
  },
  {
    name: "Moldova",
    code: "md"
  },
  {
    name: "Montenegro",
    code: "me"
  },
  {
    name: "St. Martin",
    code: "mf"
  },
  {
    name: "Madagascar",
    code: "mg"
  },
  {
    name: "Marshall Islands",
    code: "mh"
  },
  {
    name: "North Macedonia",
    code: "mk"
  },
  {
    name: "Mali",
    code: "ml"
  },
  {
    name: "Myanmar (Burma)",
    code: "mm"
  },
  {
    name: "Mongolia",
    code: "mn"
  },
  {
    name: "Macao Sar China",
    code: "mo"
  },
  {
    name: "Northern Mariana Islands",
    code: "mp"
  },
  {
    name: "Martinique",
    code: "mq"
  },
  {
    name: "Mauritania",
    code: "mr"
  },
  {
    name: "Montserrat",
    code: "ms"
  },
  {
    name: "Malta",
    code: "mt"
  },
  {
    name: "Mauritius",
    code: "mu"
  },
  {
    name: "Maldives",
    code: "mv"
  },
  {
    name: "Malawi",
    code: "mw"
  },
  {
    name: "Mexico",
    code: "mx"
  },
  {
    name: "Malaysia",
    code: "my"
  },
  {
    name: "Mozambique",
    code: "mz"
  },
  {
    name: "Namibia",
    code: "na"
  },
  {
    name: "New Caledonia",
    code: "nc"
  },
  {
    name: "Niger",
    code: "ne"
  },
  {
    name: "Norfolk Island",
    code: "nf"
  },
  {
    name: "Nigeria",
    code: "ng"
  },
  {
    name: "Nicaragua",
    code: "ni"
  },
  {
    name: "Netherlands",
    code: "nl"
  },
  {
    name: "Norway",
    code: "no"
  },
  {
    name: "Nepal",
    code: "np"
  },
  {
    name: "Nauru",
    code: "nr"
  },
  {
    name: "Niue",
    code: "nu"
  },
  {
    name: "New Zealand",
    code: "nz"
  },
  {
    name: "Oman",
    code: "om"
  },
  {
    name: "Panama",
    code: "pa"
  },
  {
    name: "Peru",
    code: "pe"
  },
  {
    name: "French Polynesia",
    code: "pf"
  },
  {
    name: "Papua New Guinea",
    code: "pg"
  },
  {
    name: "Philippines",
    code: "ph"
  },
  {
    name: "Pakistan",
    code: "pk"
  },
  {
    name: "Poland",
    code: "pl"
  },
  {
    name: "St. Pierre & Miquelon",
    code: "pm"
  },
  {
    name: "Pitcairn Islands",
    code: "pn"
  },
  {
    name: "Puerto Rico",
    code: "pr"
  },
  {
    name: "Palestinian Territories",
    code: "ps"
  },
  {
    name: "Portugal",
    code: "pt"
  },
  {
    name: "Palau",
    code: "pw"
  },
  {
    name: "Paraguay",
    code: "py"
  },
  {
    name: "Qatar",
    code: "qa"
  },
  {
    name: "Réunion",
    code: "re"
  },
  {
    name: "Romania",
    code: "ro"
  },
  {
    name: "Serbia",
    code: "yu"
  },
  {
    name: "Russia",
    code: "ru"
  },
  {
    name: "Rwanda",
    code: "rw"
  },
  {
    name: "Saudi Arabia",
    code: "sa"
  },
  {
    name: "Solomon Islands",
    code: "sb"
  },
  {
    name: "Seychelles",
    code: "sc"
  },
  {
    name: "Sudan",
    code: "sd"
  },
  {
    name: "Sweden",
    code: "se"
  },
  {
    name: "Singapore",
    code: "sg"
  },
  {
    name: "St. Helena",
    code: "sh"
  },
  {
    name: "Slovenia",
    code: "si"
  },
  {
    name: "Svalbard & Jan Mayen",
    code: "sj"
  },
  {
    name: "Slovakia",
    code: "sk"
  },
  {
    name: "Sierra Leone",
    code: "sl"
  },
  {
    name: "San Marino",
    code: "sm"
  },
  {
    name: "Senegal",
    code: "sn"
  },
  {
    name: "Somalia",
    code: "so"
  },
  {
    name: "Suriname",
    code: "sr"
  },
  {
    name: "South Sudan",
    code: "ss"
  },
  {
    name: "São Tomé & Príncipe",
    code: "st"
  },
  {
    name: "El Salvador",
    code: "sv"
  },
  {
    name: "Sint Maarten",
    code: "sx"
  },
  {
    name: "Syria",
    code: "sy"
  },
  {
    name: "Eswatini",
    code: "sz"
  },
  {
    name: "Tristan Da Cunha",
    code: "sh"
  },
  {
    name: "Turks & Caicos Islands",
    code: "tc"
  },
  {
    name: "Chad",
    code: "td"
  },
  {
    name: "French Southern Territories",
    code: "tf"
  },
  {
    name: "Togo",
    code: "tg"
  },
  {
    name: "Thailand",
    code: "th"
  },
  {
    name: "Tajikistan",
    code: "tj"
  },
  {
    name: "Tokelau",
    code: "tk"
  },
  {
    name: "Timor-Leste",
    code: "tl"
  },
  {
    name: "Turkmenistan",
    code: "tm"
  },
  {
    name: "Tunisia",
    code: "tn"
  },
  {
    name: "Tonga",
    code: "to"
  },
  {
    name: "Turkey",
    code: "tr"
  },
  {
    name: "Trinidad & Tobago",
    code: "tt"
  },
  {
    name: "Tuvalu",
    code: "tv"
  },
  {
    name: "Taiwan",
    code: "tw"
  },
  {
    name: "Tanzania",
    code: "tz"
  },
  {
    name: "Ukraine",
    code: "ua"
  },
  {
    name: "Uganda",
    code: "ug"
  },
  {
    name: "U.S. Outlying Islands",
    code: "um"
  },
  {
    name: "United Nations",
    code: "un"
  },
  {
    name: "United States",
    code: "us"
  },
  {
    name: "USA",
    code: "us"
  },
  {
    name: "Uruguay",
    code: "uy"
  },
  {
    name: "Uzbekistan",
    code: "uz"
  },
  {
    name: "Vatican City",
    code: "va"
  },
  {
    name: "St. Vincent & Grenadines",
    code: "vc"
  },
  {
    name: "Venezuela",
    code: "ve"
  },
  {
    name: "British Virgin Islands",
    code: "vg"
  },
  {
    name: "U.S. Virgin Islands",
    code: "vi"
  },
  {
    name: "Vietnam",
    code: "vn"
  },
  {
    name: "Vanuatu",
    code: "vu"
  },
  {
    name: "Wallis & Futuna",
    code: "wf"
  },
  {
    name: "Samoa",
    code: "ws"
  },
  {
    name: "Kosovo",
    code: "xk"
  },
  {
    name: "Yemen",
    code: "ye"
  },
  {
    name: "Mayotte",
    code: "yt"
  },
  {
    name: "South Africa",
    code: "za"
  },
  {
    name: "Zambia",
    code: "zm"
  },
  {
    name: "Zimbabwe",
    code: "zw"
  },
  {
    name: "England",
    code: "uk"
  },
  {
    name: "Scotland",
    code: "uk"
  },
  {
    name: "Wales",
    code: "uk"
  }
];

