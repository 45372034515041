import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { LinkResolver } from "../../../../browsing/services/link-resolver";
import { ValueFormatterService } from "../../../../core/services/value-formatter.service";
import { ColorListService } from "../../../../environment/services/color-list.service";
import { isDefined } from "../../../../ts-utils";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { DataStatus } from "../../../models/data-status";
import { IPieDisplayConfig } from "../../../models/i-view-config/i-base-display-config";
import { DataConnectorDescriptor } from "../../../models/store/data-connector-descriptor";
import { RuntimeSettingsSelector } from "../../../services/entity-selectors/runtime-settings.selector";
import { DonutDisplayService } from "../../../services/highcharts/donut-display.service";
import { PieDisplayService } from "../../../services/highcharts/pie-display.service";
import { SimpleChartComponent } from "../simple-chart/simple-chart.component";

@Component({
  selector: "c-pie",
  templateUrl: "./pie.component.html",
  styleUrls: ["./pie.component.scss"],
  host: { class: "simple-component" },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PieComponent extends SimpleChartComponent implements OnChanges {
  constructor(
    private linkResolver: LinkResolver,
    ngZone: NgZone,
    protected colorService: ColorListService,
    runtimeSettingsService: RuntimeSettingsSelector,
    private valueFormatter: ValueFormatterService
  ) {
    super(ngZone, colorService, runtimeSettingsService);
  }
  @Input()
  public values: Maybe<DataConnectorDescriptor[]> = null;

  @Input() viewConfig: Maybe<IPieDisplayConfig> = null;

  @Input() dataStatus: DataStatus = DataStatus.NoDataDefined;

  @Input()
  asDonut: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (isDefined(this.viewConfig)) {
      const displayService = this.getDisplayService();

      const options = displayService.getChartOptions(
        this.viewConfig,
        this.values ?? [],
        this.linkResolver
      );
      this.mergeChartOptions(options, false, this.dataStatus);
    }
  }

  private getDisplayService(): PieDisplayService {
    return this.asDonut
      ? new DonutDisplayService(this.valueFormatter)
      : new PieDisplayService(this.valueFormatter);
  }
}
