import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import * as Highcharts from "highcharts";
import { TooltipFormatterContextObject } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { DataConnectorDto } from "../../../data-connectivity";
import { DataPointDto } from "../../../data-connectivity/models/data-point";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  EditableWidget,
  LayoutBuilder,
  NumberOfDataPointsToRequest
} from "../../../meta/decorators";
import { getEntityTitle } from "../../../meta/helpers/get-title.helper";
import { ComponentCategory } from "../../../meta/models";
import { insertItemsIntoGroups, sortGroups } from "../../../property-sheet/helpers/groups.helper";
import { ConnectorGroupDto } from "../../../shared/models/connector-group";
import {
  getNumber,
  isDefined,
  isEmpty,
  isEmptyOrNotDefined,
  last,
  tryConvertToNumber
} from "../../../ts-utils/helpers";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { SPIDER_CHART } from "../../models/help-constants";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { DataConnectorViewSelector } from "../../services/entity-selectors/data-connector-view.selector";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID
} from "../../services/highcharts/base-highcharts-options.helper";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ChartComponent } from "../chart/chart.component";
import { Roles } from "./roles";
import { DisplayStrategies, SpiderChartViewConfig } from "./view-config";

const NO_GROUP_SERIES_NAME = "Non series";
const NO_TOOLTIP_POINT_NAME = "NoTooltip";

@Component({
  selector: "c-spider-chart",
  templateUrl: "./spider-chart.component.html",
  styleUrls: ["./spider-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: SpiderChartComponent }]
})
@LayoutBuilder(
  ComponentCategory.Category,
  "SpiderChartComponent",
  "Spider-Chart-3",
  "abb-km-icon ",
  null,
  LOCALIZATION_DICTIONARY.layoutEditor.SpiderChart,
  SPIDER_CHART
)
@ConnectorRoles(Roles)
@MaxConnectors(50)
@NumberOfDataPointsToRequest(calculateNumberOfDataPointsToRequest)
@EditableWidget({
  fullName: "SpiderChartComponent",
  title: "spider-chart"
})
export class SpiderChartComponent extends ChartComponent {
  public Highcharts = Highcharts;
  public viewConfig!: SpiderChartViewConfig;
  groups: ConnectorGroupDto[] = [];

  @View(SpiderChartViewConfig)
  public get view(): SpiderChartViewConfig {
    return this.currentState.view as SpiderChartViewConfig;
  }

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    protected cdr: ChangeDetectorRef,
    public dataConnectorViewSelector: DataConnectorViewSelector,
    private valueFormatter: ValueFormatterService
  ) {
    super(params, hostElementRef, cdr);
  }

  protected updateChartData(): void {
    const interpolatedProperties =
      this.propertyInterpolationService.prepareAndInterpolateProperties<SpiderChartViewConfig>(
        this.currentState,
        this.dataAccessor.getAllConnectors()
      );
    this.viewConfig = interpolatedProperties.viewConfig;
    const dataConnectors = interpolatedProperties.connectorDescriptors.reduce(
      (acc: DataConnectorDto[], descriptor: DataConnectorDescriptor) => {
        if (isDefined(descriptor?.connector) && isDefined(descriptor.connector.dataPoints)) {
          acc.push(descriptor.connector);
        }
        return acc;
      },
      []
    );

    this.setGroups(dataConnectors);
    this.resetChartData();

    const maxNumberOfDcs = getMaxNumberOfDCsInGroups(this.groups);
    const categories: string[] = this.getXAxesCategories(maxNumberOfDcs);

    if (isDefined(this.chartObject) && isDefined(this.groups)) {
      this.chartObject.xAxis[0].setCategories(categories, false);
      this.groups.forEach((group: ConnectorGroupDto, currIndex) => {
        const groupDCsValues: any[] = [];
        let numberOfDcs: number = 0;
        group.items.forEach((dataConnector: DataConnectorDto) => {
          if (isDefined(dataConnector.dataPoints)) {
            const lastDataPoint: Maybe<DataPointDto> = last(dataConnector.dataPoints);
            groupDCsValues.push([
              getEntityTitle(dataConnector),
              getNumber(lastDataPoint?.evaluatedValue ?? lastDataPoint?.y)
            ]);
            numberOfDcs++;
          }
        });

        if (isEmpty(groupDCsValues)) {
          return;
        }

        while (numberOfDcs < maxNumberOfDcs) {
          groupDCsValues.push([NO_TOOLTIP_POINT_NAME, 0]);
          numberOfDcs++;
        }

        if (groupDCsValues.length === 1) {
          groupDCsValues.push([NO_TOOLTIP_POINT_NAME, 0]);
        }

        this.chartObject?.addSeries(
          {
            name: group.id === "" ? NO_GROUP_SERIES_NAME : group.name,
            type: this.getType(currIndex),
            pointPlacement: "on",
            data: groupDCsValues
          },
          false,
          false
        );
      });
    }
  }

  private getType(currIndex: number) {
    const defaultType = "line" as any;
    let fallbackToDefault = false;
    if (isDefined(this.viewConfig.seriesConfigs[currIndex])) {
      const selectedType = this.viewConfig.seriesConfigs[currIndex].type;
      fallbackToDefault =
        this.viewConfig.displayStrategies === DisplayStrategies.SpiderChart &&
        selectedType?.toLowerCase() === "column";
      return fallbackToDefault ? defaultType : selectedType?.toLowerCase();
    }
    return defaultType;
  }

  private setGroups(dataConnectors: DataConnectorDto[]): void {
    this.groups = [];
    const filledGroups = insertItemsIntoGroups(
      this.viewConfig.groups ?? [],
      dataConnectors,
      this.dataConnectorViewSelector
    );
    this.groups = sortGroups(filledGroups);
  }

  private resetChartData(): void {
    if (isDefined(this.chartObject)) {
      this.chartObject.xAxis[0].setCategories([], false);
      this.chartObject.update(
        {
          series: []
        },
        true,
        true,
        false
      );
    }
  }

  getXAxesCategories(numberOfAxes: number): string[] {
    const categories: string[] = [];
    new Array(numberOfAxes).fill(0).forEach((_, index) => {
      if (
        !isEmptyOrNotDefined(this.view.axisNames) &&
        !isEmptyOrNotDefined(this.view.axisNames[index])
      ) {
        categories.push(this.view.axisNames[index]);
      } else {
        categories.push("");
      }
    });
    return categories;
  }

  protected setChartOptions(): void {
    const component = this;
    const isPolarChart = this.viewConfig.displayStrategies === DisplayStrategies.PolarChart;
    const options: Highcharts.Options = {
      chart: {
        polar: true
      },
      pane: {
        size: "80%"
      },
      title: {
        text: this.viewConfig.title ?? ""
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          gridLineInterpolation: isPolarChart ? "circle" : "polygon",
          labels: {
            formatter: function () {
              return component.valueFormatter.formatValue(
                Number(this.value),
                component.view.displayFormat
              );
            }
          },
          min: tryConvertToNumber(this.viewConfig.min),
          max: tryConvertToNumber(this.viewConfig.max)
        }
      ],
      tooltip: {
        shared: true,
        headerFormat: "",
        formatter: function () {
          return component.getTooltip(this.points, component);
        }
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical"
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal"
              }
            }
          }
        ]
      }
    };
    this.mergeChartOptions(options);
    this.chartOptions.xAxis = [
      {
        id: PRIMARY_X_AXIS_ID,
        tickmarkPlacement: "on",
        lineWidth: 0
      }
    ];
  }

  private getTooltip(points: TooltipFormatterContextObject[], component: SpiderChartComponent) {
    const result = points.reduce((previousValue, point) => {
      const pointName = point.key as any;
      return pointName !== NO_TOOLTIP_POINT_NAME
        ? `${previousValue}<span style='color:${
            point.color
          }'>${pointName}:<b>${component.valueFormatter.formatValue(
            point.y,
            component.viewConfig.displayFormat
          )}</b></br>`
        : previousValue;
    }, "");

    return result === "" ? false : result;
  }
}
function getMaxNumberOfDCsInGroups(groups: ConnectorGroupDto[]): number {
  let maxNumberOfDcs = 0;
  groups.forEach((group: ConnectorGroupDto) => {
    const numberOfDcs = group.items.length;
    if (maxNumberOfDcs < numberOfDcs) {
      maxNumberOfDcs = numberOfDcs;
    }
  });
  return maxNumberOfDcs;
}

export function calculateNumberOfDataPointsToRequest(): number {
  return 1;
}
