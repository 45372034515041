import { getSupportedApiUrls } from "../../../core/helpers/api-data-source.helper";
import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableArray } from "../../../meta/decorators/configurable-array.decorator";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { EditorType, PropertyCategory } from "../../../meta/models";
import { EditorSize } from "../../../meta/models/editor-size";
import { isEmptyOrNotDefined } from "../../../ts-utils";
import { KeyValuePair } from "../key-value-pair";
import { DataSourceDto } from "./data-source";
import { API_DATA_SOURCE, ApiDataSourceType } from "./data-source.type";

// @dynamic
@EditableType({ fullName: API_DATA_SOURCE, title: "api-data-source-dto" })
export class ApiDataSourceDto extends DataSourceDto {
  typeName: ApiDataSourceType = API_DATA_SOURCE;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: getSupportedApiUrls,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Url,
    editorType: EditorType.EditableSelectList,
    showCustomInputInSelectList: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ApiUrlTooltip
  })
  @Serializable()
  url!: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableArray({
    editorType: EditorType.Array,
    typeConstructor: KeyValuePair,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    arrayEditorSize: EditorSize.Small,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.APIDataSourceTooltip
  })
  @Serializable([], KeyValuePair)
  params: KeyValuePair[];

  constructor(url: string, params: KeyValuePair[]) {
    super();
    const partial: Partial<ApiDataSourceDto> = { url, params };
    construct(this, partial, API_DATA_SOURCE);
  }
}

export function isEmptyApiSource(apiDataSource: ApiDataSourceDto): boolean {
  return isEmptyOrNotDefined(apiDataSource.url);
}
