import moment from "moment";
import { LiveModeFilter } from "../../../shared/models/live-mode-filter";
import { TimePresetType } from "../../../shared/models/time-preset-filter";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import {
  CURRENT,
  FIXED_DATE_REGEX,
  GENERIC_DATE_PARAMETER_REGEX,
  GENERIC_DATE_REGEX,
  GLOBAL_OFFSET_REGEX,
  isGenericDateExpression,
  isGlobalOffsetDateExpression,
  LIVE_MODE_TIME_UNIT
} from "../../helpers/filter/filter-validation.helper";
import { TimeRange, TimeRangeConfigurationDto, TimeUnit } from "../../models";

const GENERIC_DATE_INDEX_YEAR = 1;
const GENERIC_DATE_INDEX_MONTH = 5;
const GENERIC_DATE_INDEX_DAY = 9;
const GENERIC_DATE_INDEX_HOUR = 13;
const GENERIC_DATE_INDEX_MINUTE = 17;
export class DateExpressionParser {
  public createDateFromExpression(expression: string, sourceTimeRange: TimeRange): Date | null {
    expression = expression.replace(/\s/g, "");

    if (isGenericDateExpression(expression)) {
      return this.createDateFromGenericExpression(expression, sourceTimeRange);
    } else if (isGlobalOffsetDateExpression(expression)) {
      return this.createDateFromGlobalOffset(expression, sourceTimeRange);
    } else {
      return null;
    }
  }

  private createDateFromGlobalOffset(expression: string, sourceTimeRange: TimeRange): Date | null {
    const expressionResult = GLOBAL_OFFSET_REGEX.exec(expression);
    if (expressionResult) {
      const referentDate = expressionResult[2];
      const sourceDate = referentDate === "Start" ? sourceTimeRange.from : sourceTimeRange.to;
      const hasOffset = expressionResult[4] != null;
      if (hasOffset) {
        const offsetValue = parseInt(expressionResult[4].replace(/\s/g, ""));
        const offsetType = expressionResult[7];
        return moment(sourceDate)
          .add(offsetValue as any, offsetType)
          .toDate();
      } else {
        return moment(sourceDate).toDate();
      }
    } else {
      return null;
    }
  }

  public createDateFromFixedExpression(expression: string): Date | null {
    expression = expression.replace(/\s/g, "");
    const fixedDateFormatMatch = FIXED_DATE_REGEX.exec(expression);
    if (fixedDateFormatMatch != null) {
      const year = Number(fixedDateFormatMatch[1]);
      const month = Number(fixedDateFormatMatch[3]) - 1;
      const day = fixedDateFormatMatch[5] ? Number(fixedDateFormatMatch[5]) : 1;
      const hours = fixedDateFormatMatch[7] ? Number(fixedDateFormatMatch[7]) : 0;
      const minutes = fixedDateFormatMatch[9] ? Number(fixedDateFormatMatch[9]) : 0;
      const seconds = fixedDateFormatMatch[11] ? Number(fixedDateFormatMatch[11]) : 0;
      const date: Date = new Date(year, month, day, hours, minutes, seconds);
      return date;
    }
    return null;
  }

  private createDateFromGenericExpression(
    expression: string,
    sourceTimeRange: TimeRange
  ): Date | null {
    const expressionResult = GENERIC_DATE_REGEX.exec(expression);
    if (expressionResult) {
      const year = this.getParameterValueFromExpression(
        expressionResult[GENERIC_DATE_INDEX_YEAR],
        sourceTimeRange,
        TimeUnit.Years
      );
      const month = this.getParameterValueFromExpression(
        expressionResult[GENERIC_DATE_INDEX_MONTH],
        sourceTimeRange,
        TimeUnit.Months
      );
      const day = expressionResult[GENERIC_DATE_INDEX_DAY]
        ? this.getParameterValueFromExpression(
            expressionResult[GENERIC_DATE_INDEX_DAY],
            sourceTimeRange,
            TimeUnit.Days
          )
        : 1;
      const hours = expressionResult[GENERIC_DATE_INDEX_HOUR]
        ? this.getParameterValueFromExpression(
            expressionResult[GENERIC_DATE_INDEX_HOUR],
            sourceTimeRange,
            TimeUnit.Hours
          )
        : 0;
      const minutes = expressionResult[GENERIC_DATE_INDEX_MINUTE]
        ? this.getParameterValueFromExpression(
            expressionResult[GENERIC_DATE_INDEX_MINUTE],
            sourceTimeRange,
            TimeUnit.Minutes
          )
        : 0;
      const newDate: Date = new Date(year, month, day, hours, minutes);
      return newDate;
    }
    return null;
  }

  private getParameterValueFromExpression(
    parameterExpression: string,
    sourceTimeRange: TimeRange,
    paramterType: TimeUnit
  ): number {
    const parameterExec = GENERIC_DATE_PARAMETER_REGEX.exec(parameterExpression);
    if (parameterExec != null) {
      const sourceDate = parameterExec[1] === "Start" ? sourceTimeRange.from : sourceTimeRange.to;
      const parameterSourceValue = this.getRelativeParameterSourceValue(sourceDate, paramterType);
      const offset = parameterExec[2];
      return offset ? parameterSourceValue + Number(offset) : parameterSourceValue;
    } else {
      return paramterType === TimeUnit.Months
        ? Number(parameterExpression) - 1
        : Number(parameterExpression);
    }
  }

  private getRelativeParameterSourceValue(sourceDate: Date, paramterType: TimeUnit): number {
    switch (paramterType) {
      case TimeUnit.Years: {
        return sourceDate.getFullYear();
      }
      case TimeUnit.Months: {
        return sourceDate.getMonth();
      }
      case TimeUnit.Days: {
        return sourceDate.getDate();
      }
      case TimeUnit.Hours: {
        return sourceDate.getHours();
      }
      case TimeUnit.Minutes: {
        return sourceDate.getMinutes();
      }
      default:
        return 0;
    }
  }

  public createExpressionFromDate(date: Date, includeSeconds: boolean = true): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return includeSeconds
      ? `Date(${year},${month},${day},${hours},${minutes},${date.getSeconds()})`
      : `Date(${year},${month},${day},${hours},${minutes})`;
  }

  public createStandaloneFilterLiveModeExpressions(spanInHours: number): TimeRangeConfigurationDto {
    const fromExpression = `${CURRENT}-${spanInHours}${LIVE_MODE_TIME_UNIT}`;
    return new TimeRangeConfigurationDto({ fromExpression });
  }

  public createFilterLiveModeExpressions(
    liveModeFilter: Maybe<LiveModeFilter>,
    timePreset: TimePresetType
  ): TimeRangeConfigurationDto {
    const fromExpression = `${CURRENT}-${liveModeFilter?.amount}${liveModeFilter?.unit}`;
    return new TimeRangeConfigurationDto({ fromExpression, timePreset });
  }
}
