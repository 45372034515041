import "reflect-metadata";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { HELP_LANDING_PAGE } from "../../elements/models/help-constants";
import { Access } from "../models/access";
import { EditorType } from "../models/editor-type";
import {
  PropertyDescriptor,
  defaultValidationFunction,
  hideLabel
} from "../models/property-descriptor";
import { ValidationFunction } from "../models/validation-function";
import { TypeProvider } from "../services/type-provider";

const TYPE_PROVIDER = TypeProvider.getInstance();

export type UserHelpFactory = (context: DecoratorDelegateContext) => string;

export class ConfigurableDecoratorParams {
  displayName?: string;
  editorType?: EditorType;
  access?: Access;
  tooltipKey?: string;
  validationFunction?: ValidationFunction;
  allowEmpty?: boolean;
  advancedMode?: boolean;
  isGroupable?: boolean;
  inlineAlignment?: boolean;
  showLabel?: boolean;
  userHelp?: string | UserHelpFactory;
}

export function Configurable(params: ConfigurableDecoratorParams) {
  return function (proto: Object, key: string) {
    const displayName = params.displayName || key;
    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      displayName,
      editorType: params.editorType || EditorType.TextBox,
      access: params.access || Access.Write,
      isHidden: false,
      tooltipKey: params.tooltipKey || displayName,
      validationFunction: params.validationFunction ?? defaultValidationFunction,
      allowEmpty: params.allowEmpty ?? true,
      advancedMode: params.advancedMode ?? false,
      isGroupable: params.isGroupable ?? false,
      inlineAlignment: params.inlineAlignment ?? false,
      showLabel: params.showLabel ?? !hideLabel(params.editorType ?? EditorType.TextBox),
      userHelp: params.userHelp ?? HELP_LANDING_PAGE
    };

    TYPE_PROVIDER.upsertProperty(proto, key, partialPropertyDescriptor);
  };
}
