import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren
} from "@angular/core";
import { MatTab, MatTabChangeEvent } from "@angular/material/tabs";
import { cloneDeep as _cloneDeep, isEqual as _isEqual } from "lodash";
import { takeUntil } from "rxjs/operators";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { EntityId } from "../../../meta/models/entity";
import { isDefined, isEmpty, isNotDefined } from "../../../ts-utils/helpers";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { getConfiguredOrForegroundColor } from "../../helpers/color.helper";
import { findClosestBasicCard } from "../../helpers/widget-dragging.helper";
import { ComponentStateDto } from "../../models/component-state";
import { TAB_CONTENT, TAB_GROUP } from "../../models/element-type.constants";
import { PositioningType } from "../../models/positioning-type";
import { TabConfig } from "../../models/tab-config";
import { findSelectableParent } from "../../services/component-selection.service";
import { ContainerTimestampService } from "../../services/container-timestamp.service";
import { ComponentStateActions } from "../../store/component-state/component-state.actions";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ContainerComponentViewConfig } from "../container/view-config";
import { TabContentViewConfig } from "../tab-content/view-config";
import { TabGroupCardViewConfig } from "./view-config";

@Component({
  selector: "c-tab-card",
  templateUrl: "./tab-group.component.html",
  styleUrls: ["./tab-group.component.scss"]
})
@LayoutBuilder(
  ComponentCategory.Container,
  TAB_GROUP,
  "icon-Tab-Group",
  "dashboard-icon",
  null,
  LOCALIZATION_DICTIONARY.layoutEditor.TabGroup
)
@ConnectorRoles()
@EditableWidget({ fullName: TAB_GROUP, title: "tab-group" })
export class TabGroupComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChildren(MatTab) protected tabList: QueryList<MatTab>;
  tabsWithDefinedContentContainer: TabConfig[] = [];

  private tabConfigsBeforeViewChange: TabConfig[] = [];
  private timestampService = new ContainerTimestampService();
  private activeTabContentIndex: number = 0;
  private cardSnapToGridMode: boolean = false;
  private cardPositioningMode: PositioningType = PositioningType.Absolute;

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const parentCardView = this.componentStateSelector.getParent(this.id)
      ?.view as ContainerComponentViewConfig;
    if (isDefined(parentCardView)) {
      this.cardSnapToGridMode = parentCardView.snapToGrid;
      this.cardPositioningMode = parentCardView.positioningType;
    }
    this.subscribeOnTabConfigChanges();
    this.subscribeToCardSnapToGridMode();
    this.subscribeToCardAutoPositionMode();
  }

  protected updateDisplay(): void {
    this.getInterpolatedTabsConfig(this.view.tabs);
  }

  private subscribeOnTabConfigChanges(): void {
    this.componentStatePropertySelector.subscribeOnSliceWithPreviousValue(
      (state) => (state.view as TabGroupCardViewConfig).tabs,
      ([previousTabs, currentTabs]) => {
        const addedTabs: TabConfig[] = findAddedTabs(previousTabs, currentTabs);
        if (!isEmpty(addedTabs)) {
          this.addContentForNewTabs(addedTabs);
        }
        if (areTabConfigsChanged(this.tabsWithDefinedContentContainer, currentTabs)) {
          this.getInterpolatedTabsConfig(currentTabs, !isEmpty(addedTabs));
        }
        this.cdr.detectChanges();
      }
    );
  }

  private getInterpolatedTabsConfig(tabs: TabConfig[], ignoreInterpolation: boolean = false): void {
    this.tabsWithDefinedContentContainer = tabs.reduce((acc: TabConfig[], tab: TabConfig) => {
      if (isDefined(tab.contentContainerId)) {
        const tabConfig = ignoreInterpolation
          ? tab
          : (this.propertyInterpolationService.interpolatePropertiesForTarget(tab, {
              componentViewModel: this.componentStateVmDeserializer.convert(this.currentState),
              connectorDescriptors: []
            }) as TabConfig);
        acc.push(tabConfig);
      }
      return acc;
    }, []);
  }

  private subscribeToCardSnapToGridMode(): void {
    const basicCardAncestor = findSelectableParent(
      this.currentState.id,
      this.componentStateSelector
    );
    if (isDefined(basicCardAncestor)) {
      this.componentStateSelector
        .selectSnapToGrid(basicCardAncestor.id)
        .pipe(takeUntil(this.unsubscribeSubject$))
        .subscribe((parentCardSnapToGridMode: boolean) => {
          this.cardSnapToGridMode = parentCardSnapToGridMode;
          this.updateTabsSnapToGrid();
        });
    }
  }

  private subscribeToCardAutoPositionMode(): void {
    const basicCardAncestor = findSelectableParent(
      this.currentState.id,
      this.componentStateSelector
    );
    if (isDefined(basicCardAncestor)) {
      this.componentStateSelector
        .selectPositioningType(basicCardAncestor.id)
        .pipe(takeUntil(this.unsubscribeSubject$))
        .subscribe((parentCardPositioningMode) => {
          this.cardPositioningMode = parentCardPositioningMode;
          this.updateTabsPositioningMode();
        });
    }
  }

  updateLatestTimestamp(): void {
    // NOTE has different way to update timestamp
  }

  selectedTabChange(event: MatTabChangeEvent): void {
    this.activeTabContentIndex = event.index;
    const tabContentId: EntityId | undefined = this.getActiveTabContentId();
    this.latestTimestamp = this.timestampService.getTimestampForComponent(tabContentId);
    this.timestampUpdated.emit(this.latestTimestamp);
  }

  tabContentTimestampUpdated(tabContentId: EntityId, newTimestamp: Date): void {
    this.timestampService.setTimestampForComponent(tabContentId, newTimestamp);
    const activeContentId = this.getActiveTabContentId();
    if (activeContentId === tabContentId) {
      this.latestTimestamp = newTimestamp;
      this.timestampUpdated.emit(this.latestTimestamp);
    }
  }

  private addContentForNewTabs(tabsToAdd: TabConfig[]): void {
    const updatedTabConfigsWithContent = _cloneDeep(this.view.tabs);
    tabsToAdd.forEach((newTabConfig, index) => {
      const tabIndex = this.view.tabs.findIndex((tab) => tab.id === newTabConfig.id);
      const addedTabContentId = this.addTabContent();
      updatedTabConfigsWithContent[tabIndex].contentContainerId = addedTabContentId;
      if (index === 0 && this.parentComponentHasContent) {
        this.moveParentComponentsIntoTab(addedTabContentId);
      }
    });
    this.updateTabConfigurations(updatedTabConfigsWithContent);
  }

  private addTabContent(): EntityId {
    const newTabContent = ComponentStateDto.createByName(
      TAB_CONTENT,
      this.typeProvider,
      this.componentStateSelector
    );
    (newTabContent.view as TabContentViewConfig).snapToGrid = this.cardSnapToGridMode;
    (newTabContent.view as TabContentViewConfig).positioningType = this.cardPositioningMode;

    this.dispatch(ComponentStateActions.addOne({ newComponent: newTabContent, parentId: this.id }));
    return newTabContent.id;
  }

  private updateTabConfigurations(newTabConfigs: TabConfig[]): void {
    const updatedView = {
      ...this.view,
      tabs: newTabConfigs
    };
    this.dispatch(
      ComponentStateActions.updateOne({
        componentUpdate: { id: this.id.toString(), changes: { view: updatedView } }
      })
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.tabConfigsBeforeViewChange = [...this.view.tabs];
    this.tabList.changes.pipe(takeUntil(this.unsubscribeSubject$)).subscribe(() => {
      const removedTabConfigs = findRemovedTabConfigs(
        this.tabConfigsBeforeViewChange,
        this.view.tabs
      );
      if (removedTabConfigs.length > 0) {
        const targetComponents: ComponentStateDto[] =
          this.getTabContentComponentStates(removedTabConfigs);
        this.dispatch(
          ComponentStateActions.deleteManyWithChildren({
            targetComponents
          })
        );
      }
      this.tabConfigsBeforeViewChange = [...this.view.tabs];
    });
  }

  private getTabContentComponentStates(tabConfigs: TabConfig[]): ComponentStateDto[] {
    const contentContainerIds: EntityId[] = tabConfigs.map(
      (tabConfig) => tabConfig.contentContainerId
    );
    return this.componentStateSelector.getManyById(contentContainerIds);
  }

  @View(TabGroupCardViewConfig)
  public get view(): TabGroupCardViewConfig {
    return this.currentState.view as TabGroupCardViewConfig;
  }

  canAcceptDrop(): boolean {
    return false;
  }

  shouldApplyBackgroundImageToHost(): boolean {
    return false;
  }

  get parentComponentHasContent(): boolean {
    const parentComponent = this.componentStateSelector.getParent(this.id);
    if (parentComponent != null) {
      return parentComponent.childrenIds.filter((id) => id !== this.id).length > 0;
    } else {
      return false;
    }
  }

  private moveParentComponentsIntoTab(tabContentId: EntityId): void {
    const parentComponentState = this.componentStateSelector.getParent(this.id);
    const componentsForMoving = parentComponentState.childrenIds.filter((id) => id !== this.id);
    this.dispatch(
      ComponentStateActions.updateOne({
        componentUpdate: {
          id: tabContentId.toString(),
          changes: { childrenIds: componentsForMoving }
        }
      })
    );

    this.dispatch(
      ComponentStateActions.updateOne({
        componentUpdate: {
          id: parentComponentState.id.toString(),
          changes: { childrenIds: [this.id] }
        }
      })
    );
  }

  protected get isResizable(): boolean {
    return false;
  }

  public getActiveTabContentId(): Maybe<EntityId> {
    const activeTabConfig = this.tabsWithDefinedContentContainer[this.activeTabContentIndex];
    if (activeTabConfig == null) {
      return;
    }
    return activeTabConfig.contentContainerId;
  }

  protected pointerDownHandler(event: PointerEvent): void {
    event.stopPropagation();
    this.selectComponent(false);
  }

  updatePropertySheetTargetOnSelection(componentState: ComponentStateDto): void {
    super.updatePropertySheetTargetOnSelection(this.currentState);
  }

  private updateTabsSnapToGrid(): void {
    if (this.undoRedoService.isLocked) {
      return;
    }
    const tabsUpdates = this.currentState.childrenIds.map((childId) => ({
      id: childId.toString(),
      changes: {
        view: {
          snapToGrid: this.cardSnapToGridMode
        } as Partial<ContainerComponentViewConfig>
      } as Partial<ComponentStateDto>
    }));
    if (!isEmpty(tabsUpdates)) {
      this.dispatch(
        ComponentStateActions.updateMany({
          componentUpdates: tabsUpdates
        })
      );
    }
  }

  private updateTabsPositioningMode(): void {
    if (this.undoRedoService.isLocked) {
      return;
    }
    const tabsUpdates = this.currentState.childrenIds.map((childId) => ({
      id: childId.toString(),
      changes: {
        view: {
          positioningType: this.cardPositioningMode
        } as Partial<ContainerComponentViewConfig>
      } as Partial<ComponentStateDto>
    }));
    if (!isEmpty(tabsUpdates)) {
      this.dispatch(
        ComponentStateActions.updateMany({
          componentUpdates: tabsUpdates
        })
      );
    }
  }

  protected makeConfigButtonsFullyVisible(): void {}

  protected hideParentsConfigButtons(event: MouseEvent): void {
    const basicCard = findClosestBasicCard(document.elementsFromPoint(event?.pageX, event?.pageY));
    basicCard?.hideConfigButtons();
  }

  protected revertOverlappingWidgetsState(): void {}

  protected unhideParentsConfigButtons(event: MouseEvent): void {
    const basicCard = findClosestBasicCard(document.elementsFromPoint(event?.pageX, event?.pageY));
    if (basicCard?.view.showHeader) {
      basicCard.unhideConfigButtons();
    }
  }

  getTabTitleColor(configuredColor: Maybe<string>): string {
    return getConfiguredOrForegroundColor(configuredColor, this.view.foregroundColor);
  }

  getTabId(index: number, tab: TabConfig): string {
    return tab.id;
  }
}

function findAddedTabs(previousTabConfigs: TabConfig[], newTabConfigs: TabConfig[]): TabConfig[] {
  return (newTabConfigs as TabConfig[]).filter(
    (newTab) =>
      (previousTabConfigs as TabConfig[]).findIndex((tab) => tab.id === newTab.id) === -1 &&
      isNotDefined(newTab.contentContainerId)
  );
}

function findRemovedTabConfigs(
  oldTabConfigs: TabConfig[],
  newTabConfigs: TabConfig[]
): TabConfig[] {
  return oldTabConfigs.filter(
    (oldTab) => newTabConfigs.findIndex((newTab) => newTab.id === oldTab.id) === -1
  );
}

function areTabConfigsChanged(previousTabs: TabConfig[], newTabs: TabConfig[]): boolean {
  if (previousTabs.length !== newTabs.length) {
    return true;
  }
  const changedTab = previousTabs.find((tab, index) => !_isEqual(tab, newTabs[index]));
  return isDefined(changedTab);
}
