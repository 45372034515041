import { TimeUnit } from "../../core/models/time-unit";
import { DAY_HOURS } from "../../core/models/time.constants";

export const DEFAULT_HOURS = 4;

export enum TimeModeSpecificPresets {
  Interval = "Interval",
  Custom = "Custom"
}

export enum StandardPresets {
  Today = "Today",
  Yesterday = "Yesterday",
  ThisWeek = "ThisWeek",
  ThisMonth = "ThisMonth",
  Last4hours = "Last4hours",
  Last24hours = "Last24hours"
}

export const TimePreset = { ...StandardPresets, ...TimeModeSpecificPresets };

export type TimePresetType = TimeModeSpecificPresets | StandardPresets;

export enum TimeRangeMode {
  Live = "Live",
  Historical = "Historical",
  Other = "Other"
}

export function mapPresetToTimeUnit(timePreset: TimePresetType): TimeUnit {
  let unit: TimeUnit = TimeUnit.Days;
  if (timePreset === StandardPresets.ThisWeek) {
    unit = TimeUnit.Weeks;
  }
  if (timePreset === StandardPresets.ThisMonth) {
    unit = TimeUnit.Months;
  }

  return unit;
}

export function getAmountForHoursBasedPreset(timePreset: TimePresetType): number {
  if (timePreset === StandardPresets.Last4hours) {
    return DEFAULT_HOURS;
  }
  if (timePreset === StandardPresets.Last24hours) {
    return DAY_HOURS;
  }
  return 0;
}
