import { v4 as uuid } from "uuid";
import { CONNECTOR_GROUP_DTO } from "../../core/models/dto-type.constants";
import { construct } from "../../core/services/construct.helper";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Key } from "../../meta/decorators/key.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { isEmpty } from "../../ts-utils/helpers/is-empty.helper";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../ts-utils/models/maybe.type";

const TYPE_NAME = CONNECTOR_GROUP_DTO;

@EditableType({ fullName: TYPE_NAME })
export class ConnectorGroupDto {
  @Key()
  @Serializable()
  id!: string;

  @Serializable("")
  name!: string;

  @Serializable(null)
  order!: Maybe<number>;

  isExpanded: boolean = false;
  items: DataConnectorDto[] = [];

  constructor(groupDto: DeepPartial<ConnectorGroupDto> = {}) {
    construct(this, groupDto, TYPE_NAME, isEmpty(groupDto) ? { id: "" } : { id: uuid() });
  }
}
