import { DroppableElement } from "../../../core/models/droppable-element";
import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { NotInheritable } from "../../../meta/decorators/not-inheritable.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { EditorType, PropertyCategory } from "../../../meta/models/";
import { DeepPartial } from "../../../ts-utils";
import { SignalInfoDto } from "../signal-info";
import { DataSourceDto } from "./data-source";
import { SIGNAL_DATA_SOURCE, SignalDataSourceType } from "./data-source.type";

// @dynamic
@EditableType({ fullName: SIGNAL_DATA_SOURCE, title: "signal-data-source-dto" })
export class SignalDataSourceDto extends DataSourceDto implements DroppableElement {
  typeName: SignalDataSourceType = SIGNAL_DATA_SOURCE;

  @NotInheritable()
  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Name,
    editorType: EditorType.SignalBrowser
  })
  @Serializable({ id: null, name: "" })
  signal!: SignalInfoDto;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Forecast,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ForecastTooltip,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  forecast!: boolean;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Anomalies,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.AnomaliesTooltip,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  anomalies!: boolean;

  constructor(params: DeepPartial<SignalDataSourceDto> = {}) {
    super();
    construct(this, params, SIGNAL_DATA_SOURCE);
  }
}
