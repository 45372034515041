import { Maybe } from "../../ts-utils/models/maybe.type";

export function compareOrders(order1: string, order2: string): Maybe<number> {
  const order1Number = getOrderAsNumber(order1);
  const order2Number = getOrderAsNumber(order2);
  if (Number.isNaN(order1Number) || Number.isNaN(order2Number)) {
    return null;
  }
  if (order1Number > order2Number) {
    return 1;
  }
  if (order1Number < order2Number) {
    return -1;
  }
  return 0;
}

export function getOrderAsNumber(order: string): number {
  return order === "" ? 0 : parseInt(order);
}
