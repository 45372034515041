import { ComponentRef, Directive, Input, Type, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[dynamic-template]"
})
export class DynamicTemplateDirective {
  @Input()
  public components: ComponentRef<any>[] = [];

  constructor(public viewContainerRef: ViewContainerRef) {}

  public clear(): void {
    this.viewContainerRef.clear();
    this.components = [];
  }

  public loadComponent<T>(componentType: Type<T>): T {
    return this.appendComponent(componentType);
  }

  public appendComponent<T>(componentType: Type<T>): T {
    const createdComponentRef: ComponentRef<any> =
      this.viewContainerRef.createComponent(componentType);

    this.components.push(createdComponentRef);
    return createdComponentRef.instance;
  }

  public removeComponent(targetComponent: any) {
    this.components
      .filter((component: ComponentRef<any>) => component.instance === targetComponent)
      .forEach((component) => {
        const index = this.viewContainerRef.indexOf(component.hostView);
        this.viewContainerRef.remove(index);
        component.destroy();
      });
    this.components = this.components.filter(
      (component: ComponentRef<any>) => component.instance !== targetComponent
    );
  }
}
