import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { RUNTIME_FILTER_ID } from "../../../core/helpers/filter/filter-id.helper";
import {
  isCorrectTimeRangeInterval,
  isTimeRangeSame,
  validateTimeRangeInputFormat
} from "../../../core/helpers/filter/filter-validation.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { TimeRange } from "../../../core/models/time-range";
import { TimeRangeConfigurationDto } from "../../../core/models/time-range-configuration";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import { Dispatcher } from "../../../dispatcher";
import { FilterActions } from "../../../elements/store/filter/filter.actions";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { TimeInfoActions } from "../../../environment/store/time-info/time-info.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { SelectionOption } from "../../../meta/models/selection";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { resolveLiveModeFilter } from "../../helpers/live-mode-time-unit.helper";
import { getTimePresetOptions } from "../../helpers/time-filter.helper";
import { FilterToolbarElementConfig } from "../../models/button/filter-toolbar-element.config";
import { FilterType } from "../../models/filter-type";
import { LiveModeFilter } from "../../models/live-mode-filter";
import {
  StandardPresets,
  TimeModeSpecificPresets,
  TimePreset,
  TimePresetType
} from "../../models/time-preset-filter";

@Component({
  selector: "runtime-date-time-range",
  templateUrl: "runtime-date-time-range.component.html",
  styleUrls: ["./runtime-date-time-range.component.scss"]
})
export class RuntimeDateTimeRangeComponent implements OnInit, OnDestroy {
  @Input() isFilterBarSelected: boolean = false;
  @Input() canExpandHorizontally: boolean = false;
  @Output() showFilterBar: EventEmitter<any> = new EventEmitter();

  timeRangeButton!: FilterToolbarElementConfig;
  isDateTimePickerOpened: boolean = false;
  historicTimeRangeInput: string = "";
  isTimeRangeValid: boolean = true;
  canApplyFilterChanges: boolean = false;
  historicTimeRange!: TimeRange;
  timeRangeUpdate = new Subject<string>();

  localFilterConfiguration!: FilterConfigurationDto;
  liveModeFilter: Maybe<LiveModeFilter> = null;
  dateTimeFormat: string = "";
  timePreset!: TimePresetType;
  presetOptions: SelectionOption[] = [];
  TimePreset = TimePreset;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private dispatcher: Dispatcher,
    private filterFactory: FilterFactory,
    public localizer: LocalizationService,
    private filterSelector: IFilterSelector,
    private dateFormatter: DateFormatterService,
    private environmentSelector: EnvironmentSelector
  ) {}

  ngOnInit(): void {
    this.timeRangeButton = this.createTimeRangeButtonConfig();
    this.subscribeToGlobalFilter();
    this.subscribeToTimeRangeUpdate();
    this.environmentSelector.selectDateFormat().subscribe((format) => {
      this.dateTimeFormat = `Date format: ${format} - ${format}`;
    });
    this.presetOptions = getTimePresetOptions(this.localizer);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private createTimeRangeButtonConfig(): FilterToolbarElementConfig {
    return new FilterToolbarElementConfig({
      icon: "Calendar",
      clickFunction: () => {
        return this.showOrHideTimeRangeBar();
      },
      class: "medium-label date-time-range__label--responsive"
    });
  }

  private subscribeToGlobalFilter(): void {
    this.filterSelector
      .selectById(RUNTIME_FILTER_ID)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((filterConfig) => {
        this.initFilterConfiguration(filterConfig);
      });
  }

  initFilterConfiguration(filterConfiguration: FilterConfigurationDto): void {
    this.localFilterConfiguration = _cloneDeep(filterConfiguration);
    this.timePreset = this.localFilterConfiguration.timeRange.timePreset;
    this.resetFilterConfiguration(this.localFilterConfiguration);
  }

  private resetFilterConfiguration(filterConfiguration: FilterConfigurationDto): void {
    const timeRangeConfig =
      this.filterFactory.getTimeRangeForStandardPreset(this.timePreset) ??
      filterConfiguration.timeRange;
    this.initHistoricTimeRange(timeRangeConfig);
    this.initLiveModeFilter(timeRangeConfig);
  }

  private initLiveModeFilter(timeRange: TimeRangeConfigurationDto): void {
    this.liveModeFilter =
      this.filterFactory.createStandaloneLiveModeFilter(timeRange) ??
      resolveLiveModeFilter(this.historicTimeRange);
  }

  private initHistoricTimeRange(timeRange: TimeRangeConfigurationDto): void {
    const newTimeRange = this.filterFactory.createStandaloneFilterTimeRange(timeRange);
    if (isDefined(newTimeRange)) {
      this.historicTimeRange = newTimeRange;
      this.canApplyFilterChanges = false;
      this.isTimeRangeValid = true;
      this.historicTimeRangeInput = resolveTimeRangeInputValue(newTimeRange, this.dateFormatter);
    }
  }

  private subscribeToTimeRangeUpdate(): void {
    this.timeRangeUpdate.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      const newTimeRange = validateTimeRangeInputFormat(value, this.dateFormatter);
      this.setTimeRangeIfValid(newTimeRange);
    });
  }

  onChangeLiveModeFilter(liveModeFilter: LiveModeFilter): void {
    this.liveModeFilter = liveModeFilter;
    const timeRange = this.filterFactory.createTimeRangeFromLiveModeFilter(
      this.liveModeFilter,
      this.timePreset
    );
    this.saveChanges(timeRange);
  }

  onChangeTimePreset(newTimePreset: TimePresetType): void {
    this.timePreset = newTimePreset;
    this.dispatcher.dispatch(TimeInfoActions.updateCurrentTime({ now: new Date() }));
    const newTimeRangeConfiguration: TimeRangeConfigurationDto =
      this.filterFactory.computeTimeRangeByMode(
        this.timePreset,
        this.liveModeFilter,
        this.historicTimeRange,
        this.localFilterConfiguration.timeRange
      );
    this.saveChanges(newTimeRangeConfiguration);
  }

  applyChanges(): void {
    const timeRange = this.filterFactory.createTimeRangeConfiguration(this.historicTimeRange);
    this.saveChanges(timeRange);
  }

  saveChanges(newTimeRangeConfiguration: TimeRangeConfigurationDto): void {
    if (!this.isTimeRangeValid) {
      console.warn("Invalid filter data");
      return;
    }
    if (isTimeRangeSame(this.localFilterConfiguration.timeRange, newTimeRangeConfiguration)) {
      return;
    }
    this.localFilterConfiguration.timeRange = newTimeRangeConfiguration;
    this.updateFilterInStore(this.localFilterConfiguration);
  }

  private updateFilterInStore(newFilterConfiguration: FilterConfigurationDto): void {
    const filterForStore = _cloneDeep(newFilterConfiguration);
    this.dispatcher.dispatch(
      FilterActions.upsertOne({
        filterUpdate: { id: RUNTIME_FILTER_ID, changes: filterForStore }
      })
    );
  }

  onSelectDateTimePicker(newTimeRange: TimeRange): void {
    this.historicTimeRangeInput = resolveTimeRangeInputValue(newTimeRange, this.dateFormatter);
    this.setTimeRangeIfValid(newTimeRange);
  }

  onChangeTimeRangeParams(newTimeRange: TimeRange): void {
    if (this.setTimeRangeIfValid(newTimeRange)) {
      this.historicTimeRangeInput = resolveTimeRangeInputValue(newTimeRange, this.dateFormatter);
    } else {
      const timeRange = this.filterFactory.createStandaloneFilterTimeRange(
        this.localFilterConfiguration.timeRange
      );
      if (isDefined(timeRange)) {
        this.historicTimeRangeInput = resolveTimeRangeInputValue(timeRange, this.dateFormatter);
        this.isTimeRangeValid = true;
      }
    }
  }

  setTimeRangeIfValid(newTimeRange: Maybe<TimeRange>): boolean {
    if (
      isNotDefined(newTimeRange) ||
      !isCorrectTimeRangeInterval(newTimeRange.from, newTimeRange.to)
    ) {
      this.isTimeRangeValid = false;
      this.canApplyFilterChanges = false;
      return false;
    }
    const newTimeRangeConfiguration: TimeRangeConfigurationDto =
      this.filterFactory.createTimeRangeConfiguration(newTimeRange);
    if (isTimeRangeSame(this.localFilterConfiguration.timeRange, newTimeRangeConfiguration)) {
      this.isTimeRangeValid = true;
      this.canApplyFilterChanges = false;
      return false;
    }
    this.isTimeRangeValid = true;
    this.canApplyFilterChanges = true;
    this.historicTimeRange = newTimeRange;
    return true;
  }

  showOrHideDateTimePicker(showDateTimePicker: boolean, event: Event): void {
    this.isDateTimePickerOpened = showDateTimePicker;
    event.stopPropagation();
  }

  hideDateTimePicker(): void {
    this.isDateTimePickerOpened = false;
  }

  closeBar(event: Event): void {
    this.showOrHideTimeRangeBar();
    this.resetFilterConfiguration(this.localFilterConfiguration);
    event.stopPropagation();
  }

  closeIfDropdown(): void {
    if (!this.canExpandHorizontally && this.isFilterBarSelected) {
      this.showOrHideTimeRangeBar();
    }
  }

  showOrHideTimeRangeBar(): void {
    this.showFilterBar.emit(FilterType.TimeRange);
  }

  shouldRenderStandardPresetVertically(): boolean {
    return !this.canExpandHorizontally && Object.keys(StandardPresets).includes(this.timePreset);
  }

  shouldRenderPresetHorizontally(): boolean {
    return (
      this.canExpandHorizontally || Object.keys(TimeModeSpecificPresets).includes(this.timePreset)
    );
  }
}

function resolveTimeRangeInputValue(
  timeRange: TimeRange,
  dateFormatter: DateFormatterService
): string {
  const fromExpression: string = dateFormatter.formatDate(timeRange.from);
  const toExpression: string = dateFormatter.formatDate(timeRange.to);
  return fromExpression + " - " + toExpression;
}
