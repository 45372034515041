import { select, Store } from "@ngrx/store";
import { map, take } from "rxjs/operators";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { LocalizationService } from "../../i18n/localization.service";
import { tableColumnLocalizationKey } from "../../i18n/models/localization-dictionary";
import { addEmptyEntry, EMPTY_COMBO_BOX_ENTRY } from "../../meta/helpers/enum-combobox.helper";
import { SelectionOption } from "../../meta/models/selection";
import { isDefined } from "../../ts-utils";
import { CriticalError } from "../../ts-utils/models/critical-error";
import {
  DataColumnType,
  DataTableColumn,
  GenericDataSourceDescriptor
} from "../models/data-source/data-source-descriptors";
import { IGenericDataSourceService } from "../services/i-generic-data-source.service";
import {
  selectAllGenericDatasourceDescriptors,
  selectAllGenericDatasourceNames
} from "../store/generic-data-source-descriptor/generic-data-source-descriptor.selectors";

export function getColumns(context: DecoratorDelegateContext): SelectionOption[] {
  const columnsExtractor = context.services.genericDataSourceService as IGenericDataSourceService;
  return columnsExtractor.getColumns(context.ownerInstance);
}

export function getEntityColumns(
  store: Store<any>,
  entity: string,
  columnFilter: (col: DataTableColumn) => boolean,
  localizationService: LocalizationService
): SelectionOption[] {
  let options: SelectionOption[] = [];
  const selectedInfo = getEntityInfo(store, entity);
  if (isDefined(selectedInfo)) {
    options = selectedInfo.columns.filter(columnFilter).map((column) => {
      const title = localizationService.getWithFallback(
        tableColumnLocalizationKey(entity, column.name),
        column.name
      );
      return {
        key: column.name,
        title: title
      };
    });
  }
  return options;
}

function getEntityInfo(store: Store<any>, entity: string): GenericDataSourceDescriptor {
  let entityInfo: GenericDataSourceDescriptor;
  store
    .pipe(
      select(selectAllGenericDatasourceDescriptors),
      map((entityDescriptors: GenericDataSourceDescriptor[]) =>
        isDefined(entityDescriptors)
          ? entityDescriptors.find((descriptor) => descriptor.entity === entity)
          : null
      ),
      take(1)
    )
    .subscribe((desc) => {
      entityInfo = desc;
    });
  return entityInfo;
}

export function getEntities(context: DecoratorDelegateContext): SelectionOption[] {
  const store = getStoreFromContext(context);
  let result: SelectionOption[] = [];
  store
    .pipe(
      select(selectAllGenericDatasourceNames),
      map((names) => {
        const options = names.map((name) => {
          return {
            key: name !== EMPTY_COMBO_BOX_ENTRY ? name : "",
            title: name
          };
        });
        addEmptyEntry(options);
        return options;
      }),
      take(1)
    )
    .subscribe((entities) => {
      result = entities;
    });
  return result;
}

export function getSelectedEntity(context: DecoratorDelegateContext): string {
  return context.services.genericDataSourceService.getSelectedEntity(context.ownerInstance);
}

export function getSelectedEntityColumns(context: DecoratorDelegateContext): SelectionOption[] {
  const selectedEntity = getSelectedEntity(context);
  const columns = getEntityColumns(
    getStoreFromContext(context),
    selectedEntity,
    (x) => true,
    context.services.localizationService
  );
  return addEmptyEntry(columns);
}

export function getNumericColumnsForSelectedEntity(
  context: DecoratorDelegateContext
): SelectionOption[] {
  const selectedEntity = getSelectedEntity(context);
  return getNumericColumnsForEntity(
    getStoreFromContext(context),
    selectedEntity,
    context.services.localizationService
  );
}

export function getNumericColumnsForEntity(
  store: Store<any>,
  entity: string,
  localizationService: LocalizationService
): SelectionOption[] {
  const columns = getEntityColumns(
    store,
    entity,
    (col) => col.type === DataColumnType.Number,
    localizationService
  );
  return addEmptyEntry(columns);
}

export function getStoreFromContext(context: DecoratorDelegateContext): Store<any> {
  const store = context.store;
  if (!store) {
    throw new CriticalError("Can't find store instance");
  }
  return store;
}
