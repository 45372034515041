import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { construct } from "../../core/services/construct.helper";
import { EMPTY_DATA_SOURCE } from "../../data-connectivity";
import { createDataSource } from "../../data-connectivity/helpers/data-source-creation.helper";
import {
  DataConnectorDto,
  isNotDynamicallyCreated
} from "../../data-connectivity/models/data-connector";
import { DataSourceDto } from "../../data-connectivity/models/data-source/data-source";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  ConfigurableArray,
  ConfigurationCategory,
  EditableType,
  Serializable,
  Virtual
} from "../../meta/decorators";
import { EditorType, PropertyCategory } from "../../meta/models";
import { Entity } from "../../meta/models/entity";
import { createViewConfig } from "../helpers/view-config-creation.helper";
import { BaseViewConfigDto } from "./base-view-config";
import { ComponentStateDto } from "./component-state";
import { COMPONENT_STATE_DTO, COMPONENT_STATE_VIEW_MODEL } from "./entity-type.constants";

const TYPE_NAME = COMPONENT_STATE_VIEW_MODEL;

@EditableType({ fullName: TYPE_NAME })
export class ComponentStateViewModel extends Entity {
  typeName = TYPE_NAME;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataConnectors
  )
  @ConfigurableArray({
    editorType: EditorType.EntityArray,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DataConnectors,
    typeConstructor: DataConnectorDto,
    arrayItemEditorType: EditorType.NestedSheet,
    validationFunction: isNotDynamicallyCreated,
    isGroupable: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.DataConnectorsTooltip
  })
  @Serializable([], DataConnectorDto)
  dataConnectors!: DataConnectorDto[];

  @Virtual()
  @Serializable({})
  view!: BaseViewConfigDto;

  @Serializable([], ComponentStateViewModel)
  children!: ComponentStateViewModel[];

  @Serializable()
  @Virtual()
  dataConnectorQuery: DataSourceDto;

  @Serializable(null)
  @Virtual()
  filterConfig: FilterConfigurationDto;

  @Serializable()
  type: string;

  get allDescendants(): ComponentStateViewModel[] {
    return getChildrenDeep(this.children);
  }

  constructor(componentState: ComponentStateDto) {
    super();
    componentState = {
      ...componentState,
      dataConnectorQuery: createDataSource(
        componentState.dataConnectorQuery?.typeName ?? EMPTY_DATA_SOURCE,
        componentState.dataConnectorQuery ?? {},
        COMPONENT_STATE_DTO
      ),
      view: createViewConfig(componentState.type, componentState.view),
      typeName: TYPE_NAME
    };
    construct(this, componentState, TYPE_NAME);
  }
}

function getChildrenDeep(children: ComponentStateViewModel[] = []): ComponentStateViewModel[] {
  const allChildren: ComponentStateViewModel[] = children.reduce((acc, child) => {
    acc = acc.concat(getChildrenDeep(child.children));
    return acc;
  }, children);
  return allChildren;
}
