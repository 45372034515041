<div class="layout-builder-menu-content__container" *ngIf="isCategoryVisible">
  <div class="title-wrapper"  (click)="toggleExpandCategory()">
    <span class="category-title"> {{ localizer.sidebar[category.id] | translate }} </span>
    <i [ngClass]="showElements ? 'Up' : 'Down'" class="category-button abb-icon" tabIndex="0"></i>
  </div>
  <div class="layout-builder-menu-content__items" *ngIf="showElements">
    <div
      class="layout-builder-menu-content__item"
      *ngFor="let componentAndAlias of filteredWidgets ?? components"
      draggable="true"
      (drag)="dragStart($event, componentAndAlias)"
      (dragend)="expandSidebarOnDrop()"
      (touchstart)="dragStart($event, componentAndAlias)"
      (touchmove)="touchMove($event)"
      (touchend)="dropOnTouchDevices($event)"
      (mousedown)="setSelectedItem(componentAndAlias)"
      tabindex="0"
      (keydown)="openHelp(componentAndAlias, $event)"
    >
      <i class="item-icon" [ngClass]="getIconFromComponentMetadata(componentAndAlias)"></i>
      <div class="item-name">
        {{ getComponentClassName(componentAndAlias) | translate }}
      </div>
    </div>
  </div>
</div>
