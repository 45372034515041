import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { SidemenuComponents } from "../../core/models/sidemenu-components.enum";
import { EnvironmentName } from "../models/environment-name";
import { selectEnvironmentName } from "../store/feature.selector";

@Injectable({ providedIn: "root" })
export class FeatureAvailabilityService {
  private environmentName: string = EnvironmentName.None;

  constructor(private store$: Store<any>) {
    this.store$.select(selectEnvironmentName).subscribe((environmentName: string) => {
      this.environmentName = environmentName;
    });
  }

  public get userMenu(): boolean {
    return this.environmentName === EnvironmentName.Rds;
  }

  public get timezoneSwitching(): boolean {
    return this.environmentName === EnvironmentName.Rds;
  }

  public get rootPathConfiguration(): boolean {
    return this.environmentName === EnvironmentName.Km;
  }

  public get aliasName(): boolean {
    return this.environmentName === EnvironmentName.Km;
  }

  public get description(): boolean {
    return this.environmentName === EnvironmentName.Km;
  }

  public get allowLiveModeUpdate(): boolean {
    return this.environmentName === EnvironmentName.Km;
  }

  public get previewModeButtons(): SidemenuComponents[] {
    return this.environmentName === EnvironmentName.Km
      ? [SidemenuComponents.DataExplorer]
      : [
          SidemenuComponents.TreeExplorer,
          SidemenuComponents.MapExplorer,
          SidemenuComponents.Copilot
        ];
  }
}
