import { Dictionary } from "@ngrx/entity/src/models";
import { createSelector } from "@ngrx/store";
import { REPORT_FILTER_ID, RUNTIME_FILTER_ID } from "../../../core/helpers/filter/filter-id.helper";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { EntityId } from "../../../meta/models/entity";
import { isDefined, Maybe } from "../../../ts-utils";
import { getReportFeature } from "../feature.selector";
import { ReportContents } from "../state";
import { FilterState } from "./filter.state";

export const selectFilterState = createSelector(
  getReportFeature,
  (state: ReportContents) => state.filters
);

export const selectFilterEntities = createSelector(
  selectFilterState,
  (state: FilterState) => state.entities
);

export const selectFiltersArray = createSelector(selectFilterEntities, (filters) =>
  Object.values(filters)
);

export const selectFilterById = (id: EntityId) =>
  createSelector<FilterState, any, FilterConfigurationDto>(
    selectFilterEntities,
    (filters) => filters[id]
  );

// had to create as a factory function in order to use it in store.select
export const selectFilterByIdOrDefault = (filterId: Maybe<EntityId>) =>
  createSelector(selectFilterEntities, (entities: Dictionary<FilterConfigurationDto>) => {
    return entities[filterId ?? ""] ?? (entities[RUNTIME_FILTER_ID] as FilterConfigurationDto);
  });

export const selectDependentFilters = (sourceFilterId: EntityId) =>
  createSelector(selectFiltersArray, (filtersArray: FilterConfigurationDto[]) => {
    return filtersArray.filter(
      (filter: FilterConfigurationDto) =>
        filter &&
        filter.sourceFilterId !== null &&
        filter.sourceFilterId !== undefined &&
        filter.sourceFilterId.toString() === sourceFilterId.toString()
    );
  });

export const selectFiltersById = (filterIds: EntityId[]) =>
  createSelector(selectFilterState, (state) =>
    filterIds.map((filterId) => state.entities[filterId]).filter(isDefined)
  );

export const selectGlobalTimeRange = () =>
  createSelector(
    selectFilterByIdOrDefault(REPORT_FILTER_ID),
    (filter: FilterConfigurationDto) => filter.timeRange
  );
