<ng-container *ngFor="let item of items; let itemIndex = index; trackBy: trackItemsByIndex">
  <div class="extendable-checkbox-list__container">
    <span
      #item
      class="item-drag-dots"
      draggable="true"
      (dragstart)="dragStart($event, item, itemIndex)"
      (dragend)="onDragEnd()"
      (drop)="drop($event, itemIndex)"
      (dragover)="onDragOver($event, itemIndex)"
      (dragleave)="onDragLeave($event, itemIndex)"
    >
      <i
        class="dashboard-icon icon-Drag-dots"
        title="{{ translationService.propertySheet.DragToReorder | translate }}"
      ></i>
    </span>
    <ng-container *ngIf="item.isPredefinedItem; else customItem">
      <div class="extendable-checkbox-list__selectable-item">
        <div #focusedContainer class="extendable-checkbox-list__selectable-item-input">
          <input
            type="checkbox"
            focusVisualization
            [focusedElement]="focusedContainer"
            [checked]="item.isSelected"
            (change)="onSelectionChange(itemIndex)"
          />
        </div>
        <span
          class="extendable-checkbox-list__selectable-item-label"
          [class.editor--value-configured]="hasDefaultValueChanged(itemIndex)"
          >{{ getSelectableItemLabelText(item) }}</span
        >
      </div>
    </ng-container>

    <ng-template #customItem>
      <div class="extendable-checkbox-list__custom-item-wrapper">
        <array-editor-item
          [descriptor]="propertyInfo.descriptor"
          [value]="item"
          [itemTitle]="itemTitleProperty?.name"
          [itemOwner]="itemOwner"
          (updatePropertyValue)="editCustomItem($event, itemIndex)"
        ></array-editor-item>
        <div class="rounded-button__wrapper">
          <button class="rounded-button" (click)="removeItem(itemIndex)">
            <i class="abb-icon Close"></i>
            <span>{{ translationService.propertySheet.RemoveData | translate }} </span>
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
<ng-container *ngIf="propertyInfo.descriptor.enableCustomItems">
  <div class="rounded-button__wrapper extendable-checkbox-list__add-button">
    <button class="rounded-button" (click)="addCustomItem()">
      <i class="abb-icon Plus"></i>
      <span>{{ translationService.propertySheet.AddItem | translate }} </span>
    </button>
  </div>
</ng-container>
