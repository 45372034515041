import { Component, HostBinding, ViewChild } from "@angular/core";
import {
  BaseComponent,
  ChartComponent,
  ConnectorRoles,
  EditableType,
  formatNumberByStringFormat,
  LegendComponent,
  View
} from "ui-core";
import { Roles } from "./roles";
import { EventFrequencyChartViewConfig } from "./view-config";

@Component({
  selector: "rds-event-frequency-chart",
  templateUrl: "./event-frequency-chart.component.html",
  styleUrls: ["./event-frequency-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: EventFrequencyChartComponent }]
})
// @LayoutBuilder(ComponentCategory.Category, "EventFrequencyChartComponent", "Bar_chart")
@ConnectorRoles(Roles)
@EditableType({ fullName: "EventFrequencyChartComponent", title: "event-frequency-chart" })
export class EventFrequencyChartComponent extends ChartComponent {
  @HostBinding("attr.title") public occurrence: string;

  @ViewChild(LegendComponent) chartLegend: LegendComponent;

  @View(EventFrequencyChartViewConfig)
  private get view() {
    return this.currentState.view as EventFrequencyChartViewConfig;
  }

  protected updateChartData(): void {
    this.view.categories = [
      "RotorCBIs Tripping",
      "AuxiliaryFuses_PhaseU",
      "LineOvervoltArrester_CA1",
      "ExternalHWFault_LINCA1",
      "PLLAngleDeviation",
      "LineUnderfrequency",
      "WaterConductivity",
      "LineUndervoltage",
      "SerialCommFault",
      "OPCCommFault"
    ];
    this.view.eventFrequencies = this.dataAccessor.getValuesForSimilarRoles(Roles.EventPrefix.name);
  }

  protected setChartOptions(): void {
    const component = this;

    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        type: "bar"
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false,
        backgroundColor: "#ffffff",
        outside: true,
        headerFormat: /* "<b>{point.x}</b><br/>" */ "",
        pointFormatter: function () {
          return (
            /* this.series.name + ": " */ "<b>" +
            formatNumberByStringFormat(
              component.view.eventFrequencies[this.x],
              component.view.displayFormat.numberFormat
            ) +
            "</b>"
          );
        }
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: function (event) {
                component.occurrence = `${
                  component.view.tooltipTitle
                }: ${formatNumberByStringFormat(
                  this.y,
                  component.view.displayFormat.numberFormat
                )}`;
              },
              mouseOut: function (event) {
                component.occurrence = "";
              }
            }
          }
        }
      },
      xAxis: {
        categories: this.view.categories,
        tickWidth: 0,
        labels: {
          useHTML: true,
          format: "<b>{value}</b>"
        }
      },
      yAxis: {
        min: 0,
        // max: Math.max(...this.view.eventFrequencies) + 10,
        title: {
          enabled: false
        }
      },
      series: [
        {
          name: "Frequencies",
          data: this.view.eventFrequencies,
          color: this.view.barColor
        }
      ]
    };
  }
}
