import { TimeRange } from "../../core/models/time-range";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { LiveModeFilter } from "../models/live-mode-filter";
import { TimePresetType } from "../models/time-preset-filter";

export function isTimeAmountChanged(
  currentLiveModeFilter: LiveModeFilter,
  newLiveModeFilter: Maybe<LiveModeFilter>
): boolean {
  return isDefined(newLiveModeFilter) && newLiveModeFilter.amount !== currentLiveModeFilter.amount;
}

export function isTimeUnitChanged(
  currentLiveModeFilter: LiveModeFilter,
  newLiveModeFilter: Maybe<LiveModeFilter>
): boolean {
  return isDefined(newLiveModeFilter) && newLiveModeFilter.unit !== currentLiveModeFilter.unit;
}

export function isStartDateTimeChanged(
  currentTimeRange: TimeRange,
  newTimeRange: Maybe<TimeRange>
): boolean {
  return (
    isDefined(newTimeRange) &&
    newTimeRange.from.toDateString() !== currentTimeRange.from.toDateString()
  );
}

export function isEndDateTimeChanged(
  currentTimeRange: TimeRange,
  newTimeRange: Maybe<TimeRange>
): boolean {
  return (
    isDefined(newTimeRange) && newTimeRange.to.toDateString() !== currentTimeRange.to.toDateString()
  );
}

export function isTimePresetChanged(
  currentPreset: TimePresetType,
  newPreset: TimePresetType
): boolean {
  return currentPreset !== newPreset;
}
