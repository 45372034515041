import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { View } from "../../decorators/view.decorator";
import { openLink } from "../../helpers/link-resolution.helper";
import { isContainerWidget } from "../../models/component-type.helper";
import { DisplayStrategies } from "../../models/display-strategies/label-display-strategies";
import { LABEL } from "../../models/element-type.constants";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { LabelViewConfig } from "./view-config";

export const CSS_MULTILINE_LABEL = "multiline-label__container";

@Component({
  selector: "c-label",
  templateUrl: "./label.component.html",
  styleUrls: ["./label.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: LabelComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@LayoutBuilder(
  ComponentCategory.Glitter,
  LABEL,
  "Label",
  "abb-icon ",
  DisplayStrategies.Label,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Label
)
@LayoutBuilder(
  ComponentCategory.Glitter,
  LABEL,
  "Comment",
  "abb-icon",
  DisplayStrategies.MultilineLabel,
  LOCALIZATION_DICTIONARY.propertySheet.Display_MultilineLabel
)
@EditableWidget({ fullName: LABEL, title: "label" })
export class LabelComponent extends BaseComponent {
  @View(LabelViewConfig)
  public get view(): LabelViewConfig {
    return this.currentState.view as LabelViewConfig;
  }
  interpolatedViewConfig!: LabelViewConfig;
  strategyEnum = DisplayStrategies;

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, cdr);
  }

  protected updateDisplay(callerInfo?: string): void {
    super.updateDisplay(callerInfo);
    this.interpolatedViewConfig =
      this.propertyInterpolationService.collectInterpolatedProperties<LabelViewConfig>(
        { ...this.currentState, view: this.view },
        []
      ).viewConfig;
    this.cdr.markForCheck();
  }

  protected onViewChange(view: LabelViewConfig): void {
    super.onViewChange(view);
    this.cdr.markForCheck();
  }

  resolveLink(): void {
    openLink(this);
  }

  canAcceptDrop(): boolean {
    const target = this.draggedComponentService.target;
    return (
      target?.type === DraggedItemType.Component &&
      !isContainerWidget(target?.item?.descriptor?.name)
    );
  }
}
