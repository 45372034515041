import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { ItemBrowserComponent } from "../../../browsing/components/item-browser/item-browser.component";
import { CategoryInfo } from "../../../core/models/component-category-info";
import { DisplayMode } from "../../../core/models/display-mode";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { DroppableElement } from "../../../core/models/droppable-element";
import { ViewMode } from "../../../core/models/view-mode";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { Dispatcher } from "../../../dispatcher";
import { EnvironmentSelector } from "../../../environment";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { IS_ATTACHABLE } from "../../../meta/decorators/editable-type.decorator";
import { TypeDescriptor, TypeDescriptorAlias } from "../../../meta/models";
import { TypeProvider } from "../../../meta/services/type-provider";
import { searchWidgetByPattern } from "../../../property-sheet/helpers/search-widget.helper";
import { isDefined } from "../../../ts-utils";
import { resolveComponentClassName } from "../../../ts-utils/helpers/component-display-name.helper";
import { ComponentTypeInfo } from "../../models/component-type-info";
import { BaseComponent } from "../base/base.component";

@Component({
  selector: "layout-builder-menu-category",
  templateUrl: "layout-builder-menu-category.component.html",
  styleUrls: ["./layout-builder-menu-category.component.scss"]
})
export class LayoutBuilderMenuCategoryComponent extends ItemBrowserComponent implements OnInit {
  @Input() category: CategoryInfo;
  showElements: boolean = false;
  @Input() searchPattern: string;
  displayMode: string;
  @Output() collapseSidebar: EventEmitter<any> = new EventEmitter();
  @Output() expandSidebar: EventEmitter<any> = new EventEmitter();

  public filteredWidgets: ComponentTypeInfo[];
  public components: ComponentTypeInfo[];
  isCategoryVisible: boolean = true;
  public previewMode: boolean;

  constructor(
    private dispatcher: Dispatcher,
    dragDropService: IDragDropService,
    protected environmentSelector: EnvironmentSelector,
    private typeProvider: TypeProvider,
    public localizer: LocalizationService
  ) {
    super(dragDropService, environmentSelector);
  }

  ngOnInit(): void {
    this.subscribeToDisplayMode();
    this.subscribeToViewMode();
    this.components = this.getComponentsOfCategory(this.category.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterWidgets();
    if (changes["searchPattern"]) {
      if (this.searchPattern === "") {
        this.isCategoryVisible = true;
        this.showElements = false;
      } else {
        this.hideCategory();
      }
    }
  }

  dragStart(event, draggableItem: DroppableElement, rootPath: string = "") {
    this.collapseSidebar.emit();
    super.dragStart(event, draggableItem, rootPath);
    if (this.displayMode === DisplayMode.Tablet) {
      this.dispatcher.dispatch(AppStatusActions.closeSidebar());
    }
  }

  expandSidebarOnDrop(): void {
    this.expandSidebar.emit();
  }

  dropOnTouchDevices(event) {
    super.dropOnTouchDevices(event);
    if (isDefined(this.selectedItem)) {
      this.dispatcher.dispatch(AppStatusActions.closeSidebar());
    }
  }

  private getAllAttachables(): TypeDescriptor[] {
    const types: TypeDescriptor[] = this.typeProvider.getTypes();
    const baseComponentType: TypeDescriptor = this.typeProvider.getTypeByConstructor(BaseComponent);

    const allAttachables: TypeDescriptor[] = types.filter((type: TypeDescriptor) => {
      const isAttachable =
        type.isInheritedFrom(baseComponentType) && Reflect.getMetadata(IS_ATTACHABLE, type);
      return isAttachable;
    });
    return allAttachables;
  }

  private getComponentsOfCategory(category: string): ComponentTypeInfo[] {
    const attachables: TypeDescriptor[] = this.getAllAttachables();
    const filteredAttachables: TypeDescriptor[] = attachables.filter((component: TypeDescriptor) =>
      component.aliases.some((alias) => alias.category === category)
    );

    return filteredAttachables
      .map((component: TypeDescriptor) => {
        const filteredAliases: TypeDescriptorAlias[] = component.aliases.filter(
          (alias: TypeDescriptorAlias) => alias.category === category
        );
        return filteredAliases.map((alias) => new ComponentTypeInfo(component, alias));
      })
      .flat();
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
      });
  }

  private subscribeToViewMode(): void {
    this.environmentSelector
      .selectViewMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        this.previewMode = viewMode === ViewMode.PreviewMode;
        if (this.previewMode) {
          this.showElements = false;
        }
      });
  }

  getComponentClassName(componentInfo: ComponentTypeInfo): string {
    return resolveComponentClassName(componentInfo.alias.displayName, this.environmentSelector);
  }

  getIconFromComponentMetadata(component: ComponentTypeInfo) {
    return component.alias.iconGroup + " " + component.alias.icon;
  }

  getDragTarget(componentTypeName: ComponentTypeInfo) {
    return {
      type: DraggedItemType.Component,
      item: componentTypeName
    };
  }

  private filterWidgets(): void {
    if (isDefined(this.components)) {
      this.filteredWidgets = searchWidgetByPattern(
        this.components,
        this.searchPattern,
        this.localizer
      );
    }
  }

  private hideCategory(): void {
    if (isDefined(this.filteredWidgets)) {
      if (this.filteredWidgets.length > 0) {
        this.isCategoryVisible = true;
        this.showElements = true;
      } else {
        this.isCategoryVisible = false;
        this.showElements = false;
      }
    }
  }

  toggleExpandCategory(): void {
    this.showElements = !this.showElements;
  }

  openHelp(draggableItem: ComponentTypeInfo, event: KeyboardEvent): void {
    if (event.key === "F1") {
      event.preventDefault();
      window.top?.open(draggableItem.alias.userHelp);
    }
  }
}
