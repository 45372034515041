import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import _isEqual from "lodash/isEqual";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, first, map } from "rxjs/operators";
import { LinkDto } from "../../../core/models/link";
import { EventSubscription } from "../../../core/models/widget-eventing/widget-event";
import { EntityId } from "../../../meta/models/entity";
import { ConnectorGroupDto } from "../../../shared/models/connector-group";
import { isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { CacheOptionsDto } from "../../models/cache-options";
import { CardImageProps } from "../../models/card-image-props";
import { ComponentCssSize } from "../../models/component-size";
import { ComponentStateDto } from "../../models/component-state";
import { DataStatus } from "../../models/data-status";
import { PAGE } from "../../models/element-type.constants";
import { FullComponentStoreInfo } from "../../models/full-component-store-info";
import { LimitsDto } from "../../models/limits";
import { NavLinkInfo } from "../../models/nav-link-info";
import { PositioningType } from "../../models/positioning-type";
import {
  CardRuntimeViewProperties,
  RuntimeViewProperties
} from "../../models/runtime-view-properties";
import { SizeInPx } from "../../models/size-in-px";
import { YAxisDescriptor } from "../../models/y-axis-descriptor";
import { selectComponentsCounter } from "../../store/component-counter/component-counter.selectors";
import {
  componentsByFilterIdSelector,
  selectAllComponentsForFilter,
  selectBackgroundImage,
  selectBackgroundImageData,
  selectCardImageProps,
  selectComponentByConnectorId,
  selectComponentByFilterId,
  selectComponentCacheOptions,
  selectComponentDataStatus,
  selectComponentFilterId,
  selectComponentRuntimeSize,
  selectComponentsById,
  selectComponentSize,
  selectComponentStateAvailability,
  selectComponentStateById,
  selectComponentStateEntities,
  selectComponentView,
  selectComponentWithFilter,
  selectContainerPositioningType,
  selectContainerSnapToGrid,
  selectDisplayStrategy,
  selectEquipmentPathByComponent,
  selectFooterVisibility,
  selectGroupsForComponent,
  selectHeaderVisibility,
  selectLimits,
  selectLink,
  selectNavigationBarLinks,
  selectParent,
  selectRootComponentState,
  selectRootPath,
  selectRootRuntimeSize,
  selectRuntimeView,
  selectSiblings,
  selectSubscribedEvents,
  selectYAxes
} from "../../store/component-state/component-state.selectors";

@Injectable()
export class ComponentStateSelector {
  constructor(private store$: Store<any>) {}

  selectComponentCount(): Observable<number> {
    return this.store$.select(selectComponentsCounter);
  }

  selectAllComponentStates(): Observable<Dictionary<Maybe<ComponentStateDto>>> {
    return this.store$.select(selectComponentStateEntities);
  }

  selectComponentStateById(id: EntityId): Observable<Maybe<ComponentStateDto>> {
    // return this.store$.select(selectTypedComponentStateById(id)).pipe(
    return this.store$.select(selectComponentStateById(id)).pipe(
      distinctUntilChanged((previous, current) => {
        if (!current) {
          throw new CriticalError("Undefined component state");
        }
        return _isEqual(previous, current);
      })
    );
  }

  selectComponentsByFilterId(filterId: EntityId): Observable<ComponentStateDto[]> {
    return this.store$.select(componentsByFilterIdSelector(filterId));
  }

  selectComponentStateAvailability(componentId: EntityId): Observable<boolean> {
    return this.store$.select(selectComponentStateAvailability(componentId));
  }

  selectRootComponentState(): Observable<Maybe<ComponentStateDto>> {
    return this.store$.select(selectRootComponentState);
  }

  selectComponentSize(componentId: EntityId): Observable<ComponentCssSize | undefined> {
    return this.store$.select(selectComponentSize(componentId));
  }

  selectComponentRuntimeSize(componentId: EntityId): Observable<SizeInPx> {
    return this.store$.select(selectComponentRuntimeSize(componentId));
  }

  selectComponentFilterId(componentId: EntityId): Observable<EntityId> {
    return this.store$.select(selectComponentFilterId(componentId)).pipe(distinctUntilChanged());
  }

  selectComponentByFilterId(filterId: EntityId): Observable<Maybe<ComponentStateDto>> {
    return this.store$.select(selectComponentByFilterId(filterId));
  }

  selectComponentDataStatus(componentId: EntityId): Observable<DataStatus | undefined> {
    return this.store$.select(selectComponentDataStatus(componentId)).pipe(distinctUntilChanged());
  }

  selectDisplayStrategy(componentId: EntityId): Observable<string> {
    return this.store$.select(selectDisplayStrategy(componentId));
  }

  selectComponentView(componentId: EntityId): Observable<Maybe<BaseViewConfigDto>> {
    return this.store$.select(selectComponentView(componentId)).pipe(
      map(() => this.getById(componentId)?.view),
      distinctUntilChanged()
    );
  }

  selectComponentCacheOptions(componentId: EntityId): Observable<CacheOptionsDto | undefined> {
    return this.store$
      .select(selectComponentCacheOptions(componentId))
      .pipe(distinctUntilChanged());
  }

  selectBackgroundImage(componentId: EntityId): Observable<string> {
    return this.store$.select(selectBackgroundImage(componentId)).pipe(distinctUntilChanged());
  }

  selectComponentWithFilter(componentId: EntityId): Observable<FullComponentStoreInfo> {
    return this.store$.select(selectComponentWithFilter(componentId)).pipe(distinctUntilChanged());
  }

  selectBackgroundImageData(componentId: EntityId): Observable<string> {
    return this.store$.select(selectBackgroundImageData(componentId)).pipe(distinctUntilChanged());
  }

  selectClosestParentBackgroundImageData(componentId: EntityId): Observable<string> {
    const imageObservables = this.buildParentObservables(componentId);
    return combineLatest(imageObservables).pipe(
      map((imageUrls: string[]) => imageUrls.find((url: string) => !isEmptyOrNotDefined(url)) ?? "")
    );
  }

  selectScalingFactor(componentId: EntityId): Observable<number> {
    return this.store$.select(selectRuntimeView(componentId)).pipe(
      map(
        (viewProps: RuntimeViewProperties) =>
          (viewProps as CardRuntimeViewProperties)?.scalingFactor
      ),
      distinctUntilChanged()
    );
  }

  private buildParentObservables(
    componentId: EntityId,
    acc: Observable<string>[] = []
  ): Observable<string>[] {
    acc.push(this.selectBackgroundImageData(componentId));
    const parent = this.getParent(componentId);
    if (isDefined(parent) && parent.type !== PAGE) {
      return this.buildParentObservables(parent.id, acc);
    } else {
      return acc;
    }
  }

  selectLink(componentId: EntityId): Observable<LinkDto | undefined> {
    return this.store$.select(selectLink(componentId)).pipe(distinctUntilChanged());
  }

  selectSiblings(componentId: EntityId): Observable<ComponentStateDto[]> {
    return this.store$.select(selectSiblings(componentId));
  }

  selectManyById(componentIds: EntityId[]): Observable<Maybe<ComponentStateDto>[]> {
    return this.store$.select(selectComponentsById(componentIds));
  }

  selectRootPath(): Observable<string> {
    return this.store$.select(selectRootPath());
  }

  selectCardImageProps(componentId: EntityId): Observable<CardImageProps> {
    return this.store$.select(selectCardImageProps(componentId)).pipe(distinctUntilChanged());
  }

  selectNavigationBarLinks(navBarComponentId: EntityId): Observable<NavLinkInfo[]> {
    return this.store$.select(selectNavigationBarLinks(navBarComponentId));
  }

  selectPositioningType(containerComponentId: EntityId): Observable<PositioningType> {
    return this.store$.select(selectContainerPositioningType(containerComponentId));
  }

  selectSnapToGrid(containerComponentId: EntityId): Observable<boolean> {
    return this.store$.select(selectContainerSnapToGrid(containerComponentId));
  }

  selectLimits(cardComponentId: EntityId): Observable<LimitsDto> {
    return this.store$.select(selectLimits(cardComponentId));
  }

  selectYAxes(componentId: EntityId): Observable<YAxisDescriptor[]> {
    return this.store$.select(selectYAxes(componentId));
  }

  selectSubscribedEvents(componentId: EntityId): Observable<EventSubscription[]> {
    return this.store$.select(selectSubscribedEvents(componentId));
  }

  selectRootRuntimeSize(): Observable<Maybe<SizeInPx>> {
    return this.store$.select(selectRootRuntimeSize);
  }

  selectComponentByConnectorId(connectorId: EntityId): Observable<Maybe<ComponentStateDto>> {
    return this.store$.select(selectComponentByConnectorId(connectorId));
  }

  selectEquipmentPathByComponent(componentId: EntityId): Observable<string> {
    return this.store$.select(selectEquipmentPathByComponent(componentId));
  }

  selectGroupsForComponent(componentId: EntityId): Observable<Maybe<ConnectorGroupDto[]>> {
    return this.store$.select(selectGroupsForComponent(componentId));
  }

  selectFooterVisibility(componentId: EntityId): Observable<boolean> {
    return this.store$.select(selectFooterVisibility(componentId));
  }

  selectHeaderVisibility(componentId: EntityId): Observable<boolean> {
    return this.store$.select(selectHeaderVisibility(componentId));
  }

  getComponentCount(): number {
    let componentCounter: number;
    this.selectComponentCount()
      .pipe(first())
      .subscribe((counter: number) => (componentCounter = counter));
    return componentCounter;
  }

  getSiblings(componentId: EntityId): ComponentStateDto[] {
    let siblings: ComponentStateDto[];
    this.selectSiblings(componentId)
      .pipe(first())
      .subscribe((value: ComponentStateDto[]) => (siblings = value));
    return siblings;
  }

  getManyById(componentIds: EntityId[]): ComponentStateDto[] {
    let components: ComponentStateDto[];
    this.selectManyById(componentIds)
      .pipe(first())
      .subscribe((value: Maybe<ComponentStateDto>[]) => (components = value?.filter(isDefined)));
    return components;
  }

  getAllAsDict(): Dictionary<ComponentStateDto> {
    let componentStates: Dictionary<ComponentStateDto> = {};
    this.selectAllComponentStates()
      .pipe(first())
      .subscribe((componentStateEntities: Dictionary<ComponentStateDto>) => {
        componentStates = componentStateEntities;
      });
    return componentStates;
  }

  getAllAsArray(): ComponentStateDto[] {
    const componentStates: Dictionary<ComponentStateDto> = this.getAllAsDict();
    return Object.values(componentStates).filter(isDefined);
  }

  getById(componentId: EntityId): Maybe<ComponentStateDto> {
    let componentState: Maybe<ComponentStateDto>;
    this.store$
      .select(selectComponentStateById(componentId))
      .pipe(first())
      .subscribe((value) => (componentState = value));
    return componentState;
  }

  getComponentByFilterId(filterId: EntityId): Maybe<ComponentStateDto> {
    let componentState: Maybe<ComponentStateDto>;
    this.store$
      .select(selectComponentByFilterId(filterId))
      .pipe(first())
      .subscribe((value) => (componentState = value));
    return componentState;
  }

  getRoot(): ComponentStateDto {
    let rootComponentState: ComponentStateDto;
    this.store$
      .select(selectRootComponentState)
      .pipe(
        first(),
        map((dto) => {
          if (!dto) {
            throw new CriticalError("Root component state not found.");
          }
          return dto;
        })
      )
      .subscribe((value: ComponentStateDto) => (rootComponentState = value));
    return rootComponentState;
  }

  getParent(childId: EntityId): Maybe<ComponentStateDto> {
    let parentComponentState: Maybe<ComponentStateDto> = null;
    this.store$
      .select(selectParent(childId))
      .pipe(first())
      .subscribe((parent) => {
        parentComponentState = parent;
      });
    return parentComponentState;
  }

  getChildrenAsDict(parentId: EntityId): Dictionary<ComponentStateDto> {
    const childStatesArray: ComponentStateDto[] = this.getChildrenAsArray(parentId);
    return childStatesArray.reduce((acc, state: ComponentStateDto) => {
      acc[state.id] = state;
      return acc;
    }, {});
  }

  getChildrenAsArray(parentId: EntityId): ComponentStateDto[] {
    const parentState: ComponentStateDto = this.getById(parentId);
    const allStates: ComponentStateDto[] = this.getAllAsArray();

    return allStates.filter(
      (state: ComponentStateDto) =>
        parentState.childrenIds.findIndex((childId: EntityId) => childId === state.id) >= 0
    );
  }

  getAllForFiltersAsDict(filterIds: EntityId[]): Dictionary<ComponentStateDto[]> {
    return filterIds.reduce((acc: Dictionary<ComponentStateDto[]>, filterId: EntityId) => {
      acc[filterId] = this.getAllForFilter(filterId).filter(isDefined);
      return acc;
    }, {});
  }

  getAllForFiltersAsArray(filterIds: EntityId[]): ComponentStateDto[] {
    return filterIds.reduce((acc: ComponentStateDto[], filterId: EntityId) => {
      acc = acc.concat(this.getAllForFilter(filterId).filter(isDefined));
      return acc;
    }, []);
  }

  getAllForFilter(filterId: EntityId): ComponentStateDto[] {
    let targetComponents: ComponentStateDto[] = [];
    this.store$
      .select(selectAllComponentsForFilter(filterId))
      .pipe(first())
      .subscribe(
        (components: Maybe<ComponentStateDto>[]) =>
          (targetComponents = components.filter(isDefined))
      );
    return targetComponents;
  }

  getCardImageProps(cardId: EntityId): CardImageProps {
    let imageProps: CardImageProps = null;
    this.selectCardImageProps(cardId)
      .pipe(first())
      .subscribe((propsFromStore) => {
        imageProps = propsFromStore;
      });
    return imageProps;
  }

  getPositioningType(cardId: EntityId): PositioningType {
    let positioningType: PositioningType = null;
    this.selectPositioningType(cardId)
      .pipe(first())
      .subscribe((positioning) => {
        positioningType = positioning;
      });
    return positioningType;
  }

  getSnapToGrid(cardId: EntityId): boolean {
    let snapToGridMode: boolean = null;
    this.selectSnapToGrid(cardId)
      .pipe(first())
      .subscribe((mode) => {
        snapToGridMode = mode;
      });
    return snapToGridMode;
  }

  getComponentByConnectorId(connectorId: EntityId): Maybe<ComponentStateDto> {
    let component: Maybe<ComponentStateDto> = null;
    this.store$
      .select(selectComponentByConnectorId(connectorId))
      .pipe(first())
      .subscribe((value) => {
        component = value;
      });
    return component;
  }

  getLinkedComponents(sourceComponentId: EntityId): EntityId[] {
    return this.getAllAsArray().reduce((acc: EntityId[], component: ComponentStateDto) => {
      const foundEvent = this.getSubscribedEvents(component.id)?.find(
        (event) => event.sourceWidgetId === sourceComponentId
      );
      return isDefined(foundEvent) ? acc.concat(component.id) : acc;
    }, []);
  }

  getSubscribedEvents(componentId: EntityId): Maybe<EventSubscription[]> {
    let subscribedEvents: EventSubscription[] = null;
    this.selectSubscribedEvents(componentId)
      .pipe(first())
      .subscribe((events) => {
        subscribedEvents = events;
      });
    return subscribedEvents;
  }
}
