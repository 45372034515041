import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ColorPickerModule } from "ngx-color-picker";
import { QuillModule } from "ngx-quill";
import { BrowsingModule } from "../browsing/browsing.module";
import { CoreModule } from "../core/core.module";
import { DynamicsModule } from "../dynamics/dynamics.module";
import { I18NModule } from "../i18n/i18n.module";
import { MaterialModule } from "../material/material.module";
import { MetaModule } from "../meta/meta.module";
import { ClickOutsideDirectiveModule } from "../shared/directives/click-outside.directive";
import { SharedModule } from "../shared/shared.module";
import { ArrayEditorItemComponent } from "./components/array-editor-item/array-editor-item.component";
import { ArrayEditorComponent } from "./components/array-editor/array-editor.component";
import { BaseEditorComponent } from "./components/base-editor.component";
import { BorderSideSelectorEditorComponent } from "./components/border-side-selector-editor/border-side-selector-editor.component";
import { CheckBoxEditorComponent } from "./components/check-box-editor/check-box-editor.component";
import { ColorPickerDialogComponent } from "./components/color-picker-dialog/color-picker-dialog.component";
import { ColorPickerEditorComponent } from "./components/color-picker-editor/color-picker-editor.component";
import { ComboBoxEditorComponent } from "./components/combo-box-editor/combo-box-editor.component";
import { DataConnectorArrayItemComponent } from "./components/data-connector-array-item/data-connector-array-item.component";
import { EditableSelectListEditorComponent } from "./components/editable-select-list-editor/editable-select-list-editor.component";
import { EditorWrapperComponent } from "./components/editor-wrapper/editor-wrapper.component";
import { EntityArrayEditorComponent } from "./components/entity-array-editor/entity-array-editor.component";
import { ExtendableCheckboxListComponent } from "./components/extendable-checkbox-list/extendable-checkbox-list.component";
import { FileSelectionEditorComponent } from "./components/file-selection-editor/file-selection-editor.component";
import { GroupedPropertySheetEditors } from "./components/grouped-property-sheet-editors/grouped-property-sheet-editors.component";
import { IconPickerEditorComponent } from "./components/icon-picker-editor/icon-picker-editor.component";
import { ImageSelectionEditorComponent } from "./components/image-selection-editor/image-selection-editor.component";
import { LimitValueColorEditorComponent } from "./components/limit-value-color-editor/limit-value-color-editor.component";
import { LimitsEditorComponent } from "./components/limits-editor/limits-editor.component";
import { LinkEditorComponent } from "./components/link-editor/link-editor.component";
import { LinkWidgetEditorComponent } from "./components/link-widget-editor/link-widget-editor.component";
import { NestedObjectEditorWrapperComponent } from "./components/nested-object-editor-wrapper/nested-object-editor-wrapper.component";
import { NestedObjectEditorComponent } from "./components/nested-object-editor/nested-object-editor.component";
import { NumberEditorComponent } from "./components/number-editor/number-editor.component";
import { PropertySheetEditorWrapperComponent } from "./components/property-sheet-editor-wrapper/property-sheet-editor-wrapper.component";
import { PropertySheetPageComponent } from "./components/property-sheet-page/property-sheet-page.component";
import { PropertySheetSubcategoryComponent } from "./components/property-sheet-subcategory/property-sheet-subcategory.component";
import { PropertySheetComponent } from "./components/property-sheet/property-sheet.component";
import { ReportBrowserDialogComponent } from "./components/report-browser-dialog/report-browser-dialog.component";
import { SliderEditorComponent } from "./components/slider-editor/slider-editor.component";
import { TextAreaEditorComponent } from "./components/text-area-editor/text-area-editor.component";
import { TextBoxEditorComponent } from "./components/text-box-editor/text-box-editor.component";
import { EditorProvider, IEditorProvider } from "./services/editor-provider.service";
import { PropertySheetDependencyService } from "./services/property-sheet-dependency.service";

export const DYNAMIC_COMPONENTS_PROPERTY_SHEET = [
  BaseEditorComponent,
  CheckBoxEditorComponent,
  ColorPickerEditorComponent,
  ColorPickerDialogComponent,
  ComboBoxEditorComponent,
  PropertySheetEditorWrapperComponent,
  PropertySheetPageComponent,
  TextBoxEditorComponent,
  NumberEditorComponent,
  LinkEditorComponent,
  LinkWidgetEditorComponent,
  ArrayEditorComponent,
  EntityArrayEditorComponent,
  NestedObjectEditorComponent,
  EditorWrapperComponent,
  NestedObjectEditorWrapperComponent,
  FileSelectionEditorComponent,
  ImageSelectionEditorComponent,
  IconPickerEditorComponent,
  PropertySheetComponent,
  LimitValueColorEditorComponent,
  LimitsEditorComponent,
  DataConnectorArrayItemComponent,
  ArrayEditorItemComponent,
  ReportBrowserDialogComponent,
  TextAreaEditorComponent,
  PropertySheetSubcategoryComponent,
  EditableSelectListEditorComponent,
  BorderSideSelectorEditorComponent,
  SliderEditorComponent,
  GroupedPropertySheetEditors,
  ExtendableCheckboxListComponent
];

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ColorPickerModule,
    MaterialModule,
    DynamicsModule,
    BrowsingModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    I18NModule,
    MetaModule,
    SharedModule,
    ClickOutsideDirectiveModule,
    QuillModule.forRoot({})
  ],
  declarations: [DYNAMIC_COMPONENTS_PROPERTY_SHEET],
  providers: [PropertySheetDependencyService],
  exports: [DYNAMIC_COMPONENTS_PROPERTY_SHEET]
})
export class PropertySheetModule {
  static forRoot(): ModuleWithProviders<PropertySheetModule> {
    return {
      ngModule: PropertySheetModule,
      providers: [{ provide: IEditorProvider, useClass: EditorProvider }]
    };
  }
}
