import { EMPTY_VALUE } from "../../../core/models/time-range";
import { construct } from "../../../core/services/construct.helper";
import { formatNumberByStringFormat } from "../../../core/services/value-formatter.service";
import { BaseViewConfigDto } from "../../../elements/models/base-view-config";
import { ComponentCssSize } from "../../../elements/models/component-size";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurationCategory,
  EditableType,
  Serializable
} from "../../../meta/decorators";
import { EditorType, PropertyCategory } from "../../../meta/models";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";

const TYPE_NAME = "StaticNumericValueViewConfig";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class StaticNumericValueViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  value: string = EMPTY_VALUE;
  timestamp: string = "";
  greaterThanBeforeColor: string = "#049669";
  lesserThanBeforeColor: string = "#ef2f40";

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 5)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Unit,
    editorType: EditorType.TextBox
  })
  @Serializable("")
  unit!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#004C97")
  color!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Centered,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  centered!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowTimestamp,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  showTimestamp!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowCircle,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  showCircle!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowBreadcrumb,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  showGrowth!: boolean;

  constructor(viewConfigDto: DeepPartial<StaticNumericValueViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }

  get displayValue(): string {
    const numericValue: number = parseFloat(this.value);
    if (isNaN(numericValue)) {
      return EMPTY_VALUE;
    } else {
      return formatNumberByStringFormat(numericValue, this.displayFormat.numberFormat);
    }
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("100", "100");
}
