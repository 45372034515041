import { construct } from "../../core/services/construct.helper";
import { createDataSource } from "../../data-connectivity/helpers/data-source-creation.helper";
import { isEquipment } from "../../data-connectivity/helpers/data-source-type.helper";
import { DataSourceDto } from "../../data-connectivity/models/data-source/data-source";
import { EMPTY_DATA_SOURCE } from "../../data-connectivity/models/data-source/data-source.type";
import { EquipmentDataSourceDto } from "../../data-connectivity/models/data-source/equipment-data-source";
import { EditableType, NotSerializable, Serializable, Virtual } from "../../meta/decorators";
import { Entity, EntityId } from "../../meta/models";
import { TypeProvider } from "../../meta/services/type-provider";
import { DeepPartial, isNotDefined } from "../../ts-utils";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { StrictDeepPartial } from "../../ts-utils/models/strict-partial.type";
import { createViewConfig } from "../helpers/view-config-creation.helper";
import { ComponentStateSelector } from "../services/entity-selectors/component-state.selector";
import { BaseViewConfigDto } from "./base-view-config";
import { CacheOptionsDto } from "./cache-options";
import { DataStatus } from "./data-status";
import { COMPONENT_STATE_DTO } from "./entity-type.constants";

const TYPE_NAME = COMPONENT_STATE_DTO;

@EditableType({ fullName: TYPE_NAME, title: "component-state" })
export class ComponentStateDto extends Entity {
  typeName = TYPE_NAME;

  @Serializable(new BaseViewConfigDto())
  @Virtual()
  view!: BaseViewConfigDto;

  @Serializable([], Object.getPrototypeOf("").constructor)
  dataConnectorIds!: EntityId[];

  @Serializable()
  type: string;

  @Serializable([], Object.getPrototypeOf("").constructor)
  childrenIds!: EntityId[];

  @Serializable(null)
  filterId: Maybe<EntityId>;

  @Serializable(new CacheOptionsDto())
  cache!: CacheOptionsDto;

  @Serializable()
  @Virtual()
  dataConnectorQuery!: DataSourceDto;

  @NotSerializable()
  activeTabIndex: number = 0;

  maxNumberOfDataConnectors: number;

  componentDataStatus: DataStatus = DataStatus.NoDataDefined;

  dataConnectorQueryDataStatus: DataStatus = DataStatus.NoDataDefined;

  constructor(componentState: StrictDeepPartial<ComponentStateDto, "id" | "type">) {
    super();
    componentState = {
      ...componentState,
      dataConnectorQuery: createDataSource(
        componentState.dataConnectorQuery?.typeName ?? EMPTY_DATA_SOURCE,
        componentState.dataConnectorQuery ?? {},
        TYPE_NAME
      ),
      view: createViewConfig(componentState.type, componentState.view)
    };

    construct(this, componentState, TYPE_NAME);
  }

  static createByName(
    typeName: string,
    typeProvider: TypeProvider,
    componentStateSelector: ComponentStateSelector,
    initialState: DeepPartial<ComponentStateDto> = {}
  ): ComponentStateDto {
    const componentCount: number = componentStateSelector.getComponentCount();
    const componentTitle: string = typeProvider.getType(typeName).titleProperty;
    const componentId: string = this.generateComponentId(componentTitle, componentCount);
    return new ComponentStateDto({ ...initialState, id: componentId, type: typeName });
  }

  static createWithCounter(
    typeName: string,
    counter: number,
    typeProvider: TypeProvider,
    initialState: DeepPartial<ComponentStateDto> = {}
  ): ComponentStateDto {
    const componentTitle: string = typeProvider.getType(typeName).titleProperty;
    const componentId: string = this.generateComponentId(componentTitle, counter);
    return new ComponentStateDto({ ...initialState, id: componentId, type: typeName });
  }

  static generateComponentId(componentTitle: string, componentCount: number) {
    return componentTitle + "-" + (componentCount + 1);
  }
}

export function getDcqEquipmentPath(state: ComponentStateDto): string {
  if (state != null || typeof state.dataConnectorQuery === "undefined") {
    return "";
  }
  if (isEquipment(state.dataConnectorQuery)) {
    return (<EquipmentDataSourceDto>state.dataConnectorQuery).path || "";
  }
  return "";
}

export function isValidComponentState(state: ComponentStateDto): boolean {
  if (hasEmptyViewConfig(state)) {
    return true;
  }
  return state.view.typeName.includes("ViewConfig");
}

export function hasEmptyViewConfig(state: ComponentStateDto) {
  if (isNotDefined(state.view)) {
    return true; // as empty is valid
  }
  if (!Object.keys(state.view).length) {
    return true;
  }
}

export function hasChildren(componentState: Maybe<ComponentStateDto>): boolean {
  return (
    componentState != null && componentState.childrenIds && componentState.childrenIds.length > 0
  );
}
