import { Component } from "@angular/core";
import {
  BaseComponent,
  ChartComponent,
  ConnectorRoles,
  EditableType,
  formatNumberByStringFormat,
  View
} from "ui-core";
import { Roles } from "./roles";
import { PoleFluxMonitoringChartViewConfig } from "./view-config";

@Component({
  selector: "rds-pole-flux-monitoring-chart",
  templateUrl: "./pole-flux-monitoring-chart.component.html",
  styleUrls: ["./pole-flux-monitoring-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: PoleFluxMonitoringChartComponent }]
})
// @LayoutBuilder(ComponentCategory.OtherComponents, "PoleFluxMonitoringChartComponent", "Dashboard_2")
@ConnectorRoles(Roles)
@EditableType({ fullName: "PoleFluxMonitoringChartComponent", title: "pole-flux-monitoring-chart" })
export class PoleFluxMonitoringChartComponent extends ChartComponent {
  // constant to scale Density on chart
  chartDensityAdjustment: number;

  @View(PoleFluxMonitoringChartViewConfig)
  public get view() {
    return this.currentState.view as PoleFluxMonitoringChartViewConfig;
  }

  protected updateDisplay() {
    super.updateDisplay();

    // let chart = this.Highcharts. as Chart;
    // const axis = this.Highcharts.chart. ref.get('x-axis-1') as AxisObject;
    // const axis = this.get('x-axis-1') as AxisObject;
    /*axis.update({
      labels: {
        //distance: -(this['clipBox'].width / 9)
        distance: -25
      }
    });*/

    this.updateLegendItem(
      "Min. Flux Density",
      formatNumberByStringFormat(
        Math.min(...this.view.currentPoleDensityValues) + this.chartDensityAdjustment,
        this.view.displayFormat.numberFormat
      ) + "(T))"
    );
    this.updateLegendItem(
      "Max. Flux Density",
      formatNumberByStringFormat(
        Math.max(...this.view.currentPoleDensityValues) + this.chartDensityAdjustment,
        this.view.displayFormat.numberFormat
      ) + "(T))"
    );
    this.updateLegendItem(
      "Min. Airgap",
      formatNumberByStringFormat(
        Math.min(...this.view.currentPoleAirgapValues),
        this.view.displayFormat.numberFormat
      ) + "(mm))"
    );
    this.updateLegendItem(
      "Max. Airgap",
      formatNumberByStringFormat(
        Math.max(...this.view.currentPoleAirgapValues),
        this.view.displayFormat.numberFormat
      ) + "(mm))"
    );
  }

  private calculatePoleProximity(min: number, max: number, result: number): number {
    if (Math.floor(min / result) === 1 || Math.floor(max / result) === 1) {
      return result;
    } else {
      return this.calculatePoleProximity(min, max, result + 10);
    }
  }

  private calculateDensityProximity(min: number, max: number, result: number): number {
    if (Math.floor(min / result) === 0 || Math.floor(max / result) === 0) {
      return result;
    } else {
      return this.calculateDensityProximity(min, max, result - 1);
    }
  }

  protected updateChartData(): void {
    const newPoleAirgapData = this.dataAccessor.getAllValuesForSimilarRoles(
      Roles.PoleAirgapValues.name
    );
    const newPoleDensityData = this.dataAccessor.getAllValuesForSimilarRoles(
      Roles.PoleDensityValues.name
    );

    if (
      newPoleAirgapData &&
      newPoleAirgapData.length > 0 &&
      newPoleAirgapData[0].length > 0 &&
      newPoleDensityData &&
      newPoleDensityData.length > 0 &&
      newPoleDensityData[0].length > 0
    ) {
      this.view.currentPoleAirgapValues = newPoleAirgapData[0];
      this.view.currentPoleDensityValues = newPoleDensityData[0];

      this.chartDensityAdjustment = this.calculatePoleProximity(
        Math.min(...newPoleDensityData[0]),
        Math.max(...newPoleDensityData[0]),
        1
      );

      this.view.currentPoleDensityValues = [];
      for (const value in newPoleDensityData[0]) {
        this.view.currentPoleDensityValues.push(
          newPoleDensityData[0][value] - this.chartDensityAdjustment /* * 100*/
        );
      }

      const minAirgapVal = Math.min(...this.view.currentPoleAirgapValues);
      const maxAirgapVal = Math.max(...this.view.currentPoleAirgapValues);
      this.view.smallestAirgapValues = [];
      this.view.largestAirgapValues = [];

      this.view.currentPoleAirgapValues.forEach((element) => {
        this.view.smallestAirgapValues.push(minAirgapVal);
        this.view.largestAirgapValues.push(maxAirgapVal);
      });

      const minDensityVal = Math.min(...this.view.currentPoleDensityValues);
      const maxDensityVal = Math.max(...this.view.currentPoleDensityValues);
      this.view.smallestDensityValues = [];
      this.view.largestDensityValues = [];

      this.view.currentPoleDensityValues.forEach((element) => {
        this.view.smallestDensityValues.push(minDensityVal);
        this.view.largestDensityValues.push(maxDensityVal);
      });
    }
  }

  protected setChartOptions(): void {
    const component = this;
    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        type: "column",
        polar: true,
        events: {
          load: function () {
            // TODO broken by new library used for Highcharts, it was done before after redrawing chart
            // x-axis-1 labels distance must be set according to clibBox.width / 9 - or similar value
            /*const axis = this.get('x-axis-1') as AxisObject;
            axis.update({
              labels: {
                //distance: -(this['clipBox'].width / 9)
                distance: -25
              }
            });*/
            /*this.updateAxis({
              id: 'x-axis-1',
              max: this.view.currentPoleAirgapValues.length,
              labels: {
                distance: -(this.chart.ref['clipBox'].width / 9)
              },
            });*/
          }
        }
      },
      tooltip: {
        formatter: function () {
          if (this.series.index === 1) {
            return (
              "Pole: <b>" +
              (+this.x + 1) +
              "</b>. Flux Density: <b>" +
              formatNumberByStringFormat(
                +this.y + component.chartDensityAdjustment,
                component.view.displayFormat.numberFormat
              ) +
              "</b>."
            );
          } else if (this.series.index === 4) {
            return "Pole: <b>" + (+this.x + 1) + "</b>. Airgap: <b>" + +this.y + "</b>.";
          }
          return "";
        }
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false,
        align: "left",
        verticalAlign: "middle",
        layout: "vertical"
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            symbol: "cricle",
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: [
        {
          yAxis: 1,
          type: "line",
          name: "Min Flux Density",
          data: this.view.smallestDensityValues,
          dashStyle: "longdash",
          lineWidth: 1,
          enableMouseTracking: false,
          color: this.view.minFluxDensityColor
        },
        {
          yAxis: 1,
          type: "line",
          name: "Flux Density",
          data: this.view.currentPoleDensityValues,
          dashStyle: "shortdash",
          lineWidth: 1,
          marker: {
            enabled: true,
            radius: 3
          },
          color: this.view.fluxDensityColor
        },
        {
          yAxis: 1,
          type: "line",
          name: "Max Flux Density",
          data: this.view.largestDensityValues,
          dashStyle: "LongDashDot",
          lineWidth: 1,
          enableMouseTracking: false,
          color: this.view.maxFluxDensityColor
        },
        {
          type: "line",
          name: "Min Airgap",
          data: this.view.smallestAirgapValues,
          dashStyle: "shortdash",
          lineWidth: 1,
          enableMouseTracking: false,
          color: this.view.minAirgapColor
        },
        {
          type: "line",
          name: "Airgap",
          data: this.view.currentPoleAirgapValues,
          dashStyle: "shortdash",
          lineWidth: 1,
          marker: {
            enabled: true,
            radius: 2
          },
          color: this.view.airgapColor
        },
        {
          type: "line",
          name: "Max Airgap",
          data: this.view.largestAirgapValues,
          dashStyle: "longdash",
          lineWidth: 1,
          enableMouseTracking: false,
          color: this.view.maxAirgapColor
        }
      ],
      xAxis: [
        {
          id: "x-axis-1",
          lineColor: "transparent",
          labels: {
            distance: -35,
            style: {
              fontSize: "8px"
            },
            formatter: function () {
              return +this.value + 1;
            }
          },
          tickInterval: 1,
          min: 0,
          max: this.view.currentPoleAirgapValues.length
        },
        {
          id: "x-axis-2",
          lineColor: "transparent",
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          tickInterval: 1,
          min: 0,
          max: this.view.currentPoleAirgapValues.length,
          labels: {
            formatter: function () {
              return "";
            }
          }
        }
      ],
      yAxis: [
        {
          id: "y-axis-1",
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          floor: 0,
          ceiling: Math.max(...this.view.currentPoleAirgapValues),
          max: Math.max(...this.view.currentPoleAirgapValues),
          maxPadding: 0,
          endOnTick: false,

          min: -150,
          labels: {
            // Hide first axis label
            formatter: function () {
              return ""; // this.isFirst ? '' : this.value
            }
          }
        },
        {
          id: "y-axis-2",
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          floor: 0,
          ceiling: Math.max(...this.view.currentPoleDensityValues) * 1.6,
          max: Math.max(...this.view.currentPoleDensityValues) * 1.6,
          maxPadding: 0,
          endOnTick: false,
          min: -150,
          labels: {
            formatter: function () {
              return "";
            }
          }
        }
      ],
      subtitle: {
        text: "View From Feed Side",
        align: "center",
        verticalAlign: "bottom"
      }
    };
  }
}
