import { DataTransferObject } from "../../core/models/data-transfer-object";
import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { GroupNestedProperties } from "../../meta/decorators/group-nested-properties.decorator";
import { OnPropertyChange } from "../../meta/decorators/on-property-change.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyCategory } from "../../meta/models/property-category";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";

const TYPE_NAME = "BorderRadiusDto";
@EditableType({ fullName: TYPE_NAME, title: "border-radius-dto" })
@GroupNestedProperties({
  subgroupsWidthRatio: [0.7, 0.3],
  layout: [["radiusSlider"], ["radiusInput"]]
})
export class BorderRadiusDto implements DataTransferObject {
  typeName: string = TYPE_NAME;

  @OnPropertyChange<any, BorderRadiusDto, any>(onRadiusValueChange)
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Border)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.BorderRadius,
    editorType: EditorType.SliderEditor,
    advancedMode: true
  })
  @Serializable(0)
  radiusSlider!: number;

  @OnPropertyChange<any, BorderRadiusDto, any>(onRadiusValueChange)
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Border)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.BorderRadius,
    editorType: EditorType.Number,
    advancedMode: true,
    showLabel: false
  })
  @Serializable(0)
  radiusInput!: number;

  constructor(radius: number = 0) {
    construct(this, { radiusInput: radius, radiusSlider: radius }, TYPE_NAME);
  }
}

function onRadiusValueChange(
  _context: any,
  owner: BorderRadiusDto,
  propertyChange: any
): BorderRadiusDto {
  if (isDefined(propertyChange.value)) {
    return new BorderRadiusDto(propertyChange.value);
  }
  return owner;
}
