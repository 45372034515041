import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SidemenuComponents } from "../../core/models/sidemenu-components.enum";
import { Maybe } from "../../ts-utils/models/maybe.type";
@Injectable()
export class SidebarService {
  constructor() {}

  public componentToShow = new BehaviorSubject<Maybe<SidemenuComponents>>(null);

  public setComponentToShow(component: SidemenuComponents): void {
    this.componentToShow.next(component);
  }
}
