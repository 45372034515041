import { WIDGET_PROPERTIES__NAVIGATION_LINK } from "../../elements/models/help-constants";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { PropertySheetLocalizationDictionary } from "../../i18n/models/property-sheet-localization-dictionary";
import {
  Configurable,
  ConfigurableEnum,
  ConfigurationCategory,
  EditorType,
  OnPropertyChange,
  PropertyCategory,
  PropertyInfo,
  SelectionOption,
  Serializable
} from "../../meta";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { ValidationContext } from "../../meta/models/validation-context";
import { Maybe, isDefined, isEmpty, isEmptyOrNotDefined } from "../../ts-utils";
import { exhaustiveTypeCheck } from "../../ts-utils/helpers/exhaustive-type-check.helper";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { StrictPartial } from "../../ts-utils/models/strict-partial.type";
import { DataTransferObject } from "./data-transfer-object";
import { DecoratedReportDescriptionDto } from "./decorated-report-description-dto";
import { EquipmentPath } from "./equipment-path";
import { LinkOpenMode } from "./link-open-mode";
import { ReportId } from "./report-id";

export type LinkType = "LinkDto" | "ReportLinkDto" | "ExternalLinkDto" | "EmptyLinkDto";

const LINK_TYPE = "LinkDto";
export const REPORT_LINK = "ReportLinkDto";
export const EXTERNAL_LINK = "ExternalLinkDto";
export const EMPTY_LINK = "EmptyLinkDto";

@EditableType({ fullName: LINK_TYPE, title: "link-dto", virtual: true })
export abstract class LinkDto implements DataTransferObject {
  @ConfigurationCategory(
    PropertyCategory.Interaction,
    LOCALIZATION_DICTIONARY.propertySheet.Navigation
  )
  @ConfigurableEnum({
    enumSource: getLinkEnum,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Type,
    advancedMode: true,
    userHelp: WIDGET_PROPERTIES__NAVIGATION_LINK
  })
  @OnPropertyChange<any, LinkDto, any>(onLinkTypeChange)
  @Serializable(EMPTY_LINK)
  typeName: LinkType = LINK_TYPE;
}

@EditableType({ fullName: REPORT_LINK, title: "report-link-dto" })
export class ReportLinkDto extends LinkDto {
  typeName: "ReportLinkDto" = REPORT_LINK;

  type: "ReportLink" = "ReportLink";
  @ConfigurationCategory(
    PropertyCategory.Interaction,
    LOCALIZATION_DICTIONARY.propertySheet.Navigation
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Link,
    editorType: EditorType.LinkEditor,
    advancedMode: true
  })
  @Serializable()
  info: DecoratedReportDescriptionDto;

  @ConfigurationCategory(
    PropertyCategory.Interaction,
    LOCALIZATION_DICTIONARY.propertySheet.Navigation
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.RootPath,
    editorType: EditorType.EquipmentPathBrowser,
    advancedMode: true
  })
  @Serializable()
  rootPath: Maybe<EquipmentPath>;

  @ConfigurationCategory(
    PropertyCategory.Interaction,
    LOCALIZATION_DICTIONARY.propertySheet.Navigation
  )
  @ConfigurableEnum({
    enumSource: LinkOpenMode,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LinkOpenMode,
    advancedMode: true
  })
  @Serializable(LinkOpenMode.SameWindow)
  openMode: LinkOpenMode;

  constructor(link: Partial<ReportLinkDto>) {
    super();
    this.info = new DecoratedReportDescriptionDto(
      link.info?.reportId as ReportId,
      link.info?.reportName ?? "",
      link.info?.reportTemplateUrl
    );
    this.rootPath = link.rootPath;
    this.openMode = link.openMode ?? LinkOpenMode.SameWindow;
  }
}

@EditableType({ fullName: EXTERNAL_LINK, title: "external-link-dto" })
export class ExternalLinkDto extends LinkDto {
  typeName: "ExternalLinkDto" = EXTERNAL_LINK;

  @ConfigurationCategory(
    PropertyCategory.Interaction,
    LOCALIZATION_DICTIONARY.propertySheet.Navigation
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Url,
    editorType: EditorType.TextBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.UrlTooltip,
    advancedMode: true,
    validationFunction: validateExternalLinkUrl
  })
  @Serializable()
  url: string;

  @ConfigurationCategory(
    PropertyCategory.Interaction,
    LOCALIZATION_DICTIONARY.propertySheet.Navigation
  )
  @ConfigurableEnum({
    enumSource: LinkOpenMode,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.LinkOpenMode,
    advancedMode: true
  })
  @Serializable(LinkOpenMode.NewBrowserTab)
  openMode: LinkOpenMode;

  // can be set via ApiDatasource, but (for now) not in property sheet
  title: Maybe<string>;

  constructor(url: string, openMode?: LinkOpenMode) {
    super();
    this.url = url;
    this.openMode = openMode ?? LinkOpenMode.NewBrowserTab;
  }
}

// NOTE because of update action that merges links in store, empty link can be used to clear all previously saved link
@EditableType({ fullName: EMPTY_LINK, title: "empty-link-dto" })
export class EmptyLinkDto extends LinkDto {
  typeName: "EmptyLinkDto" = EMPTY_LINK;
}

export function onLinkTypeChange(
  _context: any,
  _owner: any,
  propertyChange: PropertyInfo<LinkType>
): any {
  const newType: LinkType = propertyChange.value;
  return linkCreate({ typeName: newType });
}

export function linkCreate(link: StrictPartial<LinkDto, "typeName">): LinkDto {
  return switchLink<LinkDto>(link, {
    EmptyLinkDto: () => new EmptyLinkDto(),
    ExternalLinkDto: (typedLink) => new ExternalLinkDto(typedLink?.url, typedLink.openMode),
    ReportLinkDto: (typedLink) => new ReportLinkDto(typedLink)
  });
}

export function getLinkEnum(): SelectionOption[] {
  const allLinks: LinkType[] = ["EmptyLinkDto", "ExternalLinkDto", "ReportLinkDto"];
  return allLinks.map((link) => ({
    key: link,
    title: LOCALIZATION_DICTIONARY.propertySheet[link as keyof PropertySheetLocalizationDictionary]
  }));
}

export function getNavigableUrl(link: ReportLinkDto): string {
  return `report/${link.info.reportId}`;
}

export function getDisplayValue(link: LinkDto): string {
  return switchLink(link, {
    EmptyLinkDto: () => "",
    ExternalLinkDto: (typedLink) => typedLink.url,
    ReportLinkDto: (typedLink) => typedLink.info.reportName
  });
}

export function switchLink<Out>(
  link: LinkDto,
  callbacks: {
    ReportLinkDto: (link: ReportLinkDto) => Out;
    ExternalLinkDto: (link: ExternalLinkDto) => Out;
    EmptyLinkDto: (link: EmptyLinkDto) => Out;
  }
): Out {
  switch (link.typeName) {
    case "ReportLinkDto": {
      return callbacks[link.typeName](link as ReportLinkDto);
    }
    case "ExternalLinkDto": {
      return callbacks[link.typeName](link as ExternalLinkDto);
    }
    case "EmptyLinkDto": {
      return callbacks[link.typeName](link as EmptyLinkDto);
    }
    case "LinkDto": {
      throw new CriticalError(`Cannot use abstract class ${link.typeName}.`);
    }
    default: {
      exhaustiveTypeCheck("Unknown link type: ", link.typeName);
    }
  }
}

export function isReportLink(link: LinkDto): link is ReportLinkDto {
  return link.typeName === REPORT_LINK;
}

export function isExternalLink(link: LinkDto): link is ExternalLinkDto {
  return link.typeName === EXTERNAL_LINK;
}

export function isEmptyLink(link: LinkDto): link is EmptyLinkDto {
  return link.typeName === EMPTY_LINK;
}

export function isLinkDefined(link: Maybe<LinkDto>): boolean {
  return (
    isDefined(link) &&
    !isEmptyLink(link) &&
    (isReportLinkDefined(link) || isExternalLinkDefined(link))
  );
}

export function isReportLinkDefined(link: LinkDto): boolean {
  return isReportLink(link) && isDefined(link.info.reportId);
}

export function isExternalLinkDefined(link: LinkDto): boolean {
  return isExternalLink(link) && !isEmptyOrNotDefined(link.url);
}

function validateExternalLinkUrl(url: string, _validationContext: ValidationContext): boolean {
  return !isEmpty(url);
}
