import { createAction, props } from "@ngrx/store";

export namespace ComponentCounterActions {
  export const increment = createAction(
    "[Environment] Increment Component Counter",
    props<{ incrementValue: number }>()
  );

  export const decrement = createAction(
    "[Environment] Decrement Component Counter",
    props<{ decrementValue: number }>()
  );

  export const setCounter = createAction(
    "[Environment] Set Component Counter",
    props<{ value: number }>()
  );
}
