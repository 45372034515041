import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import { REPORT_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { TimeModeSpecificPresets } from "../../shared/models/time-preset-filter";
import { isEmptyOrNotDefined } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpgradeGlobalFilterWithPresets implements UpgradeStep {
  fromVersion: Version = new Version(4, 0, 18);
  name: string = "UpgradeGlobalFilterWithPresets";

  perform(oldConfig: any): UpgradeStepResult {
    const filters = oldConfig.filters;
    let modified = false;
    if (isDefined(filters)) {
      modified = this.addTimePreset(filters) || modified;
    }
    return {
      result: oldConfig,
      modified: modified,
      warning: null
    };
  }

  private addTimePreset(filters: EntityState<FilterConfigurationDto>): boolean {
    const globalFilter = filters.entities[REPORT_FILTER_ID];
    let isModified: boolean = false;
    if (isDefined(globalFilter)) {
      const timeRange = globalFilter.timeRange;
      if (isDefined(timeRange)) {
        const timePreset: TimeModeSpecificPresets = isEmptyOrNotDefined(timeRange.toExpression)
          ? TimeModeSpecificPresets.Interval
          : TimeModeSpecificPresets.Custom;

        globalFilter.timeRange = { ...timeRange, timePreset };
        isModified = true;
      }
    }

    return isModified;
  }
}
