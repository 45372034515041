import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyCategory } from "../../meta/models/property-category";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { WHITE_COLOR_HEX } from "./colors.constants";
import { ComponentStyleDto } from "./component-style";
import { WIDGET_PROPERTIES__BACKGROUND_IMAGE_AND_COLOR } from "./help-constants";

const TYPE_NAME = "ContainerComponentStyleDto";

@EditableType({ fullName: TYPE_NAME, title: "container-component-style-dto" })
export class ContainerComponentStyleDto extends ComponentStyleDto {
  typeName = TYPE_NAME;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Background)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker,
    advancedMode: true,
    inlineAlignment: true,
    userHelp: WIDGET_PROPERTIES__BACKGROUND_IMAGE_AND_COLOR
  })
  @Serializable(WHITE_COLOR_HEX)
  backgroundColor!: string;

  constructor(viewConfigDto: DeepPartial<ContainerComponentStyleDto> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, {});
  }
}
