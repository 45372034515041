import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import { getConnectorViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { ComponentStateDto } from "../../elements/models/component-state";
import { EntityId } from "../../meta/models/entity";
import { ConnectorGroupDto } from "../../shared/models/connector-group";
import { isEmptyOrNotDefined, Maybe } from "../../ts-utils";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpgradeConnectorGroups implements UpgradeStep {
  name = "UpgradeConnectorGroups";
  fromVersion = new Version(4, 0, 14);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates = oldConfig.componentStates;
    const dataConnectorViews = oldConfig.dataConnectorViews;

    let modified = false;
    if (isDefined(componentStates) && isDefined(dataConnectorViews)) {
      for (const componentState of Object.values(componentStates.entities)) {
        if (isDefined((componentState as any).view)) {
          modified =
            this.linkConnectorViewWithGroups(
              componentStates,
              dataConnectorViews,
              (componentState as any).id
            ) || modified;
        }
      }
    }

    return {
      result: oldConfig,
      modified: modified,
      warning: null
    };
  }

  private linkConnectorViewWithGroups(
    componentStates: EntityState<ComponentStateDto>,
    dataConnectorViews: EntityState<DataConnectorViewDto>,
    componentId: EntityId
  ): boolean {
    const component = componentStates.entities[componentId];
    let modified = false;
    if (isDefined(component) && !isEmptyOrNotDefined(component.dataConnectorIds)) {
      component.view.groups = [new ConnectorGroupDto()];
      component.dataConnectorIds.forEach((connectorId: EntityId) => {
        const connectorViewId = getConnectorViewId(connectorId);
        const connectorView = dataConnectorViews.entities[connectorViewId];

        if (isDefined(connectorView)) {
          const currentGroup: Maybe<ConnectorGroupDto> = component.view.groups.find(
            (group) =>
              group.name === (isDefined(connectorView.groupId) ? connectorView.groupId : "")
          );

          if (isNotDefined(currentGroup)) {
            const newGroup = new ConnectorGroupDto({
              name: connectorView.groupId,
              order: component.view.groups?.length ?? null
            });
            connectorView.groupId = newGroup.id;
            component.view.groups = [...component.view.groups, newGroup];
          } else {
            connectorView.groupId = currentGroup.id;
          }
          modified = true;
        }
        dataConnectorViews.entities[connectorViewId] = connectorView;
      });
    }
    return modified;
  }
}
