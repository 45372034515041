import { cloneDeep as _cloneDeep } from "lodash";
import { REPORT_FILTER_ID, RUNTIME_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import {
  CURRENT,
  DEFAULT_SPAN_IN_HOURS,
  LIVE_MODE_REGEX,
  LIVE_MODE_TIME_UNIT
} from "../../core/helpers/filter/filter-validation.helper";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { TimeRangeConfigurationDto } from "../../core/models/time-range-configuration";
import { TimeUnit } from "../../core/models/time-unit";
import { EntityId } from "../../meta/models/entity";
import { LiveModeFilter } from "../../shared/models/live-mode-filter";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { FilterState } from "../store/filter/filter.state";
import { ReportContents } from "../store/state";

export function createDefaultFilter(id: string): FilterConfigurationDto {
  const defaultFromExpression = `${CURRENT}-${DEFAULT_SPAN_IN_HOURS}${LIVE_MODE_TIME_UNIT}`;
  return new FilterConfigurationDto({
    id,
    timeRange: new TimeRangeConfigurationDto({
      fromExpression: defaultFromExpression,
      toExpression: ""
    })
  });
}

export function initializeRuntimeFilter(filters: Maybe<FilterState>): Maybe<FilterState> {
  if (
    isNotDefined(filters) ||
    isNotDefined(filters.entities[RUNTIME_FILTER_ID]) ||
    isNotDefined(filters.entities[REPORT_FILTER_ID])
  ) {
    return filters;
  }

  filters.entities[RUNTIME_FILTER_ID] = {
    ..._cloneDeep(filters.entities[REPORT_FILTER_ID]),
    id: RUNTIME_FILTER_ID
  };
  return filters;
}

export function removeRuntimeFilter(typedReportContents: ReportContents): ReportContents {
  const filtersToSave = Object.values(typedReportContents.filters.entities).filter(
    (filter) => filter?.id !== RUNTIME_FILTER_ID
  );

  const filterState: FilterState = {
    ids: filtersToSave.map((filter) => filter?.id.toString()),
    entities: filtersToSave.reduce((acc, filter) => {
      acc[filter?.id] = filter;
      return acc;
    }, {})
  };
  return {
    ...typedReportContents,
    filters: filterState
  };
}

export function isRuntimeFilterAvailable(filterIds: EntityId[]): boolean {
  return Object.values(filterIds).some((filterId) => filterId === RUNTIME_FILTER_ID);
}

export function isUnitBiggerThanHours(unit: string): boolean {
  return (
    unit === TimeUnit.Days ||
    unit === TimeUnit.Weeks ||
    unit === TimeUnit.Months ||
    unit === TimeUnit.Years
  );
}

export function extractLiveModeParams(fromExpression: Maybe<string>): Maybe<LiveModeFilter> {
  if (isNotDefined(fromExpression)) {
    return null;
  }
  const convertedFromExpression = (fromExpression || "").replace(/\s/g, "");
  const expressionResult = LIVE_MODE_REGEX.exec(convertedFromExpression);
  if (isNotDefined(expressionResult)) {
    return null;
  }
  const offset = Math.abs(Number(expressionResult[2]));
  const unit = expressionResult[6] as TimeUnit;
  return new LiveModeFilter({ amount: offset, unit });
}
