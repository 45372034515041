import "reflect-metadata";
import { ComponentStateDto } from "../../elements/models/component-state";
import { DeepPartial, isDefined, Maybe } from "../../ts-utils";
import { TypeDescriptor } from "../models";
import { TypeProvider } from "../services/type-provider";
import { EditableTypeParams, IS_ATTACHABLE } from "./editable-type.decorator";

const TYPE_PROVIDER = TypeProvider.getInstance();

export class EditableWidgetParams extends EditableTypeParams {
  initialConfig?: DeepPartial<ComponentStateDto>;
}

export function EditableWidget(params: EditableWidgetParams) {
  return function (typeConstructor: Function) {
    const existingType: Maybe<TypeDescriptor> = TYPE_PROVIDER.tryGetType(params.fullName);
    if (isDefined(existingType)) {
      throw new Error(`Type ${params.fullName} already exists.`);
    }

    const typeDescriptor: TypeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: typeConstructor as new () => any,
      name: params.fullName,
      isVirtual: params.virtual,
      initialConfig: params.initialConfig
    });
    if (!params.virtual) {
      Reflect.defineMetadata(IS_ATTACHABLE, true, typeDescriptor);
    }
    typeDescriptor.titleProperty = params.title ? params.title : params.fullName;
  };
}
