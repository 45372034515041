import { construct, LinkDto } from "../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { AllowInterpolation, PropertyCategory } from "../../../meta";
import {
  ConfigurableArray,
  ConfigurationCategory,
  NotSerializable
} from "../../../meta/decorators";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { HideChildProperties } from "../../../meta/decorators/hide-child-properties.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { EditorSize } from "../../../meta/models/editor-size";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial, isDefined } from "../../../ts-utils";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";
import { FULL_HEIGHT_CSS_SIZE, FULL_WIDTH_CSS_SIZE } from "../../models/component-size.constants";
import { DisplayFormatDto } from "../../models/display-format";
import { TabConfig } from "../../models/tab-config";
import { TAB_GROUP_VIEW_CONFIG } from "../../models/view-config-type.constants";

// @dynamic
@EditableType({ fullName: TAB_GROUP_VIEW_CONFIG })
export class TabGroupCardViewConfig extends BaseViewConfigDto {
  typeName = TAB_GROUP_VIEW_CONFIG;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Tabs)
  @ConfigurableArray({
    editorType: EditorType.Array,
    typeConstructor: TabConfig,
    arrayItemEditorType: EditorType.NestedObjectEditor,
    arrayEditorSize: EditorSize.Medium,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.TabsTooltip
  })
  @AllowInterpolation()
  @Serializable([new TabConfig()], TabConfig)
  tabs!: TabConfig[];

  @NotSerializable()
  title: string;

  @NotSerializable()
  @HideChildProperties()
  displayFormat: DisplayFormatDto;

  @HideChildProperties()
  @NotSerializable()
  link: LinkDto;

  @NotSerializable()
  exporting!: boolean;

  constructor(viewConfigDto: DeepPartial<TabGroupCardViewConfig> = {}) {
    super();
    const params: DeepPartial<TabGroupCardViewConfig> = {
      ...viewConfigDto,
      tabs: isDefined(viewConfigDto.tabs) ? viewConfigDto.tabs.map((tab) => new TabConfig(tab)) : []
    };
    const defaultOverrides: Partial<TabGroupCardViewConfig> = {
      size: getDefaultSize(),
      useDragOverlay: false,
      selectable: false,
      hasRuntimeData: false,
      title: ""
    };
    construct(this, params, TAB_GROUP_VIEW_CONFIG, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize(FULL_WIDTH_CSS_SIZE, FULL_HEIGHT_CSS_SIZE);
}
