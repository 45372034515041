import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  isDevMode,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Event, NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { DeviceDetectorService } from "ngx-device-detector";
import { fromEvent, Observable, of, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import {
  DecoratorDelegateContext,
  DecoratorDelegateServices
} from "./core/models/decorator-delegate-context";
import { DefaultPropertyConfiguration } from "./core/models/default-property-configuration";
import { DisplayMode, getDisplayMode } from "./core/models/display-mode";
import { IName } from "./core/models/i-name";
import { ReportId } from "./core/models/report-id";
import { HelpService } from "./core/services/help.service";
import { IUserPreferencesService } from "./core/services/i-user-preferences.service";
import { QueryStringService } from "./core/services/query-string.service";
import { TimeService } from "./core/services/time.service";
import { ComponentMetadataService } from "./data-connectivity/services/component-metadata.service";
import { DataService } from "./data-connectivity/services/data.service";
import { IGenericDataSourceService } from "./data-connectivity/services/i-generic-data-source.service";
import { DataSourceDescriptorActions } from "./data-connectivity/store/data-source-descriptor";
import { PeriodTypeActions } from "./data-connectivity/store/period-type/period-type.actions";
import { Dispatcher } from "./dispatcher";
import { hideElement } from "./elements/helpers/dom-element-visibility.helper";
import { ComponentStateSelector } from "./elements/services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "./elements/services/entity-selectors/data-connector.selector";
import { FilterSelector } from "./elements/services/entity-selectors/filter.selector";
import { GeneralSettingsSelector } from "./elements/services/entity-selectors/general-settings.selector";
import { ReportInfoSelector } from "./elements/services/entity-selectors/report-info.selector";
import { ProjectDefaults } from "./elements/services/project-defaults";
import { UnsavedChangesService } from "./elements/services/unsaved-changes.service";
import { CommonActions } from "./elements/store/common/common.actions";
import {
  ChosenActionCallback,
  SaveChangesDialogActions
} from "./elements/store/dialogs/actions/save-changes-dialog.actions";
import { EnvironmentSelector } from "./environment/services/environment.selector";
import { AppSettingsActions } from "./environment/store/app-settings/app-settings.actions";
import { AppStatusActions } from "./environment/store/app-status/app-status.actions";
import { ProjectEnvironmentActions } from "./environment/store/project-environment/project-environment.actions";
import { TimeInfoActions } from "./environment/store/time-info/time-info.actions";
import { LocalizationService } from "./i18n/localization.service";
import { PlaceholderFactory } from "./meta/decorators/placeholder.decorator";
import { startMonitoring } from "./meta/helpers/performance-measurement.helper";
import { DraftFunction, PropertyDescriptor, TypeDescriptor, UserHelpFactory } from "./meta/index";
import { TypeProvider } from "./meta/services/type-provider";
import { Theme } from "./theme";
import { Dictionary, isDefined, Maybe } from "./ts-utils";
import { getFunctionToRunInNgZone } from "./ts-utils/helpers/get-function-to-run-in-ngzone.helper";

@Component({
  selector: "webgui-core-outlet",
  templateUrl: "./ui-core.component.html",
  styleUrls: ["ui-core.component.scss", "../theme/material-custom.scss"],
  encapsulation: ViewEncapsulation.None
})
export class UiCoreComponent implements OnInit, OnDestroy {
  browserName: string;
  standalone: boolean;
  public displayMode: string;
  DisplayMode = DisplayMode;
  activeReport: { reportId: ReportId };
  private unsubscribeSubject$: Subject<void> = new Subject<void>();
  isHistoryViewOpened$: Observable<boolean> = of(false);
  private currentTheme: Theme = Theme.Dark;
  @Output() toggleReportBrowser: EventEmitter<any> = new EventEmitter<any>();
  @Output() activeReportChanged: EventEmitter<any> = new EventEmitter();

  @Input("title")
  set title(projectName: Partial<IName>) {
    const fullProjectName: IName = {
      fullName: projectName.fullName ?? "",
      shortName: projectName.shortName ?? ""
    };
    this.updateProjectNameInStore(fullProjectName);
  }

  @Input("specificEnvironmentName")
  set environmentName(newEnvironmentName: string) {
    this.updateEnvironmentNameInStore(newEnvironmentName);
  }

  @Input() hasAccessToEdit: boolean = true;
  @Input() hideHeader: boolean = false;

  constructor(
    private store$: Store<any>,
    public dialog: MatDialog,
    private router: Router,
    private ngZone: NgZone,
    private dispatcher: Dispatcher,
    public helpService: HelpService,
    private dataService: DataService,
    private typeProvider: TypeProvider,
    private localizer: LocalizationService,
    public projectDefaults: ProjectDefaults,
    private deviceDetector: DeviceDetectorService,
    private reportInfoSelector: ReportInfoSelector,
    private queryStringService: QueryStringService,
    private environmentSelector: EnvironmentSelector,
    private unsavedChangesService: UnsavedChangesService,
    private userPreferencesService: IUserPreferencesService,
    private generalSettingsSelector: GeneralSettingsSelector,
    private dataSourceColumnsExtractor: IGenericDataSourceService,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private metadataService: ComponentMetadataService,
    private filterSelector: FilterSelector,
    private timeService: TimeService
  ) {
    this.browserName = this.deviceDetector.browser;
  }

  ngOnInit(): void {
    this.initPerformanceMeasuring();
    this.subscribeToStandalone();
    this.takeProjectDefaults();
    this.subscribeToRouteChange();
    this.subscribeToActiveReport();
    this.updateStandaloneModeInStore();
    this.initDecoratorDelegates();
    this.initDataSourceDescriptors();
    this.initDateFormatInStore();
    this.initCustomDropdownClosing();
    this.initProjectSpecificHandler();
    this.getPeriodTypes();
    this.getProductNameFromQueryString();
    this.subscribeToDisplayMode();
    this.subscribeToWindowResize();
    this.dispatcher.dispatch(CommonActions.loadLanguages());
    this.initHistoryViewModeObservable();
    this.subscribeToTheme();
    this.initAliasMode();
    this.getShiftStartTime();
  }

  private initPerformanceMeasuring(): void {
    if (isDevMode()) {
      startMonitoring();
    }
  }

  private subscribeToStandalone(): void {
    this.environmentSelector
      .selectStandalone()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((standalone: boolean) => (this.standalone = standalone));
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
      });
  }

  private subscribeToWindowResize(): void {
    fromEvent(window, "resize")
      .pipe(debounceTime(250), takeUntil(this.unsubscribeSubject$))
      .subscribe(() => {
        const displayMode: DisplayMode = getDisplayMode();
        if (this.displayMode !== displayMode) {
          this.dispatcher.dispatch(
            AppStatusActions.changeDisplayMode({ displayMode: displayMode })
          );
        }
      });
  }

  private initHistoryViewModeObservable(): void {
    this.isHistoryViewOpened$ = this.environmentSelector
      .selectHistoryViewVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$));
  }

  private subscribeToTheme(): void {
    this.environmentSelector
      .selectTheme()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((newTheme: Theme) => {
        document.body.classList.remove(this.currentTheme);
        document.body.classList.add(newTheme);
        this.currentTheme = newTheme;
      });
  }

  private initAliasMode(): void {
    const aliasMode: boolean = this.userPreferencesService.getAliasMode();
    this.dispatcher.dispatch(AppSettingsActions.changeAliasMode({ aliasMode }));
  }

  private takeProjectDefaults(): void {
    const defaults = this.projectDefaults.typeDescriptorDefaults;
    Object.keys(defaults).forEach((typeName: string) => {
      const typeDescriptor = this.typeProvider.getType(typeName);
      if (isDefined(typeDescriptor)) {
        const projectDefaultsForType = defaults[typeName];
        updateDefaultsForType(typeDescriptor, projectDefaultsForType, this.typeProvider);
      }
    });
  }

  private initProjectSpecificHandler(): void {
    (window as any).onReportClose = getFunctionToRunInNgZone(this.ngZone, (callback) =>
      this.handleOnReportClose(callback)
    );
  }

  private initDateFormatInStore(): void {
    const dateFormat: string = this.userPreferencesService.getDateTimeFormat();
    this.dispatcher.dispatch(AppSettingsActions.updateDateFormat({ dateFormat }));
  }

  private getPeriodTypes(): void {
    this.dispatcher.dispatch(PeriodTypeActions.load());
  }

  private getShiftStartTime(): void {
    this.dispatcher.dispatch(TimeInfoActions.getShiftStartTime());
  }

  public toggleReportBrowserSidebar(): void {
    this.toggleReportBrowser.emit();
  }

  private getProductNameFromQueryString(): void {
    // TODO add support for short product name
    const productNameAndShortName: IName = this.queryStringService.getProductName();
    this.updateProductNameInStore(productNameAndShortName);
  }

  public handleOnReportClose(callback: ChosenActionCallback): void {
    const saveAndClose = true;
    if (!this.shouldPromptForUnsavedChanges()) {
      callback(saveAndClose);
      return;
    }
    this.dispatcher.dispatch(
      SaveChangesDialogActions.projectSpecificSaveChangesDialog({
        displayText: this.localizer.get(this.localizer.dialogs.UnsavedChangesMessage),
        callback,
        reportId: this.activeReport.reportId
      })
    );
  }

  private initDecoratorDelegates(): void {
    UiCoreComponent.initDecoratorDelegates(this.typeProvider, this.store$, {
      genericDataSourceService: this.dataSourceColumnsExtractor,
      generalSettingsSelector: this.generalSettingsSelector,
      typeProvider: this.typeProvider,
      dataService: this.dataService,
      localizationService: this.localizer,
      componentStateSelector: this.componentStateSelector,
      environmentSelector: this.environmentSelector,
      dataConnectorSelector: this.dataConnectorSelector,
      metadataService: this.metadataService,
      filterSelector: this.filterSelector,
      timeService: this.timeService
    });
  }

  private static alreadyDecorated = false;

  public static initDecoratorDelegates(
    typeProvider: TypeProvider,
    store$: Store<any>,
    services: DecoratorDelegateServices
  ): void {
    if (UiCoreComponent.alreadyDecorated) {
      console.error("initDecoratorDelegates called twice");
    }
    UiCoreComponent.alreadyDecorated = true;
    typeProvider.getTypes().forEach((type: TypeDescriptor) => {
      type.properties.forEach((property: PropertyDescriptor) => {
        if ((<DraftFunction<any>>property.constructorFunction).isDraft) {
          const delegateWithContext = this.attachDecoratorDelegateContext(
            property.constructorFunction,
            store$,
            services
          );
          property.withConstructor(delegateWithContext);
          typeProvider.addType({
            constructorFunction: delegateWithContext as unknown as new () => any,
            name: `Enum_${property.displayName}`,
            isPrimitive: true
          });
        }
        if (isDefined(property.visibilitySelector)) {
          const delegateWithContext = this.attachDecoratorDelegateContext(
            property.visibilitySelector,
            store$,
            services
          );
          property.visibilitySelector = delegateWithContext;
        }
        if (typeof property.userHelp === "function") {
          const delegateWithContext = this.attachDecoratorDelegateContext(
            property.userHelp,
            store$,
            services
          );
          property.userHelp = delegateWithContext as UserHelpFactory;
        }

        // IP looks like a copy/paste code
        if (property.customPropertyExpander != null) {
          const delegateWithContext = this.attachDecoratorDelegateContext(
            property.customPropertyExpander,
            store$,
            services
          );

          property.setCustomPropertyExpander(delegateWithContext);
        }

        if (
          isDefined(property.placeholderConfig) &&
          isDefined(property.placeholderConfig.placeholderFunction)
        ) {
          const delegateWithContext = this.attachDecoratorDelegateContext(
            property.placeholderConfig.placeholderFunction,
            store$,
            services
          );
          property.placeholderConfig.placeholderFunction =
            delegateWithContext as PlaceholderFactory;
        }
      });
    });
  }

  private initDataSourceDescriptors(): void {
    this.dispatcher.dispatch(DataSourceDescriptorActions.loadDataSourceDescriptors());
  }

  private initCustomDropdownClosing(): void {
    document.body.addEventListener("mousedown", () => closeCustomDropdowns());
  }

  updateEnvironmentNameInStore(newName: string): void {
    this.dispatcher.dispatch(
      ProjectEnvironmentActions.updateEnvironmentName({ environmentName: newName })
    );
  }

  updateProjectNameInStore(projectName: IName): void {
    this.dispatcher.dispatch(ProjectEnvironmentActions.setProjectName(projectName));
  }

  updateProductNameInStore(productName: IName): void {
    this.dispatcher.dispatch(ProjectEnvironmentActions.setProductName(productName));
  }

  subscribeToRouteChange(): void {
    this.router.events.pipe(takeUntil(this.unsubscribeSubject$)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setupLanguage();
      }
    });
  }

  private subscribeToActiveReport(): void {
    this.reportInfoSelector
      .selectReportId()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((reportId: Maybe<ReportId>) => {
        if (isDefined(reportId)) {
          this.activeReport = { reportId };
          this.activeReportChanged.emit(this.activeReport);
        }
      });
  }

  setupLanguage(): void {
    const language: string = this.userPreferencesService.getLanguage();
    this.dispatcher.dispatch(AppSettingsActions.updateCurrentLanguage({ language }));
  }

  updateStandaloneModeInStore(): void {
    this.dispatcher.dispatch(
      ProjectEnvironmentActions.updateStandalone({ standalone: this.isStandaloneMode() })
    );
  }

  isStandaloneMode(): boolean {
    // IP move to core
    return parent.location.href.toLocaleLowerCase() === window.location.href.toLocaleLowerCase();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  logOut(): void {
    this.userPreferencesService.logOut();
  }

  shouldPromptForUnsavedChanges(): boolean {
    return this.unsavedChangesService.shouldShowPrompt();
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event): Maybe<string> {
    const preventClosing = "exit before save";

    if (this.shouldPromptForUnsavedChanges()) {
      event.returnValue = preventClosing;
      return preventClosing;
    }

    delete event["returnValue"];
  }

  private static attachDecoratorDelegateContext(
    oldDelegate: Function,
    store$: Store<any>,
    services: DecoratorDelegateServices
  ): Function {
    return (ownerInstance: any) => {
      const context: DecoratorDelegateContext = {
        store: store$,
        ownerInstance,
        services: services
      };
      return oldDelegate(context);
    };
  }
}

function closeCustomDropdowns(): void {
  const dropdowns = Array.from(
    document.body.getElementsByClassName("custom-button__dropdown")
  ) as HTMLElement[];
  dropdowns.forEach(hideElement);
}

function updateDefaultsForType(
  typeDescriptor: TypeDescriptor,
  defaults: Dictionary<DefaultPropertyConfiguration>,
  typeProvider: TypeProvider
): void {
  Object.keys(defaults).forEach((propName: string) => {
    const propDesc = typeDescriptor.getPropertyByName(propName);
    if (isDefined(propDesc)) {
      const displayValues = defaults[propName].displayValues ?? propDesc.displayValues;
      typeDescriptor.updateProperty(propName, {
        defaultValue: defaults[propName].defaultValue ?? propDesc.defaultValue,
        isHidden: defaults[propName].isHidden ?? propDesc.isHidden,
        dynamicDefaults: defaults[propName].dynamicDefaults ?? propDesc.dynamicDefaults,
        displayValues
      });
      const subPropertyTypeDescriptor = typeProvider.tryGetTypeByConstructor(
        propDesc.constructorFunction
      );
      updatePropertiesWithDisplayValues(subPropertyTypeDescriptor, displayValues);
    }
  });
}

function updatePropertiesWithDisplayValues(
  typeDescriptor: Maybe<TypeDescriptor>,
  displayValues: Maybe<any[]>
): void {
  if (shouldUpdateProperties(typeDescriptor, displayValues)) {
    typeDescriptor.properties.forEach((property) =>
      typeDescriptor.updateProperty(property.name, {
        displayValues
      })
    );
  }
}

function shouldUpdateProperties(
  typeDescriptor: Maybe<TypeDescriptor>,
  displayValues: Maybe<any[]>
): boolean {
  return isDefined(typeDescriptor) && !typeDescriptor.isPrimitive && isDefined(displayValues);
}
