import { map, Observable } from "rxjs";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { PropertySheetLocalizationDictionary } from "../../i18n/models/property-sheet-localization-dictionary";
import { SelectionOption } from "../../meta/models/selection";
import { exhaustiveTypeCheck } from "../../ts-utils/helpers/exhaustive-type-check.helper";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { StrictPartial } from "../../ts-utils/models/strict-partial.type";
import {
  SingleValueDisplayStrategy,
  TextualSingleValueDisplayStrategies
} from "../models/display-strategies/single-value-display-strategies";
import {
  LIMITS_MODE,
  LimitsDisplayMode,
  LimitsDisplayOptions,
  LimitsModeDto,
  NO_LIMITS_MODE,
  NoLimitsModeDto,
  ONLY_LIMIT_COLORS_MODE,
  OnlyLimitColorsModeDto,
  PRIMARY_LIMITS_MODE,
  PrimaryLimitsModeDto
} from "../models/limit-modes";

export function isPrimaryLimitsMode(limitsMode: LimitsModeDto): boolean {
  return limitsMode.typeName === PRIMARY_LIMITS_MODE;
}

export function isOnlyLimitColorsMode(limitsMode: LimitsModeDto): boolean {
  return limitsMode.typeName === ONLY_LIMIT_COLORS_MODE;
}

export function isNoLimitsMode(limitsMode: LimitsModeDto): boolean {
  return limitsMode.typeName === NO_LIMITS_MODE;
}

export function getLimitsDisplayModes(
  context: DecoratorDelegateContext
): Observable<SelectionOption[]> {
  return context.services.componentStateSelector
    .selectDisplayStrategy(context.ownerInstance.id)
    .pipe(
      map((displayStrategy: string) =>
        getSupportedLimitsDisplayModes(displayStrategy as SingleValueDisplayStrategy).map(
          (mode) => ({
            key: mode,
            title:
              LOCALIZATION_DICTIONARY.propertySheet[
                mode as keyof PropertySheetLocalizationDictionary
              ]
          })
        )
      )
    );
}

export function isLimitsDisplayModeCompatibleWithStrategy(
  displayStrategy: SingleValueDisplayStrategy,
  limitsDisplayMode: LimitsDisplayMode
): boolean {
  const limitsDisplayModes: LimitsDisplayMode[] = getSupportedLimitsDisplayModes(displayStrategy);
  return limitsDisplayModes.includes(limitsDisplayMode);
}

function getSupportedLimitsDisplayModes(
  displayStrategy: SingleValueDisplayStrategy
): LimitsDisplayMode[] {
  const availableModes: LimitsDisplayMode[] = [
    PRIMARY_LIMITS_MODE,
    ONLY_LIMIT_COLORS_MODE,
    NO_LIMITS_MODE
  ];
  if (TextualSingleValueDisplayStrategies.includes(displayStrategy)) {
    availableModes.splice(0, 1);
  }
  if (shouldHideOnlyLimitColorsMode(displayStrategy)) {
    availableModes.splice(1, 1);
  }
  return availableModes;
}

function shouldHideOnlyLimitColorsMode(displayStrategy: SingleValueDisplayStrategy): boolean {
  return (
    displayStrategy === SingleValueDisplayStrategy.DialGauge ||
    displayStrategy === SingleValueDisplayStrategy.MultiDialGauge ||
    displayStrategy === SingleValueDisplayStrategy.BulletChart
  );
}

export function getLimitsDisplayOptions(limitsMode: LimitsModeDto): LimitsDisplayOptions {
  return switchLimitsMode<LimitsDisplayOptions>(limitsMode, {
    PrimaryLimitsModeDto: (mode) => ({
      showLimitValues: mode.showLimitValues,
      showLimitsAsBars: mode.showLimitsAsBars
    }),
    OnlyLimitColorsModeDto: (mode) => ({
      showLimitValues: mode.showLimitValues,
      showLimitsAsBars: mode.showLimitsAsBars
    }),
    NoLimitsModeDto: () => ({})
  });
}

export function createLimitsMode(
  limitsMode: StrictPartial<LimitsModeDto, "typeName">
): LimitsModeDto {
  return switchLimitsMode<LimitsModeDto>(limitsMode, {
    PrimaryLimitsModeDto: (mode) => new PrimaryLimitsModeDto(mode),
    OnlyLimitColorsModeDto: (mode) => new OnlyLimitColorsModeDto(mode),
    NoLimitsModeDto: () => new NoLimitsModeDto()
  });
}

function switchLimitsMode<Out>(
  limitsMode: LimitsModeDto,
  callbacks: {
    PrimaryLimitsModeDto: (mode: PrimaryLimitsModeDto) => Out;
    OnlyLimitColorsModeDto: (mode: OnlyLimitColorsModeDto) => Out;
    NoLimitsModeDto: (mode: NoLimitsModeDto) => Out;
  }
): Out {
  const { typeName } = limitsMode;
  switch (typeName) {
    case PRIMARY_LIMITS_MODE: {
      return callbacks[typeName](limitsMode as PrimaryLimitsModeDto);
    }
    case ONLY_LIMIT_COLORS_MODE: {
      return callbacks[typeName](limitsMode as OnlyLimitColorsModeDto);
    }
    case NO_LIMITS_MODE: {
      return callbacks[typeName](limitsMode as NoLimitsModeDto);
    }
    case LIMITS_MODE: {
      throw new CriticalError(`Cannot use abstract class ${typeName}.`);
    }
    default: {
      exhaustiveTypeCheck("Unknown limit mode: ", typeName);
    }
  }
}

export function getDefaultLimitsDisplayMode(
  displayStrategy: Maybe<SingleValueDisplayStrategy>
): LimitsDisplayMode {
  return isNotDefined(displayStrategy) ||
    TextualSingleValueDisplayStrategies.includes(displayStrategy)
    ? ONLY_LIMIT_COLORS_MODE
    : PRIMARY_LIMITS_MODE;
}
