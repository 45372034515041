import { SidemenuComponents } from "../../../core/models/sidemenu-components.enum";
import { HeaderButtonConfig } from "./header-button.config";

export class BuilderHeaderButtonConfig extends HeaderButtonConfig {
  public icon: string = "";
  public sidebarDisplay: SidemenuComponents;

  constructor(params: Partial<BuilderHeaderButtonConfig> = {}) {
    super(params);
    this.icon = params.icon ?? "";
    this.sidebarDisplay = params.sidebarDisplay ?? null;
  }
}
