import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { first, map, take } from "rxjs/operators";
import { CustomFilterDescriptorDto } from "../../../core/models/filter/custom-filter-descriptor";
import { GeneralSettingsDto } from "../../../core/models/general-settings";
import { IGeneralSettingsSelector } from "../../../core/services/filter/i-general-settings.selector";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import {
  selectCustomFiltersDeclaration,
  selectGeneralSettings,
  selectPeriodType,
  selectRootClass,
  selectRootPath,
  selectUseServerTime,
  selectWorkDayStartTime
} from "../../store/general-settings/general-settings.selectors";

@Injectable()
export class GeneralSettingsSelector extends IGeneralSettingsSelector {
  constructor(private store$: Store<any>) {
    super();
  }

  select(): Observable<GeneralSettingsDto> {
    return this.store$.select(selectGeneralSettings);
  }

  selectCustomFilterDescriptors(): Observable<CustomFilterDescriptorDto[]> {
    return this.store$
      .select(selectGeneralSettings)
      .pipe(map((generalSettings) => generalSettings.customFilterDeclarations ?? []));
  }

  selectRootPath(): Observable<Maybe<string>> {
    return this.store$.select(selectRootPath);
  }

  selectRootClass(): Observable<string> {
    return this.store$.select(selectRootClass);
  }

  selectPeriodType(): Observable<Maybe<string>> {
    return this.store$.select(selectPeriodType);
  }

  selectUseServerTime(): Observable<boolean> {
    return this.store$.select(selectUseServerTime);
  }

  selectWorkDayStartTime(): Observable<Maybe<string>> {
    return this.store$.select(selectWorkDayStartTime);
  }

  getGeneralSettings(): GeneralSettingsDto {
    let generalSettings: GeneralSettingsDto;
    this.select()
      .pipe(take(1))
      .subscribe((genSettings) => (generalSettings = genSettings));
    return generalSettings;
  }

  getPeriodType(): string {
    let periodType: Maybe<string>;
    this.selectPeriodType()
      .pipe(take(1))
      .subscribe((pType) => (periodType = pType));
    return periodType ?? "";
  }

  getRootPath(): string {
    let rootPath: Maybe<string>;
    this.selectRootPath()
      .pipe(take(1))
      .subscribe((path) => (rootPath = path));
    return rootPath ?? "";
  }

  getUseServerTime(): boolean {
    let useServerTime;
    this.store$
      .select(selectUseServerTime)
      .pipe(first())
      .subscribe((useServerTiemState) => (useServerTime = useServerTiemState));
    return useServerTime;
  }

  getCustomFilterDescriptors(): CustomFilterDescriptorDto[] {
    let customFilterDescriptors: CustomFilterDescriptorDto[] = [];
    this.store$
      .select(selectCustomFiltersDeclaration)
      .pipe(first())
      .subscribe((customFilters) => (customFilterDescriptors = customFilters));
    return customFilterDescriptors;
  }

  getWorkDayStartTime(): Maybe<string> {
    let result: Maybe<string> = null;
    this.store$
      .select(selectWorkDayStartTime)
      .pipe(take(1))
      .subscribe((res) => (result = res));
    return result;
  }
}
