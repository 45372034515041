import { animate, style, transition, trigger } from "@angular/animations";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataExplorerSelector } from "../../../browsing/services/data-explorer.selector";
import { DataExplorerActions } from "../../../browsing/store/data-explorer/data-explorer.actions";
import { DisplayMode } from "../../../core/models/display-mode";
import { SidemenuComponents } from "../../../core/models/sidemenu-components.enum";
import { Dispatcher } from "../../../dispatcher";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { SidebarService } from "../../services/sidebar.service";

@Component({
  selector: "sidebar",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [style({ opacity: 0 }), animate("200ms", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("200ms", style({ opacity: 0 }))])
    ])
  ]
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("sidebar") sidebar: ElementRef;
  public isSidebarOpened: boolean;
  public displayMode: string;
  public DisplayMode = DisplayMode;
  public SidemenuComponents = SidemenuComponents;
  isNavigationActive = false;
  isPropertyBrowserExpanded!: boolean;
  isSignalTabSelected!: boolean;
  private unsubscribeSubject$: Subject<void> = new Subject();
  @Output() toggleReportBrowser: EventEmitter<any> = new EventEmitter<any>();
  @Input() templateBuilderMode: boolean = false;
  componentToShow: Maybe<SidemenuComponents>;

  constructor(
    private dispatcher: Dispatcher,
    public localizer: LocalizationService,
    private environmentSelector: EnvironmentSelector,
    private dataExplorerSelector: DataExplorerSelector,
    private cdr: ChangeDetectorRef,
    private sidebarService: SidebarService
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngAfterViewInit(): void {
    this.subscribeToPropertyBrowserMode();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  initSubscriptions(): void {
    this.subscribeToDisplayedComponent();
    this.subscribeToVisibilityMode();
    this.subscribeToDisplayMode();
    this.subscribeToSignalTabMode();
  }

  private subscribeToDisplayedComponent(): void {
    this.sidebarService.componentToShow.subscribe((value) => {
      this.componentToShow = value;
      this.selectedButtonChanged();
      this.cdr.detectChanges();
    });
  }

  private subscribeToPropertyBrowserMode(): void {
    this.dataExplorerSelector
      .selectPropertyBrowserMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isExpanded) => {
        this.isPropertyBrowserExpanded = isExpanded;
        this.cdr.detectChanges();
      });
  }

  private subscribeToVisibilityMode(): void {
    this.environmentSelector
      .selectSidebarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isSidebarOpened: boolean) => {
        this.isSidebarOpened = isSidebarOpened;
        this.shouldDisplay(this.isSidebarOpened, this.displayMode);
        this.cdr.detectChanges();
      });
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
        this.shouldDisplay(this.isSidebarOpened, this.displayMode);
      });
  }

  private subscribeToSignalTabMode(): void {
    this.dataExplorerSelector
      .selectSignalTabMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isSignalTabSelected: boolean) => {
        this.isSignalTabSelected = isSignalTabSelected;
      });
  }

  public toggleReportBrowserSidebar(): void {
    this.isNavigationActive = !this.isNavigationActive;
    this.toggleReportBrowser.emit();
  }

  selectedButtonChanged(): void {
    if (isDefined(this.componentToShow)) {
      if (this.isPropertyBrowserExpanded) {
        this.collapseEquipmentPropertyBrowser();
      }
    }
  }

  shouldDisplay(showSidebar: boolean, displayMode: string): void {
    if (isNotDefined(this.sidebar)) {
      return;
    }
    displayMode === DisplayMode.Mobile && showSidebar
      ? this.sidebar.nativeElement.classList.toggle("responsive")
      : this.sidebar.nativeElement.classList.remove("responsive");
  }

  collapseEquipmentPropertyBrowser(): void {
    this.dispatcher.dispatch(DataExplorerActions.collapsePropertyBrowser());
  }

  checkIfSidebarShouldCollapse(): void {
    if (this.isSidebarOpened) {
      this.dispatcher.dispatch(AppStatusActions.closeSidebar());
    }
  }

  expandSidebar(): void {
    this.dispatcher.dispatch(AppStatusActions.openSidebar());
  }
}
