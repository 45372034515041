import { cloneDeep as _cloneDeep } from "lodash";
import { CommonDataPropertyNames } from "../../../data-connectivity/models/common-data-properties";
import { DataSourceDto } from "../../../data-connectivity/models/data-source/data-source";
import { SignalDataSourceDto } from "../../../data-connectivity/models/data-source/signal-data-source";
import { Dictionary } from "../../../ts-utils";
import { DataSourceDragInfo } from "./data-source-drag-info";

export class SignalDragInfo implements DataSourceDragInfo {
  constructor(public dataSource: SignalDataSourceDto, public properties: Dictionary<any>) {}

  public getDataSource(): DataSourceDto {
    return _cloneDeep(this.dataSource);
  }

  public getTitle(): string {
    return this.dataSource.signal.name;
  }

  public getDescription(): string {
    return this.properties[CommonDataPropertyNames.description];
  }

  public getAliasName(): string {
    return this.properties[CommonDataPropertyNames.aliasName];
  }
}
