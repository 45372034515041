import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { concatMap, map, mergeMap } from "rxjs/operators";
import { CustomFilterValue } from "../../../core/models/filter/filter-type-descriptor";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { getInitialValueOrDefault } from "../../../shared/models/custom-filter-value-type";
import { Dictionary, Maybe, isDefined, isNotDefined } from "../../../ts-utils";
import { getFullRequestActions } from "../../helpers/full-range-request-actions.helper";
import { getAllRequestParams } from "../../helpers/request-params-creation.helper";
import { DataRequestParams } from "../../models/data-request-params";
import { resolveCustomFilterKey } from "../../services/custom-filter.helper";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../../services/entity-selectors/data-connector.selector";
import { FilterActions } from "../filter/filter.actions";
import { RuntimeSettingsActions } from "../runtime-settings";
import { GeneralSettingsActions } from "./general-settings.actions";

@Injectable()
export class GeneralSettingsEffects {
  constructor(
    private actions$: Actions,
    private environmentSelector: EnvironmentSelector,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private filterFactory: FilterFactory
  ) {}

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralSettingsActions.update),
      concatMap(({ changes }) => {
        const actions: Action[] = [];
        if (isDefined(changes.useServerTime)) {
          const offset =
            (changes.useServerTime ? 1 : 0) * this.environmentSelector.getServerOffset();
          actions.push(
            GeneralSettingsActions.toggleUseServerTime({
              useServerTime: changes.useServerTime,
              filtersUpdateOffset: offset
            })
          );
        }
        if (isDefined(changes.periodType)) {
          actions.push(
            RuntimeSettingsActions.setPeriodType({
              pType: changes.periodType
            })
          );
        }
        if (isDefined(changes.customFilterDeclarations)) {
          const customFilterValues = changes.customFilterDeclarations
            .filter(isDefined)
            .reduce((acc: Dictionary<CustomFilterValue>, customFilter) => {
              const customFilterKey: Maybe<string> = resolveCustomFilterKey(customFilter);
              if (isNotDefined(customFilterKey)) {
                return acc;
              }
              acc[customFilterKey] = getInitialValueOrDefault(customFilter);
              return acc;
            }, {});

          actions.push(
            FilterActions.resolveUpdatedCustomFilters({
              customFilters: customFilterValues
            })
          );
        }

        if (isDefined(changes.workDayStartTime)) {
          actions.push(GeneralSettingsActions.setWorkDayStartTime());
        }
        return actions;
      })
    )
  );

  useServerTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralSettingsActions.toggleUseServerTime),
      map(({ filtersUpdateOffset }) =>
        FilterActions.updateManyWithTimeOffset({ offset: filtersUpdateOffset })
      )
    )
  );

  setGlobalWorkDayStartTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralSettingsActions.setWorkDayStartTime),
      mergeMap(() => {
        const components = this.componentStateSelector.getAllAsArray();
        const connectors = this.dataConnectorSelector.getAllAsArray();
        const requestParams: DataRequestParams[] = getAllRequestParams(
          components,
          connectors,
          this.componentStateSelector,
          this.filterFactory
        );
        return getFullRequestActions(requestParams);
      })
    )
  );
}
