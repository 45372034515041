import { Inject, Injectable } from "@angular/core";
import { ColorList } from "../../core/models/color-list";

@Injectable({ providedIn: "root" })
export class ColorListService {
  constructor(@Inject(ColorList) private colorList: string[]) {}

  public get seriesColors(): string[] {
    return this.colorList;
  }

  public getSeriesColorAtIndex(index: number): string {
    const colorsCount = this.seriesColors.length;
    const color = this.seriesColors[index % colorsCount];
    return color;
  }

  public getFirstColor(): string {
    return this.seriesColors[0];
  }
}
