import { CommonModule, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatTreeModule } from "@angular/material/tree";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from "@azure/msal-angular";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import {
  DYNAMIC_COMPONENTS_ELEMENTS,
  DYNAMIC_COMPONENTS_PROPERTY_SHEET,
  MaterialModule,
  UiCoreModule
} from "ui-core";
import { RdsTranslateLoader } from "../assets/i18n/models/rds-translate-loader";
import { buildSpecificModules, environment } from "../environments/environment";
import { buildRdsKmTrendConfig } from "../environments/km-trend-config.rds";
import { AppComponent } from "./app.component";
import {
  AirgapProfileChartComponent,
  ArrayChartComponent,
  DialogComponent,
  DowntimeAnalysisComponent,
  DowntimeComparisonChartComponent,
  EventFrequencyChartComponent,
  FleetListComponent,
  FleetMapComponent,
  LoadSharingChartComponent,
  PoleAlignmentChartComponent,
  PoleFluxMonitoringChartComponent,
  PoleStatusComponent,
  ReportElementComponent,
  RotorShapeChartComponent,
  SidebarNavigationComponent,
  StaticEccentricityChartComponent,
  StatorWindingTemperatureChartComponent,
  StatusHeatmapComponent,
  WaterConductivityChartComponent
} from "./components";
import { GradientChartComponent } from "./components/gradient-chart/gradient-chart.component";
import { MainComponent } from "./components/main/main.component";
import { PolarChartComponent } from "./components/polar-chart/polar-chart.component";
import { CustomerSearchComponent } from "./components/sidebar-navigation/customer-search/customer-search.component";
import { VibrationChartComponent } from "./components/vibration/vibration-chart.component";
import { SERIES_COLORS } from "./models/colors";
import {
  MSALGuardConfigurationFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigurationFactory
} from "./msal";
import { CapitalizePipe } from "./pipes/capitalize.pipe";
import { GetMapValuesPipe } from "./pipes/get-map-values.pipe";
import { SeparateWordsPipe } from "./pipes/separate-words.pipe";
import { StatusTransformer } from "./pipes/status-tansformer.pipe";
import { TimeStampFormatter } from "./pipes/time-stamp-format.pipe";
import { AzureEquipmentConverter } from "./services/api/azure-equipment.converter";
import { AzureQueryStringService } from "./services/api/azure-query-string.service";
import { GenericQueriesService } from "./services/api/generic-queries.service";
import { PlantTimeConverter } from "./services/api/plant-time.converter";
import { SignalRService } from "./services/api/signalR.service";
import { AccountService } from "./services/auth/account.service";
import { AzureAuthInterceptor } from "./services/auth/azure-auth.interceptor";
import { RoleService } from "./services/auth/role.service";
import { AzureReportsService } from "./services/core/azure-reports.service";
import { HttpErrorHandlerInterceptor } from "./services/core/http-error-handler.interceptor";
import { PTypeCheckService } from "./services/core/ptype-check-service";
import { RdsQueryParamKeyConverter } from "./services/core/query-param-key.converter";
import { RdsComponentCategoryProvider } from "./services/core/rds-component-category-provider";
import { RdsErrorHandler } from "./services/core/rds-error-handler.service";
import { RdsHelpService } from "./services/core/rds-help.service";
import { RdsLocalizationService } from "./services/core/rds-localization.service";
import { RdsHeaderButtonsForSidebarGenerator } from "./services/core/rds-toolbar-items-generator.service";
import { TelemetryService } from "./services/core/telemetry.service";
import { RdsUserPreferencesService } from "./services/core/user-preferences.service";
import { DashboardNavigationService } from "./services/sidebar/dashboard-navigation.service";
import { DashboardValueValidatorService } from "./services/sidebar/dashboard-value-validator.service";
import { FleetMapSidebarBridgeService } from "./services/sidebar/fleet-map-sidebar-bridge.service";
import { FleetViewDataService } from "./services/sidebar/fleet-view.service";
import { ClientDataEffects } from "./store/effects/client-data.effect";
import { HierarchyEffects } from "./store/effects/hierarchy.effect";
import { clientDataReducer } from "./store/reducers/client-data.reducer";
import { hierarchyCollectionReducer } from "./store/reducers/hierarchy-collection.reducer";
import { reducer as reportNameReducer } from "./store/reducers/report-name.reducer";
import { CLIENT_DATA, HIERARCHY_COLLECTION } from "./store/selectors/hierarchy-collection.selector";
import { REPORT_NAME } from "./store/selectors/report-name.selector";

const DYNAMIC_COMPONENTS = [
  DowntimeComparisonChartComponent,
  EventFrequencyChartComponent,
  LoadSharingChartComponent,
  PoleStatusComponent,
  StatusHeatmapComponent,
  StatorWindingTemperatureChartComponent,
  WaterConductivityChartComponent,
  DowntimeAnalysisComponent,
  PoleAlignmentChartComponent,
  PoleFluxMonitoringChartComponent,
  RotorShapeChartComponent,
  AirgapProfileChartComponent,
  StaticEccentricityChartComponent,
  FleetListComponent,
  FleetMapComponent,
  SidebarNavigationComponent,
  ReportElementComponent,
  DialogComponent,
  GradientChartComponent,
  CustomerSearchComponent,
  MainComponent,
  ArrayChartComponent
];

@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    CommonModule,
    MatTreeModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    GoogleMapsModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true }
      }
    ),
    StoreModule.forFeature(REPORT_NAME, reportNameReducer),
    StoreModule.forFeature(HIERARCHY_COLLECTION, hierarchyCollectionReducer),
    StoreModule.forFeature(CLIENT_DATA, clientDataReducer),
    EffectsModule.forFeature([HierarchyEffects, ClientDataEffects]),
    ...buildSpecificModules,
    MsalModule,
    UiCoreModule.forRoot({
      kmTrendConfig: buildRdsKmTrendConfig(),
      dataService: environment.dataService,
      webServicesConfiguration: environment.webServicesConfiguration,
      statusConverter: environment.statusConverter,
      projectDefaults: environment.defaultConfiguration,
      configurationService: environment.configurationService,
      localizationLoader: RdsTranslateLoader,
      localizationService: RdsLocalizationService,
      userPreferencesService: RdsUserPreferencesService,
      componentCategoryProvider: RdsComponentCategoryProvider,
      reportsService: AzureReportsService,
      helpService: RdsHelpService,
      feedbackService: environment.feedbackService,
      headerButtonsForSidebarGenerator: RdsHeaderButtonsForSidebarGenerator,
      colorList: SERIES_COLORS,
      queryStringService: AzureQueryStringService,
      pTypeCheckService: PTypeCheckService,
      queryParamKeyConverter: RdsQueryParamKeyConverter
    })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: RdsErrorHandler
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: Window,
      useValue: window
    },
    TelemetryService,
    FleetViewDataService,
    FleetMapSidebarBridgeService,
    AzureEquipmentConverter,
    AzureQueryStringService,
    AccountService,
    RoleService,
    GenericQueriesService,
    PlantTimeConverter,
    MsalInterceptor,
    SignalRService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AzureAuthInterceptor,
      multi: true
    },
    DashboardNavigationService,
    DashboardValueValidatorService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigurationFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigurationFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  declarations: [
    AppComponent,
    DYNAMIC_COMPONENTS,
    GetMapValuesPipe,
    CapitalizePipe,
    SeparateWordsPipe,
    StatusTransformer,
    TimeStampFormatter,
    VibrationChartComponent,
    PolarChartComponent
  ],
  exports: [DYNAMIC_COMPONENTS],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  static entryComponents = [
    DYNAMIC_COMPONENTS_ELEMENTS,
    DYNAMIC_COMPONENTS_PROPERTY_SHEET,
    DYNAMIC_COMPONENTS
  ];
}
