import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { Title } from "../../../meta/decorators/title.decorator";
import { EditorType, PropertyCategory } from "../../../meta/models";
import { DataSourceDto } from "./data-source";
import { VALUE_DATA_SOURCE, ValueDataSourceType } from "./data-source.type";

@EditableType({ fullName: VALUE_DATA_SOURCE, title: "value-data-source-dto" })
export class ValueDataSourceDto extends DataSourceDto {
  typeName: ValueDataSourceType = VALUE_DATA_SOURCE;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Value,
    editorType: EditorType.TextBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.ValueDataSourceTooltip
  })
  @Title()
  @Serializable()
  value: any;

  constructor(value: any) {
    super();
    construct(this, { value }, VALUE_DATA_SOURCE);
  }
}
