import moment from "moment";
import { Time } from "../models/time";

export function getTime(date: Date): string {
  return (
    getValueWithTwoDigits(date.getHours()) +
    ":" +
    getValueWithTwoDigits(date.getMinutes()) +
    ":" +
    getValueWithTwoDigits(date.getSeconds())
  );
}

export function getValueWithTwoDigits(value: number): string {
  return (value < 10 ? "0" : "") + value.toString();
}

export function updateDate(newTime: moment.Moment, newDate: Date): Date {
  newDate.setHours(newTime.hour());
  newDate.setMinutes(newTime.minute());
  newDate.setSeconds(newTime.second());
  return newDate;
}

export function updateTime(oldDateTime: Date, newTime: string): Date {
  const newFromTime: Time = parseStringToTime(newTime);
  if (!isNaN(oldDateTime.getTime())) {
    return getDateTime(oldDateTime, newFromTime);
  }
  return getDateTime(new Date(), newFromTime);
}

export function getDateTime(date: Date, time: Time): Date {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.hours,
    time.minutes,
    time.seconds
  );
}

export function parseStringToTime(time: string): Time {
  const splittedTime = time.split(":");
  const splitSeconds = splittedTime[2];
  const hours: number = parseInt(splittedTime[0]);
  const minutes: number = parseInt(splittedTime[1]);
  const seconds: number = splitSeconds ? parseInt(splitSeconds) : 0;
  return new Time(hours, minutes, seconds);
}
