import { fontSize16 } from "../../../../_typography";
import { construct } from "../../../core";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Configurable,
  EditableType,
  NotSerializable,
  Serializable
} from "../../../meta/decorators";
import { HideChildProperties } from "../../../meta/decorators/hide-child-properties.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { DeepPartial } from "../../../ts-utils";
import { BaseViewConfigDto } from "../../models/base-view-config";
import { ComponentCssSize } from "../../models/component-size";
import { DisplayFormatDto } from "../../models/display-format";

const TYPE_NAME = "ButtonViewConfig";
const BUTTON_FONT_SIZE = fontSize16;

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class ButtonViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Bold,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  bold!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FontSize,
    editorType: EditorType.Number
  })
  @Serializable(BUTTON_FONT_SIZE)
  fontSize!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FontColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("#000")
  fontColor!: string;

  @NotSerializable()
  @HideChildProperties()
  displayFormat!: DisplayFormatDto;

  constructor(viewConfigDto: DeepPartial<ButtonViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<ButtonViewConfig> = {
      title: "BUTTON",
      size: getDefaultSize(),
      hasRuntimeData: false
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("200", "50");
}
