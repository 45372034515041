import { EditableType } from "../../../meta/decorators";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { SimpleViewConfig } from "../../../shared/models/simple-view-config";
import { Maybe } from "../../../ts-utils/models/maybe.type";

export enum ColumnType {
  Text = "Text",
  Number = "Number",
  Date = "Date"
}

@EditableType({ fullName: "TableColumnConfig" })
export class TableColumnConfig {
  @Serializable()
  cellConfig: SimpleViewConfig;

  id: string;

  @Serializable(null)
  columnType?: Maybe<ColumnType>;
}
