import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { OfType } from "../../../meta/decorators/of-type.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "slider-editor",
  templateUrl: "slider-editor.component.html",
  styleUrls: ["slider-editor.component.scss"]
})
@OfType(EditorType.SliderEditor)
export class SliderEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  inputChanged$: Subject<number> = new Subject<number>();

  ngOnInit(): void {
    super.ngOnInit();
    this.inputChanged$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((newValue) => {
      this.onValueChanged(newValue);
    });
  }

  ngOnDestroy(): void {
    this.inputChanged$.complete();
  }

  onInputChange(newValue: string): void {
    this.inputChanged$.next(Number(newValue));
  }
}
