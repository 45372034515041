import { Maybe } from "../../../ts-utils/models/maybe.type";
import { DataLabelSizeConfig } from "../../models/data-label-size-config";
import { getScaledFontSize } from "../scalable-font-size.helper";

export const GAUGE_SCALING_FACTOR = 0.07;
export const MIN_DIAL_GAUGE_DIMENSIONS = 250;
export const SOLID_GAUGE_MIN_HEIGHT = 125;
export const SOLID_GAUGE_MIN_WIDTH = 175;

export function getGaugeDataLabelSize({
  componentWidthPx,
  componentHeightPx,
  minComponentWidthPx,
  minComponentHeightPx,
  scalingFactor,
  minFontSizePx
}: DataLabelSizeConfig): Maybe<number> {
  const shouldHideLabel =
    componentWidthPx <= minComponentWidthPx || componentHeightPx <= minComponentHeightPx;
  return shouldHideLabel
    ? null
    : getScaledFontSize(componentWidthPx, componentHeightPx, scalingFactor, minFontSizePx);
}
