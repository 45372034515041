import { EntityId } from "../../../meta/models/entity";
import { TimePresetType } from "../../../shared/models/time-preset-filter";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { TimeRange } from "../time-range";
import { CustomFilterValue } from "./filter-type-descriptor";

export class Filter {
  id: EntityId;
  timeRange: TimeRange;
  customFilters: Dictionary<CustomFilterValue>;
  timePreset: Maybe<TimePresetType>;

  constructor(
    id: EntityId,
    timeRange: TimeRange,
    timePreset: Maybe<TimePresetType> = null,
    customFilters: Dictionary<CustomFilterValue> = {}
  ) {
    this.id = id;
    this.timeRange = timeRange;
    this.customFilters = customFilters;
    this.timePreset = timePreset;
  }
}
