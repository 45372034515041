import { EditorType, PropertyDescriptor } from "../../meta";

export function shouldRenderEditor(property: PropertyDescriptor): boolean {
  if (property.editorType === EditorType.NestedSheet) {
    return false;
  }
  if (property.isHidden) {
    return false;
  }
  return true;
}

export function shouldDisplayProperty(
  property: PropertyDescriptor,
  parentProperty: PropertyDescriptor
): boolean {
  return property.editorType === EditorType.NestedSheet ||
    property.isHidden ||
    (property.isTitle && parentProperty.editorType !== EditorType.ExtendableCheckboxList)
    ? false
    : true;
}
