import { Point } from "highcharts";
import { ValueFormatterService } from "../../core/services/value-formatter.service";
import {
  ComponentMetadataService,
  DataConnectorDto,
  DataPoint,
  DataPointDto,
  DataPointValue,
  ICommonDataProperties
} from "../../data-connectivity";
import { DateFormatterService } from "../../environment/services/date-formatter.service";
import { getEntityTitle } from "../../meta/helpers/get-title.helper";
import {
  Maybe,
  first,
  isDefined,
  isEmptyOrNotDefined,
  isNotDefined,
  toStringOrUndefined
} from "../../ts-utils";
import { BaseComponent } from "../components/base/base.component";
import { getSingleValue, getSingleValueDataPoint } from "../helpers/component-data-accessor.helper";
import { DisplayFormatDto } from "../models/display-format";
import { DataConnectorDescriptor } from "../models/store/data-connector-descriptor";

const METADATA_PROVIDER = ComponentMetadataService.getInstance();

export interface TooltipData {
  title: string;
  value: DataPointValue;
  description?: string;
  additionalInfo: string;
  unit?: string;
  axisName?: string;
}

export interface SingleValueTooltipProperties {
  displayFormat: DisplayFormatDto;
  unit?: string;
}

// #region called from static numeric value, when we remove it, delete these three functions
export function getSingleValueTooltipTextFromComponent(component: BaseComponent): string {
  const view = component.currentState.view as any;
  return getSingleValueTooltipTextFromComponentAndValue(component, view.displayValue);
}

function getSingleValueTooltipTextFromComponentAndValue(
  component: BaseComponent,
  displayValue: number
): string {
  if (METADATA_PROVIDER.getMaxConnectors(component.currentState.type) !== 1) {
    return "";
  }
  const dataConnector: Maybe<DataConnectorDto> = first(component.dataConnectors);
  if (isNotDefined(dataConnector)) {
    return "";
  }

  const view = component.currentState.view as any;
  const tooltipData: TooltipData = createSingleValueTooltipData(
    component,
    dataConnector,
    displayValue
  );
  return getTooltipText(tooltipData, true);
}

function createSingleValueTooltipData(
  component: BaseComponent,
  dataConnector: DataConnectorDto,
  displayValue: number
): TooltipData {
  const view = component.currentState.view as any;
  const tooltipData: TooltipData = {
    title: getEntityTitle(dataConnector),
    // FIXME 19 define project-specific interface from DataConnector.properties
    description: toStringOrUndefined(dataConnector.properties.Description),
    additionalInfo: component.getFormattedLatestTimestamp(),
    value: `${displayValue || ""}`,
    unit: `${view.unit}`
  };
  return tooltipData;
}
//#endregion

export function getSingleValueTooltip(
  dataConnector: Maybe<DataConnectorDto>,
  dataPoint: Maybe<DataPoint>,
  commonProps: SingleValueTooltipProperties,
  dateFormatter: DateFormatterService,
  valueFormatterService: ValueFormatterService,
  tooltipTextValue: DataPointValue
): string {
  if (isNotDefined(dataConnector) || isNotDefined(dataPoint)) {
    return "";
  }
  const props = dataConnector.properties as ICommonDataProperties;

  const time = dataPoint.x as Date;
  const tooltipData: TooltipData = {
    title: getEntityTitle(dataConnector),
    description: props.description,
    additionalInfo: isEmptyOrNotDefined(commonProps.displayFormat.dateFormat)
      ? dateFormatter.formatDate(time)
      : valueFormatterService.formatValue(time, commonProps.displayFormat),
    value: valueFormatterService.formatValue(tooltipTextValue, commonProps.displayFormat),
    unit: commonProps.unit ?? ""
  };

  return getTooltipText(tooltipData, true);
}

export function getMultiValueTooltip(
  dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
  commonProps: SingleValueTooltipProperties,
  dateFormatter: DateFormatterService,
  valueFormatter: ValueFormatterService
): string {
  if (isEmptyOrNotDefined(dataConnectorDescriptors)) {
    return "";
  }
  return dataConnectorDescriptors
    .map((dataConnectorDescriptor) => {
      const connector: Maybe<DataConnectorDto> = dataConnectorDescriptor?.connector;
      const dataPoint: Maybe<DataPointDto> = getSingleValueDataPoint(connector);
      const singleValue: DataPointValue = getSingleValue(dataPoint);
      return getSingleValueTooltip(
        connector,
        dataPoint,
        commonProps,
        dateFormatter,
        valueFormatter,
        singleValue
      );
    })
    .join("\n");
}

export function getChartTooltip(
  connectorDescriptors: DataConnectorDescriptor[],
  chartPoint: Point,
  valueFormatter: ValueFormatterService,
  hasTimeSeriesData: boolean,
  displayFormat: Maybe<DisplayFormatDto>
): string {
  const seriesName: string = chartPoint.series.name;
  const options = chartPoint.series.options as any;
  const dataConnector: Maybe<DataConnectorDto> = connectorDescriptors.find(
    (descriptor) =>
      isDefined(descriptor.connector) && getEntityTitle(descriptor.connector) === seriesName
  )?.connector;
  let description: string = "";
  if (isDefined(options.custom)) {
    description = options.custom.nature;
  } else if (isDefined(dataConnector)) {
    description = (dataConnector.properties as ICommonDataProperties).description ?? "";
  }
  const tooltipData: TooltipData = {
    title: seriesName,
    description,
    additionalInfo: hasTimeSeriesData
      ? valueFormatter.formatValue(new Date(chartPoint.x), displayFormat)
      : "",
    value: valueFormatter.formatValue(chartPoint.y, displayFormat),
    unit: dataConnector ? toStringOrUndefined(dataConnector.properties.unit) : "",
    axisName: chartPoint.series.yAxis.options.title?.text ?? undefined
  };
  return getTooltipText(tooltipData);
}

// refactor: time and value should be treated the same.
// Either pass formatting service for both, or have both already as string in TooltipData
export function getTooltipText(
  tooltipData: TooltipData,
  useBrowserTooltip: boolean = false
): string {
  const {
    title = "",
    value,
    description = "",
    additionalInfo: timestamp = "",
    unit = "",
    axisName = ""
  } = tooltipData;

  const splitter = useBrowserTooltip ? "\n" : "<br>";
  const tooltipText = `${title}${splitter}${value} ${unit}${splitter}${
    !!description ? `(${description})` : ""
  }${splitter}${!!timestamp ? `(${timestamp})` : ""}${splitter}${axisName}`;
  return tooltipText;
}
