import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ComponentCategory } from "../../meta";
import { CategoryInfo } from "../models/component-category-info";

@Injectable()
export class CoreComponentCategoryProvider {
  public selectAllCategories(): Observable<CategoryInfo[]> {
    return of([
      new CategoryInfo(ComponentCategory.Container, 100),
      new CategoryInfo(ComponentCategory.SingleValue, 200),
      new CategoryInfo(ComponentCategory.TimeSeries, 300),
      new CategoryInfo(ComponentCategory.Category, 400),
      new CategoryInfo(ComponentCategory.Glitter, 500)
    ]);
  }
}
