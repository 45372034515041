import { select, Store } from "@ngrx/store";
import { isEqual } from "lodash";
import { combineLatest, Observable, Subject } from "rxjs";
import {
  distinctUntilChanged,
  first,
  map,
  pairwise,
  skip,
  startWith,
  switchMap,
  takeUntil
} from "rxjs/operators";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { SubscribeFunction } from "../../../core/models/subscribe-function";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { DataConnectorSubscriptionContext } from "../../../data-connectivity/models/data-connector-subscription-context";
import { EntityId } from "../../../meta/models/entity";
import { isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { ComponentStateDto } from "../../models/component-state";
import { ComponentStateViewModel } from "../../models/component-state.vm";
import {
  createComponentStateDtoSliceSelector,
  selectChildren
} from "../../store/component-state/component-state.selectors";
import { selectFilterByIdOrDefault } from "../../store/filter/filter.selectors";
import { ComponentStateViewModelDeserializer } from "../deserializers/component-state-vm.deserializer";
import { ComponentStateSelector } from "./component-state.selector";
import { DataConnectorSelector } from "./data-connector.selector";
import { RuntimeSettingsSelector } from "./runtime-settings.selector";

export class ComponentStatePropertySelector {
  private componentState$: Observable<ComponentStateDto>;
  public unsubscribeSubject$: Subject<void> = new Subject();

  constructor(
    private store$: Store<any>,
    private componentStateId: EntityId,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private componentStateVMDeserializer: ComponentStateViewModelDeserializer,
    private runtimeSettingsSelector: RuntimeSettingsSelector
  ) {
    this.componentState$ = this.componentStateSelector.selectComponentStateById(componentStateId);
  }

  // IP method should be a verb
  activeDataConnectorDataChanges(): Observable<DataConnectorDto[]> {
    return this.componentState$.pipe(
      select((state: ComponentStateDto) => state.dataConnectorIds),
      distinctUntilChanged(),
      map((dataConnectorIds: EntityId[]) =>
        this.dataConnectorSelector.getManyById(dataConnectorIds)
      ),
      map((connectorsDict) => Object.values(connectorsDict)),
      takeUntil(this.unsubscribeSubject$)
    );
  }

  subscribeOnSlice(
    stateSelector: (x: ComponentStateDto) => any,
    delegate: SubscribeFunction<any>
  ): void {
    this.componentState$
      .pipe(select(stateSelector), distinctUntilChanged(), takeUntil(this.unsubscribeSubject$))
      .subscribe(delegate);
  }

  selectSlice<T>(stateSelector: (x: ComponentStateDto) => T): Observable<T> {
    return this.store$
      .select(createComponentStateDtoSliceSelector<T>(this.componentStateId, stateSelector))
      .pipe(
        // map((slice) => _cloneDeep(slice)),
        takeUntil(this.unsubscribeSubject$)
      );
  }

  subscribeOnSliceWithPreviousValue(
    stateSelector: (x: ComponentStateDto) => any,
    delegate: SubscribeFunction<any>
  ): void {
    this.componentState$
      .pipe(select(stateSelector), startWith([]), pairwise(), takeUntil(this.unsubscribeSubject$))
      .subscribe(delegate);
  }

  subscribe(
    delegate: SubscribeFunction<ComponentStateDto>,
    stateSelector?: (x: ComponentStateDto) => any
  ) {
    this.subscribeOnSlice((state) => state, delegate);
  }

  selectChildren(): Observable<ComponentStateDto[]> {
    return this.store$
      .select(selectChildren(this.componentStateId))
      .pipe(takeUntil(this.unsubscribeSubject$));
  }

  unsubscribe() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private get componentState(): ComponentStateDto {
    let componentState: ComponentStateDto;
    this.componentState$.pipe(first()).subscribe((state) => (componentState = state));
    return componentState;
  }

  getComponentStateViewModel(): ComponentStateViewModel {
    return this.componentStateVMDeserializer.convert(this.componentState);
  }

  subscribeToDataConnectorChanges(
    componentId: EntityId,
    delegate: SubscribeFunction<ComponentStateDto>
  ) {
    this.subscribeOnSlice((state: ComponentStateDto) => state.dataConnectorIds, delegate);
  }

  subscribeOnDataConnectors(subscribeContext: DataConnectorSubscriptionContext) {
    this.dataConnectorSelector.subscribeToComponentDataConnectors({
      ...subscribeContext,
      componentId: this.componentStateId,
      takeUntil: this.unsubscribeSubject$
    });
  }

  subscribeOnFilter(delegate: SubscribeFunction<FilterConfigurationDto>): void {
    const filter$ = this.selectSlice((x) => x.filterId).pipe(
      switchMap((filterId) => this.store$.select(selectFilterByIdOrDefault(filterId))),
      takeUntil(this.unsubscribeSubject$)
    );
    filter$.subscribe((context: FilterConfigurationDto) => delegate(context));
  }

  subscribeOnPeriodType(delegate: SubscribeFunction<string>, skipCount: number = 0): void {
    const periodType$ = combineLatest([
      this.selectSlice((state) => state.dataConnectorQuery?.aggregationConfig.periodType),
      this.runtimeSettingsSelector.selectPeriodType()
    ]).pipe(
      skip(skipCount),
      map(([componentPeriodType, globalPeriodType]) =>
        isEmptyOrNotDefined(componentPeriodType) ? globalPeriodType : componentPeriodType
      ),
      distinctUntilChanged(isEqual),
      takeUntil(this.unsubscribeSubject$)
    );
    periodType$.subscribe((periodType) => delegate(periodType));
  }
}
