import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Actions } from "@ngrx/effects";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject, timer } from "rxjs";
import { debounce, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { DraggedItem } from "../../../core/models/drag/dragged-item";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { areGroupsEnabled } from "../../../core/models/enable-groups";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { ComponentMetadataService } from "../../../data-connectivity/services/component-metadata.service";
import { Dispatcher } from "../../../dispatcher";
import { DropHelper } from "../../../elements/services/drop.helper";
import { ComponentStateSelector } from "../../../elements/services/entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "../../../elements/services/entity-selectors/data-connector-view.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { OfType } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyDescriptor } from "../../../meta/models/property-descriptor";
import { TypeDescriptor } from "../../../meta/models/type-descriptor";
import { TypeProvider } from "../../../meta/services/type-provider";
import { AutocompleteInterpolationComponent } from "../../../shared/components/autocomplete-interpolation/autocomplete-interpolation.component";
import { ConnectorGroupDto } from "../../../shared/models/connector-group";
import { CriticalError, Maybe } from "../../../ts-utils";
import { isDefined, isEmptyOrNotDefined, isNotDefined, last } from "../../../ts-utils/helpers";
import { resolveItemDisplayName } from "../../helpers/array-items.helper";
import { getDefaultGroupName } from "../../helpers/groups.helper";
import { focusItemInput } from "../../helpers/input-editor-helper";
import { BaseEditorComponent } from "../base-editor.component";
import { GroupUpdate, ItemToEdit, ItemType } from "./models";

@Component({
  selector: "array-editor",
  templateUrl: "array-editor.component.html",
  styleUrls: ["./array-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@OfType(EditorType.Array)
export class ArrayEditorComponent
  extends BaseEditorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  protected unsubscribeSubject$: Subject<void> = new Subject<void>();

  public isGroupable: boolean = false;
  public defaultGroupName: Maybe<string> = "";
  public enableGroups: boolean = false;
  public groupNameObserver$ = new Subject<GroupUpdate>();
  public groups: ConnectorGroupDto[] = [];

  invalidTitleInputs: boolean[] = [];
  public draggedOverTargets: boolean[] = [];
  public itemsInEditMode: boolean[] = [];
  protected selectedItem: ItemType;

  public itemTitleChanged$: Subject<ItemToEdit> = new Subject();
  public items: ItemType[] = [];
  public itemTitleProperty: Maybe<PropertyDescriptor> = null;
  private draggedItemIndex!: number;
  private lastChangedItemIndex!: number;
  @ViewChild("arrayWrapper") arrayWrapper: ElementRef;
  @ViewChildren("itemInput")
  itemInputs?: QueryList<ElementRef>;
  @ViewChildren("itemWrapper")
  itemWrappers?: QueryList<ElementRef>;

  @ViewChildren("autocompleteInterpolation")
  autocompleteInterpolationComponents!: QueryList<AutocompleteInterpolationComponent>;
  @ViewChild("itemInput", { read: MatAutocompleteTrigger })
  autocompleteTrigger?: MatAutocompleteTrigger;
  autocompleteReferences: MatAutocomplete[] = [];

  public canAddCustomItem: boolean = false;

  constructor(
    protected dispatcher: Dispatcher,
    protected typeProvider: TypeProvider,
    protected componentSelector: ComponentStateSelector,
    protected metadataService: ComponentMetadataService,
    protected elementRef: ElementRef,
    protected actions$: Actions,
    public translationService: LocalizationService,
    protected cdr: ChangeDetectorRef,
    protected dragDropService: IDragDropService,
    protected dropHelper: DropHelper,
    protected connectorViewSelector: DataConnectorViewSelector
  ) {
    super(cdr, typeProvider, translationService);
  }

  @HostListener("click", ["$event"])
  onClick(event: any) {
    event.stopPropagation();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.value = _cloneDeep(this.value);
    this.items = _cloneDeep(this.value);
    this.initItemsInEditMode();
    this.isGroupable = this.propertyInfo.descriptor.isGroupable;
    this.enableGroups = areGroupsEnabled(this.itemOwner);
    this.defaultGroupName = getDefaultGroupName(this.itemOwner);
    this.initDraggedTargetsArray();
    this.determineItemTitleProperty();
    this.subscribeToItemTitleChanges();

    window.addEventListener("scroll", this.scrollEvent, true);
    this.cdr.detectChanges();
    this.resolveAddingCustomItem();
  }

  ngAfterViewInit(): void {
    this.autocompleteInterpolationComponents.forEach((component) => {
      this.autocompleteReferences.push(component.autocomplete);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  scrollEvent = (event) => {
    if (isDefined(this.autocompleteTrigger) && this.autocompleteTrigger.panelOpen) {
      this.autocompleteTrigger.updatePosition();
    }
  };

  refreshValue(value): void {
    this.lastChangedItemIndex = value.findIndex(
      (item, i) => JSON.stringify(item) !== JSON.stringify(this.value[i])
    );
    super.refreshValue(value);
    this.enableGroups = areGroupsEnabled(this.itemOwner);
    this.isGroupable = this.propertyInfo.descriptor.isGroupable;

    if (this.autocompleteReferences.length > this.autocompleteInterpolationComponents.length) {
      this.removeAutocompleteReference();
    }
    if (this.autocompleteInterpolationComponents.length > this.autocompleteReferences.length) {
      this.addAutocompleteReference();
    }
  }

  focus(): void {
    const itemWrapper = this.itemWrappers?.get(this.lastChangedItemIndex)?.nativeElement;
    itemWrapper?.scrollIntoView({
      block: "nearest",
      behavior: "smooth"
    });
    const itemInput = this.itemInputs?.get(this.lastChangedItemIndex)?.nativeElement;
    focusItemInput(itemInput);
  }

  getArrayItemType(): TypeDescriptor {
    return this.typeProvider.getTypeByConstructor(this.typeDescriptor.constructorFunction);
  }

  initItemsInEditMode(): void {
    this.value.forEach(() => {
      this.itemsInEditMode.push(false);
    });
  }

  protected initDraggedTargetsArray(): void {
    this.value.forEach(() => {
      this.draggedOverTargets.push(false);
    });
  }

  private determineItemTitleProperty(): void {
    if (!this.typeDescriptor.isPrimitive) {
      this.itemTitleProperty = this.typeDescriptor.properties.find((prop) => prop.isTitle);
    }
  }

  private subscribeToItemTitleChanges(): void {
    this.itemTitleChanged$
      .pipe(
        debounce((_newValue) => {
          return this.propertyInfo.descriptor.allowInterpolation ? timer(150) : timer(300);
        }),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((itemToEdit: ItemToEdit) => {
        this.onChangeItemTitle((itemToEdit.event.target as any).value, itemToEdit.index);
      });
  }

  private onChangeItemTitle(newTitle: string, index: number): void {
    this.typeDescriptor.isPrimitive
      ? this.changePrimitiveArrayItem(newTitle, index)
      : this.changeNestedObjectTitle(newTitle, index);
  }

  changePrimitiveArrayItem(value: string, index: number): void {
    this.items = _cloneDeep(this.value);
    this.items[index] = value;
    this.onValueChanged(this.items);
  }

  changeNestedObjectTitle(value: string, index: number): void {
    if (this.shouldUpdateItemTitle(value, index)) {
      this.items = _cloneDeep(this.value);
      this.items[index][this.itemTitleProperty.name] = value;
      this.onValueChanged(this.items);
      this.invalidTitleInputs[index] = false;
    } else {
      this.invalidTitleInputs[index] = true;
    }
    this.cdr.markForCheck();
  }

  private shouldUpdateItemTitle(value: string, index: number): boolean {
    return (
      this.itemTitleProperty?.validationFunction(value, {
        itemOwner: this.value[index],
        parentInfo: this.itemOwner
      }) ?? false
    );
  }

  trackItemsByIndex(index: number): number {
    return index;
  }

  expandOrCollapseItem(arrayItem: ItemType, index: number): void {
    this.itemsInEditMode[index] = !this.itemsInEditMode[index];
  }

  getItemDisplayIcon(arrayItem: ItemType): string {
    return this.resolveIconBasedOnData(arrayItem);
  }

  resolveIconBasedOnData(arrayItem: ItemType): string {
    return "";
  }

  getItemDisplayRole(arrayItem: ItemType): string {
    return "";
  }

  resolveItemDisplayName(arrayItem: ItemType): string {
    return resolveItemDisplayName(arrayItem, this.itemTitleProperty, this.typeProvider);
  }

  get canChangeItemTitle(): boolean {
    return (
      (isDefined(this.typeDescriptor) && this.typeDescriptor.isPrimitive) ||
      isDefined(this.itemTitleProperty)
    );
  }

  hideOrShowItem(itemToHide: ItemType, index: number): void {
    this.items = _cloneDeep(this.value);
    this.items[index].isHidden = !itemToHide.isHidden;
    this.onValueChanged(this.items);
  }

  addArrayItem(event: Event): void {
    if (!this.typeDescriptor) {
      throw new CriticalError("Undefined type descriptor");
    }
    const newItem = new this.typeDescriptor.constructorFunction();
    const newItemIndex: number = this.value.length;
    this.value = [...this.value, newItem];
    event.stopPropagation();
    this.editArrayItem(newItem, newItemIndex);
    if (this.propertyInfo.descriptor.allowInterpolation) {
      this.addAutocompleteReference();
    }
  }

  editArrayItem(itemToEdit: ItemType, index?: number): void {
    this.items = _cloneDeep(this.value);
    if (isDefined(index)) {
      this.items[index] = _cloneDeep(itemToEdit);
      this.onValueChanged(this.items);
    }
  }

  removeArrayItem(itemToRemove: ItemType, indexOfItemToRemove: number, event: Event): void {
    event.stopPropagation();
    this.remove(itemToRemove, indexOfItemToRemove);
  }

  remove(itemToRemove: ItemType, indexOfItemToRemove: number): void {
    this.items = _cloneDeep(this.value);
    this.items.splice(indexOfItemToRemove, 1);
    this.itemsInEditMode.splice(indexOfItemToRemove, 1);
    if (this.propertyInfo.descriptor.allowInterpolation) {
      this.removeAutocompleteReference();
    }
    this.onValueChanged(this.items);
  }

  onValueChanged(newValue: ItemType[]): void {
    super.onValueChanged(newValue);
  }

  selectItem(item: ItemType): void {
    this.selectedItem = item;
  }

  public dragStart(event: Event, item: ItemType, index?: number): void {
    if (isDefined(index)) {
      this.draggedItemIndex = index;
    }
    const dragTarget: DraggedItem = this.getDragTarget(item);
    this.dragDropService.enableDrag = true;
    this.dragDropService.setDragTarget(dragTarget, "");
  }

  protected getDragTarget(item: ItemType): DraggedItem {
    return {
      type: DraggedItemType.ArrayEditorItemType,
      item
    };
  }

  public onDragOver(event: DragEvent, targetIndex?: number): void {
    if (this.canAcceptDrop()) {
      event.preventDefault(); // HTML drop disabled by default
    }
    event.stopPropagation();
    if (isDefined(targetIndex)) {
      this.setDraggedOverTarget(targetIndex);
    }
  }

  protected canAcceptDrop(): boolean {
    if (isNotDefined(this.dragDropService.target)) {
      return false;
    }
    return this.dragDropService.target.type === DraggedItemType.ArrayEditorItemType;
  }

  protected setDraggedOverTarget(index: number): void {
    if (this.draggedItemIndex !== index) {
      this.draggedOverTargets[index] = true;
    }
  }

  public onDragEnd(): void {
    this.resetDraggedOverTarget();
    this.dragDropService.dragEnd();
  }

  public onDragLeave(event: DragEvent, targetIndex: number): void {
    this.draggedOverTargets[targetIndex] = false;
    event.stopPropagation();
  }

  public drop(event: DragEvent | TouchEvent, groupId: string, index?: number): void {
    if (isNotDefined(index) || this.draggedItemIndex === index) {
      return;
    }
    this.items = _cloneDeep(this.value);
    this.swapItems(index);
    this.onValueChanged(this.items);
    if (this.areBothSwappedItemsExpanded(index)) {
      this.rerenderSwappedExpandedItems(index);
    }
  }

  private swapItems(index: number): void {
    swapArrayItems(this.itemsInEditMode, this.draggedItemIndex, index);
    swapArrayItems(this.items, this.draggedItemIndex, index);
  }

  private areBothSwappedItemsExpanded(secondIndex: number): boolean {
    return this.itemsInEditMode[this.draggedItemIndex] && this.itemsInEditMode[secondIndex];
  }

  private rerenderSwappedExpandedItems(secondIndex: number): void {
    this.itemsInEditMode[this.draggedItemIndex] = this.itemsInEditMode[secondIndex] = false;
    this.cdr.detectChanges();
    this.itemsInEditMode[this.draggedItemIndex] = this.itemsInEditMode[secondIndex] = true;
  }

  protected resetDraggedOverTarget(): void {
    const index = this.draggedOverTargets.findIndex(Boolean);
    if (isDefined(index)) {
      this.draggedOverTargets[index] = false;
      this.cdr.detectChanges();
    }
  }

  addAutocompleteReference(): void {
    const newReference = last(this.autocompleteInterpolationComponents.toArray());
    if (isDefined(newReference)) {
      this.autocompleteReferences.push(newReference.autocomplete);
    }
  }

  removeAutocompleteReference(): void {
    this.autocompleteReferences.pop();
  }

  resolveAddingCustomItem(): void {
    this.canAddCustomItem = false;
  }

  protected addCustomArrayItem(event: Event): void {}

  resolveTooltipForTitleProperty(): string {
    if (isNotDefined(this.itemTitleProperty)) {
      return "";
    }
    const translatedTooltip = this.translationService.get(this.itemTitleProperty.tooltipKey);
    return isEmptyOrNotDefined(translatedTooltip) ? this.itemTitleProperty.name : translatedTooltip;
  }

  trackGroupsById(group: ConnectorGroupDto): string {
    return group.id;
  }

  toggleExpansionPanel(index: number): void {
    this.groups[index].isExpanded = !this.groups[index].isExpanded;
  }

  protected createNewGroup(_event: Event): void {}

  protected removeGroup(_group: ItemType, _groupIndex: number): void {}

  protected onGroupDragStart(_event: Event, _group: ItemType): void {}

  protected onGroupDrop(_event: DragEvent | TouchEvent, _index: number): void {}

  getGroupItemsById(groupId: string): Maybe<ItemType[]> {
    const group = this.getGroupById(groupId);
    if (isDefined(group)) {
      return group.items;
    }
  }

  getGroupById(groupId: string): Maybe<ConnectorGroupDto> {
    return this.groups.find((group: ConnectorGroupDto) => group.id === groupId);
  }
}

export function swapArrayItems(array: any[], firstIndex: number, secondIndex: number): void {
  const temp = array[firstIndex];
  array[firstIndex] = array[secondIndex];
  array[secondIndex] = temp;
}
