import { Filter } from "projects/ui-core/src/lib/core/models/filter/filter";
import {
  DataColumnType,
  DataConnectorDto,
  DataTableResponse,
  Dictionary,
  GROUPED_DATA_SOURCE,
  GenericDataSourceDto,
  GroupedDataSourceDto,
  LabelOrValue,
  OrderDirection,
  QueryParamsResolverService,
  TABULAR_DATA_SOURCE,
  TypedDataTable,
  setDataStatusFromPoints
} from "ui-core";
import { ApiResponse } from "../../../models/api/api-response";
import { GenericClientDto, QueryDto } from "../../../models/api/query";
import { AzureQueryStringService } from "../azure-query-string.service";
import { GenericQueriesService } from "../generic-queries.service";
import { PlantTimeConverter } from "../plant-time.converter";

export class GenericQueriesStrategy {
  tabularDataSourceIds: string[] = [];

  constructor(
    protected queryStringService: AzureQueryStringService,
    protected timeConverter: PlantTimeConverter,
    protected queryParamsResolver: QueryParamsResolverService
  ) {}

  prepareRequests(
    filters: Dictionary<Filter>,
    genericQueries: Dictionary<GenericDataSourceDto>
  ): QueryDto<GenericClientDto>[] {
    const queriesWithGroups: QueryDto<GenericClientDto> = { Clients: [] };  
    const queriesWithoutGroups: QueryDto<GenericClientDto> = { Clients: [] };  

    Object.keys(genericQueries).forEach((componentId) => {
      if (genericQueries[componentId].typeName === TABULAR_DATA_SOURCE) {
        this.tabularDataSourceIds.push(componentId);
      }
      
      const genericQuery = genericQueries[componentId];
      const filterUsed = filters[componentId];

      let client: GenericClientDto = {
        ClientId: componentId,
        Customer: this.queryStringService.getPlantName(),
        Motor: this.queryStringService.getMotorName(),
        From: this.timeConverter.removeLocalTimeBias(filterUsed.timeRange.from),
        To: this.timeConverter.removeLocalTimeBias(filterUsed.timeRange.to),
        Frequency: this.queryParamsResolver.getDCQPeriodType(genericQuery) as string,
        Group1: (genericQuery as GroupedDataSourceDto)?.groupBy1,
        Group2: (genericQuery as GroupedDataSourceDto)?.groupBy2,
        MaxRecords: genericQuery.maxRecords,
        AggregationOf: (genericQuery as GroupedDataSourceDto)?.aggregationOf,
        Aggregation: (genericQuery as GroupedDataSourceDto)?.aggregation,
        SimpleFilterColumn: genericQuery.simpleFilterColumn,
        SimpleFilterValue: genericQuery.simpleFilterValue
      };

      if (genericQuery.typeName === GROUPED_DATA_SOURCE) {
        if (genericQuery.orderBy != null && genericQuery.orderBy.columnName != null) {
          client.OrderBy = genericQuery.orderBy.columnName;
          client.OrderDirection = genericQuery.orderBy.direction;
        } else {
          client.OrderBy = LabelOrValue.Label;
          client.OrderDirection = OrderDirection.Descending;
        }
      }

      if (genericQuery.whereJson) {
        Object.assign(client, JSON.parse(genericQuery.whereJson));
      }

      Object.keys(client).forEach((key: string) => client[key] === "" && delete client[key]);

      if (client.Group1 || client.Group2) {  
        queriesWithGroups.Clients.push(client);  
      } else {  
        queriesWithoutGroups.Clients.push(client);  
      } 
    });

    return [queriesWithGroups, queriesWithoutGroups].filter(query => query.Clients.length > 0); 
  }

  processResponse(
    response: ApiResponse[],
    genericQueries: Dictionary<GenericDataSourceDto>
  ): DataTableResponse {

    return response.map((client: ApiResponse) => {
      const genericDS = genericQueries[client.ClientId];
      const columns = GenericQueriesService.getGenericDataSourceDescriptors().filter(e => e.entity === genericDS.entity)[0].columns;

      return {
        queryId: client.ClientId,
        schema: {
          tableName: client.RiCode,
          columns: genericDS.typeName === "GroupedDataSourceDto"
            ? [
                { name: "keyLabel", type: DataColumnType.String },
                { name: "Timestamp", type: DataColumnType.Date }
            ]            
            :  columns
        },
        table: genericDS.typeName === "GroupedDataSourceDto" ? client.DataPoints?.map((dp) => {
          var group = dp.text

          if (dp.Timestamp) {
            group = this.timeConverter.addLocalTimeBias(dp.Timestamp);
          }
          
          return Object.keys(dp).
            filter(key => key != "Timestamp").
            map((key) => [key, group, dp[key]]);
          
        }).flat() : client.DataPoints.
            map(dp => columns.
              map(c => c.name === "Timestamp" ?
                this.timeConverter.addLocalTimeBias(dp[c.name]) :
                dp[c.name]))
      } as TypedDataTable;
    });
  }

  filterConnectorsFromGenericQuery(
    dataConnectors: Dictionary<DataConnectorDto[]>,
    genericQuery: Dictionary<GenericDataSourceDto>
  ): Dictionary<DataConnectorDto[]> {
    Object.keys(dataConnectors).forEach((key) => {
      const query: any = genericQuery[key];
      const columns = query.columns;
      const titleConnector = dataConnectors[key].find((dc) => dc.title === "text");
      const title = titleConnector ? titleConnector!.dataPoints[0].y : "";
      let orderIndices = dataConnectors[key][0]
        ? Array.from(Array(dataConnectors[key][0].dataPoints.length).keys())
        : [];

      // Sorting
      if (columns && query.orderBy && query.orderBy.columnName) {
        const dataConn = dataConnectors[key].filter((dc) => dc.title === query.orderBy.columnName);
      }

      // Filtering columns
      if (columns && columns.length > 0) {
        dataConnectors[key] = dataConnectors[key].filter((dc) => columns.includes(dc.title));
      }

      dataConnectors[key].forEach((dc) => {
        dc.dataPoints = orderIndices.map((i) => dc.dataPoints[i]);

        dc.title = title !== "" ? title : dc.title;
        setDataStatusFromPoints(dc);
        if (this.tabularDataSourceIds.includes(key)) {
          dc.isTimeSeries = false;
          dc.dataPoints.forEach((dp) => (dp.x = undefined));
          if (dc.title === "Timestamp") {
            dc.dataPoints.forEach(
              (dp) => (dp.y = new Date(this.timeConverter.addLocalTimeBias(dp.y)).toISOString())
            );
          }
        }
      });
    });

    return dataConnectors;
  }
}
