import { FlatTreeControl } from "@angular/cdk/tree";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorCatchingActions } from "../../../core";
import { Equipment } from "../../../core/models/equipment";
import {
  EquipmentPath,
  EquipmentPathStep,
  PathAxis,
  PathPredicate,
  PredicateType
} from "../../../core/models/equipment-path";
import {
  ePathSelect,
  getClassesOfEquipment,
  SelectableEquipment
} from "../../../core/services/equipment-path-select.helper";
import { ePathStepsToString } from "../../../core/services/equipment-path-to-string.helper";
import { Dispatcher } from "../../../dispatcher";
import { CriticalError, isDefined, isNotDefined, last } from "../../../ts-utils";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { EquipmentNode } from "../../models/equipment-node";
import { EquipmentSelector } from "../../services/equipment.selector";

@Component({
  selector: "equipment-path-browser",
  templateUrl: "equipment-path-browser.component.html",
  styleUrls: ["equipment-path-browser.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentPathBrowserComponent implements OnInit, OnDestroy {
  @Output() selectedEquipmentPathChanged: EventEmitter<EquipmentPath> =
    new EventEmitter<EquipmentPath>();

  @Input() applyLightTheme: boolean = true;
  @Input() activePath: Maybe<EquipmentPath> = null;
  @Input() openFullTree: boolean = false;

  private unsubscribeSubject$: Subject<void> = new Subject();
  public treeControl: FlatTreeControl<EquipmentNode>;
  public treeFlattener: MatTreeFlattener<Equipment, EquipmentNode>;
  public dataSource: MatTreeFlatDataSource<Equipment, EquipmentNode>;
  public showProgressBar: boolean;

  public overallIndex: Maybe<number>;

  private root: Maybe<Equipment>;
  private parents: Map<Equipment, Equipment>;
  private nodes: Map<Equipment, EquipmentNode>;

  matTreeNodePadding = 25;

  constructor(
    private equipmentSelector: EquipmentSelector,
    protected dispatcher: Dispatcher,
    private cdr: ChangeDetectorRef
  ) {
    this.parents = new Map<Equipment, Equipment>();
    this.nodes = new Map<Equipment, EquipmentNode>();
    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.treeFlattener = new MatTreeFlattener(
      this.equipmentToNode,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.showProgressBar = true;
  }

  ngOnInit(): void {
    this.equipmentSelector
      .selectEquipmentTreeFromRootPath(this.openFullTree)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((rootEquipment: Maybe<Equipment>) => {
        if (isNotDefined(rootEquipment)) {
          this.dispatcher.dispatch(
            ErrorCatchingActions.catchError({
              messageToDisplay: "Equipment not found",
              autoClose: true
            })
          );
          return;
        }
        this.showProgressBar = false;

        this.root = rootEquipment;
        const pseudoParent = this.buildPseudoParent(this.root);
        this.dataSource.data = [pseudoParent, rootEquipment];
        this.fixPseudoParentLinks(pseudoParent);
        this.updateAllSelectionPaths();
        this.expandInterestingNodes();
        if (isDefined(this.activePath)) {
          this.overallIndex = this.activePath.overallIndex;
        }
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private buildPseudoParent(equipment: Equipment): Equipment {
    const pseudoParent = new PseudoEquipment();
    pseudoParent.name = "..";
    pseudoParent.children = [equipment];
    pseudoParent.properties = [];
    return pseudoParent;
  }

  private fixPseudoParentLinks(pseudoParent: Equipment): void {
    const rootNode = this.nodes.get(this.root!)!;
    const pseudoParentNode = this.nodes.get(pseudoParent)!;
    this.parents.set(this.root!, pseudoParent);
    rootNode.parent = pseudoParentNode;
  }

  private isPseudoParent(equipment: Equipment): boolean {
    return equipment instanceof PseudoEquipment;
  }

  private getLevel = (node: EquipmentNode): number => {
    return node.level;
  };

  private isExpandable = (node: EquipmentNode): boolean => {
    return node.equipment.children.length > 0;
  };

  private getChildren = (equipment: Equipment): Equipment[] => {
    if (this.isPseudoParent(equipment)) {
      return [];
    }
    equipment.children.forEach((child: Equipment) => {
      this.parents.set(child, equipment);
    });
    return equipment.children;
  };

  private equipmentToNode = (equipment: Equipment, level: number): EquipmentNode => {
    const parent = this.parents.get(equipment);
    const parentNode = isDefined(parent) ? this.nodes.get(parent) : null;

    const node: EquipmentNode = {
      equipment: equipment,
      name: equipment.name ?? "",
      level: level,
      parent: parentNode,
      children: [],
      classes: getClassesOfEquipment(equipment),
      selectionPath: null
    };
    if (isDefined(parentNode)) {
      parentNode.children.push(node);
    }
    this.nodes.set(equipment, node);

    return node;
  };

  public isExpanded(node: EquipmentNode): boolean {
    return this.treeControl.isExpanded(node);
  }

  public hasChildren(node: EquipmentNode): boolean {
    if (this.isPseudoParent(node.equipment)) {
      return false;
    }
    return node.equipment.children.length > 0;
  }

  private updateAllSelectionPaths(): void {
    this.clearAllSelectionPaths();
    if (isDefined(this.activePath) && isDefined(this.root)) {
      const rootNode = this.nodes.get(this.root)!;
      this.updateSelectionPaths(rootNode, this.activePath);
    }
    return;
  }

  private clearAllSelectionPaths(): void {
    for (const node of this.nodes.values()) {
      node.selectionPath = {
        isInSelectedPath: false,
        lastStepToThisNode: null,
        stepsAfterThisNode: []
      };
    }
  }

  private updateSelectionPaths(root: EquipmentNode, path: EquipmentPath): void {
    ePathSelect(root, path, this.matchedNodeCallback);
  }

  private matchedNodeCallback(
    matchedSelectable: SelectableEquipment,
    lastStepToThisNode: EquipmentPathStep,
    remainder: EquipmentPathStep[]
  ): void {
    const matchedNode = matchedSelectable as EquipmentNode;
    matchedNode.selectionPath = {
      isInSelectedPath: true,
      lastStepToThisNode: lastStepToThisNode,
      stepsAfterThisNode: remainder
    };
  }

  private expandInterestingNodes(): void {
    this.treeControl.dataNodes
      .filter((node) => this.isInterestingNode(node))
      .forEach((node) => {
        this.treeControl.expand(node);
      });
  }

  private isInterestingNode(node: EquipmentNode): boolean {
    return (
      node.equipment === this.root ||
      node.equipment.children.find((child: Equipment) => {
        const childNode = this.nodes.get(child);
        return childNode?.selectionPath?.isInSelectedPath === true;
      }) !== undefined
    );
  }

  public onNodeSelected(node: EquipmentNode, selectedWith: PathPredicate): void {
    const overallIndex = this.isOverallIndexValid()
      ? this.overallIndex
      : this.activePath?.overallIndex;
    if (isDefined(node.selectionPath) && node.selectionPath.isInSelectedPath) {
      if (isNotDefined(this.activePath)) {
        throw new CriticalError("Active path not found.");
      }
      const modifiedStep: EquipmentPathStep = this.buildStep(node, selectedWith);
      this.activePath = {
        steps: [
          ...this.activePath.steps.slice(0, node.level),
          modifiedStep,
          ...this.activePath.steps.slice(node.level + 1)
        ],
        overallIndex: overallIndex
      };
    } else {
      this.activePath = {
        steps: this.buildActivationPath(node, selectedWith),
        overallIndex: overallIndex
      };
    }
    this.updateAllSelectionPaths();

    this.selectedEquipmentPathChanged.emit(this.activePath);
  }

  private buildActivationPath(
    target: EquipmentNode,
    selectWith: Maybe<PathPredicate>
  ): EquipmentPathStep[] {
    if (!isDefined(target.selectionPath)) {
      throw new CriticalError("Selection path not found.");
    }
    const stepsBeforeTarget: EquipmentPathStep[] =
      target.equipment !== this.root && isDefined(target.parent)
        ? this.buildActivationPath(target.parent, null)
        : [];

    let finalStep: EquipmentPathStep;
    if (isDefined(selectWith)) {
      finalStep = this.buildStep(target, selectWith);
    } else if (target.selectionPath.isInSelectedPath) {
      finalStep = target.selectionPath.lastStepToThisNode!;
    } else {
      // default: select node by name
      if (!isDefined(target.equipment.name)) {
        throw new CriticalError("Equipment has no name");
      }
      const byName: PathPredicate = {
        type: PredicateType.NodeName,
        expected: target.equipment.name
      };
      finalStep = this.buildStep(target, byName);
    }

    return [...stepsBeforeTarget, finalStep];
  }

  private buildStep(node: EquipmentNode, selectWith: PathPredicate): EquipmentPathStep {
    if (this.isPseudoParent(node.equipment)) {
      return {
        axis: PathAxis.Parent,
        predicates: []
      };
    } else {
      return {
        axis: PathAxis.Child,
        predicates: [selectWith]
      };
    }
  }

  public selectedSteps(): string {
    if (isDefined(this.activePath)) {
      return ePathStepsToString(this.activePath.steps);
    } else {
      return "none";
    }
  }

  public onOverallIndexChange(newValue: number): void {
    this.overallIndex = newValue;

    if (isDefined(this.activePath) && this.isOverallIndexValid()) {
      this.activePath.overallIndex = this.overallIndex;
      this.selectedEquipmentPathChanged.emit(this.activePath);
    }
  }

  private isOverallIndexValid(): boolean {
    return (
      isNotDefined(this.overallIndex) ||
      (this.overallIndex >= 1 && Number.isInteger(this.overallIndex))
    );
  }

  public canHaveOverallIndex(): boolean {
    if (isDefined(this.activePath)) {
      return last(this.activePath.steps)?.axis !== PathAxis.Parent;
    }
    return false;
  }
}

class PseudoEquipment extends Equipment {}
