import { DataConnectorDto } from "../../data-connectivity";
import { getConnectorGroupId } from "../../data-connectivity/helpers/data-connector-view.helper";
import { sortConnectors } from "../../elements/helpers/connectors.helper";
import { DATA_CONNECTOR_DTO } from "../../elements/models/entity-type.constants";
import { DataConnectorViewSelector } from "../../elements/services/entity-selectors/data-connector-view.selector";
import { ConnectorGroupDto } from "../../shared/models/connector-group";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ItemType } from "../components/array-editor/models";

const GROUP_NUMBER = 2;
const MAX_ORDER_VALUE = 10000;

export function resolveNewGroupName(groups: ConnectorGroupDto[], groupNamePrefix: string): string {
  const pattern = `(${groupNamePrefix})?\\((\\d+)\\)`;
  let maxNumber = 1;

  groups.forEach((group: ConnectorGroupDto) => {
    const result = group.name?.match(pattern);

    if (isDefined(result)) {
      const numberOfGroup = Number(result[GROUP_NUMBER]);
      if (maxNumber <= numberOfGroup) {
        maxNumber = numberOfGroup;
        maxNumber++;
      }
    }
  });
  return `${groupNamePrefix}(${maxNumber})`;
}

export function addOrMoveItemToGroup(
  groups: ConnectorGroupDto[],
  groupId: string,
  itemToChange: Maybe<ItemType>,
  connectorViewSelector: DataConnectorViewSelector
): ConnectorGroupDto[] {
  const itemGroupId: string = getItemGroupId(itemToChange, connectorViewSelector);
  const oldGroupIndex: number = groups.findIndex(
    (group: ConnectorGroupDto) => group.id === itemGroupId
  );
  const newGroupIndex: number = groups.findIndex(
    (group: ConnectorGroupDto) => group.id === groupId
  );

  if (oldGroupIndex === newGroupIndex) {
    return groups;
  }
  if (oldGroupIndex > -1) {
    groups[oldGroupIndex] = removeItemFromGroup(groups[oldGroupIndex], itemToChange);
  }
  if (newGroupIndex > -1) {
    groups[newGroupIndex].items.push(itemToChange);
  }
  return groups;
}

export function getItemGroupId(
  item: Maybe<ItemType>,
  dataConnectorViewSelector: DataConnectorViewSelector
): string {
  return item.typeName === DATA_CONNECTOR_DTO
    ? getConnectorGroupId(item.id, dataConnectorViewSelector)
    : item.groupId;
}

export function removeItemFromGroup(
  group: ConnectorGroupDto,
  itemToRemove: ItemType
): ConnectorGroupDto {
  const itemIndex = group.items.findIndex((item: ItemType) => item.id === itemToRemove.id);
  group.items.splice(itemIndex, 1);
  return group;
}

export function getDefaultGroupName(target: any): Maybe<string> {
  if (isDefined(target.view) && isDefined(target.view.groupName)) {
    return target.view.groupName;
  }
}

export function sortGroups(groups: ConnectorGroupDto[]): ConnectorGroupDto[] {
  return groups.sort((a, b) => {
    const firstOrderValue = a.order ?? MAX_ORDER_VALUE;
    const secondOrderValue = b.order ?? MAX_ORDER_VALUE;
    return firstOrderValue - secondOrderValue;
  });
}

export function insertItemsIntoGroups(
  groups: ConnectorGroupDto[],
  connectors: DataConnectorDto[],
  connectorViewSelector: DataConnectorViewSelector
): ConnectorGroupDto[] {
  sortConnectors(connectors, connectorViewSelector);
  return groups.map((group: ConnectorGroupDto) => ({
    ...group,
    items: getGroupItems(group.id, connectors, connectorViewSelector)
  }));
}

export function getGroupItems(
  groupId: string,
  connectors: DataConnectorDto[],
  connectorViewSelector: DataConnectorViewSelector
): ItemType[] {
  return connectors.filter(
    (connector: DataConnectorDto) => getItemGroupId(connector, connectorViewSelector) === groupId
  );
}
