import { FlatTreeControl } from "@angular/cdk/tree";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { cloneDeepWith as _cloneDeepWith, unset as _unset } from "lodash";
import { NgxSkeletonLoaderConfigTheme } from "ngx-skeleton-loader";
import { Observable, Subject, of } from "rxjs";
import { catchError, map, takeUntil, tap } from "rxjs/operators";
import {
  AppStatusActions,
  CommonActions,
  CreateReportDialogActions,
  Dispatcher,
  ErrorCatchingActions,
  LinkResolver,
  ReportCreationType,
  ReportId,
  ReportLinkDto
} from "ui-core";
import { IClientData } from "../../models/api/client-data.interface";
import {
  FLEET_OVERVIEW_REPORT_ID,
  FLEET_VIEW_REPORT_ID
} from "../../models/constants/predefined-reports";
import { IProjectHierarchyWithStatus } from "../../models/motor-response.interface";
import { Dashboard, DashboardNode } from "../../models/side-nav/sidebar/dashboard";
import { DashboardType } from "../../models/side-nav/sidebar/dashboard-type";
import { DragPosition } from "../../models/side-nav/sidebar/drag-position";
import { NavigationButton } from "../../models/side-nav/sidebar/navigation-button";
import { HierarchyService } from "../../services/hierarchy.service";
import { DashboardNavigationService } from "../../services/sidebar/dashboard-navigation.service";
import { FleetViewDataService } from "../../services/sidebar/fleet-view.service";
import { HierarchyEffects } from "../../store/effects/hierarchy.effect";
import { isDashboardDropCorrect } from "./utils/drag-and-drop-helper";

interface ProjectNode {
  title: string;
  level: number;
  customer: string;
  area: string;
  defaultReport: string;
  children: ProjectNode[];
  shouldBeExpanded: boolean;
  rootPath: string;
}

interface FlatProjectNode {
  expandable: boolean;
  defaultReport: string;
  customer: string;
  area: string;
  title: string;
  level: number;
  rootPath: string;
}

@Component({
  selector: "sidebar-navigation",
  templateUrl: "sidebar-navigation.component.html",
  styleUrls: ["./sidebar-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarNavigationComponent implements OnInit {
  @ViewChild("searchInput", { static: false }) searchInput: ElementRef | null = null;

  public isFormShown: boolean = false;
  public isDeleteModeOn: boolean = false;
  public isDragModeOn: boolean = false;
  public isRenameModeOn: boolean = false;
  public informationText: string = "";
  public isReportsDataReady: boolean = false;
  public isSearchModeOn: boolean = false;
  public dragNode: DashboardNode | null = null;
  public dragNodeExpandOverWaitTimeMs: number = 500;
  public dragNodeExpandOverNode: DashboardNode | null = null;
  public dragNodeExpandOverTime: number = 0;
  public dragNodeExpandOverArea: DragPosition | null = null;
  private reportOpenedNodes: DashboardNode[] = [];

  PROJECT_TAB_IDX: number = 1;
  REPORTS_TAB_IDX: number = 0;

  currentRootPath: string | undefined = "";
  orgFullName = "";
  clientName = "";
  equipmentModelTitle = "";
  reportUrl = "";
  currentReportId = "";
  reportSectionMargin = "50px";
  projectSectionMargin = "0px";
  rootProject = "ABB";
  filterText = "";

  get currentCustomer(): string | undefined {
    return this.currentRootPath?.split("/")[0];
  }

  get currentMotor(): string | undefined {
    return this.currentRootPath?.split("/")[2];
  }

  get currentArea(): string | undefined {
    return this.currentRootPath?.split("/")[1];
  }

  get selectedTab(): number | null {
    return this.tabGroup.selectedIndex;
  }

  isCustomizeMode: boolean = false;
  isHierarchyLoaded: boolean = false;
  isProjectsLoading: boolean = false;
  dashboards: Dashboard[] = [];

  //unions
  isEditor: boolean | undefined = false;

  destroyComponent$ = new Subject<void>();

  //options: ITreeOptions;
  skeletonTheme: NgxSkeletonLoaderConfigTheme = {
    borderRadius: 5,
    height: "30px",
    backgroundColor: "#4C4C4C"
  };

  private _transformer = (node: ProjectNode, level: number) => {
    const flatNode = {
      expandable: !!node.children && node.children.length > 0,
      title: node.title,
      area: node.area,
      customer: node.customer,
      defaultReport: node.defaultReport,
      level: level,
      rootPath: node.rootPath
    };

    if (node.shouldBeExpanded) {
      // Schedule a task to expand the node after the data has been processed
      Promise.resolve().then(() => this.projectsTreeControl.expand(flatNode));
    }

    return flatNode;
  };

  /**
   * Changes the `Dashboard` element into `DashboardNode` by adding a level property (node depth).
   */
  private getTransformer = (dashboard: Dashboard, level: number) => {
    const node = { ...dashboard, level };

    if (node.shouldBeExpanded) {
      // Schedule a task to expand the node after the data has been processed
      Promise.resolve().then(() => this.reportsTreeControl.expand(node));
    }

    return node;
  };

  projectsTreeControl = new FlatTreeControl<FlatProjectNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  projectsTreeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  allProjects: ProjectNode[] = [];
  projects = new MatTreeFlatDataSource(this.projectsTreeControl, this.projectsTreeFlattener);

  public reportsTreeFlattener: MatTreeFlattener<Dashboard, DashboardNode> = new MatTreeFlattener(
    this.getTransformer,
    this.getLevel,
    this.isExpandable,
    this.getChildren
  );
  public reportsTreeControl: FlatTreeControl<DashboardNode> = new FlatTreeControl(
    this.getLevel,
    this.isExpandable
  );
  public reports: MatTreeFlatDataSource<Dashboard, DashboardNode> = new MatTreeFlatDataSource(
    this.reportsTreeControl,
    this.reportsTreeFlattener
  );

  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  constructor(
    private linkResolver: LinkResolver,
    private fleetViewDataService: FleetViewDataService,
    public changeDetector: ChangeDetectorRef,
    private hierarchyService: HierarchyService,
    private hierarchyEffects: HierarchyEffects,
    private store$: Store,
    private dashboardsService: DashboardNavigationService,
    private dispatcher: Dispatcher,
    private actions$: Actions
  ) {
    this.tabGroup = {} as MatTabGroup;
  }

  ngOnInit(): void {
    this.init();
    this.dashboardsService.dashboardsUpdateStarting$.subscribe(() => {
      this.isReportsDataReady = false;
      this.changeDetector.detectChanges();
    });

    this.dashboardsService.dashboardsUpdate$
      .pipe(tap(() => this.getCustomerReports(this.currentCustomer as string)))
      .subscribe();
  }

  onTabChange(tabIndex: number): void {
    switch (tabIndex) {
      case this.PROJECT_TAB_IDX:
        this.loadHierarchy$().subscribe();
        break;
      case this.REPORTS_TAB_IDX:
        this.getCustomerReports(this.currentCustomer as string);
        break;
    }
  }

  projectHasMotors(_: number, node: FlatProjectNode): boolean {
    return node.expandable === true;
  }

  loadHierarchy$(): Observable<any> {
    this.isProjectsLoading = true;
    return this.hierarchyService.getHierarchyObservable().pipe(
      map((hierarchy) => {
        const motorResponse = this.hierarchyEffects.mapMotorsGroups(hierarchy);
        this.fleetViewDataService.continentsCollection$.next(motorResponse.continents);
        return motorResponse;
      }),
      tap((hierarchy: IProjectHierarchyWithStatus) => {
        const tempNodes: ProjectNode[] = [];
        hierarchy.continents.forEach((c) => {
          c.countries.forEach((country) => {
            country.projects.forEach((project) => {
              const projectNode = {
                title: project.name,
                area: project.productionArea,
                level: 0,
                customer: project.name,
                defaultReport: project.defaultReport,
                children: [],
                shouldBeExpanded: project.name === this.currentCustomer,
                rootPath: project.rootPath
              } as ProjectNode;

              project.systems.forEach((system) => {
                projectNode.children?.push({
                  title: system.name,
                  area: project.productionArea,
                  customer: project.name,
                  level: 1,
                  defaultReport: system.defaultReport,
                  children: [],
                  shouldBeExpanded:
                    projectNode.shouldBeExpanded && system.name === this.currentMotor,
                  rootPath: system.rootPath
                } as ProjectNode);
              });

              tempNodes?.push(projectNode);
            });
          });
        });

        this.allProjects = tempNodes;
        this.projects.data = tempNodes;
        this.isProjectsLoading = false;
        this.changeDetector.detectChanges();
      }),
      catchError((error) =>
        of(
          this.dispatcher.dispatch(
            ErrorCatchingActions.catchError({
              messageToDisplay: error.message,
              error: error.stack,
              autoClose: true
            })
          )
        )
      ),
      takeUntil(this.destroyComponent$)
    );
  }

  shouldShowReportsTab(): boolean {
    return (
      this.currentReportId !== (FLEET_VIEW_REPORT_ID as string) &&
      this.currentReportId !== (FLEET_OVERVIEW_REPORT_ID as string)
    );
  }

  /**
   * Initialization
   */
  init(): void {
    this.actions$
      .pipe(
        ofType(AppStatusActions.startLoadingReport),
        tap(({ reportTag, queryParams }) => {
          this.currentReportId = reportTag as string;
          this.currentRootPath = queryParams?.get("rootPath") as string;

          if (this.shouldShowReportsTab()) {
            this.tabGroup.selectedIndex = this.REPORTS_TAB_IDX;
          } else {
            this.tabGroup.selectedIndex = this.PROJECT_TAB_IDX;
          }
          this.changeDetector.detectChanges();
        }),
        takeUntil(this.destroyComponent$)
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AppStatusActions.expandSidebar),
        tap(() => {
          this.loadHierarchy$().subscribe();

          if (!!this.currentCustomer) {
            this.getCustomerReports(this.currentCustomer as string);
          }
        }),
        takeUntil(this.destroyComponent$)
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AppStatusActions.openSidebar),
        tap(() => {
          this.loadHierarchy$().subscribe();
          if (!!this.currentCustomer) {
            this.getCustomerReports(this.currentCustomer as string);
          }
        }),
        takeUntil(this.destroyComponent$)
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(CommonActions.startCreatingReport),
        tap(() => {
          this.isReportsDataReady = false;
        }),
        takeUntil(this.destroyComponent$)
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(CommonActions.reportCreated),
        tap(() => {
          this.loadHierarchy$().subscribe();
          this.getCustomerReports(this.currentCustomer as string);
        }),
        takeUntil(this.destroyComponent$)
      )
      .subscribe();
  }

  getCustomerReports(projectName: string): void {
    this.isReportsDataReady = false;
    this.reportOpenedNodes = this.reportsTreeControl.expansionModel.selected;

    this.fleetViewDataService
      .getReportTree(projectName)
      .pipe(
        tap((response: IClientData) => {
          this.isEditor = !response.readOnly;
          this.orgFullName = response.orgFullName ?? "";

          const dashboards: Dashboard[] = [];

          const filteredReports = response?.dashboards?.filter(
            (report) => report.link !== FLEET_VIEW_REPORT_ID
          );

          filteredReports?.forEach((item, index) => {
            if (item.type === DashboardType.Child || item.type === DashboardType.Grandchild) {
              item.parentName = item.parent;
            }
            item.readOnly = response.readOnly;
            dashboards[index] = item;
          });

          this.dashboards = [...dashboards];
          this.reports.data = this.mapDashboardsArrays(dashboards);

          this.reportOpenedNodes.forEach((node) => this.reportsTreeControl.expand(node));
          this.isReportsDataReady = true;

          this.changeDetector.detectChanges();
        }),
        takeUntil(this.destroyComponent$)
      )
      .subscribe();
  }

  public addReport(): void {
    this.store$.dispatch(
      CreateReportDialogActions.openCreateReportDialog({
        reportDialogInfo: {
          creationType: ReportCreationType.New,
          existingReportNames: this.reports.data.map((item) => item.title)
        }
      })
    );
  }

  filterReportsTree(filterText: string): void {
    this.filterText = filterText.toLowerCase();
    const filteredData = this.filterReportsNodes(filterText, _cloneDeepWith(this.dashboards));
    this.reports.data = this.mapDashboardsArrays(filteredData);
    this.reportsTreeControl.expandAll();
    this.changeDetector.detectChanges();
  }

  filterProjectsTree(filterText: string): void {
    const filteredData = this.filterProjectsNodes(filterText, _cloneDeepWith(this.allProjects));
    this.projects.data = filteredData;
    this.projectsTreeControl.expandAll();
    this.changeDetector.detectChanges();
  }

  // Utility method to filter nodes
  private filterReportsNodes(filterText: string, nodes: Dashboard[]): Dashboard[] {
    const filteredNodes = nodes.filter((node) => {
      // Check if current node matches the filter and should be visible
      const matchesFilter = node.title.toLowerCase().includes(filterText.toLowerCase());

      // Recursively check for children that match the filter
      if (node.children) {
        node.children = this.filterReportsNodes(filterText, node.children);
      }

      // Node should be visible if it matches, or if it has children that match
      return matchesFilter || (node.children && node.children.length > 0);
    });

    return filteredNodes;
  }

  private filterProjectsNodes(filterText: string, nodes: ProjectNode[]): ProjectNode[] {
    const filteredNodes = nodes.filter((node) => {
      // Check if current node matches the filter and should be visible
      const matchesFilter = node.title.toLowerCase().includes(filterText.toLowerCase());

      // Recursively check for children that match the filter
      if (node.children) {
        node.children = this.filterProjectsNodes(filterText, node.children);
      }

      // Node should be visible if it matches, or if it has children that match
      return matchesFilter || (node.children && node.children.length > 0);
    });

    return filteredNodes;
  }

  /**
   * Whether to apply active CSS style for dashboard currently open.
   */
  public isActive(dashboard: Dashboard): boolean {
    return this.dashboardsService.isDashboardActive(dashboard.link);
  }

  public isNodeActive(project: ProjectNode): boolean {
    return this.currentCustomer === project?.customer && this.currentMotor === project.title;
  }

  onProjectNodeClick(project: ProjectNode): void {
    this.linkResolver.openReportInSameWindow(
      new ReportLinkDto({
        info: {
          reportId: project.defaultReport as ReportId,
          reportName: project.defaultReport
        }
      }),
      `rootPath=${project.rootPath}`
    );
  }

  /**
   * Whether the node has a children array. Method used in the template only.
   */
  public hasNestedChild(_: number, dashboard: Dashboard): boolean {
    const children = dashboard.children ?? [];
    return children.length > 0;
  }

  /**
   * Return CSS class for dashboard element based on current drag position.
   */
  public getDragStyle(dashboard: DashboardNode): string {
    if (
      this.dragNodeExpandOverArea === DragPosition.Above &&
      this.dragNodeExpandOverNode === dashboard
    ) {
      return "drop-above";
    }
    if (
      this.dragNodeExpandOverArea === DragPosition.Center &&
      this.dragNodeExpandOverNode === dashboard
    ) {
      return "drop-center";
    }
    if (
      this.dragNodeExpandOverArea === DragPosition.Below &&
      this.dragNodeExpandOverNode === dashboard
    ) {
      return "drop-below";
    }

    return "";
  }

  /**
   * Disable other modes and turn on only the clicked one.
   */
  public toggleModes(button: NavigationButton): void {
    this.isDeleteModeOn = button === NavigationButton.Delete && !this.isDeleteModeOn;
    this.isDragModeOn =
      button === NavigationButton.Drag && !this.isDragModeOn && !this.isSearchModeOn;
    this.isRenameModeOn = button === NavigationButton.Rename && !this.isRenameModeOn;

    this.informationText = this.getInformationText(button);
    this.dashboardsService.closeTooltips();

    if (button === NavigationButton.Add) {
      this.addReport();
    }

    if (!this.isFormShown) {
      this.dashboardsService.onFormClosed.next();
    }

    this.dashboardsService.isCustomizeMode.next(this.isRenameModeOn);
  }

  toggleCustomizeMode(button: NavigationButton): void {
    this.toggleModes(button);
    this.isCustomizeMode = !this.isCustomizeMode;
    this.dashboardsService.isCustomizeMode.next(this.isCustomizeMode);
  }

  /**
   * Update the dashboards configuration with tree control structure (flat).
   */
  public sendRearrangement(): void {
    this.dashboardsService
      .updateReportTree$(this.reportsTreeControl.dataNodes)
      .pipe(takeUntil(this.destroyComponent$))
      .subscribe();

    // this.convertDataToReportsType(this.reportsTreeControl.dataNodes);
  }

  /**
   * Set the current dragged node and collapse it's children.
   * Remove drag container (preview of an element) to make it more usable.
   */
  public handleDragStart(event: DragEvent, node: DashboardNode): void {
    if (!event.dataTransfer) {
      return;
    }

    event.dataTransfer.setDragImage(document.createElement("div"), 0, 0);

    this.dragNode = node;
    this.reportOpenedNodes = this.getExpandedNodes();
    this.collapseWithChildren(node);
  }

  /**
   * Add item to dashboards/children based on type of the drop.
   * Remove previous instance of the dragged node.
   */
  public handleDrop(event: DragEvent, droppedOn: DashboardNode): void {
    event.preventDefault();

    if (!this.dragNode || !this.dragNodeExpandOverArea) {
      return;
    }

    const isDragCorrect = isDashboardDropCorrect(
      droppedOn,
      this.dragNode,
      this.dragNodeExpandOverArea
    );

    if (!isDragCorrect) {
      this.handleDragEnd();
      return;
    }

    const originalDashboard = this.findDraggedNode(this.dragNode);
    const nodeCopy = _cloneDeepWith(originalDashboard);

    if (nodeCopy && originalDashboard) {
      originalDashboard.hasBeenMoved = true;
      this.moveDashboard(nodeCopy, droppedOn);
    }

    this.reports.data = this.removeMovedNodes(this.reports.data);

    this.expandNodes(this.reportOpenedNodes);
    const node = this.findNode(`${droppedOn.link}`);
    if (node) {
      this.reportsTreeControl.expand(node);
    }

    this.handleDragEnd();
  }

  /**
   * Reset all the values related to drag and drop.
   */
  public handleDragEnd(): void {
    this.dragNode = null;
    this.dragNodeExpandOverNode = null;
    this.dragNodeExpandOverTime = 0;
    this.reportOpenedNodes = [];

    this.sendRearrangement();
  }

  /**
   * Set the current drag position type and open parent after `dragNodeExpandOverWaitTimeMs`.
   */
  public handleDragOver(event: DragEvent | any, node: DashboardNode): void {
    event.preventDefault();

    if (node === this.dragNodeExpandOverNode) {
      if (this.dragNode !== node && !this.reportsTreeControl.isExpanded(node)) {
        if (
          new Date().getTime() - this.dragNodeExpandOverTime >
          this.dragNodeExpandOverWaitTimeMs
        ) {
          this.reportsTreeControl.expand(node);
        }
      }
    } else {
      this.dragNodeExpandOverNode = node;
      this.dragNodeExpandOverTime = new Date().getTime();
    }

    const percentageY = event.offsetY / event.target.clientHeight;
    if (percentageY < 0.25) {
      this.dragNodeExpandOverArea = DragPosition.Above;
    } else if (percentageY > 0.75) {
      this.dragNodeExpandOverArea = DragPosition.Below;
    } else {
      this.dragNodeExpandOverArea = DragPosition.Center;
    }
  }

  /**
   * Find a dashboard that has been dragged by the user in the data source.
   */
  private findDraggedNode(dashboard: Dashboard | null): Dashboard | null {
    switch (dashboard?.type) {
      case DashboardType.Parent: {
        const data = this.reports.data.find((node) => node.link === dashboard.link);
        return data ? data : null;
      }
      case DashboardType.Child: {
        const parent = this.reports.data.find((node) => node.link === dashboard.parentName);
        const parentChildren = parent?.children?.find((node) => node.link === dashboard.link);
        return parentChildren ? parentChildren : null;
      }
      case DashboardType.Grandchild: {
        const grandParent = this.reports.data.find((parentItem) =>
          parentItem?.children?.some((child) => child.link === dashboard.parentName)
        );
        const parent = grandParent?.children?.find(
          (grandparent) => grandparent.link === dashboard.parentName
        );
        const parentChildren = parent?.children?.find((node) => node.link === dashboard.link);
        return parentChildren ? parentChildren : null;
      }
      default:
        return null;
    }
  }

  /**
   * Remove node that has been moved and it's children.
   */
  private removeMovedNodes(dashboards: Dashboard[]): Dashboard[] {
    return dashboards.filter((node) => {
      const nodeChildren = node && node.children ? node.children : [];
      if (node.hasBeenMoved && nodeChildren.length > 0) {
        nodeChildren?.forEach((child) => (child.hasBeenMoved = true));
      }

      node.children = this.removeMovedNodes(nodeChildren);

      return !node.hasBeenMoved;
    });
  }

  /**
   * Move the dashboard position based on the drag type.
   */
  private moveDashboard(draggedNode: DashboardNode, droppedOn: DashboardNode): void {
    switch (this.dragNodeExpandOverArea) {
      case DragPosition.Above: {
        this.moveDashboardNextToSibling(draggedNode, droppedOn, true);
        break;
      }
      case DragPosition.Below: {
        this.moveDashboardNextToSibling(draggedNode, droppedOn, false);
        break;
      }
      case DragPosition.Center: {
        this.moveDashboardIntoParent(draggedNode, droppedOn);
        break;
      }
    }
  }

  /**
   * Return child type based on the parent's type.
   */
  private getChildType(parent: DashboardNode): DashboardType {
    return parent === undefined
      ? DashboardType.Parent
      : parent.type === DashboardType.Parent
      ? DashboardType.Child
      : DashboardType.Grandchild;
  }

  /**
   * Moves the dashboard into the parent's children array. Moves all children with it.
   * Update children into grandchildren.
   */
  private moveDashboardIntoParent(movedNode: DashboardNode, parent: DashboardNode): void {
    this.insertChildIntoParent(parent, movedNode);
    const moveNode = movedNode && movedNode.children ? movedNode.children : [];
    if (moveNode.length > 0) {
      moveNode.forEach((child: DashboardNode) => this.updateChild(child, movedNode));
    }
  }

  /**
   * Add item to the parent children's list.
   */
  private insertChildIntoParent(parent: DashboardNode, child: DashboardNode): void {
    child.type = this.getChildType(parent);
    child.parentName = child.parent = parent.link;
    parent?.children?.push(child);
  }

  /**
   * Drag and drop happened Above or Below. Update dashboard's children as well.
   * Add item below or above in array (data source or another dashboard's children array).
   */
  private moveDashboardNextToSibling(
    node: DashboardNode,
    sibling: DashboardNode,
    insertAbove?: boolean
  ): void {
    this.insertNextToSibling(sibling, node, !!insertAbove);
    const nodeChildren = node && node.children ? node.children : [];
    if (nodeChildren.length > 0) {
      nodeChildren.forEach((child: DashboardNode) => this.updateChild(child, node));
    }
  }

  /**
   * Add item to parent's children list or directly to data source (as a parent).
   */
  private insertNextToSibling(
    sibling: DashboardNode,
    draggedNode: DashboardNode,
    insertAbove: boolean
  ): void {
    const MOVE_BY = insertAbove ? 0 : 1;
    const parentNode = this.findNode(`${sibling.parentName}`);
    if (parentNode) {
      draggedNode.type = this.getChildType(parentNode);
      draggedNode.parentName = draggedNode.parent = sibling.parentName;
    }

    const shouldMoveIntoDataSource = parentNode === undefined;

    if (shouldMoveIntoDataSource) {
      draggedNode.type = DashboardType.Parent;

      const index = this.reports.data.findIndex(
        (dashboard: DashboardNode) => dashboard.link === sibling.link
      );
      this.reports.data.splice(index + MOVE_BY, 0, draggedNode);
      _unset(draggedNode, "parent");
    } else {
      const siblingIndex = parentNode?.children?.findIndex(
        (parentChild: DashboardNode) => parentChild.link === sibling.link
      );
      parentNode?.children?.splice(Number(siblingIndex) + MOVE_BY, 0, draggedNode);
    }
  }

  /**
   * Update child type, parent (link) and it's children.
   */
  private updateChild(child: DashboardNode, parent: DashboardNode): void {
    child.type = this.getChildType(parent);
    child.parentName = child.parent = parent.link;
    const dashboardChildren = child && child.children ? child.children : [];
    if (dashboardChildren.length > 0) {
      dashboardChildren.forEach((grandchild: DashboardNode) => this.updateChild(grandchild, child));
    }
  }

  /**
   * Collapses node and all of its children.
   */
  private collapseWithChildren(node: DashboardNode): void {
    this.reportsTreeControl.collapse(node);
    const nodeChildren = node && node.children ? node.children : [];
    if (nodeChildren.length > 0) {
      nodeChildren.forEach((child: DashboardNode) => this.collapseWithChildren(child));
    }
  }

  /**
   * Returns which nodes has been expanded prior any update.
   */
  private getExpandedNodes(): DashboardNode[] {
    return this.reportsTreeControl.dataNodes
      .reduce((nodes: DashboardNode[], node: DashboardNode) => {
        return this.reportsTreeControl.isExpanded(node) ? [...nodes, node] : nodes;
      }, [])
      .sort((a, b) => Number(a.level) - Number(b.level));
  }

  /**
   * Expand nodes which were previously expanded (before any update).
   */
  private expandNodes(nodes: DashboardNode[]): void {
    nodes.forEach((node) => {
      const dataTreeNode = this.findNode(`${node.link}`);
      if (dataTreeNode) {
        this.reportsTreeControl.expand(dataTreeNode);
      }
    });
  }

  /**
   * Array from back-end comes back as flat. Rebuild it into tree structure.
   */
  private mapDashboardsArrays(dashboards: Dashboard[]): Dashboard[] {
    const sortedReports = this.sortReports(dashboards);
    return sortedReports.reduce(
      (array: Dashboard[], dashboard: Dashboard) => this.rebuildTreeStructure(array, dashboard),
      []
    );
  }
  /**
   * Sort Reports collection
   */
  sortReports(dashboards: Dashboard[]): Dashboard[] {
    return dashboards.sort((a, b) => this.getLevel(a) - this.getLevel(b));
  }

  /**
   * Recreate the structure of dashboards from flat array.
   * MatTree methods require a node to have quick access to children through node.
   */
  private rebuildTreeStructure(dashboards: Dashboard[], dashboard: Dashboard): Dashboard[] {
    dashboard.children = [];

    switch (dashboard.type) {
      case DashboardType.Parent: {
        dashboards.push(dashboard);
        break;
      }
      case DashboardType.Child: {
        const parent = dashboards.find(
          (parentDashboard) => parentDashboard.link === dashboard.parentName
        );
        const parentChildren = parent && parent.children ? parent.children : [];
        parentChildren.push(dashboard);

        if (dashboard.link === this.currentReportId && parent) {
          parent.shouldBeExpanded = true;
        }

        break;
      }
      case DashboardType.Grandchild: {
        const grandParent = dashboards.find((grandParentItem) =>
          grandParentItem?.children?.some((child) => child.link === dashboard.parentName)
        );
        const grandparentChildren = grandParent && grandParent.children ? grandParent.children : [];
        const parent = grandparentChildren.find(
          (parentItem) => parentItem.link === dashboard.parentName
        );
        parent?.children?.push(dashboard);

        if (dashboard.link === this.currentReportId && parent) {
          parent.shouldBeExpanded = true;
        }

        if (grandParent && parent?.shouldBeExpanded) {
          grandParent.shouldBeExpanded = true;
        }

        break;
      }
    }

    return dashboards;
  }

  /**
   * Return instructions for the user for a given mode.
   */
  private getInformationText(button: NavigationButton): string {
    return button === NavigationButton.Delete ? "Delete Mode Is On" : "Drag Mode Is On";
  }

  /**
   * Find node in the tree control array.
   */
  private findNode(link: string): DashboardNode | undefined {
    const treeNodes = this.reportsTreeControl.dataNodes ? this.reportsTreeControl.dataNodes : [];
    return treeNodes.find((node: DashboardNode) => node.link === link);
  }

  /**
   * Get level of the Dashboard based on its type.
   */
  private getLevel(dashboard: DashboardNode): number {
    return dashboard.type !== DashboardType.Parent
      ? dashboard.type !== DashboardType.Child
        ? 2
        : 1
      : 0;
  }

  /**
   * Get children of the `DashboardNode`.
   */
  private getChildren(dashboard: DashboardNode): Dashboard[] {
    return dashboard.children ? dashboard.children : [];
  }

  /**
   * Returns true if dashboard type is not a Grandchild.
   */
  private isExpandable(dashboard: DashboardNode): boolean {
    return dashboard.type !== DashboardType.Grandchild;
  }

  ngOnDestroy(): void {
    this.destroyComponent$.next();
    this.destroyComponent$.complete();
  }
}
