import { FILTERING_AND_AGGREGATION__OVERRIDING_TIME_FILTERS } from "../../elements/models/help-constants";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Configurable } from "../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Placeholder } from "../../meta/decorators/placeholder.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType, PropertyCategory } from "../../meta/models";
import {
  StandardPresets,
  TimeModeSpecificPresets,
  TimePresetType
} from "../../shared/models/time-preset-filter";
import { isDefined, isEmptyOrNotDefined, isNotDefined } from "../../ts-utils";
import { validateInputForFilterExpression } from "../helpers/filter/filter-validation.helper";
import { construct } from "../services/construct.helper";
import { DataTransferObject } from "./data-transfer-object";

const TYPE_NAME = "TimeRangeConfigurationDto";

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class TimeRangeConfigurationDto implements DataTransferObject {
  typeName = TYPE_NAME;

  @Serializable(StandardPresets.Last4hours)
  timePreset!: TimePresetType;

  @Serializable()
  dayStart!: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.Filter)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.From,
    editorType: EditorType.TextBox,
    validationFunction: validateInputForFilterExpression,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.FilterConfigTooltip,
    userHelp: FILTERING_AND_AGGREGATION__OVERRIDING_TIME_FILTERS
  })
  @Serializable("")
  @Placeholder({
    text: LOCALIZATION_DICTIONARY.propertySheet.Inherited,
    combineWithRuntimeValue: false
  })
  fromExpression?: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.Filter)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.To,
    editorType: EditorType.TextBox,
    validationFunction: validateInputForFilterExpression,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.FilterConfigTooltip,
    userHelp: FILTERING_AND_AGGREGATION__OVERRIDING_TIME_FILTERS
  })
  @Serializable("")
  @Placeholder({
    text: LOCALIZATION_DICTIONARY.propertySheet.Inherited,
    combineWithRuntimeValue: false
  })
  toExpression?: string;

  constructor(timeRange: Partial<TimeRangeConfigurationDto> = {}) {
    if (isDefined(timeRange) && isNotDefined(timeRange.timePreset)) {
      const timePreset: TimePresetType = isEmptyOrNotDefined(timeRange.toExpression)
        ? TimeModeSpecificPresets.Interval
        : TimeModeSpecificPresets.Custom;
      timeRange = { ...timeRange, timePreset };
    }
    construct(this, timeRange, TYPE_NAME);
  }
}
