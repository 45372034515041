import { Injectable, OnDestroy } from "@angular/core";
import moment from "moment";
import { Subject } from "rxjs";
import { TimeService } from "../../../core/services/time.service";
import { Dispatcher } from "../../../dispatcher";
import {
  extractLiveModeParams,
  isUnitBiggerThanHours
} from "../../../elements/services/global-filter.helper";
import { LocalizationService } from "../../../i18n/localization.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EntityId } from "../../../meta/models/entity";
import { resolveLiveModeFilter } from "../../../shared/helpers/live-mode-time-unit.helper";
import {
  deriveTimeModeFromPreset,
  shouldClearEndOfTimeRange
} from "../../../shared/helpers/time-filter.helper";
import { LiveModeFilter } from "../../../shared/models/live-mode-filter";
import {
  getAmountForHoursBasedPreset,
  mapPresetToTimeUnit,
  StandardPresets,
  TimePresetType,
  TimeRangeMode
} from "../../../shared/models/time-preset-filter";
import { isDefined, isNotDefined, Maybe } from "../../../ts-utils";
import { isWhiteSpaceOrNotDefined } from "../../../ts-utils/helpers/string.helper";
import { QueryFilter } from "../..//models/filter/query-filter";
import { isEventRuntimeFilter } from "../../helpers/filter/filter-id.helper";
import {
  isCorrectTimeRangeInterval,
  isTimeBeforeCurrent
} from "../../helpers/filter/filter-validation.helper";
import { Filter } from "../../models/filter/filter";
import { FilterConfigurationDto } from "../../models/filter/filter-configuration";
import { TimeRange } from "../../models/time-range";
import { TimeRangeConfigurationDto } from "../../models/time-range-configuration";
import { TimeUnit } from "../../models/time-unit";
import { HOUR_TO_MS } from "../../models/time.constants";
import { ErrorCatchingActions } from "../../store/error-catching/error-catching.actions";
import { DateExpressionParser } from "./date-expression-parser";
import { IFilterSelector } from "./i-filter.selector";

// TODO: cleanup after incremental query refactoring
@Injectable()
export class FilterFactory implements OnDestroy {
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private timeService: TimeService,
    private filterSelector: IFilterSelector,
    private dispatcher: Dispatcher,
    private translationService: LocalizationService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  isFilterValid(filter: Filter): boolean {
    return isCorrectTimeRangeInterval(filter.timeRange?.from, filter.timeRange?.to);
  }

  createQueryFiltersById(filterIds: EntityId[]): QueryFilter[] {
    const filterConfigs = this.filterSelector.getManyById(filterIds);
    return this.createQueryFilters(filterConfigs);
  }

  createQueryFilters(filterConfigs: FilterConfigurationDto[]): QueryFilter[] {
    return filterConfigs
      .map((filterConfig) => this.createQueryFilter(filterConfig))
      .filter(isDefined);
  }

  createQueryFilterById(filterId: EntityId): Maybe<QueryFilter> {
    const filterConfig = this.filterSelector.getById(filterId);
    return this.createQueryFilter(filterConfig);
  }

  createQueryFilter(
    filterConfig: FilterConfigurationDto,
    sourceFilterConfig?: FilterConfigurationDto
  ): Maybe<QueryFilter> {
    const queryFilter = this.createQueryFilterFromConfiguration(filterConfig, sourceFilterConfig);
    if (!this.isFilterValid(queryFilter)) {
      this.displayInvalidConfigurationWarning();
      return null;
    }

    if (shouldClearEndOfTimeRange(queryFilter.timePreset)) {
      queryFilter.timeRange.to = null;
    }
    return queryFilter;
  }

  // FIXME Refactor, current code difficult to understand
  // There are many functions in this file with similar names and overlapping logic; it's unclear what should be used
  // This particular function mixes up the levels of abstraction for creating time range in the if and else branches
  createQueryFilterFromConfiguration(
    filterConfig: FilterConfigurationDto,
    sourceFilterConfig?: FilterConfigurationDto
  ): QueryFilter {
    if (isDefined(filterConfig.sourceFilterId)) {
      const sourceConfig =
        sourceFilterConfig || this.filterSelector.getByIdOrDefault(filterConfig.sourceFilterId);
      const sourceFilter = this.createQueryFilterFromConfiguration(sourceConfig);
      const merged = this.inheritFilter(filterConfig, sourceFilter);
      return merged;
    } else {
      const timeRange = this.createStandaloneFilterTimeRange(filterConfig.timeRange);
      return new QueryFilter(
        filterConfig.id,
        timeRange,
        filterConfig.timeRange.timePreset,
        filterConfig.customFilters
      );
    }
  }

  private inheritFilter(filterConfig: FilterConfigurationDto, source: QueryFilter): QueryFilter {
    const isEventRuntimeSourceFilter: boolean = isEventRuntimeFilter(filterConfig.sourceFilterId);
    const timeRange = this.createRelativeFilterTimeRange(
      filterConfig.timeRange,
      source.timeRange,
      isEventRuntimeSourceFilter
    );
    const customFilters = this.inheritProperties(filterConfig.customFilters, source.customFilters);
    return new QueryFilter(
      filterConfig.id,
      timeRange,
      filterConfig.timeRange.timePreset,
      customFilters
    );
  }

  private inheritProperties<T>(child: T, parent: T): T {
    return Object.getOwnPropertyNames(parent).reduce((merged: T, key: string) => {
      const childValue = merged[key];
      if (
        isNotDefined(childValue) ||
        (typeof childValue === "string" && isWhiteSpaceOrNotDefined(childValue))
      ) {
        merged[key] = parent[key];
      }
      return merged;
    }, Object.assign({}, child));
  }

  isInLiveMode(filterId: EntityId): boolean {
    //FIXME: add subscription on this selector, not to take it each time from store
    const liveModeFilters = this.filterSelector.getAllInLiveMode();
    return liveModeFilters[filterId] != null;
  }

  createFilterFromConfiguration(filterConfig: FilterConfigurationDto): Filter | null {
    const timeRange = this.createTimeRangeFromFilterConfig(filterConfig);
    if (isNotDefined(timeRange)) {
      this.displayInvalidConfigurationWarning();
      return null;
    }
    // TODO: Missing logic for calculating the isLiveMode
    return new Filter(filterConfig.id, timeRange, undefined);
  }

  private displayInvalidConfigurationWarning(): void {
    const invalidFilterMessage = this.translationService.get(
      LOCALIZATION_DICTIONARY.snackBarMessages.InvalidFilter
    );
    this.dispatcher.dispatch(
      ErrorCatchingActions.catchWarning({ messageToDisplay: invalidFilterMessage })
    );
  }

  createTimeRangeFromFilterConfig(filterConfig: FilterConfigurationDto): Maybe<TimeRange> {
    const standaloneFilter = !filterConfig.sourceFilterId;
    if (standaloneFilter) {
      return this.createStandaloneFilterTimeRange(filterConfig.timeRange);
    } else {
      const sourceFilterConfig = this.filterSelector.getByIdOrDefault(filterConfig.sourceFilterId);
      const sourceTimeRange = this.createTimeRangeFromFilterConfig(sourceFilterConfig);
      return this.createRelativeFilterTimeRangeAndCheck(filterConfig.timeRange, sourceTimeRange);
    }
  }

  createStandaloneFilterTimeRange(timeRangeConfig: TimeRangeConfigurationDto): Maybe<TimeRange> {
    const timeRangeMode: TimeRangeMode = deriveTimeModeFromPreset(timeRangeConfig.timePreset);
    switch (timeRangeMode) {
      case TimeRangeMode.Live:
        return this.createStandaloneLiveModeTimeRange(timeRangeConfig);
      case TimeRangeMode.Historical:
        return this.createStandaloneFixedTimeRange(timeRangeConfig);
      default:
        return this.createStandaloneStandardPresetTimeRange(timeRangeConfig.timePreset);
    }
  }

  createStandaloneStandardPresetTimeRange(timePreset: TimePresetType): TimeRange {
    const dayStart: moment.Moment = this.timeService.determineDayStart();
    const amount: number = this.getAmountForPreviousPresetShift(timePreset, dayStart);

    switch (timePreset) {
      case StandardPresets.Today:
        return this.resolveStandaloneLiveModeTimeRange(amount, TimeUnit.Days);
      case StandardPresets.ThisWeek:
        return this.resolveStandaloneLiveModeTimeRange(amount, TimeUnit.Weeks);
      case StandardPresets.ThisMonth:
        return this.resolveStandaloneLiveModeTimeRange(amount, TimeUnit.Months);
      case StandardPresets.Yesterday:
        return isTimeBeforeCurrent(dayStart, this.timeService.currentTime)
          ? this.getYesterdayTimeRange()
          : this.resolveStandaloneLiveModeTimeRange(3, TimeUnit.Days);
      case StandardPresets.Last4hours:
        return this.resolveStandaloneLiveModeTimeRange(4, TimeUnit.Hours);
      case StandardPresets.Last24hours:
        return this.resolveStandaloneLiveModeTimeRange(24, TimeUnit.Hours);
    }
  }

  private getAmountForPreviousPresetShift(
    timePreset: TimePresetType,
    dayStart: moment.Moment
  ): number {
    let amount: number = 1;
    if (isTimeBeforeCurrent(dayStart, this.timeService.currentTime)) {
      return amount;
    }
    if (timePreset === StandardPresets.Today) {
      amount = 2;
    }

    if (timePreset === StandardPresets.ThisWeek || timePreset === StandardPresets.ThisMonth) {
      const currentTimeRange = calculateLiveModeTimeRange(
        1,
        timePreset === StandardPresets.ThisWeek ? TimeUnit.Weeks : TimeUnit.Months,
        this.timeService.currentTime,
        dayStart
      );
      amount = currentTimeRange.from < this.timeService.currentTime ? 1 : 2;
    }

    return amount;
  }

  private getYesterdayTimeRange(): TimeRange {
    const dayStart: moment.Moment = this.timeService.determineDayStart();
    const endOfCurrentDay = moment(this.timeService.currentTime)
      .set({
        hour: dayStart.hour(),
        minute: dayStart.minute(),
        second: dayStart.second()
      })
      .toDate();

    return calculateLiveModeTimeRange(2, TimeUnit.Days, endOfCurrentDay, dayStart);
  }

  private createStandaloneLiveModeTimeRange(
    timeRangeConfig: TimeRangeConfigurationDto
  ): Maybe<TimeRange> {
    const liveModeFilter = this.createStandaloneLiveModeFilter(timeRangeConfig);
    if (isNotDefined(liveModeFilter)) {
      return null;
    }
    return this.resolveStandaloneLiveModeTimeRange(liveModeFilter.amount, liveModeFilter.unit);
  }

  public createStandaloneLiveModeFilter(
    timeRangeConfig: TimeRangeConfigurationDto
  ): Maybe<LiveModeFilter> {
    return extractLiveModeParams(timeRangeConfig.fromExpression);
  }

  public resolveStandaloneLiveModeTimeRange(offset: number, unit: string): TimeRange {
    const toDate = this.timeService.currentTime;
    const shiftStartTime: moment.Moment = this.timeService.determineDayStart();
    return calculateLiveModeTimeRange(
      offset,
      unit as moment.unitOfTime.Base,
      toDate,
      shiftStartTime
    );
  }

  public createStandaloneFixedTimeRange(
    timeRangeConfig: TimeRangeConfigurationDto
  ): Maybe<TimeRange> {
    const parser = new DateExpressionParser();
    const fromDate = parser.createDateFromFixedExpression(timeRangeConfig.fromExpression || "");
    const toDate = parser.createDateFromFixedExpression(timeRangeConfig.toExpression || "");
    if (fromDate && toDate) {
      const timeRange: TimeRange = new TimeRange(fromDate, toDate);
      return timeRange;
    }
    return null;
  }

  private createRelativeFilterTimeRange(
    timeRangeConfig: TimeRangeConfigurationDto,
    sourceTimeRange: Maybe<TimeRange>,
    isEventRuntimeSourceFilter?: boolean
  ): Maybe<TimeRange> {
    if (isNotDefined(sourceTimeRange)) {
      return null;
    }
    const parser = new DateExpressionParser();
    const startDate = this.shouldTakeFromSourceTimeRange(
      isEventRuntimeSourceFilter,
      timeRangeConfig.fromExpression
    )
      ? sourceTimeRange.from
      : parser.createDateFromExpression(timeRangeConfig.fromExpression || "", sourceTimeRange);

    const endDate = this.shouldTakeFromSourceTimeRange(
      isEventRuntimeSourceFilter,
      timeRangeConfig.toExpression
    )
      ? sourceTimeRange.to
      : parser.createDateFromExpression(timeRangeConfig.toExpression || "", sourceTimeRange);
    return startDate != null && endDate != null ? new TimeRange(startDate, endDate) : null;
  }

  private shouldTakeFromSourceTimeRange(
    isEventRuntimeSourceFilter: Maybe<boolean>,
    expression: Maybe<string>
  ): boolean {
    return isEventRuntimeSourceFilter || isWhiteSpaceOrNotDefined(expression);
  }

  private createRelativeFilterTimeRangeAndCheck(
    timeRangeConfig: TimeRangeConfigurationDto,
    sourceTimeRange: TimeRange | null | undefined
  ): Maybe<TimeRange> {
    const timeRange = this.createRelativeFilterTimeRange(timeRangeConfig, sourceTimeRange);
    if (isNotDefined(timeRange) || !isCorrectTimeRangeInterval(timeRange.from, timeRange.to)) {
      return null;
    }
    return timeRange;
  }

  public createStandaloneTimeRangeConfiguration(
    timeRange: TimeRange,
    liveMode = false
  ): TimeRangeConfigurationDto {
    const parser = new DateExpressionParser();
    if (liveMode) {
      const spanInHours = calculateSpanInHours(timeRange);
      return parser.createStandaloneFilterLiveModeExpressions(spanInHours);
    } else {
      return this.createTimeRangeConfiguration(timeRange);
    }
  }

  public createTimeRangeConfiguration(timeRange: TimeRange): TimeRangeConfigurationDto {
    const parser = new DateExpressionParser();
    const fromExpression = parser.createExpressionFromDate(timeRange.from);
    const toExpression = parser.createExpressionFromDate(timeRange.to);
    return new TimeRangeConfigurationDto({ fromExpression, toExpression });
  }

  public createTimeRangeFromLiveModeFilter(
    liveModeFilter: Maybe<LiveModeFilter>,
    timePreset: TimePresetType
  ): TimeRangeConfigurationDto {
    const parser = new DateExpressionParser();
    return parser.createFilterLiveModeExpressions(liveModeFilter, timePreset);
  }

  getTimeRangeForStandardPreset(timePreset: TimePresetType): Maybe<TimeRangeConfigurationDto> {
    const timeRangeConfiguration: Maybe<TimeRangeConfigurationDto> =
      this.mapTimePresetToRange(timePreset);
    if (isNotDefined(timeRangeConfiguration)) {
      return null;
    }
    return { ...timeRangeConfiguration, timePreset };
  }

  private mapTimePresetToRange(timePreset: TimePresetType): Maybe<TimeRangeConfigurationDto> {
    const parser = new DateExpressionParser();
    switch (timePreset) {
      case StandardPresets.Today:
      case StandardPresets.ThisWeek:
      case StandardPresets.ThisMonth:
        return this.generateLiveModeExpressionFromUnitStart(
          1,
          mapPresetToTimeUnit(timePreset),
          parser
        );
      case StandardPresets.Yesterday:
        return this.createTimeRangeConfiguration(this.getYesterdayTimeRange());
      case StandardPresets.Last4hours:
      case StandardPresets.Last24hours:
        return parser.createFilterLiveModeExpressions(
          {
            amount: getAmountForHoursBasedPreset(timePreset),
            unit: TimeUnit.Hours
          },
          timePreset
        );
      default:
        return null;
    }
  }

  private generateLiveModeExpressionFromUnitStart(
    amount: number,
    unit: TimeUnit,
    parser: DateExpressionParser
  ): TimeRangeConfigurationDto {
    const timeRange = this.resolveStandaloneLiveModeTimeRange(amount, unit);
    const spanInHours = calculateSpanInHours({
      from: timeRange.from,
      to: timeRange.to
    });
    return parser.createStandaloneFilterLiveModeExpressions(parseFloat(spanInHours.toFixed(2)));
  }

  computeTimeRangeByMode(
    timePreset: TimePresetType,
    liveModeFilter: Maybe<LiveModeFilter>,
    historicalTimeRange: TimeRange,
    timeRangeConfig: Maybe<TimeRangeConfigurationDto> = null
  ): TimeRangeConfigurationDto {
    const timeRangeMode: TimeRangeMode = deriveTimeModeFromPreset(timePreset);
    let timeRangeConfiguration: Maybe<TimeRangeConfigurationDto> = null;
    if (timeRangeMode === TimeRangeMode.Live) {
      timeRangeConfiguration = this.getLiveTimeRangeConfig(
        timePreset,
        liveModeFilter,
        timeRangeConfig
      );
    } else if (timeRangeMode === TimeRangeMode.Historical) {
      timeRangeConfiguration = this.createTimeRangeConfiguration(historicalTimeRange);
    } else {
      timeRangeConfiguration = this.getTimeRangeForStandardPreset(timePreset);
    }
    if (isDefined(timeRangeConfiguration)) {
      return { ...timeRangeConfiguration, timePreset };
    }
  }

  private getLiveTimeRangeConfig(
    timePreset: TimePresetType,
    liveModeFilter: Maybe<LiveModeFilter>,
    currentTimeRangeConfig: Maybe<TimeRangeConfigurationDto>
  ): TimeRangeConfigurationDto {
    let liveModeFilterParams = liveModeFilter;
    if (isDefined(currentTimeRangeConfig)) {
      const timeRange = this.createStandaloneFilterTimeRange(currentTimeRangeConfig);
      liveModeFilterParams = resolveLiveModeFilter(
        timeRange ?? new TimeRange(new Date(), new Date())
      );
    }
    return this.createTimeRangeFromLiveModeFilter(liveModeFilterParams, timePreset);
  }
}

export function calculateSpanInHours(timeRange: TimeRange): number {
  const difference = timeRange.to.getTime() - timeRange.from.getTime();
  return difference / HOUR_TO_MS;
}

export function calculateLiveModeTimeRange(
  amount: number,
  unit: moment.unitOfTime.Base,
  now: Date,
  dayStart: moment.Moment
): TimeRange {
  const fromMoment = isUnitBiggerThanHours(unit)
    ? getRoundedStartDateForInterval(amount, unit, now, dayStart)
    : moment(now).add(-amount, unit);
  return new TimeRange(fromMoment.toDate(), now);
}

function getRoundedStartDateForInterval(
  amount: number,
  unit: moment.unitOfTime.Base,
  now: Date,
  dayStart: moment.Moment
): moment.Moment {
  const startOf: moment.unitOfTime.StartOf =
    unit === "week" || unit === "weeks" || unit === "w" ? "isoWeeks" : unit; // always start week on Monday
  const roundedStart = moment(now)
    .add(-(amount - 1), unit)
    .startOf(startOf);
  const startDate = roundedStart.set({
    hour: dayStart.hour(),
    minute: dayStart.minute(),
    second: dayStart.second()
  });
  return startDate;
}
