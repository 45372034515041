import { ChangeDetectorRef, Component, ElementRef, HostBinding, OnInit } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";
import { Dispatcher } from "../../../dispatcher";
import {
  COLOR_PICKER_DIALOG_HEIGHT,
  COLOR_PICKER_DIALOG_WIDTH,
  isValidColorFormat
} from "../../../elements/helpers/color.helper";
import { LocalizationService } from "../../../i18n/localization.service";
import { TypeProvider } from "../../../meta";
import { OfType } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models";
import { ColorPickerDialogActions } from "../../../shared/dialogs/actions/color-picker-dialog.actions";
import { ColorPickerDialogInfo } from "../../../shared/models/color-picker-dialog-info";
import { isDefined } from "../../../ts-utils";
import { focusItemInput } from "../../helpers/input-editor-helper";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "color-picker-editor",
  templateUrl: "color-picker-editor.component.html",
  styleUrls: ["color-picker-editor.component.scss"]
})
@OfType(EditorType.ColorPicker)
export class ColorPickerEditorComponent extends BaseEditorComponent implements OnInit {
  public color: string;
  public isColorFormatValid: boolean = true;
  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }
  private unsubscribeSubject$: Subject<void> = new Subject();

  constructor(
    protected typeProvider: TypeProvider,
    protected cdr: ChangeDetectorRef,
    public localizer: LocalizationService,
    public elementRef: ElementRef,
    public dispatcher: Dispatcher,
    private actions$: Actions
  ) {
    super(cdr, typeProvider, localizer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.color = this.value;
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  refreshValue(value): void {
    super.refreshValue(value);
    this.color = value;
  }

  onInputChange(event): void {
    const newColor: string = event.target.value;
    this.isColorFormatValid = isValidColorFormat(newColor);
    if (this.isColorFormatValid) {
      this.color = newColor;
      this.onValueChanged(this.color);
    }
  }

  openColorPicker(event: MouseEvent): void {
    const top: number =
      window.innerHeight - event.pageY < COLOR_PICKER_DIALOG_HEIGHT
        ? Math.max(event.pageY - COLOR_PICKER_DIALOG_HEIGHT, 0)
        : event.pageY;
    const left: number =
      window.innerWidth - event.pageX < COLOR_PICKER_DIALOG_WIDTH
        ? event.pageX - COLOR_PICKER_DIALOG_WIDTH
        : event.pageX;

    this.openColorPickerDialog(left, top);
  }

  openColorPickerDialog(left: number, top: number): void {
    const dialogConfig: ColorPickerDialogInfo = {
      top: top.toString() + "px",
      left: left.toString() + "px",
      color: this.value,
      defaultColor: this.propertyInfo.descriptor.defaultValue
    };
    this.dispatcher.dispatch(
      ColorPickerDialogActions.openColorPickerDialog({ colorPickerDialogInfo: dialogConfig })
    );
    this.subscribeToDialogClose();
  }

  private subscribeToDialogClose(): void {
    this.actions$
      .pipe(ofType(ColorPickerDialogActions.onColorPickerDialogClosed), take(1))
      .subscribe(({ selectedColorInfo }) => {
        this.color = selectedColorInfo;
        this.onValueChanged(selectedColorInfo);
        this.cdr.detectChanges();
        if (isDefined(this.editorInput)) {
          this.isColorFormatValid = isValidColorFormat(this.editorInput.nativeElement.value);
        }
      });
  }

  focus(): void {
    focusItemInput(this.editorInput?.nativeElement);
  }
}
