import { DeepPartial, Dictionary } from "../../ts-utils";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { capitalizeText } from "../../ts-utils/helpers/string.helper";
import { BorderRadiusDto } from "../models/border-radius";
import { BorderSide, BorderStyleDto } from "../models/border-style";
import { cssSizeParser } from "../models/component-size.constants";
import { ComponentStyleDto } from "../models/component-style";
import { BORDER_SIDES_ATTRIBUTE, BORDER_WIDTH_ATTRIBUTE } from "./template-builder.helper";

export function resolveElementBorderStyle(
  css: Partial<ComponentStyleDto>
): Partial<CSSStyleDeclaration> {
  let borderCssStyle: Partial<CSSStyleDeclaration> = {
    borderRadius: "",
    borderColor: "",
    borderStyle: "",
    borderWidth: ""
  };
  if (isDefined(css.border)) {
    const { radius, sides, borderWidth, borderColor, borderStyle } = css.border;
    if (isDefined(radius) && (isDefined(radius.radiusInput) || isDefined(radius.radiusSlider))) {
      borderCssStyle.borderRadius = `${radius.radiusSlider}px`;
    }
    borderCssStyle.borderColor = borderColor;
    borderCssStyle.borderStyle = borderStyle;

    const hasAll = sides.find((side) => side === BorderSide.All);

    const topBorder =
      hasAll || sides.find((side) => side === BorderSide.Top) ? `${borderWidth}px` : "0";
    const rightBorder =
      hasAll || sides.find((side) => side === BorderSide.Right) ? `${borderWidth}px` : "0";
    const bottomBorder =
      hasAll || sides.find((side) => side === BorderSide.Bottom) ? `${borderWidth}px` : "0";
    const leftBorder =
      hasAll || sides.find((side) => side === BorderSide.Left) ? `${borderWidth}px` : "0";

    borderCssStyle.borderWidth = `${topBorder} ${rightBorder} ${bottomBorder} ${leftBorder}`;
  }
  return borderCssStyle;
}

export function resolveBorderStyleFromElement(
  element: HTMLIFrameElement | HTMLBodyElement
): BorderStyleDto {
  const borderConfig: DeepPartial<BorderStyleDto> = {
    radius: new BorderRadiusDto(cssSizeParser(element.style.borderRadius).value),
    sides: resolveSides(element)
  };
  if (element.style.borderColor !== "initial") {
    borderConfig.borderColor = element.style.borderColor;
  }
  borderConfig.borderStyle = capitalizeText(element.style.borderStyle);
  borderConfig.borderWidth = parseInt(element.getAttribute(BORDER_WIDTH_ATTRIBUTE) ?? "0");

  return new BorderStyleDto(borderConfig);
}

function resolveSides(element: HTMLIFrameElement | HTMLBodyElement): string[] {
  const sidesData = element.getAttribute(BORDER_SIDES_ATTRIBUTE) ?? "";
  const sidesMap: Dictionary<string> = {};
  Object.keys(BorderSide).forEach((side) => (sidesMap[side.charAt(0).toLowerCase()] = side));

  return sidesData.split("").map((sideShort) => sidesMap[sideShort]);
}
