import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { ReportId } from "./core/models/report-id";
import { PageComponent } from "./elements/components/page/page.component";
import { UnsavedChangesService } from "./elements/services/unsaved-changes.service";
import { SaveChangesDialogActions } from "./elements/store/dialogs/actions/save-changes-dialog.actions";
import { LocalizationService } from "./i18n/localization.service";
import { ConfirmationDialogResult } from "./shared/components/confirmation-dialog/confirmation-dialog.component";
import { UndoRedoService } from "./shared/services/undo-redo.service";

const CONTINUE_NAVIGATION = true;
const ABORT_NAVIGATION = false;

@Injectable({ providedIn: "root" })
export class CanDeactivateReport  {
  constructor(
    private store$: Store<any>,
    private actions$: Actions,
    private localizer: LocalizationService,
    private unsavedChangesService: UnsavedChangesService,
    private undoRedoService: UndoRedoService
  ) {}

  canDeactivate(
    _component: PageComponent,
    currentRoute: ActivatedRouteSnapshot
  ): Observable<boolean> | boolean {
    const shouldPrompt = this.unsavedChangesService.shouldShowPrompt();
    if (!shouldPrompt) {
      this.undoRedoService.reset();
      return CONTINUE_NAVIGATION;
    }
    const reportId = currentRoute.params.id as ReportId;
    this.store$.dispatch(
      SaveChangesDialogActions.saveChangesInDialog({
        displayText: this.localizer.get(this.localizer.dialogs.UnsavedChangesMessage),
        reportId
      })
    );
    return this.dialogClose$();
  }

  private dialogClose$(): Observable<boolean> {
    return this.actions$.pipe(
      ofType(SaveChangesDialogActions.saveChangesInDialogClosed),
      first(),
      map(({ dialogResult }) => {
        switch (dialogResult) {
          case ConfirmationDialogResult.SAVE:
          case ConfirmationDialogResult.DISCARD: {
            this.undoRedoService.reset();
            return CONTINUE_NAVIGATION;
          }
          case ConfirmationDialogResult.CANCEL:
          default: {
            // NOTE default case happens when backdrop is clicked
            return ABORT_NAVIGATION;
          }
        }
      })
    );
  }
}
