import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  Type,
  ViewRef
} from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Update } from "@ngrx/entity";
import { sortBy as _sortBy } from "lodash";
import { Observable, combineLatest } from "rxjs";
import { delay, takeUntil, tap } from "rxjs/operators";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { ReportTag } from "../../../core/models/report-id";
import { ViewMode } from "../../../core/models/view-mode";
import { AppStatusActions } from "../../../environment/store/app-status/app-status.actions";
import { TypeDescriptorAlias } from "../../../meta";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { EntityId } from "../../../meta/models/entity";
import { Dictionary, Maybe, isDefined, isNotDefined } from "../../../ts-utils";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { EditableLayoutDirective } from "../../directives/editable-layout.directive";
import { FULL_HEIGHT_PAGE_CSS, isFullWidth } from "../../helpers/component-size.helper";
import {
  ROOT_PADDING_BOTTOM,
  adjustRowDropOffset,
  droppedOnRightHalf,
  droppedOnUpperHalf,
  getTargetComponentId,
  resetDraggedCardPositioningCss
} from "../../helpers/page-component.helper";
import {
  DRAGGED_ELEMENT_CSS_CLASS,
  initializeDraggabillyContextOnDragStart,
  initializeDraggingIndicator,
  isDroppedAfter,
  limitDragMovement,
  onDropCleanup,
  resolveCardDraggingCursor,
  updateDraggingIndicatorOnMove
} from "../../helpers/widget-dragging.helper";
import { ComponentStateDto, ComponentStyleDto, CssSize } from "../../models";
import { ComponentTypeInfo } from "../../models/component-type-info";
import { Direction } from "../../models/direction";
import { DraggabillyEventContext } from "../../models/draggabilly-event-context";
import { BASIC_CARD, PAGE } from "../../models/element-type.constants";
import { PageRuntimeViewProperties } from "../../models/runtime-view-properties";
import { SizeInPx } from "../../models/size-in-px";
import { ActiveContainersService } from "../../services/active-containers.service";
import {
  ComponentPositioningService,
  PAGE_ITEM_DRAG_HANDLE
} from "../../services/component-positioning.service";
import { DomMapper } from "../../services/dom-mapper.service";
import { getSvgBackgroundImage } from "../../services/svg-grid.helper";
import { ComponentStateActions, ROOT_COMPONENT_ID } from "../../store";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { BasicCardComponent } from "../basic-card/basic-card.component";
import { CardComponent } from "../card/card.component";
import { ContainerComponent } from "../container/container.component";
import { NavigationBarComponent } from "../navigation-bar/navigation-bar.component";
import { PageViewConfig } from "./view-config";

@Component({
  selector: "c-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
  exportAs: PAGE,
  providers: [{ provide: ContainerComponent, useExisting: PageComponent }]
})
@ConnectorRoles()
@EditableWidget({ fullName: PAGE, virtual: true })
export class PageComponent extends ContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding("attr.EditableLayoutDirective") layoutEditor;
  @HostBinding("class.background-hidden") backgroundHidden: boolean = false;
  public isReportLoading$: Observable<boolean>;

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    activeContainersService: ActiveContainersService = new ActiveContainersService(),
    componentPositioningService: ComponentPositioningService,
    private activatedRoute: ActivatedRoute,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, activeContainersService, componentPositioningService, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const params$: Observable<ParamMap> = this.activatedRoute.paramMap;
    const queryParams$: Observable<ParamMap> = this.activatedRoute.queryParamMap;

    combineLatest([params$, queryParams$])
      .pipe(
        tap(([params, queryParams]) => {
          const reportTag = this.obtainReportId(params);

          if (isDefined(reportTag)) {
            this.dispatch(AppStatusActions.startLoadingReport({ reportTag, queryParams }));
          }
        })
      )
      .subscribe();

    this.draggabillyConfig = { containment: "c-page", handle: "." + PAGE_ITEM_DRAG_HANDLE };
    this.createEditableLayout();
  }

  initSubscriptions(): void {
    super.initSubscriptions();
    this.environmentSelector
      .selectViewMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((viewMode: ViewMode) => {
        this.backgroundHidden = viewMode === ViewMode.PreviewMode;
      });
  }

  createEditableLayout(): void {
    this.layoutEditor = new EditableLayoutDirective(this, this.environmentSelector);
    this.layoutEditor.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.layoutEditor.ngOnDestroy();
  }

  protected initState(): void {
    const state: ComponentStateDto = new ComponentStateDto({
      id: ROOT_COMPONENT_ID,
      type: this.typeDescriptor.name
    });
    this.id = state.id;
    this.dispatch(ComponentStateActions.addOne({ newComponent: state, parentId: null }));
    super.initState();
  }

  protected adjustCss(css: Partial<ComponentStyleDto>): Partial<ComponentStyleDto> {
    const { paddingTop, paddingLeft } = this.currentState.view
      .runtimeView as PageRuntimeViewProperties;
    return {
      ...super.adjustCss(css),
      backgroundImage: this.view.hideBackground ? "" : getSvgBackgroundImage(!this.view.snapToGrid),
      padding: `${paddingTop}px 0 ${ROOT_PADDING_BOTTOM}px ${paddingLeft}px`,
      backgroundRepeat: "",
      backgroundSize: ""
    } as Partial<ComponentStyleDto>;
  }

  protected getCssSize(runtimeSize: SizeInPx): CssSize {
    const width = { width: `${runtimeSize.widthInPx}px` };
    const height = FULL_HEIGHT_PAGE_CSS;

    return {
      ...width,
      ...height
    };
  }

  protected initDynamicButtons(): void {
    // overridden not to set edit and delete buttons
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.isReportLoading$ = this.environmentSelector.selectReportLoadingState().pipe(delay(0));
  }

  @View(PageViewConfig)
  get view(): PageViewConfig {
    return this.currentState.view as PageViewConfig;
  }

  get childrenByViewOrder(): BaseComponent[] {
    const sortedComponentRefs: ComponentRef<BaseComponent>[] = _sortBy(
      this.dynamicChildren.components,
      (componentRef) => this.dynamicChildren.viewContainerRef.indexOf(componentRef.hostView)
    );
    return sortedComponentRefs.map((componentRef) => componentRef.instance);
  }

  get childIdsByViewOrder(): EntityId[] {
    return this.childrenByViewOrder.map((child) => child.id);
  }

  private shouldWrapIntoContainer(attachedComponentType: Type<BaseComponent>): boolean {
    const isContainer: boolean = attachedComponentType.prototype instanceof ContainerComponent;
    const isTabComponent: boolean =
      attachedComponentType.name ===
      this.typeProvider.getTypeByConstructor(NavigationBarComponent).name;
    const shouldBeWrapped: boolean = !isContainer && !isTabComponent;
    return shouldBeWrapped;
  }

  private obtainReportId(params: ParamMap): ReportTag {
    return params.get("id")?.split("?")[0] as ReportTag;
  }

  protected canContain(componentForAttaching: Type<BaseComponent>): boolean {
    // FIXME: should implement interface that all PageComponent children will implement
    const isCard: boolean = componentForAttaching.prototype instanceof CardComponent;
    const isTabLinks: boolean = this.typeProvider.areEqualTypes(
      componentForAttaching,
      NavigationBarComponent
    );

    return isCard || isTabLinks;
  }

  protected initializeDynamicChildren(idsToAdd: EntityId[]): void {
    super.initializeDynamicChildren(idsToAdd);
  }

  protected handleChildSelection(
    event: PointerEvent,
    child: BaseComponent,
    _draggabillyContext: DraggabillyEventContext
  ) {
    event.stopPropagation();
    child.selectComponent(false);
  }

  protected onDragStart(
    event: Event,
    _dropPoint: MouseEvent | Touch,
    _draggabillyContext: DraggabillyEventContext,
    draggedComponent: BaseComponent
  ): void {
    _draggabillyContext?.draggable?.element?.classList.add(DRAGGED_ELEMENT_CSS_CLASS);
    this.draggedComponentService.setDragTarget({
      type: DraggedItemType.Component,
      item: new ComponentTypeInfo(draggedComponent.typeDescriptor, {} as TypeDescriptorAlias)
    });
    limitDragMovement(_draggabillyContext, []);
    initializeDraggabillyContextOnDragStart(
      _draggabillyContext,
      _dropPoint,
      [],
      draggedComponent.currentState
    );
    initializeDraggingIndicator(_draggabillyContext);
  }

  protected onDragMove(
    _dropPoint: MouseEvent | Touch,
    _draggabillyContext: DraggabillyEventContext
  ): void {
    if (!_draggabillyContext.isDraggingStarted) {
      return;
    }
    const elementsAtPoint = document.elementsFromPoint(_dropPoint.pageX, _dropPoint.pageY);
    const draggedCardHtmlId = DomMapper.getHostId(_draggabillyContext.draggableComponentId);
    const isOverPage = elementsAtPoint.some((element) => element === this.hostElement);
    const isDraggedOverItself = elementsAtPoint.some((element) => element.id === draggedCardHtmlId);

    document.body.style.cursor = resolveCardDraggingCursor(
      isDraggedOverItself,
      isOverPage,
      _dropPoint
    );
    updateDraggingIndicatorOnMove(
      isOverPage && !isDraggedOverItself,
      _draggabillyContext,
      _dropPoint
    );
  }

  protected onDragEnd(
    _event: Event,
    dropPoint: MouseEvent | Touch,
    draggabillyContext: DraggabillyEventContext
  ): void {
    if ((dropPoint as MouseEvent).ctrlKey && document.body.style.cursor === "copy") {
      this.cloneCardOnDrop(dropPoint, draggabillyContext);
    } else {
      this.updateCardOrderOnDrop(dropPoint, draggabillyContext);
    }
    onDropCleanup(draggabillyContext);
    this.draggedComponentService.clear();
    resetDraggedCardPositioningCss(draggabillyContext);
  }

  private cloneCardOnDrop(
    dropPoint: MouseEvent | Touch,
    draggabillyContext: DraggabillyEventContext
  ): void {
    const elementsAtPoint = document.elementsFromPoint(dropPoint.pageX, dropPoint.pageY);
    const cardUnderPointHost = elementsAtPoint.find(
      (element) =>
        ((element as any)?.angularComponentRef as BaseComponent)?.currentState.type === BASIC_CARD
    );
    if (isDefined(cardUnderPointHost)) {
      const cardUnderPoint = (cardUnderPointHost as any)?.angularComponentRef as BaseComponent;
      this.dispatch(
        ComponentStateActions.cloneCard({
          cardId: draggabillyContext.draggableComponentId,
          isDroppedOnRightSide: isDroppedAfter(
            dropPoint,
            cardUnderPoint.hostElement.getBoundingClientRect()
          ),
          dropTargetId: cardUnderPoint.id
        })
      );
    } else {
      this.dispatch(
        ComponentStateActions.cloneCard({
          cardId: draggabillyContext.draggableComponentId,
          isDroppedOnRightSide: false,
          dropTargetId: null
        })
      );
    }
  }

  private updateCardOrderOnDrop(
    dropPoint: MouseEvent | Touch,
    draggabillyContext: DraggabillyEventContext
  ): void {
    const draggedComponentId = draggabillyContext.draggableComponentId;
    if (isNotDefined(draggedComponentId)) {
      return;
    }
    const currentIndex = this.childIdsByViewOrder.indexOf(draggedComponentId);
    const dropIndex: Maybe<number> = this.calculateDropIndex(
      draggedComponentId,
      dropPoint,
      currentIndex
    );
    if (isNotDefined(dropIndex)) {
      return;
    }
    if (currentIndex === dropIndex) {
      this.resetChildStyle(draggedComponentId);
    } else {
      this.changeChildPositionIndex(draggedComponentId, dropIndex);
    }
  }

  calculateDropIndex(
    draggedId: EntityId,
    dropPoint: MouseEvent | Touch,
    currentIndex: number
  ): number | undefined {
    const childComponents: Dictionary<ComponentStateDto> =
      this.componentStateSelector.getChildrenAsDict(this.id);
    const draggedComponent = childComponents[draggedId];
    if (draggedComponent == null) {
      return;
    }

    const baseDropIndex: number = this.calculateBaseDropIndex(draggedId, dropPoint);
    const dropIndexOffset: number = isFullWidth(draggedComponent.view.size)
      ? this.calculateDropOffsetForFullWidth(draggedId, dropPoint)
      : this.calculateDropOffsetForFixedWidth(draggedId, dropPoint, currentIndex, baseDropIndex);
    let dropIndex: number = baseDropIndex + dropIndexOffset;
    const childComponentsLength: number = Object.keys(childComponents).length;
    if (dropIndex >= childComponentsLength) {
      dropIndex = childComponentsLength - 1;
    }
    return dropIndex;
  }

  calculateBaseDropIndex(draggedId: EntityId, dropPoint: MouseEvent | Touch): number {
    const childRects: Dictionary<ClientRect> = this.getChildRectsExcept(draggedId);
    const targetId: Maybe<EntityId> = getTargetComponentId(childRects, dropPoint);
    const baseDropIndex: number =
      targetId != null
        ? this.getChildViewIndex(targetId)
        : this.calculateIntendedIndex(childRects, dropPoint);
    return baseDropIndex;
  }

  calculateIntendedIndex(
    childRects: Dictionary<ClientRect>,
    dropPoint: MouseEvent | Touch
  ): number {
    const rectIds = Object.keys(childRects);
    const successorIds: EntityId[] = rectIds.filter((childId) => {
      const targetRect: ClientRect = childRects[childId];
      const dropX = dropPoint.clientX;
      const dropY = dropPoint.clientY;
      const targetIsSiblingSuccessor: boolean =
        dropY > targetRect.top && dropY < targetRect.bottom && dropX < targetRect.left;
      const targetIsNonSiblingSuccessor: boolean = dropY < targetRect.top;
      return targetIsSiblingSuccessor || targetIsNonSiblingSuccessor;
    });

    if (successorIds.length > 0) {
      const firstSuccessorIndex: number = this.childIdsByViewOrder.findIndex(
        (childId) => successorIds.find((siblingId) => childId === siblingId) != null
      );
      return firstSuccessorIndex;
    } else {
      return rectIds.length;
    }
  }

  calculateDropOffsetForFullWidth(draggedId: EntityId, dropPoint: MouseEvent | Touch): number {
    const childRects: Dictionary<ClientRect> = this.getChildRectsExcept(draggedId);
    const targetId: Maybe<EntityId> = getTargetComponentId(childRects, dropPoint);

    if (targetId == null) {
      return 0;
    }

    const targetRect: ClientRect = childRects[targetId];
    const targetComponentIndex: number = this.getChildViewIndex(targetId);
    const draggedComponentIndex: number = this.getChildViewIndex(draggedId);

    let adjustmentDirection: Direction;
    let dropIndexOffset: number = 0;

    if (droppedOnUpperHalf(dropPoint, targetRect)) {
      adjustmentDirection = Direction.LEFT;
      if (draggedComponentIndex < targetComponentIndex) {
        dropIndexOffset--;
      }
    } else {
      adjustmentDirection = Direction.RIGHT;
      if (draggedComponentIndex > targetComponentIndex) {
        dropIndexOffset++;
      }
    }
    dropIndexOffset += adjustRowDropOffset(
      this.childIdsByViewOrder,
      childRects,
      targetId,
      adjustmentDirection
    );
    return dropIndexOffset;
  }

  calculateDropOffsetForFixedWidth(
    draggedId: EntityId,
    dropPoint: MouseEvent | Touch,
    currentIndex: number,
    baseIndex: number
  ): number {
    const childRects: Dictionary<ClientRect> = this.getChildRectsExcept(draggedId);
    const targetId: Maybe<EntityId> = getTargetComponentId(childRects, dropPoint);

    if (targetId == null) {
      return 0;
    }

    const targetRect: ClientRect = childRects[targetId];
    if (currentIndex > baseIndex) {
      return droppedOnRightHalf(dropPoint, targetRect) ? 1 : 0;
    } else {
      return droppedOnRightHalf(dropPoint, targetRect) ? 0 : -1;
    }
  }

  protected onStaticClick(
    _event: Event,
    _dropPoint: MouseEvent | Touch,
    draggabillyContext: DraggabillyEventContext
  ): void {}

  private resetChildStyle(childId: EntityId): void {
    const component = this.getChildComponentById(childId);
    component.applyCssToHostElement(component.currentState.view.css);
  }

  private changeChildPositionIndex(childId: EntityId, newIndex: number): void {
    this.updateChildPosition(childId, newIndex);
    this.updateChildOrderInStore(this.childIdsByViewOrder);
  }

  updateChildPosition(childId: EntityId, newIndex: number): void {
    const childViewRef: ViewRef = this.dynamicChildren.components.find(
      (componentRef) => componentRef.instance.id === childId
    )?.hostView;

    this.dynamicChildren.viewContainerRef.move(childViewRef, newIndex);
  }

  private updateChildOrderInStore(orderedChildIds: EntityId[]): void {
    const componentStates = this.componentStateSelector.getAllAsDict();
    const componentUpdates: Update<ComponentStateDto>[] = orderedChildIds.reduce(
      (acc, childId, orderIndex) => {
        const componentChanges = {
          view: {
            ...componentStates[childId].view,
            css: {
              ...componentStates[childId].view.css,
              order: orderIndex.toString()
            }
          }
        };
        const childUpdate: Update<ComponentStateDto> = {
          id: childId.toString(),
          changes: componentChanges
        };
        acc.push(childUpdate);
        return acc;
      },
      []
    );
    componentUpdates.push({
      id: this.id.toString(),
      changes: {
        childrenIds: orderedChildIds
      }
    });
    this.dispatch(ComponentStateActions.updateMany({ componentUpdates }));
  }

  public getChildViewIndex(childId: EntityId): number {
    return this.childrenByViewOrder.findIndex((child: BaseComponent) => child.id === childId);
  }

  public getChildRectsExcept(idToExclude: EntityId): Dictionary<ClientRect> {
    const componentBoundRects = this.dynamicChildren.components
      .filter(
        (child: ComponentRef<BaseComponent>) =>
          child.instance.id.toString() !== idToExclude.toString()
      )
      .reduce((acc, child: ComponentRef<BaseComponent>) => {
        acc[child.instance.id] = child.instance.hostElement.getBoundingClientRect();
        return acc;
      }, {});
    return componentBoundRects;
  }

  // FIXME: Use effects for adding cards and widgets
  tryAddComponent(componentInfo: ComponentTypeInfo): EntityId | null {
    const componentType = componentInfo.descriptor.constructorFunction;
    if (this.shouldWrapIntoContainer(componentType)) {
      const basicCardComponentId: EntityId | null = super.tryAddComponent({
        descriptor: this.typeProvider.getType(BASIC_CARD),
        alias: {} as TypeDescriptorAlias
      });
      if (basicCardComponentId == null) {
        return null;
      }
      const basicCardComponent = this.getChildComponentById(
        basicCardComponentId
      ) as BasicCardComponent;
      const addedComponentId: EntityId | null = basicCardComponent.tryAddComponent(componentInfo);
      if (!!addedComponentId) {
        return addedComponentId;
      } else {
        super.removeChild(basicCardComponent);
        return null;
      }
    } else {
      const addedComponentId: EntityId | null = super.tryAddComponent(componentInfo);
      return addedComponentId;
    }
  }

  canAcceptDrop(): boolean {
    const targetType = this.draggedComponentService.target?.type;
    return targetType === DraggedItemType.Component;
  }

  protected shouldUpdateDroppedComponentPosition(
    componentState: Maybe<ComponentStateDto>
  ): boolean {
    return false;
  }

  protected get isResizable(): boolean {
    return false;
  }

  protected createDragOverlayWithCorrectDragHandle(
    hostElement: HTMLElement,
    parentIsPage = true
  ): void {
    super.createDragOverlayWithCorrectDragHandle(hostElement, true);
  }
}
