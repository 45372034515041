import { AlignValue, Options } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { first } from "../../../ts-utils/helpers/array.helper";
import { tryConvertToNumber } from "../../../ts-utils/helpers/number.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { getTextColorForNoDataStatus } from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { getLimitsDisplayOptions } from "../../helpers/limit-modes.helper";
import { YAxisLabelsAlignment } from "../../models/alignment/vertical-gauge-labels-alignment";
import { DataStatus } from "../../models/data-status";
import { IVerticalGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { PRIMARY_Y_AXIS_ID, mergeChartOptions } from "./base-highcharts-options.helper";
import { LinearGaugeDisplayService } from "./linear-gauge-display.service";
import { getBarColor, getYAxisLabelStyle } from "./single-value-display.helper";

export class VerticalGaugeDisplayService extends LinearGaugeDisplayService {
  protected colorListService: ColorListService;
  constructor(protected valueFormatter: ValueFormatterService, colorListService: ColorListService) {
    super(valueFormatter, colorListService);
    this.chartType = "column";
    this.colorListService = colorListService;
  }

  protected getSpecificGaugeOptions(
    viewConfig: IVerticalGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const min = tryConvertToNumber(viewConfig.min);
    const max = tryConvertToNumber(viewConfig.max);
    const normalizedValue = isDefined(min) ? value - min : value;

    const verticalOptions: Options = {
      chart: {
        spacingLeft: this.isLeftSpacingNeeded(viewConfig) ? 30 : undefined,
        spacingRight: this.isRightSpacingNeeded(viewConfig) ? 30 : undefined
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          threshold: min
        }
      },
      title: {
        align: "center",
        style: {
          textAlign: "center"
        }
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          plotBands: [
            {
              id: PRIMARY_Y_AXIS_ID,
              label: {
                style: {
                  color: getTextColorForNoDataStatus(dataStatus, viewConfig.foregroundColor)
                }
              }
            }
          ]
        }
      ],
      series: [
        {
          type: undefined,
          data: isDefined(max) ? [max - value] : [value],
          enableMouseTracking: false,
          color: viewConfig.neutralColor || "#F0F0F0" // upper part
        },
        {
          type: undefined,
          data: [normalizedValue],
          color: getBarColor(normalizedValue, viewConfig, this.colorListService)
        }
      ]
    };
    const linearGaugeOptions = super.getSpecificGaugeOptions(
      viewConfig,
      dataConnectorDescriptors,
      dataStatus
    );
    return mergeChartOptions(linearGaugeOptions, verticalOptions);
  }

  private isLeftSpacingNeeded(viewConfig: IVerticalGaugeDisplayConfig): Maybe<boolean> {
    const { showYAxisLabels, limitsMode } = viewConfig;
    const { showLimitValues, showLimitsAsBars } = getLimitsDisplayOptions(limitsMode);
    return (
      showLimitsAsBars &&
      (showLimitValues || (showYAxisLabels && !this.areYAxisLabelsRightAligned(viewConfig)))
    );
  }

  private isRightSpacingNeeded(viewConfig: IVerticalGaugeDisplayConfig): Maybe<boolean> {
    const { showYAxisLabels, limitsMode } = viewConfig;
    const { showLimitsAsBars } = getLimitsDisplayOptions(limitsMode);
    return showLimitsAsBars && showYAxisLabels && this.areYAxisLabelsRightAligned(viewConfig);
  }

  protected getYOffset(): number {
    return 5;
  }

  protected getTextAlignStyle(): AlignValue {
    return "center";
  }

  protected getLimitPlotLineLabel(viewConfig: IVerticalGaugeDisplayConfig, value: number) {
    const { showLimitValues, showLimitsAsBars } = getLimitsDisplayOptions(viewConfig.limitsMode);
    if (!showLimitValues) {
      return {};
    }
    const x = showLimitValues && showLimitsAsBars ? -10 : 10;
    return {
      text: value,
      textAlign: "center",
      x,
      y: -1,
      formatter: () => this.valueFormatter.formatValue(value, viewConfig.displayFormat),
      style: getYAxisLabelStyle(viewConfig.foregroundColor)
    };
  }

  protected getYAxisPlotLineLabel(viewConfig: IVerticalGaugeDisplayConfig, value: number) {
    const { showYAxisLabels, limitsMode } = viewConfig;
    const { showLimitsAsBars } = getLimitsDisplayOptions(limitsMode);
    if (!showYAxisLabels) {
      return {};
    }
    return this.areYAxisLabelsRightAligned(viewConfig)
      ? {
          text: value,
          align: "right",
          x: 30,
          y: -1,
          formatter: () => this.valueFormatter.formatValue(value, viewConfig.displayFormat),
          style: getYAxisLabelStyle(viewConfig.foregroundColor)
        }
      : {
          text: value,
          textAlign: "center",
          x: showYAxisLabels && showLimitsAsBars ? -10 : 10,
          y: -1,
          formatter: () => this.valueFormatter.formatValue(value, viewConfig.displayFormat),
          style: getYAxisLabelStyle(viewConfig.foregroundColor)
        };
  }

  protected areYAxisLabelsRightAligned(viewConfig: IVerticalGaugeDisplayConfig): boolean {
    return viewConfig.yAxisLabelsAlignment === YAxisLabelsAlignment.Right;
  }
}
