import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from "@angular/core";
import { Subject } from "rxjs";
import { isSignalBased } from "../../../data-connectivity/helpers/data-source-type.helper";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { SignalDataSourceDto } from "../../../data-connectivity/models/data-source/signal-data-source";
import { LocalizationService } from "../../../i18n/localization.service";
@Component({
  selector: "data-connector-array-item",
  templateUrl: "./data-connector-array-item.component.html",
  styleUrls: ["./data-connector-array-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataConnectorArrayItemComponent implements OnDestroy {
  @Input() dataConnector!: DataConnectorDto;
  @Output() onEdit: EventEmitter<DataConnectorDto> = new EventEmitter();
  @Output() onRemove: EventEmitter<DataConnectorDto> = new EventEmitter();
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(public translationService: LocalizationService) {}

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  edit(event: Event): void {
    event.stopPropagation();
    this.onEdit.emit(this.dataConnector);
  }

  remove(event: Event): void {
    event.stopPropagation();
    this.onRemove.emit(this.dataConnector);
  }

  resolveTitle(): string {
    return this.shouldDisplayDefaultTitle()
      ? (this.dataConnector.dataSource as SignalDataSourceDto).signal.name
      : this.dataConnector.title;
  }

  private shouldDisplayDefaultTitle(): boolean {
    return this.dataConnector.title === "" && isSignalBased(this.dataConnector.dataSource);
  }
}
