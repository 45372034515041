import { Component } from "@angular/core";
import { Options } from "highcharts";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseComponent,
  ChartComponent,
  ConnectorRoles,
  EditableType,
  formatNumberByStringFormat,
  isEmptyOrNotDefined2,
  LayoutBuilder,
  MaxConnectors,
  View
} from "ui-core";
import { RdsComponentCategory } from "../../rds-component-category";
import { Properties, Roles } from "./roles";
import { StatorWindingTemperatureChartViewConfig } from "./view-config";

@Component({
  selector: "rds-stator-winding-temperature-chart",
  templateUrl: "./stator-winding-temperature-chart.component.html",
  styleUrls: ["./stator-winding-temperature-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: StatorWindingTemperatureChartComponent }]
})
@LayoutBuilder(
  RdsComponentCategory.RDS,
  "StatorWindingTemperatureChartComponent",
  "Plugin",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.StatorWindingTemperaturChart
)
@ConnectorRoles(Roles, Properties)
@MaxConnectors(100)
@EditableType({
  fullName: "StatorWindingTemperatureChartComponent",
  title: "stator-winding-temperature-chart"
})
export class StatorWindingTemperatureChartComponent extends ChartComponent {
  unit: string;
  alarmLimit: string;
  tripLimit: string;

  locationAngleArray: string[] = [];
  signalNameArray: string[] = [];
  plcTagArray: string[] = [];
  dataPoints: number[][];
  alarmLimitArray: number[][];
  tripLimitArray: number[][];
  avgTempArray: number[][];

  @View(StatorWindingTemperatureChartViewConfig)
  public get view() {
    return this.currentState.view as StatorWindingTemperatureChartViewConfig;
  }

  protected updateDisplay(): void {
    super.updateDisplay();
    this.updateLegendItem("Alarm Limit", this.alarmLimit + this.unit);
    this.updateLegendItem("Trip Limit", this.tripLimit + this.unit);
  }

  protected updateChartData(): void {
    this.unit =
      this.dataAccessor.getPropertyValueForRole(Roles.CurrentTemp.name, Properties.Unit.name) ||
      this.view.unit;
    this.alarmLimit =
      this.dataAccessor.getPropertyValueForRole(
        Roles.CurrentTemp.name,
        Properties.AlarmLimit.name
      ) || this.view.alarmLimit;
    this.tripLimit =
      this.dataAccessor.getPropertyValueForRole(
        Roles.CurrentTemp.name,
        Properties.TripLimit.name
      ) || this.view.tripLimit;

    const tempConnectors = this.dataAccessor.getConnectorsBySimilarRole(Roles.CurrentTemp.name);
    const currentTempArray: number[] = tempConnectors.map((conn) =>
      this.dataAccessor.getValueForConnector(conn)
    );

    this.locationAngleArray = tempConnectors
      .filter((dc) => !isEmptyOrNotDefined2(dc.properties[Properties.LocationAngle.name]))
      .map((dc) => dc.properties[Properties.LocationAngle.name].toString());
    this.signalNameArray = tempConnectors.map((dc) =>
      dc.properties[Properties.signalName.name]?.toString()
    );
    this.plcTagArray = tempConnectors.map((dc) =>
      dc.properties[Properties.plcTag.name]?.toString()
    );

    const avgTempValue =
      currentTempArray.reduce((a, b) => a + b, 0) / currentTempArray.filter((item) => item).length;

    // build polar data points based on [angle,length]
    this.dataPoints = [];
    for (let i = 0; i < this.locationAngleArray.length; i++) {
      this.dataPoints.push([parseFloat(this.locationAngleArray[i]), currentTempArray[i]]);
    }
    // sort the points based on angle
    this.dataPoints.sort(function (a, b) {
      return a[0] - b[0];
    });

    this.alarmLimitArray = [];
    this.tripLimitArray = [];
    this.avgTempArray = [];
    const dist = 6.21;
    for (let i = 0; i < 360 / dist; i++) {
      this.alarmLimitArray.push([i * dist, Number(this.alarmLimit)]);
      this.tripLimitArray.push([i * dist, Number(this.tripLimit)]);
      this.avgTempArray.push([i * dist, avgTempValue]);
    }
  }

  protected setChartOptions(): void {
    const component = this;
    const opt: Options = {
      chart: {
        polar: true,
        marginTop: 50,
        marginBottom: 50,
        marginLeft: 0,
        marginRight: 0
      },
      title: {
        text: null
      },
      pane: {
        startAngle: 90,
        endAngle: 450
      },
      xAxis: {
        tickInterval: 90,
        min: 0,
        max: 360,
        lineWidth: 0,
        gridLineColor: "#000000",
        title: {
          text: "Temperature [" + component.unit + "]",
          x: 55,
          y: 145
        },
        labels: {
          enabled: false
        }
      },
      yAxis: {
        tickAmount: 0,
        min: 0,
        gridLineWidth: 0,
        title: {
          text: "Temperature [" + component.unit + "]",
          x: 5,
          y: 200
        },
        plotLines: []
      },
      plotOptions: {
        series: {
          pointStart: 0,
          pointInterval: 45,
          marker: {
            enabled: true
          },
          tooltip: {
            headerFormat: "{series.name}: ",
            pointFormatter: function () {
              const value = this.options.y;
              var nameIndex = component.locationAngleArray.indexOf(this.options.x.toString());
              if (nameIndex === -1) {
                return (
                  "<br><b>" +
                  formatNumberByStringFormat(value, component.view.displayFormat.numberFormat) +
                  "<b/>" +
                  component.unit
                );
              } else {
                return (
                  "<b>" +
                  component.plcTagArray[nameIndex] +
                  "</b><br>" +
                  component.signalNameArray[nameIndex] +
                  ": <b>" +
                  formatNumberByStringFormat(value, component.view.displayFormat.numberFormat) +
                  "<b/>" +
                  component.unit
                );
              }
            }
          }
        }
      },
      series: [
        {
          type: "spline",
          name: "Current Value",
          data: this.dataPoints,
          color: this.view.currentColor,
          width: 1,
          connectEnds: true,
          connectNulls: true
        } as any,
        {
          type: "spline",
          name: "Average Value",
          data: this.avgTempArray,
          color: this.view.maxColor,
          width: 1,
          marker: false,
          connectEnds: true,
          connectNulls: true
        } as any,
        {
          type: "spline",
          name: "Alarm limit",
          data: this.alarmLimitArray,
          color: this.view.alarmColor,
          width: 1,
          marker: false,
          connectEnds: true,
          connectNulls: true
        } as any,
        {
          type: "spline",
          name: "Trip limit",
          data: this.tripLimitArray,
          color: this.view.tripColor,
          width: 1,
          marker: false,
          connectEnds: true,
          connectNulls: true
        } as any
      ],
      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal"
      },
      subtitle: {
        text: "",
        align: "center",
        verticalAlign: "bottom"
      }
    };

    this.mergeChartOptions(opt);
  }
}
