import { cloneDeep as _cloneDeep } from "lodash";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { UserHelpFactory } from "../decorators/configurable.decorator";
import { PlaceholderConfig } from "../decorators/placeholder.decorator";
import { Access } from "./access";
import { DynamicDefaultDescriptor } from "./dynamic-default/dynamic-default-descriptor";
import { EditorSize } from "./editor-size";
import { EditorType } from "./editor-type";
import { GroupPropertiesInfo } from "./groupable-properties";
import { PropertyCategory } from "./property-category";
import { ValidationFunction } from "./validation-function";

export const DEFAULT_PROPERTY_ORDER_INDEX = 10;

export class PropertyDescriptor {
  private _name: string;
  private _displayName: string;
  private _constructorFunction: Function;
  private _editorType: EditorType = EditorType.Hidden;
  private _access: Access = Access.Write;
  private _defaultValue: any;
  private _isArray: boolean = false;
  private _isEnum: boolean = false;
  private _isVirtual: boolean = false;
  private _isHidden: boolean = true;
  private _isInheritable: boolean = true;
  private _isSerializable: boolean = true;
  private _isTitle: boolean = false;
  private _hideChildren: boolean = false;
  private _advancedMode: boolean = false;
  private _isKey: boolean = false;

  private _category: PropertyCategory = PropertyCategory.Display;
  private _subCategory: string = LOCALIZATION_DICTIONARY.propertySheet.General;
  private _order: number = DEFAULT_PROPERTY_ORDER_INDEX;

  private _eventHandler: Function = () => {};
  private _validationFunction: ValidationFunction = defaultValidationFunction;
  private _tooltipKey: string = "";
  private _allowEmpty: boolean = true;
  private _userHelp: string | UserHelpFactory;

  // FIXME: array specific properties
  private _arrayItemEditorType: EditorType = EditorType.Hidden;
  private _arrayEditorSize: EditorSize = EditorSize.Large;

  private _dynamicDefaults: DynamicDefaultDescriptor[] = [];

  private _customPropertyExpander: Maybe<Function> = null;
  private _dynamicallyCreatedFrom: string = "";

  private _isGroupable: boolean;
  private _canBeHidden: boolean;
  private _visibilitySelector: Function;
  private _displayValues: any[];
  private _dynamicallyVisibleInvert: boolean;

  private _allowInterpolation: boolean;

  private _showCustomInputInSelectList: boolean;
  private _allowMultipleSelection: boolean;

  private _inlineAlignment!: boolean;
  private _groupInfo!: GroupPropertiesInfo;
  private _showLabel: boolean = true;

  private _overrideValueFunction!: Function;

  private _placeholderConfig!: PlaceholderConfig;

  private _enableCustomItems: boolean = false;

  constructor(
    name: string,
    displayName: string,
    constructorFunction: Function,
    defaultValue?: any
  ) {
    this._name = name;
    this._displayName = displayName;
    this._constructorFunction = constructorFunction;
    this._defaultValue = defaultValue;
  }

  static create(
    key: string,
    constructorFunction: Function,
    properties?: Partial<PropertyDescriptor>
  ): PropertyDescriptor {
    return PropertyDescriptor.createFrom(
      new PropertyDescriptor(key, key, constructorFunction),
      properties
    );
  }

  static createFrom(
    descriptor: PropertyDescriptor,
    change?: Partial<PropertyDescriptor>
  ): PropertyDescriptor {
    let newDescriptor = _cloneDeep(descriptor);
    if (isDefined(change)) {
      newDescriptor = Object.keys(change).reduce((acc, sourceKey) => {
        const destKey = "_" + sourceKey;
        if (Array.isArray(acc[destKey])) {
          acc[destKey] = acc[destKey].concat(change[sourceKey]);
        } else {
          acc[destKey] = change[sourceKey];
        }
        return acc;
      }, newDescriptor);
    }
    return newDescriptor;
  }

  get name(): string {
    return this._name;
  }

  get displayName(): string {
    return this._displayName;
  }

  get editorType(): EditorType {
    return this._editorType;
  }

  set editorType(editorType: EditorType) {
    this._editorType = editorType;
  }

  get access(): Access {
    return this._access;
  }

  get isArray(): boolean {
    return this._isArray;
  }

  get arrayItemEditorType(): EditorType {
    return this._arrayItemEditorType;
  }

  get arrayEditorSize(): EditorSize {
    return this._arrayEditorSize;
  }

  get isEnum(): boolean {
    return this._isEnum;
  }

  get isReadOnly(): boolean {
    return this._access === Access.ReadOnly;
  }

  get isVirtual(): boolean {
    return this._isVirtual;
  }

  get advancedMode(): boolean {
    return this._advancedMode;
  }

  get isHidden(): boolean {
    if (isDefined(this.overrideValueFunction)) {
      return this._isHidden;
    }
    return (
      this._isHidden ||
      typeof this.editorType === "undefined" ||
      this.editorType === null ||
      this.editorType === EditorType.Hidden
    );
  }

  get isSerializable(): boolean {
    return this._isSerializable;
  }

  get isInheritable(): boolean {
    return this._isInheritable;
  }

  get isTitle(): boolean {
    return this._isTitle;
  }

  get hideChildren(): boolean {
    return this._hideChildren;
  }

  get constructorFunction(): Function {
    return this._constructorFunction;
  }

  get isKey(): boolean {
    return this._isKey;
  }

  get defaultValue(): any {
    return this._defaultValue;
  }

  get eventHandler(): Function {
    return this._eventHandler;
  }

  get category(): PropertyCategory {
    return this._category;
  }

  get subCategory(): string {
    return this._subCategory;
  }

  get dynamicDefaults(): DynamicDefaultDescriptor[] {
    return this._dynamicDefaults;
  }

  get customPropertyExpander(): Function {
    return this._customPropertyExpander;
  }

  set dynamicallyCreatedFrom(dynamicallyCreatedFrom: string) {
    this._dynamicallyCreatedFrom = dynamicallyCreatedFrom;
  }

  get dynamicallyCreatedFrom(): string {
    return this._dynamicallyCreatedFrom;
  }

  get order(): number {
    return this._order;
  }

  /// Sets index of the property's order. Default is 10. Lower than default will place property up in a list;
  set order(index: number) {
    this._order = index;
  }

  get validationFunction(): ValidationFunction {
    return this._validationFunction;
  }

  get tooltipKey(): string {
    return this._tooltipKey;
  }

  get userHelp(): string | UserHelpFactory {
    return this._userHelp;
  }

  set userHelp(value: string | UserHelpFactory) {
    this._userHelp = value;
  }

  get allowEmpty(): boolean {
    return this._allowEmpty;
  }

  get isGroupable(): boolean {
    return this._isGroupable;
  }

  get canBeHidden(): boolean {
    return this._canBeHidden;
  }

  get allowInterpolation(): boolean {
    return this._allowInterpolation;
  }

  get visibilitySelector(): Function {
    return this._visibilitySelector;
  }

  set visibilitySelector(selector: Function) {
    this._visibilitySelector = selector;
  }

  get displayValues(): any[] {
    return this._displayValues;
  }

  set displayValues(values: any[]) {
    this._displayValues = values;
  }

  get dynamicallyVisibleInvert(): boolean {
    return this._dynamicallyVisibleInvert;
  }

  set dynamicallyVisibleInvert(value: boolean) {
    this._dynamicallyVisibleInvert = value;
  }

  get showCustomInputInSelectList(): boolean {
    return this._showCustomInputInSelectList;
  }

  get allowMultipleSelection(): boolean {
    return this._allowMultipleSelection;
  }
  // asArray(): PropertyDescriptor {
  //   this._isArray = true;
  //   return this;
  // }

  get inlineAlignment(): boolean {
    return this._inlineAlignment;
  }

  set groupInfo(group: GroupPropertiesInfo) {
    this._groupInfo = group;
  }

  get groupInfo(): GroupPropertiesInfo {
    return this._groupInfo;
  }

  get showLabel(): boolean {
    return this._showLabel;
  }

  get overrideValueFunction(): Function {
    return this._overrideValueFunction;
  }

  get placeholderConfig(): PlaceholderConfig {
    return this._placeholderConfig;
  }

  set placeholderConfig(placeholderConfig: PlaceholderConfig) {
    this._placeholderConfig = placeholderConfig;
  }

  get enableCustomItems(): boolean {
    return this._enableCustomItems;
  }

  asEnum(): PropertyDescriptor {
    this._isEnum = true;
    return this;
  }

  withConstructor(constructorFunction: Function): PropertyDescriptor {
    this._constructorFunction = constructorFunction;
    return this;
  }

  setCustomPropertyExpander(expander: Function): void {
    this._customPropertyExpander = expander;
  }
}

export function defaultValidationFunction(): boolean {
  return true;
}

export function isDefaultValidationFunction(validationFunction: ValidationFunction): boolean {
  return validationFunction === defaultValidationFunction;
}

export function hideLabel(editorType: EditorType): boolean {
  return (
    editorType === EditorType.Hidden ||
    editorType === EditorType.NestedObjectEditor ||
    editorType === EditorType.NestedSheet ||
    editorType === EditorType.CheckBox ||
    editorType === EditorType.LimitsEditor ||
    editorType === EditorType.TimeRangeEditor
  );
}
