<div
  class="time-preset-filter__container"
  [class.time-preset-filter__container--horizontal]="horizontalLayout"
>
  <div class="time-preset-filter__selector-wrapper">
    <i
      class="abb-icon abb-icon--medium Time_and_date"
      title="{{ localizer.filterComponent.DateTimePicker | translate }}"
    ></i>
    <selector
      class="time-preset-filter__selector"
      [items]="presetOptions"
      [selectedItem]="timePreset"
      (onChangeValue)="updateTimePreset($event)"
      [distinctDefault]="timePreset !== TimePreset.Last4hours"
    ></selector>
  </div>
  <live-mode-filter
    *ngIf="timePreset === TimePreset.Interval"
    [liveModeFilter]="liveModeFilter"
    (onChangeValue)="updateIntervalParams($event)"
    [distinctDefault]="true"
  >
  </live-mode-filter>
  <historic-range
    *ngIf="timePreset === TimePreset.Custom && !horizontalLayout"
    [timeRange]="historicTimeRange"
    [horizontalLayout]="false"
    (onChangeTimeRange)="updateCustomParams($event)"
  >
  </historic-range>
</div>
