import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { CategoryInfo, CoreComponentCategoryProvider, WebServicesConfiguration } from "ui-core";
import { RdsComponentCategory } from "../../components/rds-component-category";

@Injectable({
  providedIn: "root"
})
export class RdsComponentCategoryProvider extends CoreComponentCategoryProvider {
  private readonly common: CategoryInfo[] = [new CategoryInfo(RdsComponentCategory.RDS, 600)];

  private readonly abb: CategoryInfo[] = [new CategoryInfo(RdsComponentCategory.ABB, 700)];

  constructor(private http: HttpClient, private apiConfig: WebServicesConfiguration) {
    super();
  }

  private rdsCategories$(): Observable<CategoryInfo[]> {
    return this.http
      .get<boolean>(`${this.apiConfig.itemsServiceUrl}/access-management/me/abb`)
      .pipe(map((isABB: boolean) => (isABB ? this.common.concat(this.abb) : this.common)));
  }

  public selectAllCategories(): Observable<CategoryInfo[]> {
    return forkJoin([super.selectAllCategories(), this.rdsCategories$()]).pipe(
      map(([coreComponents, rdsComponents]) => coreComponents.concat(rdsComponents))
    );
  }
}
