import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import {
  DEFAULT_SPAN_IN_HOURS,
  LIVE_MODE_TIME_UNIT
} from "../../../core/helpers/filter/filter-validation.helper";
import { TimeUnit } from "../../../core/models/time-unit";
import { LocalizationService } from "../../../i18n/localization.service";
import { isDefined, isEmpty, isNotDefined, Maybe } from "../../../ts-utils";
import { formatAmountToTwoDecimals } from "../../helpers/live-mode-time-unit.helper";
import { LiveModeFilter } from "../../models/live-mode-filter";
import { SelectorComponent } from "../selector/selector.component";

@Component({
  selector: "live-mode-filter",
  templateUrl: "live-mode-filter.component.html",
  styleUrls: ["./live-mode-filter.component.scss"]
})
export class LiveModeFilterComponent implements OnInit {
  @Input() liveModeFilter: LiveModeFilter;
  @Input() distinctDefault: boolean = false;
  @Output() onChangeValue: EventEmitter<any> = new EventEmitter();
  @ViewChild("timeAmountInput") timeAmountInput?: ElementRef<HTMLInputElement>;
  @ViewChild(SelectorComponent) selectorComponent?: SelectorComponent;
  timeUnits: string[] = [];
  amountUpdate: Subject<string> = new Subject<string>();
  invalidAmount: Maybe<string> = null;
  invalidUnit: Maybe<TimeUnit> = null;

  constructor(public localizer: LocalizationService) {}

  ngOnInit(): void {
    this.timeUnits = Object.values(TimeUnit);
    this.subscribeToAmountUpdate();
    this.setInitialDisplayAmount();
  }

  subscribeToAmountUpdate(): void {
    this.amountUpdate
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((amount: string) => {
        this.onChangeTimeAmount(amount);
      });
  }

  onChangeTimeAmount(newAmount: string): void {
    if (isEmpty(newAmount)) {
      return;
    }
    if (!this.isAmountCompatibleWithUnit(newAmount, this.liveModeFilter.unit)) {
      this.invalidAmount = newAmount;
      return;
    }

    this.updateTimeAmount(newAmount);
  }

  private updateTimeAmount(amount: string): void {
    const newAmount: Maybe<number> = this.evaluateAmountIfUpdated(amount);
    if (isNotDefined(newAmount)) {
      this.invalidAmount = null;
      return;
    }

    this.invalidAmount = null;
    let newUnit = this.liveModeFilter.unit;
    if (isDefined(this.invalidUnit)) {
      newUnit = this.invalidUnit;
      this.invalidUnit = null;
    }
    this.onChangeValue.emit({ unit: newUnit, amount: newAmount });
  }

  private evaluateAmountIfUpdated(newAmount: string): Maybe<number> {
    const amount: number = formatAmountToTwoDecimals(Number(newAmount));
    if (amount === this.liveModeFilter.amount) {
      return null;
    }
    return amount;
  }

  setInitialDisplayAmount(): void {
    this.liveModeFilter.amount = formatAmountToTwoDecimals(this.liveModeFilter.amount);
  }

  onChangeTimeUnit(unit: TimeUnit): void {
    if (this.isAmountValidAfterUnitChange(unit)) {
      this.onChangeValue.emit({ unit, amount: this.invalidAmount });
      this.invalidAmount = null;
      return;
    }
    if (this.isAmountCompatibleWithUnit(this.liveModeFilter.amount.toString(), unit)) {
      this.invalidUnit = null;
      this.onChangeValue.emit({ ...this.liveModeFilter, unit });
    } else {
      this.invalidUnit = unit;
    }
  }

  private isAmountValidAfterUnitChange(unit: TimeUnit): boolean {
    return (
      isDefined(this.invalidAmount) && this.isAmountCompatibleWithUnit(this.invalidAmount, unit)
    );
  }

  private isAmountCompatibleWithUnit(amount: string, unit: string): boolean {
    if (unit === TimeUnit.Seconds || unit === TimeUnit.Minutes || unit === TimeUnit.Hours) {
      return true;
    }

    return Number.isInteger(Number(amount));
  }

  get isDefaultTimeRange(): boolean {
    return (
      this.liveModeFilter.amount === DEFAULT_SPAN_IN_HOURS &&
      this.liveModeFilter.unit === LIVE_MODE_TIME_UNIT
    );
  }

  getTimeAmountInput(): Maybe<ElementRef<HTMLInputElement>> {
    return this.timeAmountInput;
  }

  getSelectElement(): Maybe<ElementRef<HTMLInputElement>> {
    return this.selectorComponent?.getSelectElement();
  }
}
