import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataExplorerSelector } from "../../../browsing/services/data-explorer.selector";
import { DataExplorerActions } from "../../../browsing/store/data-explorer";
import { Dispatcher } from "../../../dispatcher";
import { LocalizationService } from "../../../i18n/localization.service";

@Component({
  selector: "data-explorer",
  templateUrl: "data-explorer.component.html",
  styleUrls: ["./data-explorer.component.scss"]
})
export class DataExplorerComponent implements OnInit, OnDestroy {
  isSignalTabSelected: boolean = false;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();
  @Output() collapseSidebar: EventEmitter<any> = new EventEmitter();
  @Output() expandSidebar: EventEmitter<any> = new EventEmitter();

  constructor(
    public localizer: LocalizationService,
    public dispatcher: Dispatcher,
    private dataExplorerSelector: DataExplorerSelector
  ) {}

  ngOnInit(): void {
    this.dataExplorerSelector
      .selectSignalTabMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isSignalTabSelected: boolean) => {
        this.isSignalTabSelected = isSignalTabSelected;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  toggleSelectedTab(isSignalTabSelected: boolean): void {
    this.dispatcher.dispatch(DataExplorerActions.changeSignalTabMode({ isSignalTabSelected }));
  }

  collapseSidebarOnDragStart(): void {
    this.collapseSidebar.emit();
  }

  expandSidebarOnDrop(): void {
    this.expandSidebar.emit();
  }
}
