import { v4 as uuid } from "uuid";
import { CommonDataPointPropertyNames } from "../../data-connectivity/models/common-data-properties";
import { DataConnectorRole } from "../../data-connectivity/models/data-connector-role";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  ConfigurationCategory,
  DynamicDefaultFromData,
  DynamicDefaultFromFunction,
  DynamicDefaultFromMetadata,
  DynamicDefaultFromValue,
  Serializable
} from "../../meta/decorators";
import { GroupIndividualProperties } from "../../meta/decorators/group-individual-properties.decorator";
import { Placeholder } from "../../meta/decorators/placeholder.decorator";
import { EditorType } from "../../meta/models/editor-type";
import {
  FIRST_SUBGROUP_INDEX,
  GroupPropertiesInfo,
  SECOND_SUBGROUP_INDEX
} from "../../meta/models/groupable-properties";
import { PropertyCategory } from "../../meta/models/property-category";
import { validateNumberOrInterpolatedString } from "../../property-sheet/helpers/number-validation.helper";
import {
  PropertyDecoratorFunction,
  applyPropertyDecorators
} from "../../ts-utils/helpers/decorator.helper";
import { nameof } from "../../ts-utils/helpers/name-of";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { WIDGET_PROPERTIES__MIN_MAX } from "./help-constants";
import { LimitsConfig } from "./i-view-config/i-base-display-config";

export interface MinMaxConfigDto {
  min: Maybe<string>;
  max: Maybe<string>;
}

export interface MinMaxParams {
  prototype: Object;
  key: string;
  displayName: string;
  roleToTakeValuesFrom: DataConnectorRole;
  minimumRole: Maybe<DataConnectorRole>;
  maximumRole: Maybe<DataConnectorRole>;
  propertyName: string;
  defaultValue: Maybe<number>;
  isHidden: boolean;
  groupPropertiesInfo: Record<string, GroupPropertiesInfo>;
}

function configureMinOrMax(params: MinMaxParams): void {
  const configurablePropertyDecorators: PropertyDecoratorFunction[] = [
    ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General),
    Configurable({
      displayName: params.displayName,
      editorType: EditorType.TextBox,
      validationFunction: validateNumberOrInterpolatedString,
      userHelp: WIDGET_PROPERTIES__MIN_MAX
    }),
    Serializable(),
    AllowInterpolation(),
    Placeholder({
      text: LOCALIZATION_DICTIONARY.propertySheet.DynamicDefaultsPlaceholder,
      combineWithRuntimeValue: true
    })
  ];

  applyPropertyDecorators(
    params.prototype,
    params.key,
    ...[
      ...(params.isHidden ? [] : configurablePropertyDecorators),
      DynamicDefaultFromMetadata(
        params.roleToTakeValuesFrom.name,
        params.propertyName,
        -5,
        true,
        true
      )
    ]
  );

  if (isDefined(params.minimumRole)) {
    applyPropertyDecorators(
      params.prototype,
      nameof<MinMaxConfigDto>("min"),
      Serializable(null),
      DynamicDefaultFromData(params.minimumRole.name, 1)
    );
  }

  if (isDefined(params.maximumRole)) {
    applyPropertyDecorators(
      params.prototype,
      nameof<MinMaxConfigDto>("max"),
      Serializable(null),
      DynamicDefaultFromData(params.maximumRole.name, 1)
    );
  }

  if (isDefined(params.defaultValue)) {
    applyPropertyDecorators(
      params.prototype,
      params.key,
      DynamicDefaultFromValue(params.defaultValue.toString(), CALCULATED_MINMAX_PRIORITY - 1)
    );
  }

  applyPropertyDecorators(
    params.prototype,
    params.key,
    GroupIndividualProperties(params.groupPropertiesInfo[params.key])
  );
}

// this must be lower than limits priorities, as calculation deponds on limits.
const CALCULATED_MINMAX_PRIORITY = -10;

export function configureMinMax<T extends MinMaxConfigDto>(
  viewConfigType: {
    new (...args: any[]): T;
  },
  roleToTakeValuesFrom: DataConnectorRole,
  minimumRole: Maybe<DataConnectorRole>,
  maximumRole: Maybe<DataConnectorRole>,
  minDefault: Maybe<number>,
  maxDefault: Maybe<number>,
  minMaxFromLimits: number,
  isHidden: boolean = false
): void {
  const prototype = viewConfigType.prototype;
  const groupPropertiesInfo: Record<string, GroupPropertiesInfo> = getGroupProperties();

  const paramsMin: MinMaxParams = {
    prototype: prototype,
    key: nameof<MinMaxConfigDto>("min"),
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MinimumValue,
    roleToTakeValuesFrom: roleToTakeValuesFrom,
    minimumRole: minimumRole,
    maximumRole: maximumRole,
    propertyName: CommonDataPointPropertyNames.min,
    defaultValue: minDefault,
    isHidden: isHidden,
    groupPropertiesInfo: groupPropertiesInfo
  };

  configureMinOrMax(paramsMin);

  if (minMaxFromLimits !== 0) {
    applyPropertyDecorators(
      prototype,
      nameof<MinMaxConfigDto>("min"),
      DynamicDefaultFromFunction((view) => {
        const [min, max] = getOuterLimits(view);
        if (isDefined(min) && isDefined(max)) {
          return min - minMaxFromLimits * (max - min);
        }
        return null;
      }, CALCULATED_MINMAX_PRIORITY)
    );
    applyPropertyDecorators(
      prototype,
      nameof<MinMaxConfigDto>("max"),
      DynamicDefaultFromFunction((view) => {
        const [min, max] = getOuterLimits(view);
        if (isDefined(min) && isDefined(max)) {
          return max + minMaxFromLimits * (max - min);
        }
        return null;
      }, CALCULATED_MINMAX_PRIORITY)
    );
  }

  const paramsMax: MinMaxParams = {
    prototype: prototype,
    key: nameof<MinMaxConfigDto>("max"),
    displayName: LOCALIZATION_DICTIONARY.propertySheet.MaximumValue,
    roleToTakeValuesFrom: roleToTakeValuesFrom,
    minimumRole: minimumRole,
    maximumRole: maximumRole,
    propertyName: CommonDataPointPropertyNames.max,
    defaultValue: maxDefault,
    isHidden: isHidden,
    groupPropertiesInfo: groupPropertiesInfo
  };

  configureMinOrMax(paramsMax);
}

function getOuterLimits(viewConfig: LimitsConfig): Maybe<number>[] {
  const limitsDto = viewConfig.limits;
  if (isDefined(limitsDto)) {
    const lower = [limitsDto.extremeLow, limitsDto.veryLow, limitsDto.low].find(isDefined);
    const upper = [limitsDto.extremeHigh, limitsDto.veryHigh, limitsDto.high].find(isDefined);
    return [lower, upper];
  }
  return [null, null];
}

function getGroupProperties(): Record<string, GroupPropertiesInfo> {
  const groupId = uuid();
  return {
    min: { subgroupIndex: FIRST_SUBGROUP_INDEX, groupId },
    max: { subgroupIndex: SECOND_SUBGROUP_INDEX, groupId }
  };
}
