import {
  AggregationFunction,
  InheritableAggregationFunction
} from "../../data-connectivity/models/data-aggregation-function";
import { FILTERING_AND_AGGREGATION__AGGREGATION } from "../../elements/models/help-constants";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { getEnumValues } from "../../meta/decorators/configurable-enum.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyCategory } from "../../meta/models/property-category";
import { PropertyDescriptor } from "../../meta/models/property-descriptor";
import { TypeProvider } from "../../meta/services/type-provider";
import { CONNECTOR_DATA_AGGREGATION_DTO } from "../models/dto-type.constants";

const TYPE_PROVIDER = TypeProvider.getInstance();

export function replaceAggregationDefaultValue(
  propertyName: string,
  typeName: string,
  derivedVisibility: boolean
): PropertyDescriptor {
  const isConnectorOwner: boolean = typeName === CONNECTOR_DATA_AGGREGATION_DTO;
  const enumSource = isConnectorOwner ? InheritableAggregationFunction : AggregationFunction;
  const typeConstructorFunction = function Enum(_ownerInstance: any) {
    return getEnumValues(enumSource, LOCALIZATION_DICTIONARY.propertySheet.AggregateFunction);
  };

  TYPE_PROVIDER.addType({
    constructorFunction: typeConstructorFunction as unknown as new () => any,
    name: `Enum_${propertyName}`,
    isPrimitive: true
  });
  return PropertyDescriptor.create(propertyName, typeConstructorFunction, {
    displayName: LOCALIZATION_DICTIONARY.propertySheet[propertyName],
    editorType: EditorType.ComboBox,
    category: PropertyCategory.Data,
    subCategory: LOCALIZATION_DICTIONARY.propertySheet.DataAggregation,
    defaultValue: isConnectorOwner
      ? InheritableAggregationFunction.Inherited
      : AggregationFunction.None,
    isEnum: true,
    isHidden: derivedVisibility,
    userHelp: FILTERING_AND_AGGREGATION__AGGREGATION
  });
}
