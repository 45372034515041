/* eslint-disable @typescript-eslint/no-namespace */
import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { LinkDto, PositionDto } from "../../../core";
import { DataSourceDto } from "../../../data-connectivity";
import { EntityId } from "../../../meta/models/entity";
import { DeepPartial, DeepUpdate, Dictionary, Maybe } from "../../../ts-utils";
import { ComponentStateDto } from "../../models/component-state";
import { DataStatus } from "../../models/data-status";
import { LimitsDisplayMode } from "../../models/limit-modes";
import { NavLinkInfo } from "../../models/nav-link-info";
import { ComponentPositionUpdate } from "../../models/resize/component-position-update";
import { RuntimeViewUpdate } from "../../models/runtime-view-update";

export namespace ComponentStateActions {
  export const addOne = createAction(
    "[Component] Add One",
    props<{ newComponent: ComponentStateDto; parentId: EntityId | null }>()
  );

  export const addMany = createAction(
    "[Component] Add Many",
    props<{ newComponents: ComponentStateDto[] }>()
  );

  export const deleteManyWithChildren = createAction(
    "[Component] Delete Many With Children",
    props<{ targetComponents: ComponentStateDto[] }>()
  );

  export const deleteOne = createAction(
    "[Component] Delete One",
    props<{ targetComponent: ComponentStateDto }>()
  );

  export const deleteMany = createAction(
    "[Component] Delete Many",
    props<{ targetComponents: ComponentStateDto[] }>()
  );

  export const updateOne = createAction(
    "[Component] Update One",
    props<{ componentUpdate: DeepUpdate<ComponentStateDto> }>()
  );

  export const updateMany = createAction(
    "[Component] Update Many",
    props<{ componentUpdates: Update<ComponentStateDto>[] }>()
  );

  export const replace = createAction(
    "[Common] Replace",
    props<{ newComponent: ComponentStateDto }>()
  );

  export const updateWithoutChanges = createAction(
    "[Component] Update Without Changes", // FIXME: Find better approach
    props<{ componentId: EntityId }>()
  );

  export const updateComponentSize = createAction(
    "[Component] Update Component Size",
    props<{
      componentId: EntityId;
      width: string;
      height: string;
    }>()
  );

  export const changeLimitsDisplayMode = createAction(
    "[Component] Change Limits Display Mode",
    props<{ componentId: EntityId; limitsDisplayMode: LimitsDisplayMode }>()
  );

  export const updateLink = createAction(
    "[Component] Update Link",
    props<{ componentId: EntityId; link: DeepPartial<LinkDto> }>()
  );

  export const updateNavBarLinks = createAction(
    "[Component] Update Navigation Bar Link",
    props<{ componentId: EntityId; links: DeepPartial<NavLinkInfo[]> }>()
  );

  /** @param runtimeViewProps RuntimeViewUpdate RuntimeViewUpdate is inherited for specific components */
  export const updateRuntimeViewProps = createAction(
    "[Component] Update Runtime View Properties",
    props<{
      updates: RuntimeViewUpdate[];
    }>()
  );

  export const calculateRuntimeViewProps = createAction("[Component] Calculate Runtime View Props");

  export const updateExpanded = createAction(
    "[Component] Update Expanded",
    props<{ componentId: EntityId; expanded: boolean }>()
  );

  export const updatePosition = createAction(
    "[Component] Update Position",
    props<ComponentPositionUpdate>()
  );

  export const updatePositions = createAction(
    "[Component] Update Positions",
    props<{ componentPositions: ComponentPositionUpdate[] }>()
  );

  export const updateChildrenPositions = createAction(
    "[Component] Update Position Many",
    props<{ updateDict: Dictionary<PositionDto>; parentId: EntityId }>()
  );

  export const updateAbsolutePositions = createAction(
    "[Component] Update Absolute Positions",
    props<{ containerId: EntityId; updates: ComponentPositionUpdate[] }>()
  );

  export const updateRelativePositions = createAction(
    "[Component] Update Relative Positions",
    props<{ containerId: EntityId }>()
  );

  export const updateChildren = createAction(
    "[Component] Update Children",
    props<{ parentId: EntityId; childrenIdsToAdd: EntityId[] }>()
  );

  export const removeFromChildren = createAction(
    "[Component] Remove from children",
    props<{
      componentId: EntityId;
      childrenIds: EntityId[];
    }>()
  );

  export const bringToFront = createAction(
    "[Component] Bring to Front",
    props<{ componentId: EntityId }>()
  );

  export const sendToBack = createAction(
    "[Component] Send to Back",
    props<{ componentId: EntityId }>()
  );

  export const bringToFrontMany = createAction(
    "[Component] Bring to Front Many",
    props<{ componentId: EntityId; incrementalUpdateSiblings: EntityId[] }>()
  );

  export const sendToBackMany = createAction(
    "[Component] Send to Back Many",
    props<{ componentId: EntityId; incrementalUpdateSiblings: EntityId[] }>()
  );

  export const bringForward = createAction(
    "[Component] Bring Forward",
    props<{ componentId: EntityId }>()
  );

  export const sendBackward = createAction(
    "[Component] Send Backward",
    props<{ componentId: EntityId }>()
  );

  export const resolveBackgroundImage = createAction(
    "[Component] Resolve Background Image",
    props<{ componentId: EntityId; imageUrl: string }>()
  );

  export const toggleCardPositioning = createAction(
    "[Component] Toggle Card Positioning",
    props<{ componentId: EntityId }>()
  );

  export const toggleSnapToGrid = createAction(
    "[Component] Toggle Snap To Grid",
    props<{ componentId: EntityId }>()
  );

  export const calculateComponentStatusMany = createAction(
    "[Component] Calculate Component Status Many",
    props<{
      modifiedDataConnector: EntityId[];
      modifiedComponents: EntityId[];
    }>()
  );

  export const updateComponentStatusMany = createAction(
    "[Component] Update Component Status Many",
    props<{ componentStates: Dictionary<DataStatus> }>()
  );

  export const selectComponents = createAction(
    "[Component] Select Components",
    props<{
      componentIds: EntityId[];
    }>()
  );

  export const copyComponents = createAction(
    "[Component] Copy",
    props<{
      componentIds: EntityId[];
    }>()
  );

  export const pasteComponents = createAction(
    "[Component] Paste",
    props<{
      pasteIntoId: EntityId;
      areWidgetsPasted: boolean;
      pasteTargetId?: Maybe<EntityId>;
    }>()
  );

  export const cutComponents = createAction(
    "[Component] Cut",
    props<{
      componentIds: EntityId[];
    }>()
  );

  export const updateOrderSetBefore = createAction(
    "[Component] Update Order set before Sibling",
    props<{
      targetSiblingId: EntityId;
      componentId: EntityId;
    }>()
  );

  export const updateOrderSetAfter = createAction(
    "[Component] Update Order set after Sibling",
    props<{
      targetSiblingId: EntityId;
      componentId: EntityId;
    }>()
  );

  export const updateOrderSetAtEnd = createAction(
    "[Component] Update Order set at the End",
    props<{
      componentId: EntityId;
    }>()
  );

  export const cloneComponent = createAction(
    "[Component] Clone Component",
    props<{
      component: ComponentStateDto;
      cloneInto: EntityId;
      isWidget: boolean;
      orderSource?: EntityId;
    }>()
  );

  export const moveToContainer = createAction(
    "[Component] Move to Container",
    props<{
      componentsIds: EntityId[];
      destinationComponent: EntityId;
      dropPositions: Dictionary<PositionDto>;
      dropTargetId: EntityId;
      isDroppedOnRightSide: boolean;
    }>()
  );

  export const copyComponentsIntoCard = createAction(
    "[Component] Copy Into Card",
    props<{
      componentsIds: EntityId[];
      copyIntoId: EntityId;
      dropPositions: Dictionary<PositionDto>;
      dropTargetId: EntityId;
      isDroppedOnRightSide: boolean;
    }>()
  );

  export const cloneCard = createAction(
    "[Component] Clone Card",
    props<{
      cardId: EntityId;
      dropTargetId: Maybe<EntityId>;
      isDroppedOnRightSide: boolean;
    }>()
  );

  export const updateApiQueryParams = createAction(
    "[Component] Update Connector Query API Params",
    props<{ componentId: EntityId; params: DeepPartial<DataSourceDto> }>()
  );
}
