import { TimeRange } from "../../core/models/time-range";
import { TimeUnit } from "../../core/models/time-unit";
import { calculateSpanInHours } from "../../core/services/filter/filter-factory.service";
import { isFloatWithMoreThanTwoDecimals } from "../../ts-utils/helpers/number.helper";
import { LiveModeFilter } from "../models/live-mode-filter";

export interface LiveModeTimeUnit {
  key: string;
  text: TimeUnit;
  hours: number;
}

export const TimeUnits: LiveModeTimeUnit[] = [
  { key: "sec", text: TimeUnit.Seconds, hours: 1 / 3600 },
  { key: "min", text: TimeUnit.Minutes, hours: 1 / 60 },
  { key: "hrs", text: TimeUnit.Hours, hours: 1 },
  { key: "day", text: TimeUnit.Days, hours: 24 },
  { key: "wek", text: TimeUnit.Weeks, hours: 168 },
  { key: "mth", text: TimeUnit.Months, hours: 730 },
  { key: "yer", text: TimeUnit.Years, hours: 8760 }
];

export function resolveLiveModeFilter(timeRange: TimeRange): LiveModeFilter {
  const hours = calculateSpanInHours(timeRange);
  return {
    amount: parseFloat(hours.toFixed(2)),
    unit: TimeUnit.Hours
  };
}

export function formatAmountToTwoDecimals(amount: number): number {
  let liveModeAmount: number = amount;
  if (isFloatWithMoreThanTwoDecimals(liveModeAmount)) {
    liveModeAmount = parseFloat(liveModeAmount.toFixed(2));
  }
  return liveModeAmount;
}
