<div
  #linkWrapper
  *ngFor="let link of value; let linkIndex = index"
  class="rounded-button__wrapper"
  tabindex="0"
>
  <button
    title="{{ translationService.propertySheet.UnlinkButtonTooltip | translate }}"
    class="rounded-button"
    (click)="unlinkWidget(linkIndex)"
    (mouseover)="highlightSourceWidget(link.sourceWidgetId)"
    (mouseout)="unhighlightSourceWidget(link.sourceWidgetId)"
  >
    <i class="abb-icon Link"></i> Unlink {{ link.sourceWidgetId }}
  </button>
</div>
<div class="rounded-button__wrapper">
  <button
    title="{{ translationService.propertySheet.LinkButtonTooltip | translate }}"
    class="rounded-button"
    [class.link-widget__button--active]="isLinkingModeActive"
    (click)="linkWidget()"
    (contextmenu)="openHelp()"
  >
    <i class="abb-icon Link"></i> Link widget
  </button>
</div>
