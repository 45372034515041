import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BRANCH, BUILD_DATE, BUILD_NUMBER } from "../../../environment/models/build-info";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { BaseDialogComponent } from "../base-dialog/base-dialog.component";

@Component({
  selector: "c-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutComponent extends BaseDialogComponent {
  buildDate: string;
  buildNumber: string;
  branch: string;
  currentYear: number;

  constructor(dateFormatter: DateFormatterService) {
    super();
    this.buildDate = dateFormatter.formatDate(BUILD_DATE);
    this.buildNumber = BUILD_NUMBER;
    this.branch = BRANCH;
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
  }
}
