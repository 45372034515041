import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConnectivitySelector } from "../../../data-connectivity/services/connectivity.selector";
import { Dispatcher } from "../../../dispatcher";
import { RuntimeSettingsSelector } from "../../../elements/services/entity-selectors/runtime-settings.selector";
import { RuntimeSettingsActions } from "../../../elements/store/runtime-settings/runtime-settings.actions";
import { LocalizationService } from "../../../i18n/localization.service";
import { FilterToolbarElementConfig } from "../../models/button/filter-toolbar-element.config";
import { FilterType } from "../../models/filter-type";

@Component({
  selector: "runtime-period-type",
  templateUrl: "runtime-period-type.component.html",
  styleUrls: ["./runtime-period-type.component.scss"]
})
export class RuntimePeriodTypeComponent implements OnInit, OnDestroy {
  @Input() isFilterBarSelected: boolean = false;
  @Input() canExpandHorizontally: boolean = false;
  @Output() showFilterBar: EventEmitter<any> = new EventEmitter();
  public icon = "Bar_chart";
  public toggleButton: FilterToolbarElementConfig;
  public periodTypes: string[];
  public runtimePeriodType: string = "";
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private dispatcher: Dispatcher,
    public localizer: LocalizationService,
    private runtimeSettingsSelector: RuntimeSettingsSelector,
    private connectivitySelector: ConnectivitySelector
  ) {}

  ngOnInit(): void {
    this.initButtonsConfig();
    this.connectivitySelector
      .selectPeriodTypes()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((periodTypesFromStore) => {
        this.periodTypes = Object.keys(periodTypesFromStore);
      });

    this.runtimeSettingsSelector.selectPeriodType().subscribe((periodType) => {
      this.runtimePeriodType = periodType ?? "";
    });
  }

  initButtonsConfig(): void {
    this.toggleButton = this.createToggleButtonConfig();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private createToggleButtonConfig(): FilterToolbarElementConfig {
    return new FilterToolbarElementConfig({
      icon: this.icon,
      clickFunction: () => {
        return this.toggleFilterBar();
      },
      class: "small-label period-type__label--responsive"
    });
  }

  closeIfDropdown(): void {
    if (!this.canExpandHorizontally && this.isFilterBarSelected) {
      this.toggleFilterBar();
    }
  }

  public toggleFilterBar(): void {
    this.showFilterBar.emit(FilterType.PeriodType);
  }

  public onChangePeriodType(periodType: string, event: Event): void {
    event.stopPropagation();
    this.dispatcher.dispatch(RuntimeSettingsActions.setPeriodType({ pType: periodType }));
    this.closeBar();
  }

  closeBar(event?: Event): void {
    this.toggleFilterBar();
    event?.stopPropagation();
  }
}
