import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import {
  SERIES_TYPE_X_PLOT_LINE,
  SERIES_TYPE_Y_PLOT_LINE
} from "../../../data-connectivity/models/series-type.strategies";
import { first } from "../../../ts-utils/helpers/array.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ISeriesBaseConfig } from "../../models/series-base-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { YAxisDescriptor } from "../../models/y-axis-descriptor";
import { axisIdToIndex } from "./base-highcharts-options.helper";

interface IPlotLineConfig {
  isXLine: boolean;
  axisId: Maybe<string>;
  value: number;
  color: Maybe<string>;
  width?: number;
  dashStyle?: string;
  connectorId?: string;
}

export abstract class PlotLineGenerator {
  private isXPlotLine(dataConnectorDescriptor: DataConnectorDescriptor): boolean {
    return (
      dataConnectorDescriptor.connectorView?.timeSeriesConfig?.seriesType ===
      SERIES_TYPE_X_PLOT_LINE
    );
  }
  private isYPlotLine(dataConnectorDescriptor: DataConnectorDescriptor): boolean {
    return (
      dataConnectorDescriptor.connectorView?.timeSeriesConfig?.seriesType ===
      SERIES_TYPE_Y_PLOT_LINE
    );
  }
  public isPlotLine(dataConnectorDescriptor: DataConnectorDescriptor): boolean {
    return this.isXPlotLine(dataConnectorDescriptor) || this.isYPlotLine(dataConnectorDescriptor);
  }

  toHighchartsPlotLine(config: IPlotLineConfig): Highcharts.XAxisPlotLinesOptions {
    const plotLine = {
      value: config.value,
      color: config.color ?? undefined,
      width: config.width ?? 2,
      dashStyle: config.dashStyle as Highcharts.DashStyleValue,
      zIndex: 10,
      id: config.connectorId
    };
    return plotLine;
  }

  abstract getSeriesConfig(connectorView: DataConnectorViewDto): ISeriesBaseConfig;

  toPlotLineConfig(dataConnectorDescriptor: DataConnectorDescriptor): Maybe<IPlotLineConfig> {
    const connector = dataConnectorDescriptor.connector;
    const connectorView = dataConnectorDescriptor.connectorView;
    if (!isDefined(connectorView) || !isDefined(connector)) {
      return null;
    }
    const seriesConfig: ISeriesBaseConfig = this.getSeriesConfig(connectorView);
    const isX = seriesConfig?.seriesType === SERIES_TYPE_X_PLOT_LINE;
    const isY = seriesConfig?.seriesType === SERIES_TYPE_Y_PLOT_LINE;

    if (isX || isY) {
      const value = first(dataConnectorDescriptor.connector?.dataPoints)?.y;
      if (isDefined(value)) {
        return {
          isXLine: isX,
          axisId: connectorView.axisId,
          value,
          color: connectorView.color,
          connectorId: connector.id.toString()
        };
      }
    }
    return null;
  }

  public addPlotLines(
    opt: Highcharts.Options,
    dataConnectorDescriptors: DataConnectorDescriptor[],
    yAxisDesc: YAxisDescriptor[]
  ): void {
    const plotLineConfigs = dataConnectorDescriptors
      .map((dcd) => this.toPlotLineConfig(dcd))
      .filter(isDefined);

    const optXAxis = opt.xAxis as Highcharts.XAxisOptions[];
    optXAxis[0].plotLines = plotLineConfigs
      .filter((config) => config.isXLine)
      .map((config) => this.toHighchartsPlotLine(config) as Highcharts.XAxisPlotLinesOptions);

    const optYAxis = opt.yAxis as Highcharts.YAxisOptions[];
    optYAxis.forEach((yAxis, index) => {
      const forAxis = plotLineConfigs.filter(
        (config) => !config.isXLine && axisIdToIndex(config.axisId, yAxisDesc) === index
      );
      yAxis.plotLines = forAxis.map(
        (config) => this.toHighchartsPlotLine(config) as Highcharts.YAxisPlotLinesOptions
      );
    });
  }
}

export class PlotLineGeneratorTimeSeries extends PlotLineGenerator {
  getSeriesConfig(connectorView: DataConnectorViewDto): ISeriesBaseConfig {
    return connectorView.timeSeriesConfig;
  }

  override toPlotLineConfig(
    dataConnectorDescriptor: DataConnectorDescriptor
  ): Maybe<IPlotLineConfig> {
    const baseConfig = super.toPlotLineConfig(dataConnectorDescriptor);
    if (isDefined(baseConfig)) {
      const timeSeriesConfig = dataConnectorDescriptor.connectorView?.timeSeriesConfig;
      baseConfig.width = timeSeriesConfig?.lineWidth;
      baseConfig.dashStyle = timeSeriesConfig?.lineStyle;
    }
    return baseConfig;
  }
}

export class PlotLineGeneratorScatter extends PlotLineGenerator {
  getSeriesConfig(connectorView: DataConnectorViewDto): ISeriesBaseConfig {
    return connectorView.scatterSeriesConfig;
  }
}
