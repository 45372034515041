import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from "@angular/core";
import { Dispatcher } from "../../dispatcher";
import { EnvironmentSelector } from "../../environment/services/environment.selector";
import { EntityId } from "../../meta/models/entity";
import { NgxQuillEditorWrapperComponent } from "../../shared/components/ngx-quill-editor-wrapper/ngx-quill-editor-wrapper.component";
import { UndoRedoService } from "../../shared/services/undo-redo.service";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { CSS_MULTILINE_LABEL } from "../components/label/label.component";
import { LabelViewConfig } from "../components/label/view-config";
import { keepInputUpdated } from "../helpers/inline-edit.helper";
import { isSingleLineLabel } from "../models/display-strategies/display-strategy-type.helper";
import { InlineEditService } from "../services/inline-edit.service";
import { ComponentStateActions } from "../store/component-state/component-state.actions";
import { InlineEditDirective } from "./inline-edit.directive";

@Directive({
  selector: "[label-inline-edit]"
})
export class LabelInlineEditDirective extends InlineEditDirective implements OnChanges {
  @Input() editableText: string = "";
  private editorComponent: Maybe<NgxQuillEditorWrapperComponent> = null;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected element: ElementRef,
    protected inlineEditService: InlineEditService,
    protected renderer: Renderer2,
    protected dispatcher: Dispatcher,
    protected undoRedoService: UndoRedoService,
    protected environmentSelector: EnvironmentSelector,
    public viewContainerRef: ViewContainerRef
  ) {
    super(
      cdr,
      element,
      inlineEditService,
      renderer,
      dispatcher,
      undoRedoService,
      environmentSelector,
      viewContainerRef
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isDefined(this.editorComponent)) {
      this.editorComponent.value = this.editableText;
      setTimeout(() => {
        if (isDefined(this.editorComponent)) {
          this.editorComponent.setFocus();
          this.editorComponent.preserveCursorPosition();
        }
      }, 200);
    }
    if (isDefined(this.inlineComponentParams)) {
      keepInputUpdated(this.inlineComponentParams.hostElement, this.editableText);
    }
  }

  protected initializeInlineEditing(): void {
    super.initializeInlineEditing();
    if (
      isSingleLineLabel((this.inlineComponentParams?.viewConfig as LabelViewConfig).displayStrategy)
    ) {
      this.prepareInlineSimpleTextEditing(this.editableText, this.inlineComponentParams?.id);
    } else {
      this.insertEditorComponent();
    }
  }

  private insertEditorComponent(): void {
    if (isDefined(this.editorComponent)) {
      return;
    }

    this.addOrRemoveElementSiblings(false);
    this.editorComponent = this.loadComponent(NgxQuillEditorWrapperComponent);
    this.editorComponent.value = this.editableText;
    this.editorComponent.componentId = this.inlineComponentParams?.id;
    this.editorComponent.topOffset = this.inlineComponentParams?.viewConfig.css.top;
    this.applyStyleToHostElement(true);
    setTimeout(() => {
      if (isDefined(this.editorComponent?.quillEditor)) {
        this.editorComponent?.quillEditor.setSelection(this.editableText.length, 0);
      }
    }, 300);
  }

  private applyStyleToHostElement(addOverflow: boolean): void {
    const multilineLabelElement = this.inlineComponentParams?.hostElement.querySelector(
      "." + CSS_MULTILINE_LABEL
    );
    addOverflow
      ? this.renderer.setStyle(multilineLabelElement, "overflow", "visible")
      : this.renderer.removeStyle(multilineLabelElement, "overflow");
  }

  protected updateTargetTitle(componentId: EntityId, newValue: string): void {
    if (this.editableText !== newValue) {
      super.updateTargetTitle(componentId, newValue);
      this.dispatcher.dispatch(
        ComponentStateActions.updateOne({
          componentUpdate: {
            id: componentId.toString(),
            changes: {
              view: { title: newValue }
            }
          }
        })
      );
    }
  }

  protected exitInlineMode(
    componentId: EntityId,
    shouldShowDraggableOverlay: boolean = true
  ): void {
    if (isDefined(this.editorComponent)) {
      this.exitMultiLabelInlineEditing();
    }
    super.exitInlineMode(componentId, shouldShowDraggableOverlay);
  }

  private exitMultiLabelInlineEditing(): void {
    this.addOrRemoveElementSiblings(true);
    this.applyStyleToHostElement(false);
    this.removeComponent(this.editorComponent);
    this.editorComponent = null;
  }
}
