import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import {
  DataPointCategory,
  DataPointDto,
  XAxisPoint
} from "../../data-connectivity/models/data-point";
import { DateFormatterService } from "../../environment/services/date-formatter.service";
import { Dictionary, isDefined, removeDuplicates } from "../../ts-utils";
import { DisplayFormatDto } from "../models/display-format";

export class PivotTableHelper {
  private _categories: DataPointCategory[];
  public get categories(): DataPointCategory[] {
    return this._categories;
  }

  // private _matrix: number[][];

  // public get matrix(): number[][] { return this._matrix; }

  private _matrix: Dictionary<number[]>;

  public getVector(connector: DataConnectorDto): number[] {
    return this._matrix[connector.id];
  }

  constructor(
    private connectors: DataConnectorDto[],
    private dateFormatter: DateFormatterService,
    private displayFormat: DisplayFormatDto
  ) {
    this._categories = removeDuplicates(
      this.connectors
        .map((connector) =>
          this.getDataPoints(connector).map((dataPoint) => this.formatCategory(dataPoint.x))
        )
        .flat()
    );
    this._matrix = this.connectors.reduce((acc, connector) => {
      acc[connector.id] = this.sortData(this.getDataPoints(connector), this._categories);
      return acc;
    }, {});

    // this._matrix = connectors.map(c => this.sortData(this.getDataPoints(c), this._categories));
  }

  private formatCategory(catObj: XAxisPoint): string {
    if (isDefined(catObj)) {
      if (catObj instanceof Date) {
        return this.dateFormatter.formatDate(catObj, this.displayFormat.dateFormat);
      } else {
        return String(catObj);
      }
    }
    return "";
  }

  private getDataPoints(connector: DataConnectorDto): DataPointDto[] {
    if (connector.dataPoints == null) {
      return [];
    } else {
      return connector.dataPoints;
    }
  }

  private sortData(points: DataPointDto[], categories: string[]): number[] {
    const res = new Array<number>(categories.length).fill(0);
    for (const point of points) {
      const index = categories.indexOf(this.formatCategory(point.x));
      res[index] = point.evaluatedValue ?? point.y;
    }
    return res;
  }
}
