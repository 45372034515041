import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from "@angular/core";
import { LinkResolver } from "../../../browsing/services/link-resolver";
import { getDisplayValue as getLinkDisplayValue } from "../../../core/models/link";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { isDefined, Maybe } from "../../../ts-utils";
import { TableComponentCell } from "../../models/table-component-cell";
import { SimpleSingleValueViewConfig } from "./view-config";

@Component({
  selector: "c-simple-single-value",
  templateUrl: "./simple-single-value.component.html",
  styleUrls: ["./simple-single-value.component.scss"]
})
export class SimpleSingleValueComponent implements TableComponentCell, OnInit, OnChanges {
  public displayValue: number | string = "";
  @Input() public config: SimpleSingleValueViewConfig = new SimpleSingleValueViewConfig();
  @Input() public value: number | string = "";

  constructor(private linkResolver: LinkResolver, private valueFormatter: ValueFormatterService) {}

  ngOnInit(): void {
    this.setDisplayValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (anInputHasChanged(changes)) {
      this.setDisplayValue();
    }
  }

  public getLinkUrl(): Maybe<string> {
    if (isDefined(this.config.link)) {
      const value = getLinkDisplayValue(this.config.link);
      return value !== "" ? value : null;
    }
    return null;
  }

  private setDisplayValue(): void {
    this.displayValue = this.valueFormatter.formatValue(this.value, this.config.displayFormat);
  }

  onClick(event: Event): void {
    if (isDefined(this.config.link)) {
      this.linkResolver.resolveLink(this.config.link);
      event.preventDefault();
    }
  }
}

export function anInputHasChanged(changes: SimpleChanges): boolean {
  const valueChanged = (change: SimpleChange): boolean =>
    change.previousValue !== change.currentValue;
  return Object.values(changes).filter(valueChanged).length > 0;
}
