import { v4 as uuid } from "uuid";
import { fontSize14 } from "../../../_typography";
import { construct } from "../../core";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  AllowInterpolation,
  Configurable,
  EditableType,
  EditorType,
  EntityId,
  Key,
  Serializable,
  Title
} from "../../meta";
import { DeepPartial } from "../../ts-utils";
import { DEFAULT_TITLE } from "./default-property-value.constants";
import { WIDGET_PROPERTIES__BACKGROUND_IMAGE_AND_COLOR } from "./help-constants";

const TYPE_NAME = "TabConfig";
const DEFAULT_FONT_SIZE = fontSize14;

// @dynamic
@EditableType({ fullName: TYPE_NAME })
export class TabConfig {
  typeName = TYPE_NAME;

  @Key()
  @Serializable()
  id: string;

  @AllowInterpolation()
  @Title()
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    editorType: EditorType.TextBox
  })
  @Serializable(DEFAULT_TITLE)
  title!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.BackgroundImage,
    editorType: EditorType.ImageSelection,
    userHelp: WIDGET_PROPERTIES__BACKGROUND_IMAGE_AND_COLOR
  })
  @Serializable("")
  backgroundImage!: string;

  @Configurable({
    editorType: EditorType.Number,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FontSize
  })
  @Serializable(DEFAULT_FONT_SIZE)
  fontSize!: number;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Color,
    editorType: EditorType.ColorPicker,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.TextColorTooltip
  })
  @Serializable("")
  titleColor!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Bold,
    editorType: EditorType.CheckBox
  })
  @Serializable(false)
  boldTitle!: boolean;

  @Serializable()
  contentContainerId: EntityId;

  constructor(tabConfigDto: DeepPartial<TabConfig> = {}) {
    construct(this, tabConfigDto, TYPE_NAME, { id: uuid() });
  }
}
