import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  Type,
  ViewChild
} from "@angular/core";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { basicCardFooterHeight, basicCardHeaderHeight } from "../../../../style-variables";
import { getLink } from "../../../browsing/services/link-resolver";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { EntityId } from "../../../meta/models/entity";
import { ButtonConfig } from "../../../shared/models/button/button-config";
import {
  DeepPartial,
  Dictionary,
  Maybe,
  isDefined,
  isEmpty,
  isEmptyOrNotDefined,
  isNotDefined
} from "../../../ts-utils";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { EditableLayoutDirective } from "../../directives/editable-layout.directive";
import { getConfiguredOrForegroundColor } from "../../helpers/color.helper";
import { getCardRuntimeButtons } from "../../helpers/get-card-buttons.helper";
import { LINK_BUTTON_CSS_CLASS } from "../../helpers/get-dynamic-buttons.helper";
import {
  isAbsolutePositioningType,
  switchPositioningType
} from "../../helpers/positioning-type.helper";
import { findClosestTabGroup } from "../../helpers/widget-dragging.helper";
import { ComponentStateDto, ComponentStyleDto } from "../../models";
import { CardImageProps } from "../../models/card-image-props";
import { isTabGroup } from "../../models/component-type.helper";
import { CssPosition } from "../../models/css-position";
import { BASIC_CARD } from "../../models/element-type.constants";
import { BASIC_CARD as BASIC_CARD_HELP } from "../../models/help-constants";
import { Orientation } from "../../models/orientation";
import { PositioningType } from "../../models/positioning-type";
import { ComponentPositionUpdate } from "../../models/resize/component-position-update";
import { NEUTRAL_SCALING_FACTOR } from "../../models/runtime-view-properties";
import { ActiveContainersService } from "../../services/active-containers.service";
import { ComponentPositioningService } from "../../services/component-positioning.service";
import { ComponentStateViewModelDeserializer } from "../../services/deserializers/component-state-vm.deserializer";
import { ComponentStateActions } from "../../store";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { CardComponent } from "../card/card.component";
import { ContainerComponent } from "../container/container.component";
import { TabGroupComponent } from "../tab-group/tab-group.component";
import { CardButtonParams } from "./card-button-params";
import { Roles } from "./roles";
import { BasicCardViewConfig } from "./view-config";

const CSS_BASIC_CARD_FOCUSED = "basic-card--focused";

@Component({
  selector: "basic-card",
  templateUrl: "./basic-card.component.html",
  styleUrls: ["./basic-card.component.scss"],
  providers: [
    { provide: BaseComponent, useExisting: BasicCardComponent },
    { provide: ContainerComponent, useExisting: BasicCardComponent }
  ]
})
@LayoutBuilder(
  ComponentCategory.Container,
  BASIC_CARD,
  "icon-Basic-Card",
  "dashboard-icon",
  undefined,
  LOCALIZATION_DICTIONARY.layoutEditor.BasicCard,
  BASIC_CARD_HELP
)
@ConnectorRoles(Roles)
@EditableWidget({ fullName: BASIC_CARD, title: "basic-card" })
export class BasicCardComponent
  extends CardComponent
  implements CardButtonParams, OnInit, OnDestroy, AfterViewChecked
{
  @ViewChild("cardBody", { static: true }) cardBody!: ElementRef;
  @HostBinding("attr.EditableLayoutDirective") layoutEditor!: EditableLayoutDirective;
  @HostBinding("class.box__shadow--disabled") public get hasShadow() {
    return this.view.noBoxShadow;
  }
  public cardSpecificButtons: ButtonConfig[] = [];
  public backgroundImageCSS: Partial<CSSStyleDeclaration> = {};
  public resizeSnapGrid: any;
  public enableGhostResize: boolean = true;
  positioningType$!: Observable<PositioningType>;
  interpolatedViewConfig!: BasicCardViewConfig;

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    activeContainersService: ActiveContainersService,
    componentPositioningService: ComponentPositioningService,
    public filterFactory: FilterFactory,
    public vmConverter: ComponentStateViewModelDeserializer,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, activeContainersService, componentPositioningService, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initDragging();
    this.initEditableLayout();

    // NOTE if moved to initSubscriptions errors happen -> currentState is undefined
    this.subscribeToCardBackgroundImage();
    this.subscribeToPositioningTypeChange();
  }

  initDragging(): void {
    this.draggabillyConfig = Object.assign(this.draggabillyConfig, {
      containment: "#" + this.hostElement.id + " .basic-card__body"
    });
  }

  initEditableLayout(): void {
    this.layoutEditor = new EditableLayoutDirective(this, this.environmentSelector);
    this.layoutEditor.ngOnInit();
  }

  private subscribeToCardBackgroundImage(): void {
    this.componentStateSelector
      .selectCardImageProps(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((imageProps) => {
        this.adjustBackgroundImageStyle(imageProps);
      });
  }

  protected get isResizable(): boolean {
    return super.isResizable && this.view.resizable;
  }

  private subscribeToPositioningTypeChange(): void {
    this.positioningType$.subscribe((positioningType: PositioningType) => {
      if (isNotDefined(this.dynamicChildren) || this.undoRedoService.isLocked) {
        return;
      }
      switchPositioningType(positioningType, {
        Absolute: () => {
          this.dispatch(
            ComponentStateActions.updateAbsolutePositions({
              containerId: this.id,
              updates: this.getChildrenPositionUpdates()
            })
          );
        },
        Relative: () => {
          this.dispatch(ComponentStateActions.updateRelativePositions({ containerId: this.id }));
        }
      });
    });
  }

  private getChildrenPositionUpdates(): ComponentPositionUpdate[] {
    const { top: parentTop, left: parentLeft } = this.hostElement.getBoundingClientRect();
    const headerHeight: number = this.interpolatedViewConfig.showHeader ? basicCardHeaderHeight : 0;
    const scrollTop = this.cardBody.nativeElement.scrollTop;
    const scrollLeft = this.cardBody.nativeElement.scrollLeft;
    return this.dynamicChildren.components.map((component: ComponentRef<BaseComponent>) => {
      const boundingRect = (
        component.location.nativeElement as HTMLElement
      ).getBoundingClientRect();
      return {
        componentId: component.instance.id,
        offsetLeft: boundingRect.left - parentLeft + scrollLeft,
        offsetTop: boundingRect.top - parentTop - headerHeight + scrollTop
      } as ComponentPositionUpdate;
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.layoutEditor.ngOnDestroy();
  }

  @View(BasicCardViewConfig)
  public get view(): BasicCardViewConfig {
    return this.currentState.view as BasicCardViewConfig;
  }

  protected updateDisplay(): void {
    this.interpolatedViewConfig =
      this.propertyInterpolationService.collectInterpolatedProperties<BasicCardViewConfig>(
        this.currentState,
        []
      ).viewConfig;
  }

  protected preInitSubscriptions(): void {
    this.positioningType$ = this.componentStateSelector
      .selectPositioningType(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$));
  }

  protected initRuntimeButtons(): void {
    const buttonsAreNotDefined =
      isNotDefined(this.cardSpecificButtons) ||
      (isDefined(this.cardSpecificButtons) && this.cardSpecificButtons.length === 0);
    if (buttonsAreNotDefined) {
      this.cardSpecificButtons = getCardRuntimeButtons(this);
    }
  }

  protected showOrHideLinkButton(): void {
    const linkButton: Maybe<ButtonConfig> = this.cardSpecificButtons.find(
      (button) => button.style.buttonClass === LINK_BUTTON_CSS_CLASS
    );
    if (isDefined(linkButton)) {
      linkButton.pageUrl = getLink(this);
    }
  }

  protected shouldApplyBackgroundImageToHost(): boolean {
    return false;
  }

  protected canContain(componentForAttaching: Type<BaseComponent>): boolean {
    const isInstanceOfBaseComponent: boolean =
      componentForAttaching.prototype instanceof BaseComponent;
    const componentIsContainer: boolean =
      componentForAttaching.prototype instanceof ContainerComponent;
    const isTabGroup: boolean = this.typeProvider.areEqualTypes(
      componentForAttaching,
      TabGroupComponent
    );
    return isInstanceOfBaseComponent && !(componentIsContainer || (isTabGroup && this.hasTabs()));
  }

  protected getAliasDefaults(
    alias: string,
    parent: ComponentStateDto
  ): DeepPartial<ComponentStateDto> {
    const containerDefaults = super.getAliasDefaults(alias, parent);
    const cardCss = isAbsolutePositioningType(this.interpolatedViewConfig.positioningType)
      ? {
          position: CssPosition.absolute,
          top: "0",
          left: "0"
        }
      : {
          position: CssPosition.relative,
          top: "",
          left: ""
        };

    return {
      ...containerDefaults,
      view: {
        ...containerDefaults.view,
        css: {
          ...containerDefaults.view?.css,
          ...cardCss
        }
      }
    };
  }

  protected shouldUpdateDroppedComponentPosition(
    componentState: Maybe<ComponentStateDto>
  ): boolean {
    return (
      isAbsolutePositioningType(this.interpolatedViewConfig.positioningType) &&
      super.shouldUpdateDroppedComponentPosition(componentState)
    );
  }

  protected onChildrenChange(previousChildIds: EntityId[], currentChildIds: EntityId[]): void {
    super.onChildrenChange(previousChildIds, currentChildIds);
    const imageProps: CardImageProps = this.componentStateSelector.getCardImageProps(this.id);
    this.adjustBackgroundImageStyle(imageProps);
  }

  private adjustBackgroundImageStyle(imageProps: CardImageProps): void {
    const style: Partial<CSSStyleDeclaration> = {};
    const { showFooter, showHeader, imageData } = imageProps;
    if (isEmptyOrNotDefined(imageData) || this.hasTabs()) {
      this.backgroundImageCSS = {};
      return;
    }
    const topOffset = showHeader ? basicCardHeaderHeight : 0;
    const bottomOffset = showFooter ? basicCardFooterHeight : 0;

    style["top"] = `${topOffset}px`;
    style["left"] = "0px";
    style["height"] = `calc(100% - ${topOffset + bottomOffset}px)`;
    style["width"] = `100%`;
    style["backgroundImage"] = `url('${imageData}')`;
    style["backgroundSize"] = `100% 100%`;
    style["backgroundRepeat"] = "no-repeat";
    style["zIndex"] = "-1";
    this.backgroundImageCSS = style;
  }

  private hasTabs(): boolean {
    return this.componentStateSelector
      .getManyById(this.currentState.childrenIds)
      .some((childState: ComponentStateDto) => isTabGroup(childState.type));
  }

  public get bodyStyle(): Partial<CSSStyleDeclaration> {
    const style: Partial<CSSStyleDeclaration> = {};
    const shouldHideOverflow =
      this.interpolatedViewConfig.expandable && !this.interpolatedViewConfig.expanded;
    const isScalingEnabled = isAbsolutePositioningType(this.interpolatedViewConfig.positioningType);
    const isNonNeutralScalingFactor =
      this.interpolatedViewConfig.runtimeView.scalingFactor !== NEUTRAL_SCALING_FACTOR;
    if (shouldHideOverflow) {
      style["overflow"] = "hidden";
    } else {
      if (isScalingEnabled && isNonNeutralScalingFactor) {
        style["transform"] = `scale(${this.interpolatedViewConfig.runtimeView.scalingFactor})`;
        style["transformOrigin"] = `0 0`;
      } else {
        style["transform"] = `scale(1)`;
        style["overflowX"] = this.shouldEnableScrollbar(Orientation.Horizontal, this.cardBody)
          ? "auto"
          : "hidden";
        style["overflowY"] = this.shouldEnableScrollbar(Orientation.Vertical, this.cardBody)
          ? "auto"
          : "hidden";
      }
    }

    return style;
  }

  applyCssToHostElement(css: Partial<ComponentStyleDto>): void {
    super.applyCssToHostElement(css);
    this.cdr.markForCheck();
  }

  public toggleExpanded(): void {
    this.snapshotDispatch(
      ComponentStateActions.updateExpanded({
        componentId: this.id,
        expanded: !this.interpolatedViewConfig.expanded
      })
    );
  }

  protected visualizeFocus(): void {
    this.isSelected || this.isHovered
      ? this.hostElement.classList.add(CSS_BASIC_CARD_FOCUSED)
      : this.hostElement.classList.remove(CSS_BASIC_CARD_FOCUSED);
  }

  getConnectorsFromChildComponents(): Dictionary<DataConnectorDto[]> {
    return this.currentState.childrenIds.reduce(
      (acc: Dictionary<DataConnectorDto[]>, componentId) => {
        acc[componentId.toString()] = this.dataConnectorSelector.getForComponent(componentId);
        return acc;
      },
      {}
    );
  }

  shouldUnhideConfigButtons(event: MouseEvent): boolean {
    const tabGroup = findClosestTabGroup(document.elementsFromPoint(event?.pageX, event?.pageY));
    return (
      this.isEditable &&
      isNotDefined(tabGroup) &&
      isDefined(this.configButtonsContainer) &&
      this.interpolatedViewConfig.showHeader
    );
  }

  selectSingle(component: ComponentStateDto): void {
    this.componentSelectionService.selectSingle(component);
  }

  protected makeConfigButtonsFullyVisible(): void {}

  protected hideParentsConfigButtons(event: MouseEvent): void {}

  protected revertOverlappingWidgetsState(): void {}

  protected unhideParentsConfigButtons(event: MouseEvent): void {}

  public get titleColor(): string {
    return !isEmpty(this.interpolatedViewConfig.color)
      ? getConfiguredOrForegroundColor(
          this.interpolatedViewConfig.color,
          this.interpolatedViewConfig.foregroundColor
        )
      : this.interpolatedViewConfig.foregroundColor;
  }
}
