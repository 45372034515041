import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, ofType } from "@ngrx/effects";
import { isEqual as _isEqual } from "lodash";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, take } from "rxjs/operators";
import { TimeRange } from "../../../core/models/time-range";
import { Dispatcher } from "../../../dispatcher";
import { ConnectorRoles } from "../../../elements/decorators/connector-roles.decorator";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { LocalizationService } from "../../../i18n/localization.service";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { DateTimePickerDialogActions } from "../../dialogs/actions/date-time-picker-dialog.actions";
import { DateTimePickerDialogInfo } from "../../models/date-time-picker-dialog-info";

export const DATE_TIME_PICKER_DIALOG_WIDTH = 210;
export const DATE_TIME_PICKER_DIALOG_HEIGHT = 395;

@Component({
  selector: "historic-range",
  templateUrl: "./historic-range.component.html",
  styleUrls: ["./historic-range.component.scss"]
})
@ConnectorRoles()
@EditableType({ fullName: "HistoricRangeComponent", title: "historic-range" })
export class HistoricRangeComponent implements OnInit, OnDestroy {
  @Input() timeRange!: TimeRange;
  @Input() horizontalLayout!: boolean;
  @Output() onChangeTimeRange: EventEmitter<TimeRange> = new EventEmitter<TimeRange>();

  isStartDateValid: boolean = true;
  isEndDateValid: boolean = true;
  dateTimeFormat: string = "";
  isStartDateTimePickerOpened: boolean = false;
  isEndDateTimePickerOpened: boolean = false;
  startTimeRangeUpdate$: Subject<string> = new Subject<string>();
  endTimeRangeUpdate$: Subject<string> = new Subject<string>();

  @ViewChild("startDateTimeInput")
  startDateTimeInput?: ElementRef<HTMLInputElement>;
  @ViewChild("endDateTimeInput")
  endDateTimeInput?: ElementRef<HTMLInputElement>;

  constructor(
    public localizer: LocalizationService,
    private dateFormatterService: DateFormatterService,
    private dispatcher: Dispatcher,
    private actions$: Actions,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dateTimeFormat = this.dateFormatterService.getDateFormatString();
    this.subscribeToStartDateChanges();
    this.subscribeToEndDateChanges();
  }

  ngOnDestroy(): void {
    if (isDefined(this.dialog)) {
      this.dialog.closeAll();
    }
    this.startTimeRangeUpdate$.complete();
    this.endTimeRangeUpdate$.complete();
  }

  subscribeToStartDateChanges(): void {
    this.startTimeRangeUpdate$
      .pipe((debounceTime(500), distinctUntilChanged()))
      .subscribe((startDateValue: string) => {
        const newDate: Maybe<Date> = this.createDateFromString(startDateValue);
        if (isDefined(newDate)) {
          this.updateTimeRange(true, newDate);
        } else {
          this.isStartDateValid = false;
        }
      });
  }

  subscribeToEndDateChanges(): void {
    this.endTimeRangeUpdate$
      .pipe((debounceTime(500), distinctUntilChanged()))
      .subscribe((endDateValue: string) => {
        const newDate: Maybe<Date> = this.createDateFromString(endDateValue);
        if (isDefined(newDate)) {
          this.updateTimeRange(false, newDate);
        } else {
          this.isEndDateValid = false;
        }
      });
  }

  createDateFromString(newDate: string): Maybe<Date> {
    if (this.dateFormatterService.isDateInvalid(newDate)) {
      return null;
    }
    return this.dateFormatterService.createMomentFromString(newDate).toDate();
  }

  updateTimeRange(startDateUpdated: boolean, newDate: Date): void {
    return startDateUpdated ? this.updateStartDate(newDate) : this.updateEndDate(newDate);
  }

  private updateStartDate(newDate: Date): void {
    let timeRange: Maybe<TimeRange> = undefined;
    if (newDate === this.timeRange.from && isDefined(this.startDateTimeInput)) {
      this.isStartDateValid = true;
      this.startDateTimeInput.nativeElement.value = this.dateFormatterService.formatDate(newDate);
    } else if (this.isTimeRangeValid(newDate, this.timeRange.to)) {
      timeRange = new TimeRange(newDate, this.timeRange.to);
      this.isStartDateValid = true;
      this.onChangeTimeRange.emit(timeRange);
    } else {
      this.isStartDateValid = false;
    }
  }

  private updateEndDate(newDate: Date): void {
    let timeRange: Maybe<TimeRange> = undefined;
    if (newDate === this.timeRange.to && isDefined(this.endDateTimeInput)) {
      this.isEndDateValid = true;
      this.endDateTimeInput.nativeElement.value = this.dateFormatterService.formatDate(newDate);
    } else if (this.isTimeRangeValid(this.timeRange.from, newDate)) {
      timeRange = new TimeRange(this.timeRange.from, newDate);
      this.onChangeTimeRange.emit(timeRange);
      this.isEndDateValid = true;
    } else {
      this.isEndDateValid = false;
    }
  }

  private isTimeRangeValid(startDate: Date, endDate: Date): boolean {
    if (isNotDefined(startDate)) {
      return false;
    } else if (isNotDefined(endDate)) {
      return false;
    } else if (startDate > endDate || _isEqual(startDate, endDate)) {
      return false;
    } else {
      return true;
    }
  }

  getStartDateTimeInput(): Maybe<ElementRef<HTMLInputElement>> {
    return this.startDateTimeInput;
  }

  getEndDateTimeInput(): Maybe<ElementRef<HTMLInputElement>> {
    return this.endDateTimeInput;
  }

  showOrHideDateTimePicker(
    startDateTimePicker: boolean,
    endDateTimePicker: boolean,
    event: Maybe<MouseEvent> = null
  ): void {
    this.isStartDateTimePickerOpened = startDateTimePicker;
    this.isEndDateTimePickerOpened = endDateTimePicker;
    if (isNotDefined(event)) {
      return;
    }
    if (startDateTimePicker || endDateTimePicker) {
      const dialogConfig = this.getDialogConfiguration(event, startDateTimePicker);
      this.dispatcher.dispatch(
        DateTimePickerDialogActions.openDateTimePickerDialog({
          dateTimePickerDialogInfo: dialogConfig
        })
      );
      this.subscribeToDialogClose();
    }
  }

  getDialogConfiguration(
    event: MouseEvent,
    startDateTimePicker: boolean
  ): DateTimePickerDialogInfo {
    const top: number =
      window.innerHeight - event.pageY < DATE_TIME_PICKER_DIALOG_HEIGHT
        ? event.pageY - DATE_TIME_PICKER_DIALOG_HEIGHT / 2
        : event.pageY;
    const left: number =
      window.innerWidth - event.pageX < DATE_TIME_PICKER_DIALOG_WIDTH
        ? event.pageX - DATE_TIME_PICKER_DIALOG_WIDTH
        : event.pageX;
    return {
      top: top.toString() + "px",
      left: left.toString() + "px",
      date: startDateTimePicker ? this.timeRange.from : this.timeRange.to,
      dateTimeFormat: this.dateTimeFormat
    };
  }

  private subscribeToDialogClose(): void {
    this.actions$
      .pipe(ofType(DateTimePickerDialogActions.onDateTimePickerDialogClosed), take(1))
      .subscribe(({ selectedDate }) => {
        this.updateTimeRange(this.isStartDateTimePickerOpened, selectedDate);
        this.isStartDateTimePickerOpened = false;
        this.isEndDateTimePickerOpened = false;
        this.isStartDateValid = true;
        this.isEndDateValid = true;
      });
  }
}
