import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ViewMode } from "../../core";
import { SidemenuComponents } from "../../core/models/sidemenu-components.enum";
import { Dispatcher } from "../../dispatcher";
import { SidebarService } from "../../elements/services/sidebar.service";
import { AppStatusActions, EnvironmentSelector } from "../../environment";
import { LocalizationService } from "../../i18n/localization.service";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { BuilderHeaderButtonConfig } from "../models/button/builder-header-button.config";

@Injectable()
export class HeaderButtonsForSidebarGenerator {
  public componentToShow: Maybe<SidemenuComponents>;
  public sidebarOpened: boolean;
  constructor(
    protected sidebarService: SidebarService,
    protected dispatcher: Dispatcher,
    protected environmentSelector: EnvironmentSelector,
    protected localizer: LocalizationService
  ) {
    this.subscribeToDisplayedComponent();
    this.subscribeToSidebarVisibilityMode();
  }

  private subscribeToDisplayedComponent(): void {
    this.sidebarService.componentToShow.subscribe((value) => {
      this.componentToShow = value;
    });
  }

  private subscribeToSidebarVisibilityMode(): void {
    this.environmentSelector.selectSidebarVisibilityMode().subscribe((isSidebarOpened: boolean) => {
      this.sidebarOpened = isSidebarOpened;
    });
  }

  generateBuilderHeaderButtonItems$(): Observable<BuilderHeaderButtonConfig[]> {
    return this.environmentSelector.selectViewMode().pipe(
      map((viewMode: number) =>
        viewMode === ViewMode.PreviewMode
          ? []
          : [
              new BuilderHeaderButtonConfig({
                title: this.localizer.sidebar.WidgetExplorer,
                icon: "abb-icon Matrix",
                isDisabled: false,
                sidebarDisplay: SidemenuComponents.LayoutBuilderMenu,
                clickEventFunction: () =>
                  this.toggleButtonClick(SidemenuComponents.LayoutBuilderMenu)
              })
            ]
      )
    );
  }

  public toggleButtonClick(sidebarDisplay: SidemenuComponents): void {
    if (this.componentToShow === sidebarDisplay && this.sidebarOpened) {
      this.sidebarService.setComponentToShow(null);
      this.dispatcher.dispatch(AppStatusActions.closeSidebar());
    } else {
      this.sidebarService.setComponentToShow(sidebarDisplay);
      this.dispatcher.dispatch(AppStatusActions.openSidebar());
    }
  }

  generateExplorerHeaderButtonItems$(): Observable<BuilderHeaderButtonConfig[]> {
    return this.environmentSelector.selectTemplateBuilderMode().pipe(
      map((templateBuilderMode: boolean) =>
        templateBuilderMode
          ? []
          : [
              new BuilderHeaderButtonConfig({
                title: this.localizer.sidebar.DataExplorer,
                icon: "abb-icon Database",
                sidebarDisplay: SidemenuComponents.DataExplorer,
                clickEventFunction: () => this.toggleButtonClick(SidemenuComponents.DataExplorer)
              })
            ]
      )
    );
  }
}
