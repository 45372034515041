import { Options, YAxisLabelsOptions, YAxisOptions, YAxisPlotBandsOptions } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { Maybe, isDefined, tryConvertToNumber } from "../../../ts-utils";
import {
  getConfiguredOrForegroundColor,
  getTextColorForNoDataStatus
} from "../../helpers/color.helper";
import { calculateUnitFontSize } from "../../helpers/component-font-size.helper";
import { getLimitsDisplayOptions, isPrimaryLimitsMode } from "../../helpers/limit-modes.helper";
import { DEFAULT_CHART_TEXT_COLOR_HEX } from "../../models/colors.constants";
import { DataStatus } from "../../models/data-status";
import { IBaseDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { LimitMarkerHelper } from "../limit.helper";
import { PRIMARY_Y_AXIS_ID, mergeChartOptions } from "./base-highcharts-options.helper";
import {
  getDataLabelColor,
  getYAxisLabelStyle,
  getYAxisLabelsOptions
} from "./single-value-display.helper";

export abstract class GaugeDisplayService {
  protected chartType: string;
  protected isTargetAsAxis: boolean; // FIXME: proper naming
  protected abstract colorListService: ColorListService;

  constructor(protected valueFormatter: ValueFormatterService) {}

  shouldRedraw(
    previousProcessedViewConfig: IBaseDisplayConfig,
    currentProcessedViewConfig: IBaseDisplayConfig
  ): boolean {
    return this.requiresFullRedraw(previousProcessedViewConfig, currentProcessedViewConfig);
  }

  protected requiresFullRedraw(
    previousProcessedViewConfig: IBaseDisplayConfig,
    currentProcessedViewConfig: IBaseDisplayConfig
  ): boolean {
    return false;
  }

  getChartOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const specificOptions = this.getSpecificGaugeOptions(
      viewConfig,
      dataConnectorDescriptors,
      dataStatus
    );
    const commonOptions = this.getCommonGaugeOptions(
      viewConfig,
      this.chartType,
      this.isTargetAsAxis,
      dataStatus
    );
    const combined = mergeChartOptions(commonOptions, specificOptions);
    return combined;
  }

  protected abstract getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options;

  public getCommonGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    type: string,
    targetAsAxis: boolean,
    dataStatus: DataStatus
  ): Options {
    const component = this;
    const chartOptions: Options = {
      chart: {
        type: type,
        style: {},
        showAxes: true
      },
      noData: {
        style: {
          color: getTextColorForNoDataStatus(dataStatus, DEFAULT_CHART_TEXT_COLOR_HEX)
        }
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          title: null,
          min: tryConvertToNumber(viewConfig.min),
          max: tryConvertToNumber(viewConfig.max),
          labels: {
            style: { color: viewConfig.foregroundColor }
          }
        }
      ],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: viewConfig.shouldShowDataLabel,
            style: {
              fontFamily: "ABB Font",
              fontWeight: "normal"
            },
            useHTML: true,
            formatter: function () {
              return component.getDataLabelFormat(this.y, viewConfig);
            }
          }
        }
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      lang: {
        noData: dataStatus
      }
    };

    if (targetAsAxis && viewConfig.limits.target != null) {
      const { showLimitValues } = getLimitsDisplayOptions(viewConfig.limitsMode);
      (chartOptions.yAxis as YAxisOptions[]).push({
        id: "targetAxis",
        linkedTo: 0,
        lineWidth: 0,
        minorTickLength: 0,
        tickPositions: this.getTargetTickPosition(viewConfig),
        tickLength: 18,
        tickColor: viewConfig.limits.targetColor,
        tickWidth: 2,
        zIndex: 6,
        labels: {
          distance: 13,
          enabled: showLimitValues,
          style: {
            ...getYAxisLabelStyle(viewConfig.foregroundColor),
            color: getConfiguredOrForegroundColor(
              viewConfig.limits.targetColor,
              viewConfig.foregroundColor
            )
          },
          formatter: () => {
            return component.valueFormatter.formatValue(
              viewConfig.limits.target,
              viewConfig.displayFormat
            );
          }
        }
      } as any);
    }

    return chartOptions;
  }

  private getTargetTickPosition(viewConfig: IGaugeDisplayConfig): Maybe<number>[] {
    const { showLimitValues } = getLimitsDisplayOptions(viewConfig.limitsMode);
    return isPrimaryLimitsMode(viewConfig.limitsMode) || showLimitValues
      ? [viewConfig.limits.target]
      : [];
  }

  protected getDataLabelFormat(value: Maybe<number>, viewConfig: IGaugeDisplayConfig): string {
    const color = getDataLabelColor(value, viewConfig, this.colorListService);
    const unitFontSize = calculateUnitFontSize(viewConfig.fontSize);
    return `
    <div
      style="
        display:flex;
        flex-direction: row;
        align-items: baseline;
      "
    >
      <div
        style="
          font-size: ${viewConfig.fontSize}px;
          color: ${color};
          font-weight: 500;
        "
      >
      ${isDefined(value) ? this.valueFormatter.formatValue(value, viewConfig.displayFormat) : ""}
      </div>
    <div
        style="
          font-size: ${unitFontSize}px;
          color: ${color};
          font-weight: 500;
        "
      >
      &nbsp;${viewConfig.unit || ""}
      </div>
      </div>`.trim();
  }

  protected getPlotBands(viewConfig: IGaugeDisplayConfig): Maybe<YAxisPlotBandsOptions[]> {
    return isPrimaryLimitsMode(viewConfig.limitsMode)
      ? this.getYAxisPlotBands(viewConfig)
      : undefined;
  }

  protected getYAxisPlotBands(viewConfig: IGaugeDisplayConfig): YAxisPlotBandsOptions[] {
    const bands = new LimitMarkerHelper(viewConfig.limits).getBands(
      tryConvertToNumber(viewConfig.min),
      tryConvertToNumber(viewConfig.max),
      viewConfig.neutralColor
    );
    return bands;
  }

  protected getLimitsLabels(viewConfig: IGaugeDisplayConfig): YAxisLabelsOptions {
    const { showLimitValues } = getLimitsDisplayOptions(viewConfig.limitsMode);
    return getYAxisLabelsOptions(viewConfig, showLimitValues, this.valueFormatter);
  }

  protected getTickPositions(viewConfig: IGaugeDisplayConfig): Maybe<number[]> {
    const { showLimitValues } = getLimitsDisplayOptions(viewConfig.limitsMode);
    const markerHelper = new LimitMarkerHelper(viewConfig.limits);
    const markers = markerHelper.getMarkers(6, 6);
    return isPrimaryLimitsMode(viewConfig.limitsMode) || showLimitValues
      ? markers.map((marker) => marker.value)
      : undefined;
  }
}
