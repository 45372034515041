import { FILTER_CONFIGURATION_DTO } from "../../../elements/models/entity-type.constants";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import { CustomExpander } from "../../../meta/decorators/custom-expander.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { Virtual } from "../../../meta/decorators/virtual.decorator";
import { Entity, EntityId } from "../../../meta/models/entity";
import { PropertyCategory } from "../../../meta/models/property-category";
import { Dictionary, Maybe } from "../../../ts-utils";
import { StrictDeepPartial } from "../../../ts-utils/models/strict-partial.type";
import { expandCustomFilters } from "../../helpers/filter/expand-custom-filters";
import { construct } from "../../services/construct.helper";
import { TimeRangeConfigurationDto } from "../time-range-configuration";
import { CustomFilterValue } from "./filter-type-descriptor";

const TYPE_NAME = FILTER_CONFIGURATION_DTO;

// @dynamic
@EditableType({ fullName: TYPE_NAME, title: "filter-configuration-dto" })
export class FilterConfigurationDto extends Entity {
  typeName = TYPE_NAME;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.Filter)
  @Serializable(new TimeRangeConfigurationDto())
  @Virtual()
  timeRange!: TimeRangeConfigurationDto;

  @Serializable(null)
  sourceFilterId: Maybe<EntityId>;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.Filter)
  @CustomExpander(expandCustomFilters)
  @Serializable({})
  customFilters!: Dictionary<CustomFilterValue>;

  isRuntimeOnly: boolean = false;

  constructor(filterConfig: StrictDeepPartial<FilterConfigurationDto, "id">) {
    super();
    construct(this, filterConfig, TYPE_NAME);
  }
}
