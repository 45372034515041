import { Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from "@angular/core";
import { DynamicTemplateDirective } from "../../dynamics/dynamic-template.directive";
import { SimpleViewConfig } from "../../shared/models/simple-view-config";
import { TableComponentCell } from "../../shared/models/table-component-cell";
import { TableCellComponents, TableCellType } from "../models/table/table-cell-type";

@Directive({
  selector: "[dynamicCell]"
})
export class DynamicCellDirective extends DynamicTemplateDirective implements OnChanges {
  @Input() public cellType: TableCellType;
  @Input() public cellConfig: SimpleViewConfig;
  @Input() public cellData: any;

  private component: TableComponentCell; // make a CellComponent base class?

  constructor(public viewContainerRef: ViewContainerRef) {
    super(viewContainerRef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["cellType"]) {
      this.component = this.loadComponent(TableCellComponents[this.cellType]);
      this.component.value = this.cellData;
      this.component.config = this.cellConfig;
    } else {
      if (changes["cellData"]) {
        this.component.value = this.cellData;
      }
      if (changes["cellConfig"]) {
        this.component.config = this.cellConfig;
      }
    }
  }
}
