import { DataTransferObject } from "../../../core/models/data-transfer-object";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { OnPropertyChange } from "../../../meta/decorators/on-property-change.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { Virtual } from "../../../meta/decorators/virtual.decorator";
import { PropertyCategory } from "../../../meta/models";
import {
  getDataSourceHelpLink,
  getDataSourcesEnum,
  onDataSourceTypeChange
} from "../../helpers/data-source-enum.helper";
import { DataAggregationConfigDto } from "../data-aggregation-config";
import { DataSourceDecoratorContext } from "./data-source-decorator-context";
import { DATA_SOURCE, DataSourceType, EMPTY_DATA_SOURCE } from "./data-source.type";

@EditableType({ fullName: DATA_SOURCE, title: "data-source-dto", virtual: true })
export abstract class DataSourceDto implements DataTransferObject {
  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource, 1)
  @ConfigurableEnum({
    enumSource: getDataSourcesEnum,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Type,
    userHelp: getDataSourceHelpLink
  })
  @OnPropertyChange<DataSourceDecoratorContext, DataSourceDto, DataSourceType>(
    onDataSourceTypeChange
  )
  @Serializable(EMPTY_DATA_SOURCE)
  typeName: DataSourceType = DATA_SOURCE;

  @Serializable()
  @Virtual()
  aggregationConfig!: DataAggregationConfigDto;
}
