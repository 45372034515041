import { construct } from "../../../core/services/construct.helper";
import { CommonDataPropertyNames } from "../../../data-connectivity/models/common-data-properties";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { Configurable } from "../../../meta/decorators/configurable.decorator";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import {
  DynamicDefaultFromConnectorView,
  DynamicDefaultFromFunction,
  DynamicDefaultFromMetadata
} from "../../../meta/decorators/dynamic-default.decorator";
import { DynamicallyVisible } from "../../../meta/decorators/dynamically-visible.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { AllowInterpolation } from "../../../meta/decorators/interpolation.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { Virtual } from "../../../meta/decorators/virtual.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyCategory } from "../../../meta/models/property-category";
import { DynamicallyDecorated } from "../../../ts-utils/helpers/decorator.helper";
import { isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { resolveFontSizeForSingleValueComponent } from "../../helpers/component-font-size.helper";
import { selectDisplayStrategyByComponentId } from "../../helpers/dynamically-visible-properties.helper";
import { createLimitsMode, getDefaultLimitsDisplayMode } from "../../helpers/limit-modes.helper";
import { HorizontalAlignment } from "../../models/alignment/horizontal-alignment";
import { YAxisLabelsAlignment } from "../../models/alignment/vertical-gauge-labels-alignment";
import { ComponentCssSize } from "../../models/component-size";
import {
  GaugeSingleValueDisplayStrategies,
  SingleValueDisplayStrategy,
  TextualSingleValueDisplayStrategies
} from "../../models/display-strategies/single-value-display-strategies";
import { WIDGET_PROPERTIES__UNIT } from "../../models/help-constants";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { LimitsModeDto } from "../../models/limit-modes";
import { LimitsDto } from "../../models/limits";
import { configureMinMax, MinMaxConfigDto } from "../../models/min-max-config";
import { DEFAULT_UNIT_INTERPOLATION } from "../../models/property-interpolation.constants";
import { SINGLE_VALUE_VIEW_CONFIG } from "../../models/view-config-type.constants";
import { ITextualValueViewConfig } from "../simple-components/textual-value/i-textual-value-view-config";
import { StrategizedChartViewConfig } from "../strategized-chart/view-config";
import { Roles } from "./roles";

// @dynamic
@EditableType({ fullName: SINGLE_VALUE_VIEW_CONFIG })
export class SingleValueViewConfig
  extends StrategizedChartViewConfig
  implements MinMaxConfigDto, ITextualValueViewConfig, IGaugeDisplayConfig
{
  typeName = SINGLE_VALUE_VIEW_CONFIG;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 1)
  @ConfigurableEnum({
    enumSource: SingleValueDisplayStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Display
  })
  @Serializable(SingleValueDisplayStrategy.Value)
  displayStrategy!: SingleValueDisplayStrategy;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Other)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.NeutralColor,
    editorType: EditorType.ColorPicker
  })
  @Serializable("")
  @DynamicallyVisible(selectDisplayStrategyByComponentId, GaugeSingleValueDisplayStrategies)
  neutralColor: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.PrimaryColor,
    editorType: EditorType.ColorPicker,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.TextColorTooltip
  })
  @Serializable("")
  @DynamicDefaultFromConnectorView(Roles.Value.name, CommonDataPropertyNames.color, 2)
  @DynamicDefaultFromMetadata(Roles.Value.name, CommonDataPropertyNames.color, 1, true, false)
  primaryColor!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.General,
    11
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowDataLabel,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, GaugeSingleValueDisplayStrategies)
  shouldShowDataLabel!: boolean;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FontSize,
    editorType: EditorType.Number,
    advancedMode: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.FontSizeTooltip
  })
  @Serializable()
  @DynamicDefaultFromFunction((view) => resolveFontSizeForSingleValueComponent(view), -1)
  fontSize!: Maybe<number>;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits)
  @Configurable({
    editorType: EditorType.LimitsEditor,
    advancedMode: true
  })
  limits!: LimitsDto;

  //#region MinMaxConfigDto
  @DynamicallyDecorated
  min: string;

  @DynamicallyDecorated
  max: string;
  //#endregion

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 5)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Unit,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.PropertyInterpolationTooltip,
    editorType: EditorType.TextBox,
    userHelp: WIDGET_PROPERTIES__UNIT
  })
  @Serializable(DEFAULT_UNIT_INTERPOLATION)
  @DynamicDefaultFromMetadata(Roles.Value.name, CommonDataPropertyNames.unit, -1, true, false)
  @AllowInterpolation()
  unit!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 6)
  @ConfigurableEnum({
    enumSource: HorizontalAlignment,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.HorizontalAlignment,
    advancedMode: true
  })
  @Serializable(HorizontalAlignment.Center)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, TextualSingleValueDisplayStrategies)
  horizontalAlignment!: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.General,
    13
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.ShowYAxisLabels,
    editorType: EditorType.CheckBox,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.YAxisLabelTooltip
  })
  @Serializable(true)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, GaugeSingleValueDisplayStrategies)
  showYAxisLabels!: boolean;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.General,
    13
  )
  @ConfigurableEnum({
    enumSource: YAxisLabelsAlignment,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.YAxisLabelsAlignment,
    advancedMode: true
  })
  @Serializable(YAxisLabelsAlignment.Left)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [
    SingleValueDisplayStrategy.VerticalGauge
  ])
  yAxisLabelsAlignment!: string;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Title,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [
    SingleValueDisplayStrategy.VerticalGauge,
    SingleValueDisplayStrategy.HorizontalGauge,
    SingleValueDisplayStrategy.BulletChart
  ])
  showTitle!: boolean;

  @Virtual()
  @Serializable()
  limitsMode!: LimitsModeDto;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DisplayLegend,
    editorType: EditorType.CheckBox
  })
  @Serializable(true)
  @DynamicallyVisible(selectDisplayStrategyByComponentId, [
    SingleValueDisplayStrategy.MultiDialGauge
  ])
  showLegend!: boolean;

  constructor(viewConfigDto: DeepPartial<SingleValueViewConfig> = {}) {
    super();
    const limitsMode = getFullLimitsMode(viewConfigDto);
    const defaultOverrides: Partial<SingleValueViewConfig> = {
      size: getDefaultSize(),
      limitsMode: createLimitsMode(limitsMode as LimitsModeDto)
    };
    construct(this, viewConfigDto, SINGLE_VALUE_VIEW_CONFIG, defaultOverrides);
  }
}

function getFullLimitsMode(
  viewConfigDto: DeepPartial<SingleValueViewConfig>
): DeepPartial<LimitsModeDto> {
  const { limitsMode, displayStrategy } = viewConfigDto;
  if (isNotDefined(limitsMode)) {
    return { typeName: getDefaultLimitsDisplayMode(displayStrategy) };
  }
  if (isNotDefined(limitsMode.typeName)) {
    limitsMode.typeName = getDefaultLimitsDisplayMode(displayStrategy);
  }
  return limitsMode;
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("150", "50");
}

configureMinMax(
  SingleValueViewConfig,
  Roles.Value,
  Roles.Minimum,
  Roles.Maximum,
  0,
  100,
  0.1,
  false
);
