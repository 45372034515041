import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Type,
  ViewChild
} from "@angular/core";
import { isEqual } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DynamicTemplateDirective } from "../../../dynamics/dynamic-template.directive";
import { EditorType } from "../../../meta";
import { PropertyDescriptor } from "../../../meta/models/property-descriptor";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { IEditorProvider } from "../../services/editor-provider.service";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "array-editor-item",
  templateUrl: "array-editor-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrayEditorItemComponent implements AfterViewInit, OnDestroy {
  @ViewChild(DynamicTemplateDirective) dynamicChildren!: DynamicTemplateDirective;
  @Input() public descriptor: PropertyDescriptor;
  @Input() public value: any;
  @Input() public itemTitle!: Maybe<string>;
  @Input() public itemOwner: any;
  public editorComponent: BaseEditorComponent;
  @Output() updatePropertyValue = new EventEmitter<any>();
  private unsubscribeSubject$: Subject<void> = new Subject();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private editorProvider: IEditorProvider,
    private hostElementRef: ElementRef
  ) {}

  ngOnChanges(): void {
    if (isDefined(this.editorComponent)) {
      this.editorComponent.itemOwner = this.itemOwner;
      this.tryRefreshEditorValue();
    }
  }

  ngAfterViewInit(): void {
    if (isNotDefined(this.descriptor) || isNotDefined(this.value)) {
      return;
    }
    this.insertEditor();
    this.scrollIntoView();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private tryRefreshEditorValue(): void {
    if (!isEqual(this.editorComponent.value, this.value)) {
      this.editorComponent.refreshValue(this.value);
    }
  }

  private insertEditor(): void {
    const editorType: EditorType = this.descriptor.arrayItemEditorType;
    const editor: Type<BaseEditorComponent> = this.editorProvider.getEditor(editorType);
    this.editorComponent = this.dynamicChildren.loadComponent<BaseEditorComponent>(editor);

    this.editorComponent.propertyInfo = {
      value: this.value,
      descriptor: this.descriptor,
      localPath: [],
      targetInfo: null,
      originalPath: []
    };

    this.editorComponent.itemOwner = this.itemOwner;

    this.editorComponent.propertyValueChanged
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((newValue) => this.updatePropertyValue.emit(newValue));
  }

  private scrollIntoView(): void {
    //setTimeout is needed because we have to render host component first,
    //then scroll into its view; setTimeout -> adds in task queue (async)
    setTimeout(() => {
      this.hostElementRef.nativeElement.scrollIntoView({
        block: "nearest",
        behavior: "smooth"
      });
    });
  }
}
