export enum PropertySubcategory {
  Tabs,
  AxisNames,
  SeriesType,
  IndicatorStates,
  GroupNames,
  General,
  ShowHeader,
  ShowFooter,
  YAxes,
  Columns,
  Background,
  CSSOverride,
  Style,
  SizeAndPosition,
  Limits,
  TableColumn,
  DataAggregation,
  DataConnectors,
  DataSource,
  SeriesConfiguration,
  Filter,
  FilterTimeRange,
  CustomFilters,
  Navigation,
  Other
}

export const PROPERTY_SUBCATEGORY_PREFIX = "PropertySheet.";
export const DATA_CONNECTOR_SUBCATEGORY = "DataConnectors";
