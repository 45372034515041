import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatListOption, MatSelectionList, MatSelectionListChange } from "@angular/material/list";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { from, of, Subject } from "rxjs";
import { debounceTime, map, switchMap, takeUntil } from "rxjs/operators";
import { BaseDialogComponent } from "../../../../../shared/components/base-dialog/base-dialog.component";
import { SignalData } from "../../../../models/dialogs/signal-data.model";

// fixes the expression changed after it has been checked error.
// enableProdMode();

@Component({
  selector: "c-remove-signals-dialog",
  templateUrl: "./remove-signals-dialog.component.html",
  styleUrls: ["./remove-signals-dialog.component.scss"]
})
export class RemoveSignalsDialogComponent
  extends BaseDialogComponent
  implements AfterViewInit, OnDestroy
{
  public axisArray: any[] = [];
  public signalsArray: any[] = [];
  public signalsPresent = false;
  public noValuesSelected: boolean;

  @ViewChild("axisList", { static: true }) axisList: MatSelect;
  @ViewChild("signalsList") signalsList: MatSelectionList;

  unsubscribeSubject$: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<RemoveSignalsDialogComponent>
  ) {
    super();
    this.noValuesSelected = true;
    this.axisArray = data.axisArray;
  }

  ngAfterViewInit(): void {
    const DEBOUNCE_TIME = 50;

    from(this.axisList.selectionChange)
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        switchMap((selectedAxis: MatSelectChange) => {
          if (!this.signalsList) {
            return of(false);
          }
          return from(this.signalsList.selectionChange).pipe(
            map((signals: MatSelectionListChange) => {
              if (selectedAxis) {
                const hasSignalsSelected: boolean =
                  signals.source.selectedOptions.selected.length > 0 ? true : false;
                return hasSignalsSelected;
              }
              return false;
            })
          );
        }),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((anyValueSelected: boolean) => {
        this.noValuesSelected = !anyValueSelected;
      });
  }
  public getValuesFromListOptions(listOptions: MatListOption[]) {
    return listOptions.map((listOption) => listOption.value);
  }

  public getSignalsForAxis(axisId: string) {
    const selectedAxis = this.axisArray.find((axis) => axis.id === axisId);
    if (selectedAxis) {
      this.signalsArray = selectedAxis.signals;
    } else {
      this.signalsArray = [];
    }
    this.signalsPresent = this.signalsArray.length ? true : false;
  }
  onDialogClosed(): void {
    if (!this.axisList || !this.signalsList) {
      return;
    }

    const signalsToRemove: SignalData = {
      axisId: null,
      signalIds: null
    };

    signalsToRemove.axisId = this.getValueFromSelect(this.axisList);
    signalsToRemove.signalIds = this.getValuesFromListOptions(
      this.signalsList.selectedOptions.selected
    );
    this.dialogRef.close(signalsToRemove);
  }
  public getValueFromSelect(comboBox: MatSelect) {
    if (comboBox.selected) {
      return (comboBox.selected as MatOption).value;
    } else {
      return null;
    }
  }
}
