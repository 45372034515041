import { Injectable } from "@angular/core";

@Injectable()
export class WebServicesConfiguration {
  // only used as base URL for other services
  public configurationServiceUrl = "";

  // only used as base URL for other services
  public itemsServiceUrl = "";

  // get available logs
  public logItemsServiceUrl = "";

  // get log data
  public logDataServiceUrl = "";

  public pushDataServiceUrl = "";

  // getting equipments
  public equipmentUrl = "";

  // getting all equipment classes
  public equipmentClassUrl = "";

  // getting equipment properties
  public equipmentPropertiesUrl = "";

  // report configuration
  public reportsUrl = "";

  // not used
  public dbTablesUrl = "";

  // not used
  public dbTableColumnsUrl = "";

  // generic data source
  public dbSqlQueryUrl = "";

  public periodTypesUrl = "";

  public allReportsUrl = "";

  public imageUploadUrl = "";

  public imageGetUrl = "";

  public serverTimeUrl = "";

  public saveAsUrl = "";

  public shiftStartTimeUrl = "";
}
