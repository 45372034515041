import { from, Observable } from "rxjs";
import { CoreTranslateLoader } from "ui-core";

export class RdsTranslateLoader extends CoreTranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(
      import(`../../../../../ui-core/src/assets/ui-core/i18n/${lang}.json`).then((res) => {
        const coreTranslations = Object.assign({}, res);
        return import(`../${lang}.json`).then((resolve) => {
          const rdsTranslations = Object.assign({}, resolve);
          const mergedTranslations = this.mergeTranslations(coreTranslations, rdsTranslations);
          return mergedTranslations;
        });
      })
    );
  }
}
