import { GLOBAL_FILTER_VIEW_MODEL } from "../../../elements/models/entity-type.constants";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurationCategory,
  EditableType,
  Serializable
} from "../../../meta/decorators";
import { EditorType, PropertyCategory } from "../../../meta/models";
import { Entity } from "../../../meta/models/entity";
import { StrictDeepPartial } from "../../../ts-utils/models/strict-partial.type";
import { construct } from "../../services/construct.helper";
import { GeneralSettingsDto } from "../general-settings";
import { GlobalTimeRangeConfigurationDto } from "./global-time-range-configuration";

export const TYPE_NAME = GLOBAL_FILTER_VIEW_MODEL;

// @dynamic
@EditableType({ fullName: TYPE_NAME, title: "global-filter-view-model" })
export class GlobalFilterViewModel extends Entity {
  typeName = TYPE_NAME;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.FilterTimeRange,
    0
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.FilterTimeRange,
    editorType: EditorType.TimeRangeEditor
  })
  @Serializable(new GlobalTimeRangeConfigurationDto())
  timeRange!: GlobalTimeRangeConfigurationDto;

  @Serializable(new GeneralSettingsDto())
  generalSettings!: GeneralSettingsDto;

  constructor(filterConfig: StrictDeepPartial<GlobalFilterViewModel, "id">) {
    super();
    construct(this, filterConfig, TYPE_NAME);
  }
}
