import { DataTransferObject } from "../../../core/models/data-transfer-object";
import { construct } from "../../../core/services/construct.helper";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Key } from "../../../meta/decorators/key.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { Title } from "../../../meta/decorators/title.decorator";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";
import { FooterFunctions } from "./table-header-footer-options";

const TYPE_NAME = "TableFooterDescriptor";

@EditableType({ fullName: TYPE_NAME, title: "table-footer-descriptor" })
export class TableFooterDescriptor implements DataTransferObject {
  typeName: string = TYPE_NAME;

  @Key()
  @Serializable()
  id!: string;

  @Title()
  @Serializable()
  label!: string;

  @Serializable()
  function!: FooterFunctions;

  @Serializable(false)
  isSelected!: boolean;

  @Serializable(false)
  isPredefinedItem!: boolean;

  constructor(footerDescriptor: DeepPartial<TableFooterDescriptor> = {}) {
    construct(this, footerDescriptor, TYPE_NAME);
  }
}
