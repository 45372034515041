import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import { REPORT_FILTER_ID } from "../../core/helpers/filter/filter-id.helper";
import { CURRENT, LIVE_MODE_TIME_UNIT } from "../../core/helpers/filter/filter-validation.helper";
import { FilterConfigurationDto } from "../../core/models/filter/filter-configuration";
import { TimeRangeConfigurationDto } from "../../core/models/time-range-configuration";
import {
  extractLiveModeParams,
  isUnitBiggerThanHours
} from "../../elements/services/global-filter.helper";
import { TimeUnits } from "../../shared/helpers/live-mode-time-unit.helper";
import { isEmptyOrNotDefined } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpgradeGlobalFilterTimeRange implements UpgradeStep {
  name = "UpgradeGlobalFilterTimeRange";
  fromVersion: Version = new Version(4, 0, 15);

  perform(oldConfig: any): UpgradeStepResult {
    const filters = oldConfig.filters;
    let modified = false;
    if (isDefined(filters)) {
      modified = this.adjustTimeAmountForLongerUnits(filters) || modified;
    }
    return {
      result: oldConfig,
      modified: modified,
      warning: null
    };
  }

  adjustTimeAmountForLongerUnits(filters: EntityState<FilterConfigurationDto>): boolean {
    const globalFilter = filters.entities[REPORT_FILTER_ID];
    let isModified: boolean = false;
    if (isDefined(globalFilter)) {
      const timeRange = globalFilter.timeRange;
      if (isDefined(timeRange) && isEmptyOrNotDefined(timeRange.toExpression)) {
        const liveModeFilter = extractLiveModeParams(timeRange.fromExpression);
        if (isNotDefined(liveModeFilter)) {
          return isModified;
        }
        if (isUnitBiggerThanHours(liveModeFilter.unit)) {
          TimeUnits.forEach((type) => {
            if (type.text === liveModeFilter.unit) {
              liveModeFilter.amount = liveModeFilter.amount * type.hours;
            }
          });
          const fromExpression = `${CURRENT}-${liveModeFilter.amount}${LIVE_MODE_TIME_UNIT}`;
          globalFilter.timeRange = new TimeRangeConfigurationDto({ fromExpression });
          isModified = true;
        }
      }
    }
    return isModified;
  }
}
