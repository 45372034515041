import { Component } from "@angular/core";
import {
  BaseComponent,
  ChartComponent,
  ConnectorRoles,
  EditableType,
  formatNumberByStringFormat,
  View
} from "ui-core";
import { Roles } from "./roles";
import { DowntimeComparisonChartViewConfig } from "./view-config";

@Component({
  selector: "rds-downtime-comparison-chart",
  templateUrl: "./downtime-comparison-chart.component.html",
  styleUrls: ["./downtime-comparison-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: DowntimeComparisonChartComponent }]
})
// @LayoutBuilder(ComponentCategory.Category, "DowntimeComparisonChartComponent", "Bar_chart")
@ConnectorRoles(Roles)
@EditableType({ fullName: "DowntimeComparisonChartComponent", title: "downtime-comparison-chart" })
export class DowntimeComparisonChartComponent extends ChartComponent {
  @View(DowntimeComparisonChartViewConfig)
  private get view() {
    return this.currentState.view as DowntimeComparisonChartViewConfig;
  }

  protected updateChartData() {
    this.view.categories = ["GRID", "MW SWITCHGEAR", "TRANSFORMERS", "E-HOUSE", "RING MOTOR"];
    this.view.links = [
      "",
      "",
      "",
      "#/report/115",
      "#/report/ring-motor?rootPath=FRP/CVR/Grinding/BM1"
    ];

    const roles: string[] = Object.values(Roles).map((role) => role.name);
    const aRoles: string[] = [];
    const bRoles: string[] = [];
    roles.forEach((role) => {
      if (role.includes("-A")) {
        aRoles.push(role);
      } else {
        bRoles.push(role);
      }
    });

    aRoles.forEach((aRole, i) => {
      const values = this.dataAccessor.getValuesForSimilarRoles(aRole);
      values.forEach((value) => this.view.leftSeriesData.push({ x: i, y: value }));
    });

    bRoles.forEach((bRole, i) => {
      const values = this.dataAccessor.getValuesForSimilarRoles(bRole);
      values.forEach((value) => this.view.rightSeriesData.push({ x: i, y: value }));
    });
  }

  protected setChartOptions(): void {
    const component = this;

    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        type: "column",
        backgroundColor: "rgba(255, 255, 255, 0.0)"
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: this.view.categories,
        id: "xAxis",
        tickLength: 45,
        labels: {
          useHTML: true,
          formatter: function () {
            return (
              "<b style='color: #B4B4B4;'>" +
              this.value +
              "</b><br><a href='" +
              component.view.links[component.view.categories.indexOf(this.value)] +
              "' style='text-decoration: none; color: #2E92FA;'>Detail View</a>"
            );
          }
        }
      },
      yAxis: {
        min: 0,
        lineWidth: 1,
        showFirstLabel: false,
        showLastLabel: false,
        title: {
          text: "Hours of Downtime"
        },
        stackLabels: {
          enabled: true,
          formatter: function () {
            return Math.floor(this.total);
          }
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: "normal",
          maxPointWidth: 25
        }
      },
      series: [
        {
          name: "Left",
          data: this.view.leftSeriesData,
          stack: "Left",
          color: this.view.leftColor,
          tooltip: {
            headerFormat: "<b>{point.x}: </b>",
            pointFormatter: function () {
              return (
                formatNumberByStringFormat(
                  this.options.y,
                  component.view.displayFormat.numberFormat
                ) + " amps"
              );
            }
          }
        },
        {
          name: "Right",
          data: this.view.rightSeriesData,
          stack: "Right",
          color: this.view.rightColor,
          tooltip: {
            headerFormat: "<b>{point.x}: </b>",
            pointFormatter: function () {
              return (
                formatNumberByStringFormat(
                  this.options.y,
                  component.view.displayFormat.numberFormat
                ) + " amps"
              );
            }
          }
        }
      ]
    };
  }
}
