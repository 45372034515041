import { isDefined, isEmpty, isNotDefined } from "../../ts-utils";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { BaseViewConfigDto } from "../models/base-view-config";
import { ComponentStateDto } from "../models/component-state";
import { isContainerWidget } from "../models/component-type.helper";
import { SizeInPx } from "../models/size-in-px";
import { ComponentPositioningService } from "../services/component-positioning.service";
import { DomMapper } from "../services/dom-mapper.service";
import { fromPx, isSmallWidget } from "../services/drop.helper";
import { revertZIndex, unsetZIndex } from "./dom-element-visibility.helper";
import { findClosestBasicCard, findClosestTabGroup } from "./widget-dragging.helper";

const CONFIG_BUTTON_SIZE = 27;
const CONFIG_BUTTON_RIGHT_MARGIN = 3;
const CONFIG_BUTTON_TOP_MARGIN = 5;

export function resolveConfigButtonsCssPosition(
  view: BaseViewConfigDto,
  configButtonsContainer: Maybe<HTMLDivElement>,
  typeDescriptorName: string,
  configButtonsLength: number,
  parentNode: any
): void {
  if (isContainerWidget(typeDescriptorName)) {
    return;
  }
  const {
    runtimeView: { runtimeSize },
    css
  } = view;
  const widgetRightEdgeDistanceFromContainerLeftEdge: number =
    fromPx(css.left) + runtimeSize.widthInPx - parentNode.scrollLeft;
  const buttonsDistanceFromContainerLeftEdge: number =
    widgetRightEdgeDistanceFromContainerLeftEdge -
    configButtonsLength * (CONFIG_BUTTON_SIZE + CONFIG_BUTTON_RIGHT_MARGIN);
  const buttonsTruncatedLength: number =
    widgetRightEdgeDistanceFromContainerLeftEdge - parentNode.clientWidth;
  const configButtonsRightCssProperty: number =
    buttonsDistanceFromContainerLeftEdge - CONFIG_BUTTON_RIGHT_MARGIN < 0
      ? buttonsDistanceFromContainerLeftEdge
      : buttonsTruncatedLength > 0
      ? buttonsTruncatedLength
      : CONFIG_BUTTON_RIGHT_MARGIN;
  const configButtonsRightPosition: string = `right: ${configButtonsRightCssProperty}px;`;
  const configButtonsTopDistance: number = parentNode.scrollTop - fromPx(css.top);
  const configButtonsInnerWidgetStyle: string =
    configButtonsTopDistance > 0
      ? configButtonsRightPosition.concat(
          `top: ${configButtonsTopDistance + CONFIG_BUTTON_TOP_MARGIN}px;`
        )
      : configButtonsRightPosition;
  const configButtonsOuterWidgetStyle: string = resolveConfigButtonsOuterWidgetStyle(
    runtimeSize,
    configButtonsRightCssProperty,
    configButtonsTopDistance
  );
  const configButtonsCssPosition: string = isEmpty(configButtonsOuterWidgetStyle)
    ? configButtonsInnerWidgetStyle
    : configButtonsOuterWidgetStyle;
  configButtonsContainer?.setAttribute("style", configButtonsCssPosition);
}

function resolveConfigButtonsOuterWidgetStyle(
  widgetSize: SizeInPx,
  configButtonsRightCssProperty: number,
  configButtonsTopDistance: number
): string {
  if (!isSmallWidget(widgetSize)) {
    return "";
  }
  if (configButtonsRightCssProperty > 0) {
    configButtonsRightCssProperty -= 2 * CONFIG_BUTTON_RIGHT_MARGIN;
  }
  const style: string = `right: ${configButtonsRightCssProperty}px;`;
  const configButtonsCssPosition: string =
    configButtonsTopDistance + CONFIG_BUTTON_SIZE < 0
      ? `top: ${-CONFIG_BUTTON_SIZE}px;`
      : `top: unset; bottom: ${-CONFIG_BUTTON_SIZE}px;`;
  return style.concat(configButtonsCssPosition);
}

export function getOverlappingWidgets(
  host: HTMLElement,
  configButtonsContainer: Maybe<HTMLDivElement>
): HTMLElement[] {
  const hostState: ComponentStateDto = (host as any)?.angularComponentRef.currentState;
  const configButtonsRect: Maybe<DOMRect> = configButtonsContainer?.getBoundingClientRect();
  const siblings: HTMLElement[] = DomMapper.getSiblings(host);
  const hostDOMOrder: number = siblings.findIndex(
    (element) => (element as any)?.angularComponentRef.id === hostState.id
  );
  if (isNotDefined(configButtonsRect) || hostDOMOrder === -1) {
    return [];
  }
  const areButtonsTruncated: boolean = siblings.some(
    (sibling, index) =>
      hostDOMOrder !== index &&
      areWidgetsOverlapping(hostState, configButtonsRect, hostDOMOrder, sibling, index, false)
  );
  if (!areButtonsTruncated) {
    return [];
  }
  const overlappingWidgets: HTMLElement[] = [host];
  findSiblingOverlappingWidgets(siblings, host, hostDOMOrder, overlappingWidgets);
  return overlappingWidgets;
}

function findSiblingOverlappingWidgets(
  siblings: HTMLElement[],
  host: HTMLElement,
  hostDOMOrder: number,
  overlappingWidgets: HTMLElement[]
): void {
  const hostRect: Maybe<DOMRect> = host?.getBoundingClientRect();
  if (!isDefined(hostRect) || hostDOMOrder === -1) {
    return;
  }
  const currentState: ComponentStateDto = (host as any)?.angularComponentRef.currentState;
  siblings.map((element, index) => {
    const processedWidget: Maybe<HTMLElement> = overlappingWidgets.find(
      (el) => (el as any)?.angularComponentRef.id === (element as any)?.angularComponentRef.id
    );
    if (hostDOMOrder === index || isDefined(processedWidget)) {
      return;
    }
    if (areWidgetsOverlapping(currentState, hostRect, hostDOMOrder, element, index, true)) {
      overlappingWidgets.push(element);
      findSiblingOverlappingWidgets(siblings, element, index, overlappingWidgets);
    }
  });
}

function areWidgetsOverlapping(
  host: ComponentStateDto,
  hostRect: DOMRect,
  hostDOMOrder: number,
  sibling: Maybe<HTMLElement>,
  siblingDOMOrder: number,
  isEntireHost: boolean
): boolean {
  const siblingRect: Maybe<DOMRect> = sibling?.getBoundingClientRect();
  const hostZIndex: number = Number(host.view.css.zIndex);
  const siblingZIndex: number = Number(
    (sibling as any)?.angularComponentRef.currentState.view.css.zIndex
  );
  const incorrectDrawingOrder: boolean = isIncorrectDrawingOrder(
    isEntireHost,
    hostZIndex,
    siblingZIndex,
    hostDOMOrder,
    siblingDOMOrder
  );
  return isDefined(siblingRect) && doRectsOverlap(hostRect, siblingRect) && incorrectDrawingOrder;
}

function isIncorrectDrawingOrder(
  isEntireHost: boolean,
  hostZIndex: number,
  siblingZIndex: number,
  hostDOMOrder: number,
  siblingDOMOrder: number
): boolean {
  return isEntireHost && hostZIndex >= 0
    ? (hostZIndex === siblingZIndex && hostDOMOrder > siblingDOMOrder) || hostZIndex > siblingZIndex
    : (hostZIndex === siblingZIndex && hostDOMOrder < siblingDOMOrder) ||
        hostZIndex < siblingZIndex;
}

export function doRectsOverlap(rect1: DOMRect, rect2: DOMRect): boolean {
  return (
    rect1.x < rect2.right &&
    rect2.x < rect1.right &&
    rect1.y < rect2.bottom &&
    rect2.y < rect1.bottom
  );
}

export function reorderOverlappingWidgets(host: HTMLElement): void {
  let siblings: HTMLElement[] = DomMapper.getSiblings(host);
  for (let i = 0; i < siblings.length - 1; i++) {
    const index: Maybe<number> = findSiblingAboveWithLowerZIndex(i, siblings);
    if (isDefined(index)) {
      const element: HTMLElement = siblings[i];
      host.parentNode?.insertBefore(siblings[index], element);
      siblings = DomMapper.getSiblings(host);
    }
  }
}

function findSiblingAboveWithLowerZIndex(
  startIndex: number,
  elements: HTMLElement[]
): Maybe<number> {
  const hostZIndex: number = Number(elements[startIndex].style.zIndex);
  let index: Maybe<number>;
  let minZIndex: Maybe<number>;
  for (let i = startIndex + 1; i < elements.length; i++) {
    const siblingZIndex: number = Number(elements[i].style.zIndex);
    if (shouldUpdateIndexes(hostZIndex, siblingZIndex, minZIndex)) {
      index = i;
      minZIndex = siblingZIndex;
    }
  }
  return index;
}

function shouldUpdateIndexes(
  hostZIndex: number,
  siblingZIndex: number,
  minZIndex: Maybe<number>
): boolean {
  return siblingZIndex < hostZIndex && (isNotDefined(minZIndex) || minZIndex > siblingZIndex);
}

export function unsetOverlappingWidgetsZIndex(
  overlappingWidgets: HTMLElement[],
  componentPositioningService: ComponentPositioningService
): void {
  overlappingWidgets.map((component) => {
    unsetZIndex(component);
    componentPositioningService.setDragOverlayZIndex(
      component,
      (component as any)?.angularComponentRef.currentState.view.css.zIndex
    );
  });
}

export function revertOverlappingWidgetsZIndex(
  overlappingWidgets: HTMLElement[],
  componentPositioningService: ComponentPositioningService
): void {
  overlappingWidgets.map((component) => {
    revertZIndex(component);
    componentPositioningService.resetDragOverlayZIndex(component);
  });
}

export function hideContainersConfigButtons(event: MouseEvent): void {
  const elementsAtPoint: Element[] = document.elementsFromPoint(event?.pageX, event?.pageY);
  const tabGroup = findClosestTabGroup(elementsAtPoint);
  tabGroup?.hideConfigButtons();
  const basicCard = findClosestBasicCard(elementsAtPoint);
  if (isDefined(basicCard)) {
    basicCard.isHovered = false;
    if (isNotDefined(tabGroup)) {
      basicCard.hideConfigButtons();
    }
  }
}

export function unhideContainersConfigButtons(event: MouseEvent): void {
  const elementsAtPoint: Element[] = document.elementsFromPoint(event?.pageX, event?.pageY);
  const tabGroup = findClosestTabGroup(elementsAtPoint);
  tabGroup?.unhideConfigButtons();
  const basicCard = findClosestBasicCard(elementsAtPoint);
  if (isDefined(basicCard)) {
    basicCard.isHovered = true;
    if (isNotDefined(tabGroup) && basicCard.view.showHeader) {
      basicCard.unhideConfigButtons();
    }
  }
}
