import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Dictionary } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DisplayMode } from "../../../core/models/display-mode";
import {
  determineLayoutType,
  getLayoutTypeWidth,
  LayoutType
} from "../../../core/models/page-layout";
import { Dispatcher } from "../../../dispatcher";
import { RuntimeSettingsSelector } from "../../../elements/services/entity-selectors/runtime-settings.selector";
import { RuntimeSettingsActions } from "../../../elements/store/runtime-settings/runtime-settings.actions";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { GeneralSettingsLocalizationDictionary } from "../../../i18n/models/general-settings-localization-dictionary";
import { HeaderButtonConfig } from "../../models/button/header-button.config";

@Component({
  selector: "layout-settings",
  templateUrl: "layout-settings.component.html",
  styleUrls: ["./layout-settings.component.scss"]
})
export class LayoutSettingsComponent implements OnInit {
  public selectedLayout: string;
  public LayoutType = LayoutType;
  public displayMode: string;
  public DisplayMode = DisplayMode;
  isMenuOpened: boolean = false;
  layoutTypeKeys: string[];
  dropDownButton!: HeaderButtonConfig;
  layoutButtonItems: Dictionary<HeaderButtonConfig> = {};
  @ViewChild("layoutSettings") layoutSettings: ElementRef;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private dispatcher: Dispatcher,
    public localizer: LocalizationService,
    private environmentSelector: EnvironmentSelector,
    private runtimeSettingsSelector: RuntimeSettingsSelector
  ) {}

  ngOnInit(): void {
    this.subscribeToFilterToolbarMode();
    this.subscribeToDisplayMode();
    this.subscribeToPagePreviewWidth();
    this.layoutTypeKeys = Object.keys(LayoutType).filter((key) => parseInt(key).toString() !== key);
    this.layoutButtonItems = this.createButtonsDictionary();
    this.dropDownButton = this.createDropDownButtonConfig();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private subscribeToFilterToolbarMode(): void {
    this.environmentSelector
      .selectFilterToolbarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((filterToolbarOpened: boolean) => {
        if (filterToolbarOpened) {
          this.closeMenu();
        }
      });
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
      });
  }

  private subscribeToPagePreviewWidth(): void {
    this.runtimeSettingsSelector
      .selectPagePreviewWidth()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((pagePreviewWidth) => {
        this.selectedLayout = this.selectedLayout || determineLayoutType(pagePreviewWidth);
      });
  }

  createButtonsDictionary(): Dictionary<HeaderButtonConfig> {
    return this.layoutTypeKeys.reduce((acc: Dictionary<HeaderButtonConfig>, layoutType: string) => {
      acc[layoutType] = this.createLayoutButtonConfig(layoutType);
      return acc;
    }, {});
  }

  private createLayoutButtonConfig(layoutType: string): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.getButtonTitle(layoutType),
      clickFunction: () => this.onSelectLayout(layoutType)
    });
  }

  getLayoutTypeIcon(layoutType: string): string {
    switch (parseInt(LayoutType[layoutType])) {
      case LayoutType.Desktop:
        return "Screen";
      case LayoutType.Mobile:
        return "Smartphone";
      default:
        return "Devices";
    }
  }

  onSelectLayout(layoutTypeKey: string): void {
    this.selectedLayout = layoutTypeKey;
    this.updateDropDownButton();
    const pageWidth = getLayoutTypeWidth(LayoutType[this.selectedLayout]);
    this.dispatcher.dispatch(RuntimeSettingsActions.setPagePreviewWidth(pageWidth));
  }

  private updateDropDownButton(): void {
    this.dropDownButton.title = this.getButtonTitle(this.selectedLayout);
  }

  private createDropDownButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.getButtonTitle(this.selectedLayout),
      hasDropdown: true,
      clickFunction: () => this.showOrHideMenu()
    });
  }

  private getButtonTitle(layoutType: string): string {
    return this.localizer.generalSettings[
      layoutType as keyof GeneralSettingsLocalizationDictionary
    ];
  }

  private showOrHideMenu(): void {
    this.isMenuOpened ? this.closeMenu() : this.openMenu();
  }

  closeMenu(): void {
    this.isMenuOpened = false;
  }

  private openMenu(): void {
    this.isMenuOpened = true;
  }
}
