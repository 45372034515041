import { Cardinality } from "../../../core/models/cardinality";
import { DataConnectorRoles } from "../../../data-connectivity/models/data-connector-role";

export const Roles: DataConnectorRoles = {
  Value: {
    name: "Value",
    cardinality: Cardinality.One,
    isDefaultRole: true
  },

  Target: {
    name: "Target",
    cardinality: Cardinality.One,
    isDefaultRole: false
  },

  ExtremeLow: {
    name: "Extreme Low",
    cardinality: Cardinality.One,
    isDefaultRole: false
  },

  VeryLow: {
    name: "Very Low",
    cardinality: Cardinality.One,
    isDefaultRole: false
  },

  Low: {
    name: "Low",
    cardinality: Cardinality.One,
    isDefaultRole: false
  },

  High: {
    name: "High",
    cardinality: Cardinality.One,
    isDefaultRole: false
  },

  VeryHigh: {
    name: "Very High",
    cardinality: Cardinality.One,
    isDefaultRole: false
  },

  ExtremeHigh: {
    name: "Extreme High",
    cardinality: Cardinality.One,
    isDefaultRole: false
  },

  Minimum: {
    name: "Minimum",
    cardinality: Cardinality.One,
    isDefaultRole: false
  },

  Maximum: {
    name: "Maximum",
    cardinality: Cardinality.One,
    isDefaultRole: false
  }
};
