import { Options, YAxisLabelsOptions } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { first } from "../../../ts-utils/helpers/array.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import {
  getConfiguredOrForegroundColor,
  getTextColorForNoDataStatus
} from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { DEFAULT_CHART_TEXT_COLOR_HEX } from "../../models/colors.constants";
import { DataStatus } from "../../models/data-status";
import { IBaseDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { SizeInPx } from "../../models/size-in-px";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { PRIMARY_Y_AXIS_ID } from "./base-highcharts-options.helper";
import { GaugeDisplayService } from "./gauge-display.service";
import { switchedOnOff } from "./requires-full-redraw.helper";
import { getPrimaryColor, getYAxisLabelsOptions } from "./single-value-display.helper";

export class DialGaugeDisplayService extends GaugeDisplayService {
  protected colorListService: ColorListService;
  constructor(protected valueFormatter: ValueFormatterService, colorListService: ColorListService) {
    super(valueFormatter);
    this.chartType = "gauge";
    this.isTargetAsAxis = true;
    this.colorListService = colorListService;
  }

  protected requiresFullRedraw(
    previousProcessedViewConfig: IBaseDisplayConfig,
    currentProcessedViewConfig: IBaseDisplayConfig
  ): boolean {
    const prev = previousProcessedViewConfig as IGaugeDisplayConfig;
    const cur = currentProcessedViewConfig as IGaugeDisplayConfig;
    return switchedOnOff(prev.limits.target, cur.limits.target);
  }

  protected getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const color = getPrimaryColor(viewConfig, this.colorListService);
    const gaugeOptions: Options = {
      noData: {
        style: {
          color: getTextColorForNoDataStatus(
            dataStatus,
            viewConfig.foregroundColor ?? DEFAULT_CHART_TEXT_COLOR_HEX
          )
        }
      },
      plotOptions: {
        gauge: {
          wrap: false,
          dial: {
            backgroundColor: getConfiguredOrForegroundColor(color, viewConfig.foregroundColor)
          },
          dataLabels: {
            borderWidth: 0
          }
        }
      },
      pane: {
        startAngle: -140,
        endAngle: 140,
        background: [
          {
            borderWidth: 0,
            outerRadius: "105%",
            innerRadius: "103%"
          }
        ]
      },
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          labels: this.getYAxisLabels(viewConfig),

          minorTicks: shouldEnableMinorTicks(viewConfig.runtimeView.runtimeSize),
          minorTickInterval: "auto",
          minorTickWidth: 1,
          minorTickLength: 10,
          minorTickPosition: "inside",
          minorTickColor: "#000",

          tickPixelInterval: 30,
          tickWidth: 2,
          tickPosition: "inside",
          tickLength: 12,
          tickColor: "#000",
          plotBands: this.getPlotBands(viewConfig)
        },
        {
          linkedTo: 0,
          tickLength: 0,
          minorTickLength: 0,
          tickPositions: this.getTickPositions(viewConfig),
          labels: this.getLimitsLabels(viewConfig)
        }
      ],
      series: [
        {
          id: "value_gauge",
          type: undefined,
          name: viewConfig.title ?? "",
          data: value !== null ? [value] : []
        }
      ]
    };
    return gaugeOptions;
  }

  protected getYAxisLabels(viewConfig: IGaugeDisplayConfig): YAxisLabelsOptions {
    return {
      ...getYAxisLabelsOptions(viewConfig, viewConfig.showYAxisLabels, this.valueFormatter),
      step: 5
    };
  }
}

function shouldEnableMinorTicks(widgetSize: SizeInPx): boolean {
  const minorTickSizeThreshold = 225;
  const { heightInPx, widthInPx } = widgetSize;
  return widthInPx > minorTickSizeThreshold && heightInPx > minorTickSizeThreshold;
}
