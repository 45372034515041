import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { isUndefined } from "lodash";
import { Observable, iif, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import {
  Dispatcher,
  EnvironmentSelector,
  LocalizationService,
  ViewMode,
  WebServicesConfiguration
} from "ui-core";
import { SidemenuComponents } from "../../../../../ui-core/src/lib/core/models/sidemenu-components.enum";
import { SidebarService } from "../../../../../ui-core/src/lib/elements/services/sidebar.service";
import { BuilderHeaderButtonConfig } from "../../../../../ui-core/src/lib/shared/models/button/builder-header-button.config";
import { HeaderButtonsForSidebarGenerator } from "../../../../../ui-core/src/lib/shared/services/header-buttons-for-sidebar-generator-service";
import { ModuleDTO } from "../../dtos";
import { FLEET_VIEW_REPORT_ID } from "../../models/constants/predefined-reports";

@Injectable()
export class RdsHeaderButtonsForSidebarGenerator extends HeaderButtonsForSidebarGenerator {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiConfig: WebServicesConfiguration,
    protected environmentSelector: EnvironmentSelector,
    protected dispatcher: Dispatcher,
    protected sidebarService: SidebarService,
    protected localizer: LocalizationService
  ) {
    super(sidebarService, dispatcher, environmentSelector, localizer);
  }

  private readonly previewModeCommonHeaderButtonItems: BuilderHeaderButtonConfig[] = [
    new BuilderHeaderButtonConfig({
      title: "",
      icon: "abb-icon Map",
      sidebarDisplay: SidemenuComponents.MapExplorer,
      clickEventFunction: () => this.router.navigateByUrl(`/report/${FLEET_VIEW_REPORT_ID}`)
    }),
    new BuilderHeaderButtonConfig({
      title: "",
      icon: "abb-icon Tree_view",
      sidebarDisplay: SidemenuComponents.TreeExplorer,
      clickFunction: () => this.toggleButtonClick(SidemenuComponents.TreeExplorer)
    })
  ];

  private readonly editModeCommonHeaderButtonItems: BuilderHeaderButtonConfig[] = [
    new BuilderHeaderButtonConfig({
      title: "",
      icon: "abb-icon Database",
      sidebarDisplay: SidemenuComponents.DataExplorer,
      clickFunction: () => this.toggleButtonClick(SidemenuComponents.DataExplorer)
    })
  ];

  private getCommonExplorerHeaderButtonsItems(viewMode: ViewMode): BuilderHeaderButtonConfig[] {
    switch (viewMode) {
      case ViewMode.PreviewMode:
        return this.previewModeCommonHeaderButtonItems;
      case ViewMode.EditMode:
        return this.editModeCommonHeaderButtonItems;
      default:
        throw new Error(`View mode ${viewMode} not supported!`);
    }
  }

  private getExclusiveExplorerHeaderButtonItems$(
    items: BuilderHeaderButtonConfig[],
    viewMode: ViewMode
  ): Observable<BuilderHeaderButtonConfig[]> {
    const customer = this.route.snapshot.queryParamMap.get("rootPath")?.split("/")[0];

    const isCustomerSelected = !isUndefined(customer);
    const shouldShowCopilotIcon = (): boolean =>
      isCustomerSelected && viewMode === ViewMode.PreviewMode;

    const $items = of(items);
    const $modules = this.http
      .get<ModuleDTO[]>(`${this.apiConfig.itemsServiceUrl}/access-management/modules/${customer}`)
      .pipe(
        map((modules: ModuleDTO[]) => {
          let result: BuilderHeaderButtonConfig[] = []

          modules.map((module: ModuleDTO) => {
            result.push(new BuilderHeaderButtonConfig({
              title: "",
              icon: `abb-icon ${module.name}`,
              sidebarDisplay: module.name as SidemenuComponents,
              clickEventFunction: () =>
                window.open(this.getModuleLink(module.name, customer), "_blank")
            }));
          });

          return items.concat(result);
        })
      );

    return iif(shouldShowCopilotIcon, $modules, $items);
  }

  generateExplorerHeaderButtonItems$(): Observable<BuilderHeaderButtonConfig[]> {
    return this.environmentSelector.selectViewMode().pipe(
      map((viewMode) => {
        return {
          commonItems: this.getCommonExplorerHeaderButtonsItems(viewMode),
          viewMode
        };
      }),
      switchMap(({ commonItems, viewMode }) =>
        this.getExclusiveExplorerHeaderButtonItems$(commonItems, viewMode)
      )
    );
  }

  private getModuleLink(moduleName: string, customer: string | undefined): string {
    switch (moduleName) {
      case SidemenuComponents.Copilot:
        return `${window.location.origin}/copilot/customer/${customer}`;
      case SidemenuComponents.Trendex:
        return `${window.location.origin}/trendex`;
      default:
        return "";
    }
  }
}
