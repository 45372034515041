<div class="runtime-range__container" (clickOutside)="closeIfDropdown()">
  <div [class.element--hidden]="isFilterBarSelected && canExpandHorizontally">
    <filter-toolbar-element
      [buttonConfig]="timeRangeButton"
      [selectedValueLabel]="localizer.filterComponent[timePreset]"
      [isDropdown]="!canExpandHorizontally"
      [isOpened]="isFilterBarSelected"
    ></filter-toolbar-element>
  </div>
  <div
    *ngIf="isFilterBarSelected"
    class="runtime-range--expanded-wrapper"
    [class.runtime-range--expanded-wrapper-horizontal]="canExpandHorizontally"
  >
    <div *ngIf="shouldRenderStandardPresetVertically(); else excludedCustomAndIntervalPresets">
      <ul class="time-preset-list">
        <li
          class="time-preset-list-item"
          *ngFor="let preset of presetOptions"
          [ngClass]="{
            'time-preset-list-item-selected': preset.key === timePreset
          }"
          (click)="onChangeTimePreset(preset.key)"
        >
          {{ preset.title | translate }}
        </li>
      </ul>
    </div>

    <ng-template #excludedCustomAndIntervalPresets>
      <div
        [ngClass]="
          canExpandHorizontally
            ? 'runtime-range__expanded-bar'
            : 'runtime-range__expanded-bar--vertical'
        "
      >
        <div
          [ngClass]="
            canExpandHorizontally ? 'runtime-range__items' : 'runtime-range__items--vertical'
          "
        >
          <time-preset-filter
            *ngIf="shouldRenderPresetHorizontally()"
            [timePreset]="timePreset"
            [liveModeFilter]="liveModeFilter"
            [historicTimeRange]="historicTimeRange"
            [horizontalLayout]="canExpandHorizontally"
            (onTimePresetChange)="onChangeTimePreset($event)"
            (onIntervalParamsChange)="onChangeLiveModeFilter($event)"
            (onCustomParamsChange)="onChangeTimeRangeParams($event)"
          ></time-preset-filter>
          <div
            class="runtime-range__input"
            *ngIf="canExpandHorizontally && timePreset === TimePreset.Custom"
          >
            <input
              class="input-element historical-date-input"
              [ngClass]="{ 'input-element--error': !isTimeRangeValid }"
              [(ngModel)]="historicTimeRangeInput"
              (ngModelChange)="timeRangeUpdate.next($event)"
              (click)="showOrHideDateTimePicker(true, $event)"
              title="{{ dateTimeFormat }}"
            />
          </div>
          <div
            *ngIf="isDateTimePickerOpened && canExpandHorizontally"
            class="date-time-range-picker"
          >
            <date-time-range-picker
              [isOpened]="isDateTimePickerOpened"
              [dateTimeRange]="historicTimeRange"
              (onChangeDisplay)="hideDateTimePicker()"
              (onDateTimeChange)="onSelectDateTimePicker($event)"
            ></date-time-range-picker>
          </div>

          <button
            *ngIf="canApplyFilterChanges"
            class="abb-icon Check_mark app-filter-blue-button"
            (click)="applyChanges()"
            title="{{ localizer.filterComponent.Apply | translate }}"
          ></button>

          <button
            class="app-filter-button app-filter-button__collapse"
            tabindex="-1"
            [ngClass]="{ 'element--hidden': !canExpandHorizontally, selected: isFilterBarSelected }"
            (click)="closeBar($event)"
          >
            <i class="abb-icon abb-icon--medium Left"></i>
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
