import { Options } from "highcharts";
import { fontSize14 } from "../../../../_typography";
import { LinkResolver } from "../../../browsing/services/link-resolver";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { mergeDeep } from "../../../ts-utils";
import { IPieDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { PieDisplayService } from "./pie-display.service";

export class DonutDisplayService extends PieDisplayService {
  constructor(protected valueFormatter: ValueFormatterService) {
    super(valueFormatter);
  }

  getChartOptions(
    viewConfig: IPieDisplayConfig,
    dataConnectorDescriptors: DataConnectorDescriptor[],
    linkResolver: LinkResolver
  ): Options {
    const pieChartOptions = super.getChartOptions(
      viewConfig,
      dataConnectorDescriptors,
      linkResolver
    );
    const donutChartOptions: Options = {
      plotOptions: {
        pie: {
          innerSize: "80%",
          center: ["50%", "50%"]
        }
      },
      subtitle: {
        useHTML: true,
        text: this.getCenterText(viewConfig, dataConnectorDescriptors),
        verticalAlign: "middle",
        style: {
          fontSize: fontSize14 + "px",
          textAlign: "center"
        },
        y: this.getYOffset(viewConfig)
      }
    };

    return mergeDeep(pieChartOptions, donutChartOptions);
  }

  getCenterText(
    viewConfig: IPieDisplayConfig,
    dataConnectorDescriptors: DataConnectorDescriptor[]
  ): string {
    const { heightInPx, widthInPx } = viewConfig.runtimeView.runtimeSize;
    // FIXME this should be related to highcharts plot dimensions, not sure how to provide it to disp strategy
    if (heightInPx < 300 || widthInPx < 300) {
      return "";
    }

    const { showSum, centerLabelText, yAxisTitle } = viewConfig;
    if (showSum && centerLabelText) {
      return `${centerLabelText}<br/>${this.calculateSum(
        viewConfig,
        dataConnectorDescriptors
      )} ${yAxisTitle}`;
    } else if (centerLabelText) {
      return centerLabelText;
    } else if (showSum) {
      return `${this.calculateSum(viewConfig, dataConnectorDescriptors)} ${yAxisTitle}`;
    } else if (yAxisTitle) {
      return yAxisTitle;
    }

    return "";
  }

  protected calculateSum(
    viewConfig: IPieDisplayConfig,
    dataConnectorDescriptors: DataConnectorDescriptor[]
  ): string {
    const seriesDataOpts: Highcharts.PointOptionsObject[] =
      this.getHighchartPoints(dataConnectorDescriptors);
    const sum = seriesDataOpts.reduce((sum, data) => sum + (data.y ?? 0), 0);
    return this.valueFormatter.formatValue(sum, viewConfig.displayFormat);
  }

  private getYOffset(viewConfig: IPieDisplayConfig): number {
    // FIXME we should find better way to define this offset, not with magic numbers
    return viewConfig.showLegend ? 20 : 35;
  }
}
