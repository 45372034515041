import { PropertyDescriptor, TypeDescriptor, TypeProvider } from "../../meta";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { ItemType } from "../components/array-editor/models";

export function resolveItemDisplayName(
  arrayItem: ItemType,
  itemTitleProperty: Maybe<PropertyDescriptor>,
  typeProvider: TypeProvider
): string {
  if (isNotDefined(arrayItem)) {
    return "";
  }
  switch (typeof arrayItem) {
    case "object":
      return resolveNestedItemTitle(arrayItem, itemTitleProperty, typeProvider);
    case "string":
      return arrayItem.toString();
    default:
      return arrayItem;
  }
}

export function resolveNestedItemTitle(
  arrayItem: ItemType,
  itemTitleProperty: Maybe<PropertyDescriptor>,
  typeProvider: TypeProvider
): string {
  if (isDefined(itemTitleProperty)) {
    return arrayItem[itemTitleProperty.name];
  }
  const instanceType: TypeDescriptor = typeProvider.getTypeByConstructor(arrayItem.constructor);
  return instanceType.name;
}
