import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DisplayMode } from "../../../core/models/display-mode";
import { IFeedbackService } from "../../../core/services/i-feedback.service";
import { IUserPreferencesService } from "../../../core/services/i-user-preferences.service";
import { Dispatcher } from "../../../dispatcher";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { FeatureAvailabilityService } from "../../../environment/services/feature-availability.service";
import { LocalizationService } from "../../../i18n/localization.service";
import { FeedbackDialogActions } from "../../dialogs/actions/feedback-dialog.actions";
import { HeaderButtonConfig } from "../../models/button/header-button.config";

@Component({
  selector: "user-menu",
  templateUrl: "user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"]
})
export class UserMenuComponent implements OnInit, OnDestroy {
  @ViewChild("userMenu") userMenu: ElementRef;
  userName: string = "";
  userMenuOpened: boolean = false;
  displayMode: string;
  DisplayMode = DisplayMode;
  userMenuButton: HeaderButtonConfig;
  userNameButton: HeaderButtonConfig;
  logOutButton: HeaderButtonConfig;
  feedbackButton: HeaderButtonConfig;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    public dispatcher: Dispatcher,
    public localizer: LocalizationService,
    public feedbackService: IFeedbackService,
    public environmentSelector: EnvironmentSelector,
    private featureAvailability: FeatureAvailabilityService,
    private userPreferencesService: IUserPreferencesService
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.createButtons();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  get shouldShowUserMenu(): boolean {
    return this.featureAvailability.userMenu;
  }

  private initSubscriptions(): void {
    this.subscribeToUserName();
    this.subscribeToDisplayMode();
    this.subscribeToSidebarChange();
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
      });
  }

  private subscribeToUserName(): void {
    this.environmentSelector
      .selectUserName()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((userName: string) => {
        this.userName = userName;
      });
  }

  private subscribeToSidebarChange(): void {
    this.environmentSelector
      .selectSidebarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isSidebarOpened: boolean) => {
        if (shouldCloseUsermenu(this.userMenuOpened, isSidebarOpened, this.displayMode)) {
          this.closeDropdown();
        }
      });
  }

  createButtons(): void {
    this.userMenuButton = this.createDropdownButtonConfig();
    this.userNameButton = this.createUserNameButtonConfig();
    this.feedbackButton = this.createFeedbackButtonConfig();
    this.logOutButton = this.createLogOutButtonConfig();
  }

  private createDropdownButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.UserMenu,
      clickFunction: () => {
        return this.openOrCloseDropdown();
      },
      hasDropdown: true
    });
  }

  private createUserNameButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.userName
    });
  }

  private createLogOutButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: this.localizer.buttons.LogOut,
      clickFunction: () => {
        return this.logOut();
      }
    });
  }

  private createFeedbackButtonConfig(): HeaderButtonConfig {
    return new HeaderButtonConfig({
      title: "Give feedback",
      clickFunction: () => {
        return this.giveFeedback();
      }
    });
  }

  openOrCloseDropdown(): void {
    this.userMenuOpened ? this.closeDropdown() : this.openDropdown();
  }

  openDropdown(): void {
    this.userMenuOpened = true;
    this.userMenu.nativeElement.classList.toggle("show");
  }

  closeDropdown(): void {
    this.userMenuOpened = false;
    this.userMenu.nativeElement.classList.remove("show");
  }

  logOut(): void {
    this.userPreferencesService.logOut();
  }

  giveFeedback(): void {
    this.dispatcher.dispatch(FeedbackDialogActions.openFeedbackDialog());
  }
}

function shouldCloseUsermenu(
  isUserMenuOpened: boolean,
  isSidebarOpened: boolean,
  displayMode: string
): boolean {
  return isUserMenuOpened && isSidebarOpened && displayMode === DisplayMode.Mobile;
}
