import { cloneDeep as _cloneDeep, findLastIndex as _findLastIndex } from "lodash";
import { v4 as uuid } from "uuid";
import { construct } from "../../core/services/construct.helper";
import { DataStatus } from "../../elements/models/data-status";
import { DATA_CONNECTOR_DTO } from "../../elements/models/entity-type.constants";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../meta/decorators/configurable-enum.decorator";
import { ConfigurationCategory } from "../../meta/decorators/configuration-category.decorator";
import { DynamicDefaultFromMetadata } from "../../meta/decorators/dynamic-default.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { AllowInterpolation } from "../../meta/decorators/interpolation.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { Title } from "../../meta/decorators/title.decorator";
import { Virtual } from "../../meta/decorators/virtual.decorator";
import { CutOffStrategyType, InheritableCutOffStrategy } from "../../meta/models/cut-off-strategy";
import { Entity, EntityId } from "../../meta/models/entity";
import { PropertyCategory } from "../../meta/models/property-category";
import { ValidationContext } from "../../meta/models/validation-context";
import { DeepPartial, Dictionary, Maybe } from "../../ts-utils";
import { createDataSource } from "../helpers/data-source-creation.helper";
import { CommonDataPropertyNames } from "./common-data-properties";
import { DataPointDto, TimeSeriesDataPointDto } from "./data-point";
import { DataSourceDto } from "./data-source/data-source";
import { EMPTY_DATA_SOURCE } from "./data-source/data-source.type";
import { EmptyDataSourceDto } from "./data-source/empty-data-source";

export const DATA_CONNECTOR = "Data Connector";
const TYPE_NAME = DATA_CONNECTOR_DTO;
export type ConnectorMetadataProperty = string | number;

@EditableType({ fullName: TYPE_NAME, title: "data-connector" })
export class DataConnectorDto extends Entity {
  typeName = TYPE_NAME;

  public dataPoints: Maybe<DataPointDto[]> = null;
  public properties: Dictionary<ConnectorMetadataProperty> = {};
  public isTimeSeries = true;
  public isDynamicallyCreated = false;
  public isIncrementalUpdate?: boolean = false;

  /**
   * Analytics for signals. Grouped by name and containing a list of sub-series.
   * @example <caption>Inner forecasts:</caption>
   * const forecast1: DataPointDto[] = [{ x: 1, y: 1 }, { x: 3, y: 2 }]
   * const forecast2: DataPointDto[] = [{ x: 5, y: 5 }, { x: 10, y: 7 }]
   * @example <caption>Data points for `Forecast` analytic.</caption>
   * analytics["Forecast"] = [forecast1, forecast2];
   */
  public analytics?: Dictionary<DataPointDto[][]>;

  // Set by host widget, determines how many data points to query. Line chart depends on the chart width,
  // single value required only one data point
  // set by effect, not serialized
  public numberOfRequestedDataPoints: number;

  @Serializable("")
  public role!: string;

  @AllowInterpolation()
  @Serializable("")
  @Title()
  @DynamicDefaultFromMetadata("", CommonDataPropertyNames.defaultTitle, -1, true, false)
  public title!: string;

  @Serializable(new EmptyDataSourceDto())
  @Virtual()
  public dataSource!: DataSourceDto;

  @Serializable(null)
  filterId: Maybe<EntityId>;

  @ConfigurationCategory(
    PropertyCategory.Data,
    LOCALIZATION_DICTIONARY.propertySheet.DataAggregation
  )
  @ConfigurableEnum({
    enumSource: InheritableCutOffStrategy,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategy,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CutOffStrategyTooltip
  })
  @Serializable(InheritableCutOffStrategy.Inherited)
  cutOffStrategy!: CutOffStrategyType;

  @Serializable(false)
  public isComputing?: boolean;

  public dataStatus: DataStatus = DataStatus.NoDataDefined;

  constructor(connector: DeepPartial<DataConnectorDto> = {}) {
    super();
    connector = {
      ...connector,
      dataSource: createDataSource(
        connector.dataSource?.typeName ?? EMPTY_DATA_SOURCE,
        connector.dataSource ?? {},
        TYPE_NAME
      )
    };
    construct(this, connector, TYPE_NAME, { id: uuid() });
  }
}

// IP not needed
export function isTimeSeries(connector: DataConnectorDto): boolean {
  return connector.isTimeSeries;
}

export function cloneDataConnector(source: DataConnectorDto): DataConnectorDto {
  return _cloneDeep(source);
}

export function isNotDynamicallyCreated(
  dataConnector: DataConnectorDto,
  _validationContext: ValidationContext
): boolean {
  return !dataConnector.isDynamicallyCreated;
}

export function flattenDataConnectorsMatrix(
  equipmentConnectorsDict: Dictionary<DataConnectorDto[]>
): DataConnectorDto[] {
  return Object.values(equipmentConnectorsDict).reduce(
    (acc, connectorList) => acc.concat(connectorList),
    []
  );
}

export function get2ndLatestGoodTimestamp(dataConnector: DataConnectorDto): Date {
  if (!isTimeSeries(dataConnector) || dataConnector.dataPoints == null) {
    return null;
  }
  const lastGoodIndex = _findLastIndex(dataConnector.dataPoints, (p) => p.y != null);
  if (lastGoodIndex <= 0) {
    return null;
  }

  const secondLastGoodIndex = _findLastIndex(
    dataConnector.dataPoints,
    (p) => p.y != null,
    lastGoodIndex - 1
  );
  if (secondLastGoodIndex < 0) {
    return null;
  } else {
    return (dataConnector.dataPoints[secondLastGoodIndex] as TimeSeriesDataPointDto).x;
  }
}
