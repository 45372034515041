import { isDefined } from "../../ts-utils/helpers/predicates.helper";

export class SidebarLocalizationDictionary {
  RDS: string = "RDS";
  ABB: string = "ABB";
  Global: string = "Global";
  DataExplorer: string = "DataExplorer";
  TreeExplorer: string = "TreeExplorer";
  MapExplorer: string = "MapExplorer";
  Copilot: string = "Copilot";
  Trendex: string = "Trendex";
  LayoutEditor: string = "LayoutEditor";
  Equipment: string = "Equipment";
  OtherComponents: string = "OtherComponents";
  SingleValueDisplay: string = "SingleValueDisplay";
  TimeSeriesDisplay: string = "TimeSeriesDisplay";
  CategoryDisplay: string = "CategoryDisplay";
  Signals: string = "Signals";
  Premade: string = "Premade";
  Container: string = "Container";
  Widgets: string = "Widgets";
  SearchSignals: string = "SearchSignals";
  SearchEquipment: string = "SearchEquipment";
  Properties: string = "Properties";
  Recursive: string = "Recursive";
  GeneralSettings: string = "GeneralSettings";
  UserPrefrences: string = "UserPreferences";
  KM: string = "KM"; // FIXME Extend into KmSidebarLocDict and move these three props there
  EM: string = "EM";
  AV: string = "AV";
  Glitter: string = "Glitter";
  CreateNewReport: string = "CreateNewReport";
  InvalidRootPath = "InvalidRootPath";
  HideSignalsDescription = "HideSignalsDescription";
  ShowSignalsDescription = "ShowSignalsDescription";
  WidgetExplorer: string = "WidgetExplorer";

  constructor() {
    // IP FIXME this should be a func which generates an interface SidebarLocalizationDictionary
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof SidebarLocalizationDictionary;
      this[thisKey] = isDefined(thisKey) ? `Sidebar.${thisKey}` : "";
    });
  }
}
