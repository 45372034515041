import { ComponentTypeInfo } from "../../elements/models/component-type-info";
import { LocalizationService } from "../../i18n/localization.service";
import { isDefined } from "../../ts-utils";

export function searchWidgetByPattern(
  components: ComponentTypeInfo[],
  pattern: string,
  localizer: LocalizationService
): ComponentTypeInfo[] {
  const componentNames: string[] = components.map((comp) => comp.alias.displayName);
  return componentNames.reduce((acc: ComponentTypeInfo[], component) => {
    const componentType = components.find((x) => x.alias.displayName === component);
    if (isDefined(componentType)) {
      if (titleMatchPattern(component, pattern, localizer)) {
        acc.push(componentType);
        return acc;
      }
    }
    return acc;
  }, []);
}

export function titleMatchPattern(
  title: string,
  pattern: string,
  localizer: LocalizationService
): boolean {
  return localizer.get(title).toLowerCase().search(pattern.toLowerCase()) > -1;
}
