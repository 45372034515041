import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { LocalizationService } from "../../../i18n/localization.service";
import { SelectionOption } from "../../../meta/models/selection";
import { isDefined, isString } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";

@Component({
  selector: "selector",
  templateUrl: "selector.component.html",
  styleUrls: ["./selector.component.scss"]
})
export class SelectorComponent implements OnChanges {
  @Input() items: SelectionOption[] | string[] = [];
  @Input() selectedItem: string;
  @Input() distinctDefault: boolean = false;
  @Output() onChangeValue: EventEmitter<any> = new EventEmitter();
  @ViewChild("selectElement")
  selectElement?: ElementRef<HTMLInputElement>;

  constructor(public localizer: LocalizationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (isDefined(changes["items"]) && isDefined(changes["items"].currentValue)) {
      this.items = changes["items"].currentValue.map((item: string | SelectionOption) =>
        isString(item) ? { key: item, title: item } : item
      );
    }
  }

  public onValueChange(item: string): void {
    this.selectedItem = item;
    this.onChangeValue.emit(this.selectedItem);
  }

  getSelectElement(): Maybe<ElementRef<HTMLInputElement>> {
    return this.selectElement;
  }
}
