import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTreeModule } from "@angular/material/tree";
import { EffectsModule } from "@ngrx/effects";
import { Store, StoreModule } from "@ngrx/store";
import { I18NModule } from "../i18n/i18n.module";
import { MaterialModule } from "../material/material.module";
import { SharedModule } from "../shared/shared.module";
import { EquipmentBrowserComponent } from "./components/equipment-browser/equipment-browser.component";
import { EquipmentPathBrowserComponent } from "./components/equipment-path-browser/equipment-path-browser.component";
import { EquipmentPathNodeComponent } from "./components/equipment-path-node/equipment-path-node.component";
import { IconBrowserComponent } from "./components/icon-browser/icon-browser.component";
import { IconComponent } from "./components/icon/icon.component";
import { ItemBrowserComponent } from "./components/item-browser/item-browser.component";
import { ReportBrowserComponent } from "./components/report-browser/report-browser.component";
import { SignalBrowserComponent } from "./components/signal-browser/signal-browser.component";
import { DataExplorerSelector } from "./services/data-explorer.selector";
import { EquipmentSelector } from "./services/equipment.selector";
import { LinkResolver } from "./services/link-resolver";
import { ReportBrowserSelector } from "./services/report-browser.selector";
import { BrowsingReportEffects } from "./store/cached-reports/cached-reports.effects";
import { EquipmentModelEffects } from "./store/equipment/equipment.effects";
import { CACHE_FEATURE } from "./store/feature.selector";
import { reducers } from "./store/index.reducer";
import { ReportIdsEffects } from "./store/report-ids/report-ids.effects";

const COMPONENTS = [
  ReportBrowserComponent,
  SignalBrowserComponent,
  EquipmentBrowserComponent,
  EquipmentPathBrowserComponent,
  EquipmentPathNodeComponent,
  ItemBrowserComponent,
  IconComponent,
  IconBrowserComponent
];

@NgModule({
  imports: [
    CommonModule,
    I18NModule,
    StoreModule.forFeature(CACHE_FEATURE, reducers),
    EffectsModule.forFeature([ReportIdsEffects, BrowsingReportEffects, EquipmentModelEffects]),
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialModule,
    MatTreeModule
  ],
  declarations: [...COMPONENTS],
  exports: COMPONENTS,
  providers: [ReportBrowserSelector, Store, EquipmentSelector, LinkResolver, DataExplorerSelector]
})
export class BrowsingModule {}
