import { Action, createReducer, on } from "@ngrx/store";
import { DisplayMode } from "../../../core/models/display-mode";
import { ViewMode } from "../../../core/models/view-mode";
import { isReportEmpty } from "../../../elements/helpers/report-content.helper";
import { ReportEntities } from "../../../elements/models/report-entities";
import { CommonActions } from "../../../elements/store/common/common.actions";
import { AppStatus } from "../../models/app-status";
import { AppStatusActions } from "./app-status.actions";

const initialState: AppStatus = {
  viewMode: ViewMode.PreviewMode,
  isReportSaving: false,
  isReportLoading: false,
  connectionStatus: true,
  filterToolbarOpened: false,
  filterToolbarPinned: false,
  sidebarOpened: false,
  propertySheetOpened: false,
  propertySheetPinned: true,
  displayMode: DisplayMode.Desktop,
  historyViewOpened: false,
  advancedMode: false,
  templateBuilderMode: false
};
export function reducer(state: AppStatus, action: Action) {
  return _reducer(state, action);
}

const _reducer = createReducer(
  initialState,
  on(AppStatusActions.startLoadingReport, (state) => setLoadingReport(state)),
  on(AppStatusActions.finishLoadingReport, (state, { reportEntities }) =>
    unsetLoadingReport(state, reportEntities)
  ),
  on(CommonActions.startSavingReport, (state) => setSavingReport(state)),
  on(CommonActions.finishSavingReport, (state) => unsetSavingReport(state)),
  on(AppStatusActions.updateConnectionStatus, (state, { isOnline }) =>
    updateConnectionStatus(state, isOnline)
  ),
  on(AppStatusActions.enterPreviewMode, (state) => enterPreviewMode(state)),
  on(AppStatusActions.exitPreviewMode, (state) => exitPreviewMode(state)),
  on(AppStatusActions.toggleHistoryViewMode, (state) => toggleHistoryViewMode(state)),
  on(AppStatusActions.toggleAdvancedMode, (state) => toggleAdvancedMode(state)),
  on(AppStatusActions.openSidebar, (state) => openSidebar(state)),
  on(AppStatusActions.closeSidebar, (state) => closeSidebar(state)),
  on(AppStatusActions.openFilterToolbar, (state) => openFilterToolbar(state)),
  on(AppStatusActions.closeFilterToolbar, (state) => closeFilterToolbar(state)),
  on(AppStatusActions.changeFilterToolbarPinMode, (state, { pinMode }) =>
    onChangeFilterToolbarPinMode(state, pinMode)
  ),
  on(AppStatusActions.openPropertySheet, (state) => expandPropertySheet(state)),
  on(AppStatusActions.closePropertySheet, (state) => collapsePropertySheet(state)),
  on(AppStatusActions.changePropertySheetPinMode, (state, { pinMode }) =>
    onChangePropertySheetPinMode(state, pinMode)
  ),
  on(AppStatusActions.changeDisplayMode, (state, { displayMode }) =>
    onChangeDisplayMode(state, displayMode)
  ),
  on(AppStatusActions.enterTemplateBuilderMode, (state) => onEnterTemplateBuilderMode(state))
);

function setSavingReport(state: AppStatus): AppStatus {
  return {
    ...state,
    isReportSaving: true
  };
}

function unsetSavingReport(state: AppStatus): AppStatus {
  return {
    ...state,
    isReportSaving: false
  };
}

function setLoadingReport(state: AppStatus): AppStatus {
  return {
    ...state,
    isReportLoading: true
  };
}

function unsetLoadingReport(state: AppStatus, reportEntities: ReportEntities): AppStatus {
  const viewMode: ViewMode = isReportEmpty(reportEntities.componentStates.length)
    ? ViewMode.EditMode
    : ViewMode.PreviewMode;
  return {
    ...state,
    viewMode: viewMode,
    isReportLoading: false
  };
}

function updateConnectionStatus(state: AppStatus, isOnline: boolean): AppStatus {
  return {
    ...state,
    connectionStatus: isOnline
  };
}

function enterPreviewMode(state: AppStatus): AppStatus {
  const updatedState = {
    ...state,
    viewMode: ViewMode.PreviewMode
  };
  return updatedState;
}

function exitPreviewMode(state: AppStatus): AppStatus {
  const updatedState = {
    ...state,
    viewMode: ViewMode.EditMode
  };
  if (state.filterToolbarOpened) {
    updatedState.filterToolbarOpened = false;
  }
  return updatedState;
}

function openSidebar(state: AppStatus): AppStatus {
  const updatedState = {
    ...state,
    sidebarOpened: true
  };
  if (state.displayMode === DisplayMode.Mobile && state.filterToolbarOpened) {
    updatedState.filterToolbarOpened = false;
  }
  if (state.displayMode === DisplayMode.Mobile && state.propertySheetOpened) {
    updatedState.propertySheetOpened = false;
  }
  return updatedState;
}

function closeSidebar(state: AppStatus): AppStatus {
  const updatedState = {
    ...state,
    sidebarOpened: false
  };
  return updatedState;
}

function openFilterToolbar(state: AppStatus): AppStatus {
  const updatedState = {
    ...state,
    filterToolbarOpened: true
  };
  if (state.displayMode === DisplayMode.Mobile && state.sidebarOpened) {
    updatedState.sidebarOpened = false;
  }
  return updatedState;
}

function closeFilterToolbar(state: AppStatus): AppStatus {
  return {
    ...state,
    filterToolbarOpened: false
  };
}

function onChangeFilterToolbarPinMode(state: AppStatus, pinMode: boolean): AppStatus {
  return {
    ...state,
    filterToolbarPinned: pinMode
  };
}

function expandPropertySheet(state: AppStatus): AppStatus {
  return {
    ...state,
    propertySheetOpened: true
  };
}

function collapsePropertySheet(state: AppStatus): AppStatus {
  return {
    ...state,
    propertySheetOpened: false
  };
}

function onChangePropertySheetPinMode(state: AppStatus, pinMode: boolean): AppStatus {
  return {
    ...state,
    propertySheetPinned: pinMode
  };
}

function onEnterTemplateBuilderMode(state: AppStatus): AppStatus {
  return {
    ...state,
    templateBuilderMode: true
  };
}

function onChangeDisplayMode(state: AppStatus, displayMode: DisplayMode): AppStatus {
  const updatedState = {
    ...state,
    displayMode
  };
  if (displayMode !== DisplayMode.Desktop) {
    if (state.propertySheetPinned) {
      updatedState.propertySheetPinned = false;
    }
    if (displayMode === DisplayMode.Mobile) {
      if (state.filterToolbarOpened) {
        updatedState.filterToolbarOpened = false;
      }
      if (state.filterToolbarPinned) {
        updatedState.filterToolbarPinned = false;
      }
      if (state.sidebarOpened) {
        updatedState.sidebarOpened = false;
      }
    }
  }
  if (state.propertySheetOpened) {
    updatedState.propertySheetOpened = false;
  }
  return updatedState;
}

function toggleHistoryViewMode(state: AppStatus): AppStatus {
  return {
    ...state,
    historyViewOpened: !state.historyViewOpened
  };
}

function toggleAdvancedMode(state: AppStatus): AppStatus {
  return {
    ...state,
    advancedMode: !state.advancedMode
  };
}
