import { Injectable } from "@angular/core";
import moment from "moment";
import { combineLatest, Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { TIME_AM_PM_FORMAT } from "../../environment/helpers/date-formatter.helper";
import { EnvironmentSelector } from "../../environment/services/environment.selector";
import { isDefined, isEmpty, Maybe } from "../../ts-utils";
import { IGeneralSettingsSelector } from "./filter/i-general-settings.selector";

@Injectable({
  providedIn: "root"
})
export class TimeService {
  private _useServerTime: boolean = true;
  private _currentTime: Date;
  private _shiftStartTime: Maybe<string> = null;

  constructor(
    private generalSettingsSelector: IGeneralSettingsSelector,
    private environmentSelector: EnvironmentSelector
  ) {
    this.generalSettingsSelector
      .selectUseServerTime()
      .subscribe((useServerTimeState) => (this._useServerTime = useServerTimeState));
    this.getCurrentTimeObservable().subscribe((currentTime) => (this._currentTime = currentTime));
    this.environmentSelector
      .selectShiftStartTime()
      .subscribe((shiftStartTime: Maybe<string>) => (this._shiftStartTime = shiftStartTime));
    this.setStartOfWeek();
  }

  public get useServerTime(): boolean {
    return this._useServerTime;
  }

  public get currentTime(): Date {
    return this._currentTime;
  }

  get shiftStartTime(): Maybe<string> {
    return this._shiftStartTime;
  }

  set shiftStartTime(startTime: Maybe<string>) {
    this._shiftStartTime = startTime;
  }

  private setStartOfWeek(): void {
    moment.updateLocale("en", {
      week: {
        dow: 1 // Monday is the first day of the week.
      }
    });
  }

  // FIXME returns Maybe<string | Date> but Date is expected everywhere
  public patchDateToServer(clientDate: Maybe<Date>): any {
    if (this.useServerTime) {
      if (clientDate == null) {
        return null;
      }
      return moment(clientDate).format("YYYY-MM-DD[T]HH:mm");
    } else {
      return clientDate;
    }
  }

  public getCurrentTimeObservable(): Observable<Date> {
    return combineLatest([
      this.environmentSelector.selectClientNow(),
      this.getCurrentOffsetObservable()
    ]).pipe(
      map(([clientNow, offset]: [Date, number]) => {
        return moment(clientNow).add(offset).toDate();
      })
    );
  }

  public getCurrentOffsetObservable(): Observable<number> {
    return combineLatest([
      this.environmentSelector.selectServerOffset(),
      this.generalSettingsSelector.selectUseServerTime()
    ]).pipe(
      map(([serverOffset, useServerTime]: [number, boolean]) => (useServerTime ? serverOffset : 0))
    );
  }

  public getCurrentOffset(): Maybe<number> {
    let currentOffset = null;
    this.getCurrentOffsetObservable()
      .pipe(first())
      .subscribe((offset) => {
        currentOffset = offset;
      });
    return currentOffset;
  }

  determineDayStart(): moment.Moment {
    const userDefinedStartTime = this.generalSettingsSelector.getWorkDayStartTime();
    let workDayStartTime = "";
    if (isDefined(userDefinedStartTime) && !isEmpty(userDefinedStartTime)) {
      workDayStartTime = userDefinedStartTime;
    } else if (isDefined(this.shiftStartTime)) {
      workDayStartTime = this.shiftStartTime;
    } else {
      workDayStartTime = moment().startOf("day").format(TIME_AM_PM_FORMAT);
    }
    return moment(workDayStartTime, TIME_AM_PM_FORMAT);
  }
}
