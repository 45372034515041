import { TableFooterDescriptor } from "../models/table/table-footer-descriptor";
import { TableHeaderDescriptor } from "../models/table/table-header-descriptor";
import {
  DEFAULT_HEADER_TITLE,
  FooterFunctions,
  HeaderProperties
} from "../models/table/table-header-footer-options";
import { generateIdForSelectableItem } from "./column.helper";

export function getDefaultHeaders(): TableHeaderDescriptor[] {
  return Object.keys(HeaderProperties).map(
    (header: string) =>
      new TableHeaderDescriptor({
        id: generateIdForSelectableItem(header),
        label: header,
        template: HeaderProperties[header],
        isPredefinedItem: true,
        isSelected: header === DEFAULT_HEADER_TITLE
      })
  );
}

export function getDefaultFooters(): TableFooterDescriptor[] {
  return Object.keys(FooterFunctions).map(
    (footer) =>
      new TableFooterDescriptor({
        id: generateIdForSelectableItem(footer, false),
        label: FooterFunctions[footer],
        function: footer as FooterFunctions,
        isPredefinedItem: true
      })
  );
}
