import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import { ComponentStateDto } from "../../elements/models/component-state";
import { DisplayFormatDto } from "../../elements/models/display-format";
import { EntityId } from "../../meta/models/entity";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RelocateDisplayFormat implements UpgradeStep {
  name = "RelocateDisplayFormat";
  fromVersion: Version = new Version(4, 0, 17);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates = oldConfig.componentStates;
    let modified = false;
    if (isDefined(componentStates)) {
      for (const componentState of Object.values(componentStates.entities)) {
        if (
          isDefined((componentState as any).view) &&
          isDefined((componentState as any).view.displayFormat)
        ) {
          modified =
            this.relocateDisplayFormat(componentStates, (componentState as any).id) || modified;
        }
      }
    }
    return { result: oldConfig, modified, warning: null };
  }

  relocateDisplayFormat(
    componentStates: EntityState<ComponentStateDto>,
    componentId: EntityId
  ): boolean {
    const component = componentStates.entities[componentId];
    let modified: boolean = false;

    if (isDefined(component) && isDefined(component.view.displayFormat)) {
      const oldDisplayFormat: string = component.view.displayFormat as any;
      component.view.displayFormat = new DisplayFormatDto({ numberFormat: oldDisplayFormat });
      modified = true;
    }
    return modified;
  }
}
