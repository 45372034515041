import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ConfigurableEnum } from "../../../meta/decorators/configurable-enum.decorator";
import { ConfigurationCategory } from "../../../meta/decorators/configuration-category.decorator";
import { EditableType } from "../../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../../meta/decorators/serializable.decorator";
import { PropertyCategory, SelectionOption } from "../../../meta/models";
import { isEmptyOrNotDefined } from "../../../ts-utils";
import {
  getNumericColumnsForSelectedEntity,
  getSelectedEntityColumns
} from "../../helpers/generic-data-source-entities.helper";
import { GROUPED_DATA_SOURCE, GroupedDataSourceType } from "./data-source.type";
import { GenericDataSourceDto, isEmptyGenericSource } from "./generic-data-source";

enum AggregationOperation {
  Count = "Count",
  Sum = "Sum"
}

export enum LabelOrValue {
  Label = "Label",
  Value = "Value"
}

@EditableType({ fullName: GROUPED_DATA_SOURCE, title: "grouped-data-source-dto" })
export class GroupedDataSourceDto extends GenericDataSourceDto {
  typeName: GroupedDataSourceType = GROUPED_DATA_SOURCE;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: getSelectedEntityColumns,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.GroupBy
  })
  @Serializable("")
  groupBy1!: string;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: getSelectedEntityColumns,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.SecondaryGroupBy
  })
  @Serializable("")
  groupBy2!: string;

  groupBy: string[];

  // @ShowEnum("Round Date To", RoundDateFormat)
  // roundDateTo: RoundDateFormat = RoundDateFormat.None;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: AggregationOperation,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Aggregation
  })
  @Serializable(AggregationOperation.Count)
  aggregation!: AggregationOperation;

  @ConfigurationCategory(PropertyCategory.Data, LOCALIZATION_DICTIONARY.propertySheet.DataSource)
  @ConfigurableEnum({
    enumSource: getNumericColumnsForSelectedEntity,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.AggregationOf // IP mixed concerns
  })
  @Serializable("")
  aggregationOf!: string;

  constructor() {
    super();
    construct(this, {}, GROUPED_DATA_SOURCE);
  }
}

export function isEmptyGroupedSource(dataSource: GroupedDataSourceDto): boolean {
  return isEmptyGenericSource(dataSource) || isEmptyOrNotDefined(dataSource.groupBy1);
}

export function getGroupedDataSourceColumns(): SelectionOption[] {
  return Object.keys(LabelOrValue as any).map((key) => {
    return {
      key,
      title: LabelOrValue[key]
    };
  });
}
