import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { DOCUMENT } from "@angular/common";
import { Directive, Inject, OnDestroy, OnInit, Self } from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

// cdk-virtual-scroll-viewport does not handle window resizing
// similarly, if it's inside a mat-tab, the container size is not automatically updated
// see https://github.com/angular/components/issues/10117
@Directive({
  selector: "[cdk-virtual-scroll-viewport-patch]"
})
export class CdkVirtualScrollViewportPatch implements OnInit, OnDestroy {
  protected readonly destroy$ = new Subject<void>();

  constructor(
    @Self()
    @Inject(CdkVirtualScrollViewport)
    private readonly viewportComponent: CdkVirtualScrollViewport,

    @Inject(DOCUMENT)
    private readonly _document: any
  ) {}

  ngOnInit(): void {
    fromEvent(this._document.defaultView!, "resize")
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(() => {
        this.viewportComponent.checkViewportSize();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
