import { Update } from "@ngrx/entity";
import { cloneDeep as _cloneDeep } from "lodash";
import { guessDataStatusFromPoints } from "../../elements/helpers/connectors.helper";
import { DataStatus } from "../../elements/models/data-status";
import { EntityId } from "../../meta/models/entity";
import { Dictionary, isDefined, isNotDefined, Maybe } from "../../ts-utils";
import { DataConnectorDto, DataSourceDto } from "../models";
import { DataPointDto, TimeSeriesDataPointDto } from "../models/data-point";
import { SignalDataSourceDto } from "../models/data-source/signal-data-source";
import { isSignalBased } from "./data-source-type.helper";

// IMPORTANT: this module should not have any side effects as it's being used by reducers

export function getFullRangeConnectorUpdates(
  connectorsWithNewData: Dictionary<DataConnectorDto>,
  existingConnectors: Dictionary<DataConnectorDto>
): Update<DataConnectorDto>[] {
  return getConnectorUpdates(connectorsWithNewData, existingConnectors);
}

export function getIncrementalConnectorUpdates(
  connectorsWithNewData: Dictionary<DataConnectorDto>,
  existingConnectors: Dictionary<DataConnectorDto>,
  startCutoffDate: Date
): Update<DataConnectorDto>[] {
  return getConnectorUpdates(connectorsWithNewData, existingConnectors, true, startCutoffDate);
}

// FIXME refactor this into two functions that don't require a flag param
function getConnectorUpdates(
  connectorsWithNewData: Dictionary<DataConnectorDto>,
  existingConnectors: Dictionary<DataConnectorDto>,
  isIncrementalQuery: boolean = false,
  startCutoffDate: Maybe<Date> = null
): Update<DataConnectorDto>[] {
  const dataConnectorUpdates = Object.values(existingConnectors)
    .filter((existingConnector: DataConnectorDto) => isDefined(existingConnector.dataSource))
    .reduce((acc: Update<DataConnectorDto>[], existingConnector: DataConnectorDto) => {
      const changedConnector: DataConnectorDto = connectorsWithNewData[existingConnector.id];
      if (isDefined(changedConnector)) {
        const changes: Partial<DataConnectorDto> = getCommonChanges(
          existingConnector,
          changedConnector
        );

        changes.dataPoints =
          changedConnector.isIncrementalUpdate ?? isIncrementalQuery
            ? extractIncrementalDataPoints(existingConnector, changedConnector, startCutoffDate)
            : changedConnector.dataPoints;
        const newStatus: DataStatus = resolveDataStatus(
          changedConnector,
          existingConnector.dataStatus,
          changedConnector.isIncrementalUpdate ?? isIncrementalQuery
        );
        if (newStatus !== existingConnector.dataStatus) {
          changes.dataStatus = newStatus;
        }
        changes.analytics = changedConnector.analytics;

        if (dataUpdateRequired(changes)) {
          acc.push({
            id: existingConnector.id.toString(),
            changes: changes
          });
        }
      }
      return acc;
    }, []);
  return dataConnectorUpdates;
}

export function getAdditionalDataConnectorUpdates(
  connectorsWithAdditionalData: Dictionary<DataConnectorDto>,
  existingConnectors: Dictionary<DataConnectorDto>
): Update<DataConnectorDto>[] {
  return Object.values(connectorsWithAdditionalData)
    .filter((connector: DataConnectorDto) => isDefined(connector.dataSource))
    .reduce((acc: Update<DataConnectorDto>[], connectorWithAdditionalData: DataConnectorDto) => {
      const connectorId: EntityId = connectorWithAdditionalData.id;
      const existingConnector: DataConnectorDto = existingConnectors[connectorId];
      const changes: Partial<DataConnectorDto> = {};
      changes.dataPoints = [
        ...(existingConnector.dataPoints ?? []),
        ...(connectorWithAdditionalData.dataPoints ?? [])
      ];
      changes.dataStatus = resolveDataStatus(
        connectorWithAdditionalData,
        existingConnector.dataStatus
      );
      return acc.concat({
        id: connectorId.toString(),
        changes
      });
    }, []);
}

function resolveDataStatus(
  changedConnector: DataConnectorDto,
  oldDataStatus: DataStatus,
  isIncrementalUpdate: boolean = true
): DataStatus {
  const receivedDataStatus: DataStatus =
    changedConnector.dataStatus ?? guessDataStatusFromPoints(changedConnector.dataPoints);
  return getNewDataStatus(isIncrementalUpdate, oldDataStatus, receivedDataStatus);
}

function getNewDataStatus(
  isIncrementalUpdate: boolean,
  oldDataStatus: DataStatus,
  receivedDataStatus: DataStatus
): DataStatus {
  if (receivedDataStatus === DataStatus.RequestFailed) {
    return DataStatus.RequestFailed;
  }
  if (
    isIncrementalUpdate &&
    oldDataStatus === DataStatus.DataReceived &&
    receivedDataStatus === DataStatus.NoDataReceived
  ) {
    return DataStatus.DataReceived;
  }
  return receivedDataStatus;
}

function getCommonChanges(
  existingConnector: DataConnectorDto,
  changedConnector: DataConnectorDto
): Partial<DataConnectorDto> {
  const changes: Partial<DataConnectorDto> = getDataSourceChange(
    existingConnector,
    changedConnector
  );
  changes.properties = changedConnector.properties;
  changes.isTimeSeries = changedConnector.isTimeSeries;

  return changes;
}

function getDataSourceChange(
  existingConnector: DataConnectorDto,
  changedConnector: DataConnectorDto
): Partial<DataConnectorDto> {
  const changes: Partial<DataConnectorDto> = {};
  const existingDataSource = existingConnector.dataSource;
  const changedDataSource = changedConnector.dataSource;
  const mergedDataSource = _cloneDeep(existingDataSource);

  if (isSignalBased(changedDataSource)) {
    mergeSignalDataSourceChanges(
      existingDataSource as SignalDataSourceDto,
      changedDataSource as SignalDataSourceDto,
      mergedDataSource
    );
    changes.dataSource = mergedDataSource;
  }

  return changes;
}

function mergeSignalDataSourceChanges(
  existingDataSource: SignalDataSourceDto,
  changedDataSource: SignalDataSourceDto,
  mergedDataSource: DataSourceDto
): void {
  if (isSignalNameChanged(existingDataSource, changedDataSource)) {
    (mergedDataSource as SignalDataSourceDto).signal.name = changedDataSource.signal.name;
  }
}

function isSignalNameChanged(
  existingDataSource: SignalDataSourceDto,
  changedDataSource: SignalDataSourceDto
): boolean {
  const currentName = existingDataSource.signal.name;
  const newName = changedDataSource.signal.name;
  return isDefined(newName) && currentName !== newName;
}

export function extractIncrementalDataPoints(
  existingConnector: DataConnectorDto,
  changedConnector: DataConnectorDto,
  startCutoffDate: Maybe<Date>
): Maybe<DataPointDto[]> {
  if (isNotDefined(existingConnector.dataPoints)) {
    return changedConnector.dataPoints;
  }

  const mergedDataPoints = mergeDataPoints(
    existingConnector.dataPoints as TimeSeriesDataPointDto[],
    changedConnector.dataPoints as TimeSeriesDataPointDto[]
  );

  const cutDataPoints = isDefined(startCutoffDate)
    ? cutOffDataPointsBeforeStart(mergedDataPoints, startCutoffDate)
    : mergedDataPoints;

  return cutDataPoints;
}

function cutOffDataPointsBeforeStart(
  dataPoints: TimeSeriesDataPointDto[],
  startCutOffDate: Date
): DataPointDto[] {
  const startAt = dataPoints.findIndex((p) => shouldKeepPoint(p, startCutOffDate));
  const startCut = startAt >= 0 ? dataPoints.slice(startAt) : [];
  // console.log(startAt, startCutOffDate, dataPoints, startCut);
  return startCut;
}

function shouldKeepPoint(dataPoint: TimeSeriesDataPointDto, startCutoffDate: Date): boolean {
  return (dataPoint.endTime != null ? dataPoint.endTime : dataPoint.x) >= startCutoffDate;
}

function mergeDataPoints(
  oldDataPoints: TimeSeriesDataPointDto[],
  updateDataPoints: TimeSeriesDataPointDto[]
): TimeSeriesDataPointDto[] {
  if (updateDataPoints.length === 0) {
    return oldDataPoints;
  }
  const updateStartingTime = updateDataPoints[0].startTime;
  if (!isDefined(updateStartingTime)) {
    throw new Error("cannot merge datapoints without startTime");
  } else {
    const updateStartingIndex = oldDataPoints.findIndex(
      (old) => old.startTime >= updateStartingTime
    );
    const endTruncated =
      updateStartingIndex >= 0 ? oldDataPoints.slice(0, updateStartingIndex) : oldDataPoints;

    return [...endTruncated, ...updateDataPoints];
  }
}

function dataUpdateRequired(changes: Partial<DataConnectorDto>): boolean {
  return (
    isDefined(changes.dataPoints) || isDefined(changes.dataSource) || isDefined(changes.dataStatus)
  );
}
