import { createAction, props } from "@ngrx/store";
import { GeneralSettingsDto } from "../../../core/models/general-settings";
import { DeepPartial } from "../../../ts-utils/models/deep-partial.type";

export namespace GeneralSettingsActions {
  export const setRootPath = createAction(
    "[General Settings] Set Root Path",
    props<{ rootPath: string }>()
  );

  export const setRootClass = createAction(
    "[Runtime General Settings] Set Root Class",
    props<{ rootClass: string }>()
  );

  export const update = createAction(
    "[General Settings] Update",
    props<{ changes: DeepPartial<GeneralSettingsDto> }>()
  );

  export const toggleUseServerTime = createAction(
    "[General Settings] Toggle Use Server Time",
    props<{ useServerTime: boolean; filtersUpdateOffset: number }>()
  );

  export const setWorkDayStartTime = createAction("[General Settings] Set Work Day Start Time");
}
