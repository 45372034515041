import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Key } from "../../meta/decorators/key.decorator";
import { NotSerializable } from "../../meta/decorators/not-serializable.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { Title } from "../../meta/decorators/title.decorator";
import { EntityId } from "../../meta/models/entity";
import { Maybe } from "../../ts-utils";

@EditableType({ fullName: "SignalInfoDto", title: "signal-info-dto" })
export class SignalInfoDto {
  @Title()
  @NotSerializable()
  name: string;

  @Key()
  @Serializable()
  id: Maybe<EntityId>;

  constructor(id: Maybe<EntityId>, name: string) {
    this.id = id;
    this.name = name;
  }
}
