<div class="app-header">
  <div class="app-header__logo"  *ngIf="!(templateBuilderMode$ | async)">
    <span class="app-header__logo--long" *ngIf="standalone">{{ appTitle.fullName }} </span>
    <span class="app-header__logo--short" *ngIf="standalone">{{ appTitle.shortName }} </span>
    <span
      *ngIf="standalone"
      class="app-header__status-indicator"
      [ngClass]="{
        'app-header__status-indicator--green': (isOnline$ | async),
        'app-header__status-indicator--red': !(isOnline$ | async)
      }"
    ></span>
  </div>
  <div class="app-header__bar">
    <div
      class="layout-settings-wrapper"
      *ngIf="!(templateBuilderMode$ | async) && !previewMode && displayMode === DisplayMode.Desktop"
    >
      <layout-settings></layout-settings>
    </div>
    <div class="explorer__header">
      <div *ngFor="let item of headerButtons$ | async">
        <header-button
          [buttonConfig]="item"
          icon="{{ item.icon }}"
          [isSelected]="item.sidebarDisplay === componentToShow"
        ></header-button>
      </div>
    </div>
    <div class="app-header__first-group">
      <components-header-buttons *ngIf="!previewMode"></components-header-buttons>
    </div>
    <div
      class="app-header__second-group"
      [ngClass]="{ 'app-header__regrouped-buttons': previewMode }"
      *ngIf="!(templateBuilderMode$ | async)"
    >
      <div class="app-header__sub-group-buttons">
        <save-settings
          *ngIf="!previewMode"
          (saveReport)="save()"
          (saveAsReport)="saveAs()"
        ></save-settings>
        <div
          class="app-header__group-wrapper"
          [ngClass]="{ 'app-header__group-wrapper--desktop': displayMode === DisplayMode.Desktop }"
        >
          <header-button
            [buttonConfig]="filterButton"
            [isSelected]="toolbarOpened"
            icon="Filter"
          ></header-button>
          <user-menu></user-menu>
        </div>
      </div>
      <div class="app-header__sub-group-buttons">
        <header-button
          *ngIf="hasAccessToEdit"
          data-test="preview-mode-button"
          [buttonConfig]="previewButtonConfig"
          [isSelected]="previewMode"
          [icon]="previewMode ? 'View' : 'Hide'"
        >
        </header-button>
        <project-info></project-info>
        <div class="app-header__toggle-switch-wrapper">
          <toggle-switch
            label="Dark"
            [value]="isDarkTheme"
            (changeValue)="toggleTheme()"
          ></toggle-switch>
        </div>
      </div>
    </div>
  </div>
</div>
