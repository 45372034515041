import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import { TimeSeriesViewConfig } from "../../elements/components/time-series/view-config";
import { generateIdForSelectableItem } from "../../elements/helpers/column.helper";
import {
  getDefaultFooters,
  getDefaultHeaders
} from "../../elements/helpers/table-header-footer.helper";
import { ComponentStateDto } from "../../elements/models/component-state";
import { DEFAULT_TITLE_INTERPOLATION } from "../../elements/models/property-interpolation.constants";
import { TableFooterDescriptor } from "../../elements/models/table/table-footer-descriptor";
import { TableHeaderDescriptor } from "../../elements/models/table/table-header-descriptor";
import {
  DEFAULT_HEADER_TITLE,
  FooterFunctions
} from "../../elements/models/table/table-header-footer-options";
import { EntityId } from "../../meta/models/entity";
import { removeDuplicates } from "../../ts-utils/helpers/array.helper";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class UpgradeTableHeaderAndFooter implements UpgradeStep {
  name: string = "UpgradeTableHeaderFooter";
  fromVersion = new Version(4, 0, 16);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates = oldConfig.componentStates;
    let modified = false;
    if (isDefined(componentStates)) {
      for (const componentState of Object.values(componentStates.entities)) {
        if (isDefined((componentState as any).view)) {
          if (isDefined((componentState as any).view.headerDescriptors)) {
            modified =
              this.updateHeaderDescriptors(componentStates, (componentState as any).id) || modified;
          }
          if (isDefined((componentState as any).view.footerDescriptors)) {
            modified =
              this.updateFooterDescriptors(componentStates, (componentState as any).id) || modified;
          }
        }
      }
    }
    return {
      result: oldConfig,
      modified: modified,
      warning: null
    };
  }

  private updateHeaderDescriptors(
    componentStates: EntityState<ComponentStateDto>,
    componentId: EntityId
  ): boolean {
    const component = componentStates.entities[componentId];
    let modified = false;
    if (
      isDefined(component) &&
      isDefined((component.view as TimeSeriesViewConfig).headerDescriptors)
    ) {
      const timeseriesViewConfig = component.view as TimeSeriesViewConfig;
      let isDefaultHeaderIdUsed: boolean = false;
      let predefinedHeaderRows = getDefaultHeaders();
      timeseriesViewConfig.headerDescriptors.forEach((headerDescriptor: TableHeaderDescriptor) => {
        if (headerDescriptor.template === DEFAULT_TITLE_INTERPOLATION && !isDefaultHeaderIdUsed) {
          headerDescriptor.id = generateIdForSelectableItem(DEFAULT_HEADER_TITLE, true);
          headerDescriptor.label = DEFAULT_HEADER_TITLE;
          isDefaultHeaderIdUsed = true;
        }
        headerDescriptor.isSelected = true;
        const predefinedHeader: Maybe<TableHeaderDescriptor> = predefinedHeaderRows.find(
          (header) => header.id === headerDescriptor.id
        );
        headerDescriptor.isPredefinedItem = isDefined(predefinedHeader);
      });
      predefinedHeaderRows = removeDuplicates(
        timeseriesViewConfig.headerDescriptors.concat(predefinedHeaderRows),
        (header) => header.id
      );
      timeseriesViewConfig.headerDescriptors = predefinedHeaderRows;
      modified = true;
      component.view = timeseriesViewConfig;
      componentStates.entities[componentId] = component;
    }
    return modified;
  }

  private updateFooterDescriptors(
    componentStates: EntityState<ComponentStateDto>,
    componentId: EntityId
  ): boolean {
    const component = componentStates.entities[componentId];
    let modified = false;
    if (
      isDefined(component) &&
      isDefined((component.view as TimeSeriesViewConfig).footerDescriptors)
    ) {
      const timeseriesViewConfig = component.view as TimeSeriesViewConfig;
      let predefinedFooterRows = getDefaultFooters();

      predefinedFooterRows.forEach((footerDescriptor: TableFooterDescriptor) => {
        const footerDes: Maybe<TableFooterDescriptor> = timeseriesViewConfig.footerDescriptors.find(
          (footer) => footerDescriptor.id === generateIdForSelectableItem(footer.function, false)
        );
        if (isDefined(footerDes)) {
          footerDes.id = footerDescriptor.id;
          footerDes.isPredefinedItem = true;
          footerDes.isSelected = true;
          footerDes.label = FooterFunctions[footerDescriptor.function];
        }
        footerDescriptor.isSelected = isDefined(footerDes);
        footerDescriptor.isPredefinedItem = true;
      });
      predefinedFooterRows = removeDuplicates(
        timeseriesViewConfig.footerDescriptors.concat(predefinedFooterRows),
        (footer) => footer.id
      );
      timeseriesViewConfig.footerDescriptors = predefinedFooterRows;
      modified = true;
      component.view = timeseriesViewConfig;
      componentStates.entities[componentId] = component;
    }
    return modified;
  }
}
