import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { StatusTitle } from "../../../shared";
import { isDefined } from "../../../ts-utils";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { getIndicatorStatus } from "../../helpers/status-indicator.helper";
import { IndicatorDisplayStrategy } from "../../models/status-indicator/indicator-display-strategy";
import { IndicatorState } from "../../models/status-indicator/indicator-state";
import { NULL_VALUE } from "../../models/status-indicator/status-indicator-states.constants";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { SystemOverviewViewConfig } from "./view-config";

@Component({
  selector: "c-system-overview",
  templateUrl: "./system-overview.component.html",
  styleUrls: ["./system-overview.component.css"]
})
// @LayoutBuilder(ComponentCategory.OtherComponents, "SystemOverviewComponent", "icon-System-Overview", "dashboard-icon")
@ConnectorRoles()
@EditableWidget({ fullName: "SystemOverviewComponent", title: "system-overview" })
export class SystemOverviewComponent extends BaseComponent {
  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, cdr);
  }
  Status = StatusTitle;
  @View(SystemOverviewViewConfig)
  public get view(): SystemOverviewViewConfig {
    return this.currentState.view as SystemOverviewViewConfig;
  }

  get hasLink(): boolean {
    return isDefined(this.view.link);
  }

  get buttonCursorModifier(): "pointer" | "default" {
    if (this.hasLink) {
      return "pointer";
    }
    return "default";
  }

  getStatus(): IndicatorState {
    return getIndicatorStatus(NULL_VALUE, IndicatorDisplayStrategy.Binary);
  }

  openLink(): void {
    this.linkResolver.resolveLink(this.view.link);
  }
}
