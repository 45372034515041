import Highcharts, { Options, SeriesPieOptions, TooltipFormatterContextObject } from "highcharts";
import { fontSize16 } from "../../../../_typography";
import { LinkResolver } from "../../../browsing";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ICommonDataProperties } from "../../../data-connectivity/models";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { DataPointDto, hasValue } from "../../../data-connectivity/models/data-point";
import { getEntityTitle } from "../../../meta/helpers/get-title.helper";
import { isDefined, isEmpty, isNotDefined, toStringOrUndefined } from "../../../ts-utils/helpers";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { getSingleValueDataPoint } from "../../helpers/component-data-accessor.helper";
import { buildLinkFromProperties } from "../../helpers/link-resolution.helper";
import { DisplayFormatDto } from "../../models/display-format";
import { IPieDisplayConfig } from "../../models/i-view-config/i-base-display-config";
import { LinkProperties } from "../../models/link-properties";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { getTooltipText, TooltipData } from "../tooltip.helper";

export class PieDisplayService {
  constructor(protected valueFormatter: ValueFormatterService) {}

  getChartOptions(
    viewConfig: IPieDisplayConfig,
    dataConnectorDescriptors: DataConnectorDescriptor[],
    linkResolver: LinkResolver
  ): Options {
    const hasLabels =
      viewConfig.showValue || viewConfig.showPercentage || viewConfig.showCategoryInLabel;
    const valueFormatter = this.valueFormatter;

    return {
      chart: {
        type: "pie",
        showAxes: false
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: hasLabels,
            formatter: function (this: Highcharts.PointLabelObject) {
              return (
                "<span>" + dataLabelFormatter(this.point, viewConfig, valueFormatter) + "</span>"
              );
            },
            distance: 20,
            style: {
              fontSize: fontSize16 + "px",
              fontWeight: "normal"
            },
            useHTML: true
          },
          showInLegend: viewConfig.showLegend,
          events: {
            click: (pointerEvent: any) => {
              if (
                isDefined(pointerEvent.point.custom) &&
                isDefined(pointerEvent.point.custom.link)
              ) {
                linkResolver.resolveLink(pointerEvent.point.custom.link);
              }
            }
          }
        }
      },
      tooltip: {
        formatter: function () {
          return getTooltip(
            dataConnectorDescriptors,
            this,
            valueFormatter,
            viewConfig.displayFormat
          );
        },
        backgroundColor: "rgb(255, 255, 255, 1)"
      },
      series: [
        {
          colorByPoint: true,
          data: this.getHighchartPoints(dataConnectorDescriptors),
          type: undefined as any
        } as SeriesPieOptions
      ]
    };
  }

  protected getHighchartPoints(
    dataConnectorDescriptors: DataConnectorDescriptor[]
  ): Highcharts.PointOptionsObject[] {
    const hasConnectorWithValidNumber = dataConnectorDescriptors.some((connectorDesc) => {
      const point = getSingleValueDataPoint(connectorDesc.connector);
      return (
        hasValue(point) &&
        (isValidNumber(point.y) ||
          (isDefined(point.evaluatedValue) && isValidNumber(point.evaluatedValue)))
      );
    });

    const res = dataConnectorDescriptors.reduce(
      (acc: Highcharts.PointOptionsObject[], connectorDesc) => {
        const point = getSingleValueDataPoint(connectorDesc.connector);
        if (
          hasValue(point) &&
          (isValidNumber(point.y) ||
            !hasConnectorWithValidNumber ||
            isValidNumber(point.evaluatedValue))
        ) {
          acc.push({
            name: getEntityTitle(connectorDesc.connector),
            y: point.evaluatedValue ?? point.y,
            color: !isEmpty(connectorDesc.connectorView.color)
              ? connectorDesc.connectorView.color
              : undefined,
            custom: isDefined(point.properties)
              ? { link: buildLinkFromProperties(point.properties as LinkProperties) }
              : undefined
          });
        }
        return acc;
      },
      []
    );
    return res;
  }
}

function dataLabelFormatter(
  point: Highcharts.Point,
  viewConfig: IPieDisplayConfig,
  valueFormatter: ValueFormatterService
): string {
  const isValueNotEnabled = !viewConfig.showPercentage && !viewConfig.showValue;
  const categoryNameToDisplay = viewConfig.showCategoryInLabel
    ? isValueNotEnabled
      ? point.name
      : `${point.name}: `
    : "";
  const percentageString =
    viewConfig.showPercentage && isDefined(point.percentage)
      ? `${Number(Highcharts.numberFormat(point.percentage, 1))}%`
      : "";
  const valueString =
    viewConfig.showValue && isDefined(point.y)
      ? valueFormatter.formatValue(point.y, viewConfig.displayFormat)
      : "";

  const valueToDisplay =
    viewConfig.showPercentage && viewConfig.showValue
      ? `${valueString} (${percentageString})`
      : valueString + percentageString;

  return categoryNameToDisplay + "<strong>" + valueToDisplay + "</strong>";
}

function isValidNumber(value: any): boolean {
  return value !== 0 && !isNaN(value);
}

export interface IDataPointAndConnector {
  point: DataPointDto;
  connector: DataConnectorDto;
}

function getTooltip(
  connectorDescriptors: DataConnectorDescriptor[],
  tooltipContext: TooltipFormatterContextObject,
  valueFormatter: ValueFormatterService,
  displayFormat: Maybe<DisplayFormatDto>
): string {
  const category: string = `${tooltipContext.key}`;
  const dataConnector: Maybe<DataConnectorDto> = connectorDescriptors.find(
    (descriptor) => getEntityTitle(descriptor.connector) === category
  )?.connector;
  if (isNotDefined(dataConnector)) {
    return "";
  }
  const point: Highcharts.Point = tooltipContext.point;
  const percentageString = isDefined(point.percentage)
    ? `${Number(Highcharts.numberFormat(point.percentage, 1))}%`
    : "";
  const props = dataConnector.properties as ICommonDataProperties;
  const tooltipData: TooltipData = {
    title: category,
    description: props.description,
    additionalInfo: percentageString,
    value: valueFormatter.formatValue(point.y, displayFormat),
    unit: dataConnector ? toStringOrUndefined(dataConnector.properties.unit) : ""
  };
  return getTooltipText(tooltipData);
}
