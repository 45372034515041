import { construct } from "../../core/services/construct.helper";
import { validateNumberFormat } from "../../core/services/value-formatter.service";
import { validateDateFormat } from "../../environment/services/date-formatter.service";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import {
  Configurable,
  ConfigurationCategory,
  EditableType,
  PropertyCategory,
  Serializable
} from "../../meta";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { WIDGET_PROPERTIES__DISPLAY_FORMAT } from "./help-constants";

const TYPE_NAME = "DisplayFormatDto";

@EditableType({ fullName: TYPE_NAME, title: "display-format-dto" })
export class DisplayFormatDto {
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 4)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.NumberFormat,
    validationFunction: validateNumberFormat,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.NumberFormatTooltip,
    userHelp: WIDGET_PROPERTIES__DISPLAY_FORMAT
  })
  @Serializable("")
  numberFormat!: string;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.General, 4)
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.DateFormat,
    validationFunction: validateDateFormat,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.DateFormatTooltip
  })
  @Serializable("")
  dateFormat!: string;

  constructor(displayFormat: DeepPartial<DisplayFormatDto> = {}) {
    construct(this, displayFormat, TYPE_NAME);
  }
}
