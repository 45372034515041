import { generateIdForSelectableItem } from "../../helpers/column.helper";
import { DEFAULT_TITLE_INTERPOLATION } from "../property-interpolation.constants";
import { TableHeaderDescriptor } from "./table-header-descriptor";

export const DEFAULT_HEADER_TITLE = "Titles";

export enum FooterFunctions {
  Min = "Minimum",
  Max = "Maximum",
  Avg = "Average",
  Cnt = "Count non-empty items",
  StDev = "Standard Deviation",
  Var = "Variance",
  CoefficientOfVariation = "Coefficient of Variation"
}

export enum HeaderProperties {
  Titles = "${connector.title}",
  Units = "${connector.unit}"
}

export const DEFAULT_HEADER_DESCRIPTOR = new TableHeaderDescriptor({
  id: generateIdForSelectableItem(DEFAULT_HEADER_TITLE),
  label: DEFAULT_HEADER_TITLE,
  template: DEFAULT_TITLE_INTERPOLATION,
  isSelected: true
});
