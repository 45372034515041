import { DataTransferObject } from "../../core/models/data-transfer-object";
import { construct } from "../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { ConfigurationCategory, EditableType, Serializable } from "../../meta/decorators";
import { PropertyCategory } from "../../meta/models/property-category";
import { DynamicallyDecorated } from "../../ts-utils/helpers/decorator.helper";
import { DeepPartial, Maybe } from "../../ts-utils/models";
import { Roles } from "../components/single-value/roles";
import { LimitRoleConfig, configureLimitValues } from "./limit-value-config";

const TYPE_NAME = "LimitsDto";

@EditableType({ fullName: TYPE_NAME })
export class LimitsDto implements DataTransferObject {
  typeName = TYPE_NAME;
  // #region Limit Colors
  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits, 30)
  @Serializable("#000000")
  targetColor: Maybe<string>;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits, 31)
  @Serializable("#F03040")
  extremeLowColor: Maybe<string>;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits, 32)
  @Serializable("#FF7300")
  veryLowColor: Maybe<string>;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits, 33)
  @Serializable("#FFD800")
  lowColor: Maybe<string>;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits, 34)
  @Serializable("#FFD800")
  highColor: Maybe<string>;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits, 35)
  @Serializable("#FF7300")
  veryHighColor: Maybe<string>;

  @ConfigurationCategory(PropertyCategory.Display, LOCALIZATION_DICTIONARY.propertySheet.Limits, 36)
  @Serializable("#F03040")
  extremeHighColor: Maybe<string>;
  //#endregion

  //#region Limit Values
  @DynamicallyDecorated
  target: Maybe<number>;
  @DynamicallyDecorated
  extremeLow: Maybe<number>;
  @DynamicallyDecorated
  veryLow: Maybe<number>;
  @DynamicallyDecorated
  low: Maybe<number>;
  @DynamicallyDecorated
  high: Maybe<number>;
  @DynamicallyDecorated
  veryHigh: Maybe<number>;
  @DynamicallyDecorated
  extremeHigh: Maybe<number>;
  // #endregion

  constructor(params: DeepPartial<LimitsDto> = {}) {
    construct(this, params, TYPE_NAME);
  }
}

const LimitRoles: LimitRoleConfig = {
  target: Roles.Target,
  low: Roles.Low,
  veryLow: Roles.VeryLow,
  extremeLow: Roles.ExtremeLow,
  high: Roles.High,
  veryHigh: Roles.VeryHigh,
  extremeHigh: Roles.ExtremeHigh
};

configureLimitValues(LimitsDto, Roles.Value, LimitRoles, true);
