import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription, isObservable } from "rxjs";
import { F1_KEY } from "../../../keyboard.constants";
import { OfType } from "../../../meta/decorators";
import { EditorType, SelectionOption } from "../../../meta/models";
import { isDefined, isEmptyOrNotDefined } from "../../../ts-utils";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "combo-box-editor",
  templateUrl: "combo-box-editor.component.html",
  styleUrls: ["combo-box-editor.component.scss"]
})
@OfType(EditorType.ComboBox)
export class ComboBoxEditorComponent extends BaseEditorComponent implements OnInit, OnDestroy {
  private _items: SelectionOption[];
  private _itemsSubscription: Subscription;
  private currentValueMissingOption: SelectionOption;

  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (
      isDefined(this.propertyInfo?.descriptor?.constructorFunction) &&
      isDefined(this.itemOwner)
    ) {
      const enumObject = this.propertyInfo.descriptor.constructorFunction(this.itemOwner);
      this.setItems(enumObject);
    }

    if (this._items == null) {
      this._items = [];
    }
    this.ensureCurrentValueIsInOptions();
  }

  ngOnDestroy(): void {
    if (this._itemsSubscription != null) {
      this._itemsSubscription.unsubscribe();
    }
  }

  private ensureCurrentValueIsInOptions(): void {
    if (this.value != null) {
      if (!this.isCurrentValueInItems()) {
        this.currentValueMissingOption = {
          title: this.value + " *",
          key: this.value
        };
        this._items.push(this.currentValueMissingOption);
      } else if (this.currentValueMissingOption != null && this.isCurrentValueInItems()) {
        const toRemove = this._items.indexOf(this.currentValueMissingOption);
        this._items.splice(toRemove, 1);
        this.currentValueMissingOption = null;
      }
    }
  }

  private isCurrentValueInItems(): boolean {
    return this.items.some((item) => item.key === this.value);
  }

  public get items(): SelectionOption[] {
    return this._items;
  }

  public setItems(enumObject: SelectionOption[] | Observable<SelectionOption[]>) {
    if (isObservable(enumObject)) {
      this._itemsSubscription = enumObject.subscribe((x) => this.setItems(x));
    } else {
      if (!Array.isArray(enumObject)) {
        console.log(enumObject);
        throw new CriticalError("enumObject is not an array");
      }
      this._items = enumObject;
    }
  }

  public selectValueChanged(newValue: any) {
    let convertedValue: any;
    if (isEmptyOrNotDefined(newValue)) {
      convertedValue = isDefined(this.propertyInfo?.descriptor)
        ? this.propertyInfo.descriptor.defaultValue
        : null;
    } else {
      const numericValue = Number(newValue);
      convertedValue = isNaN(numericValue) ? newValue : numericValue;
    }
    this.value = convertedValue;
    this.onValueChanged(convertedValue);

    this.ensureCurrentValueIsInOptions();
  }

  public isSelected(item: SelectionOption): boolean {
    return (
      item.key === this.value || (isEmptyOrNotDefined(this.value) && isEmptyOrNotDefined(item.key))
    );
  }

  refreshValue(_value: any): void {}

  openHelp(event: KeyboardEvent): void {
    if (event.key === F1_KEY) {
      if (isDefined(this.userHelp)) {
        event.preventDefault();
        if (typeof this.userHelp === "function") {
          window.top?.open(this.userHelp(this.itemOwner));
        } else {
          window.top?.open(this.userHelp);
        }
      }
    }
  }
}
