import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Maybe } from "../../../ts-utils";
import { CustomFilterDescriptorDto } from "../../models";
import { GeneralSettingsDto } from "../../models/general-settings";

@Injectable()
export class IGeneralSettingsSelector {
  select(): Observable<GeneralSettingsDto> {
    throw new Error("Not implemented");
  }

  selectCustomFilterDescriptors(): Observable<CustomFilterDescriptorDto[]> {
    throw new Error("Not implemented");
  }

  selectRootPath(): Observable<Maybe<string>> {
    throw new Error("Not implemented");
  }

  selectPeriodType(): Observable<Maybe<string>> {
    throw new Error("Not implemented");
  }

  selectUseServerTime(): Observable<boolean> {
    throw new Error("Not implemented");
  }

  getPeriodType(): string {
    throw new Error("Not implemented");
  }

  getUseServerTime(): boolean {
    throw new Error("Not implemented");
  }

  getWorkDayStartTime(): Maybe<string> {
    throw new Error("Not implemented");
  }

  disableLiveMode?: boolean;
}
