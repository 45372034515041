import { CommonDataPointPropertyNames } from "../../data-connectivity/models/common-data-properties";
import { DataPointDto } from "../../data-connectivity/models/data-point";
import { Dictionary, isDefined, Maybe } from "../../ts-utils";
import { getNumber } from "../../ts-utils/helpers/number.helper";
import { BandConfig } from "../models/band-config";
import {
  LimitPropertyNames,
  LimitsPropertyNames,
  LimitValueConfigDto
} from "../models/limit-value-config";
import { LimitsDto } from "../models/limits";
import { MarkerConfig } from "../models/marker-config";

export interface LimitValues {
  target: number[];

  extremeLow: number[];
  veryLow: number[];
  low: number[];

  high: number[];
  veryHigh: number[];
  extremeHigh: number[];
}

export class LimitMarkerHelper {
  constructor(private limits: LimitsDto) {}

  private getLimitMarkers(limitZIndex: number): MarkerConfig[] {
    const markers: MarkerConfig[] = [
      {
        value: this.limits.extremeLow,
        color: this.limits.extremeLowColor,
        zIndex: limitZIndex
      },
      { value: this.limits.veryLow, color: this.limits.veryLowColor, zIndex: limitZIndex },
      { value: this.limits.low, color: this.limits.lowColor, zIndex: limitZIndex },
      { value: this.limits.high, color: this.limits.highColor, zIndex: limitZIndex },
      { value: this.limits.veryHigh, color: this.limits.veryHighColor, zIndex: limitZIndex },
      {
        value: this.limits.extremeHigh,
        color: this.limits.extremeHighColor,
        zIndex: limitZIndex
      }
    ];
    return markers;
  }

  public getMarkers(limitZIndex: number, targetZIndex: number): MarkerConfig[] {
    const markers: MarkerConfig[] = this.getLimitMarkers(limitZIndex);
    markers.push({
      value: this.limits.target,
      color: this.limits.targetColor,
      zIndex: targetZIndex
    });

    return markers.filter((marker) => isDefined(marker.value));
  }

  public getBands(min: number, max: number, neutralColor: string): BandConfig[] {
    const values = [min];
    const colors = [];

    if (isDefined(this.limits.extremeLow) && this.limits.extremeLow > min) {
      colors.push(this.limits.extremeLowColor);
      pushLower(values, this.limits.extremeLow, max);
    }

    if (isDefined(this.limits.veryLow) && this.limits.veryLow > min) {
      colors.push(this.limits.veryLowColor);
      pushLower(values, this.limits.veryLow, max);
    }

    if (isDefined(this.limits.low) && this.limits.low > min) {
      colors.push(this.limits.lowColor);
      pushLower(values, this.limits.low, max);
    }

    colors.push(neutralColor);

    if (isDefined(this.limits.high) && this.limits.high < max) {
      colors.push(this.limits.highColor);
      pushHigher(values, this.limits.high, min);
    }

    if (isDefined(this.limits.veryHigh) && this.limits.veryHigh < max) {
      colors.push(this.limits.veryHighColor);
      pushHigher(values, this.limits.veryHigh, min);
    }
    if (isDefined(this.limits.extremeHigh) && this.limits.extremeHigh < max) {
      colors.push(this.limits.extremeHighColor);
      pushHigher(values, this.limits.extremeHigh, min);
    }
    values.push(max);

    const bands: BandConfig[] = [];
    for (let index = 0; index < values.length - 1; index++) {
      bands.push({
        from: values[index],
        to: values[index + 1],
        color: colors[index],
        zIndex: -1
      });
    }
    return bands;
  }

  private getLimitArray(
    connectorPoints: DataPointDto[],
    pointPropName: CommonDataPointPropertyNames,
    configPropName: keyof LimitValueConfigDto
  ): Maybe<number[]> {
    const res = connectorPoints.map((point) => {
      const fromPoint = isDefined(point.properties)
        ? getNumber(point.properties[pointPropName])
        : null;
      const userConfigured = this.limits[configPropName] as number;
      return userConfigured ?? fromPoint;
    });
    const res2 = res.some((x) => isDefined(x) && !Number.isNaN(x)) ? res : null;
    return res2;
  }

  public getLimitArrays(connectorPoints: DataPointDto[]): LimitValues {
    const res = LimitsPropertyNames.reduce((acc: any, limitPropertyNames: LimitPropertyNames) => {
      acc[limitPropertyNames.viewConfigProperty] = this.getLimitArray(
        connectorPoints,
        limitPropertyNames.commonDataPointProperty,
        limitPropertyNames.viewConfigProperty
      );
      return acc;
    }, {});
    return res as LimitValues;
  }

  private getLimitsValue(point: DataPointDto): LimitValueConfigDto {
    const limitNameToValue = LimitsPropertyNames.reduce(
      (acc: Dictionary<number>, limitPropertyNames: LimitPropertyNames) => {
        const fromPoint = isDefined(point.properties)
          ? getNumber(point.properties[limitPropertyNames.commonDataPointProperty])
          : null;
        const fromViewOrPoint = isDefined(fromPoint)
          ? fromPoint
          : (this.limits[limitPropertyNames.viewConfigProperty] as number);
        acc[limitPropertyNames.viewConfigProperty] = fromViewOrPoint;
        return acc;
      },
      {}
    );
    return limitNameToValue as any as LimitValueConfigDto;
  }

  public getPointsLimitColor(point: DataPointDto): Maybe<string> {
    const limits = this.getLimitsValue(point);
    return this.getValuesLimitColor(point.y, limits);
  }

  public getValuesLimitColor(
    actualValue: any,
    effectiveLimits: LimitValueConfigDto
  ): Maybe<string> {
    if (actualValue == null || typeof actualValue !== "number" || Number.isNaN(actualValue)) {
      return null;
    }
    if (violates(actualValue, effectiveLimits.extremeLow, -1)) {
      return this.limits.extremeLowColor;
    }
    if (violates(actualValue, effectiveLimits.veryLow, -1)) {
      return this.limits.veryLowColor;
    }
    if (violates(actualValue, effectiveLimits.low, -1)) {
      return this.limits.lowColor;
    }
    if (violates(actualValue, effectiveLimits.extremeHigh, 1)) {
      return this.limits.extremeHighColor;
    }
    if (violates(actualValue, effectiveLimits.veryHigh, 1)) {
      return this.limits.veryHighColor;
    }
    if (violates(actualValue, effectiveLimits.high, 1)) {
      return this.limits.highColor;
    }
    return null;
  }
}

function violates(actual: number, limit: Maybe<number>, side: number): boolean {
  if (isDefined(limit) && !Number.isNaN(limit)) {
    return side * actual > side * limit;
  }
  return false;
}

function pushLower(array: any[], a: number, b: number): void {
  if (a < b) {
    array.push(a);
  } else {
    array.push(b);
  }
}

function pushHigher(array: any[], a: number, b: number): void {
  if (a > b) {
    array.push(a);
  } else {
    array.push(b);
  }
}
