import { Maybe } from "../models/maybe.type";
import { isEmptyOrNotDefined2 } from "./is-empty.helper";
import { isNotDefined, isNumber } from "./predicates.helper";

export const NUMBER_REGEX = new RegExp(`^-?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$`);

export function tryParseInt(stringifiedValue: Maybe<string | number>): Maybe<number> {
  if (isNotDefined(stringifiedValue)) {
    return null;
  } else {
    return parseInt(stringifiedValue.toString());
  }
}

export function getNumber(y: unknown): Maybe<number> {
  return y == null ? null : isNumber(y) ? y : Number.NaN;
}

export function tryConvertToNumber(value: Maybe<string | number>): Maybe<number> {
  if (isEmptyOrNotDefined2(value)) {
    return;
  }
  const potentialNumber = Number(value);
  return Number.isNaN(potentialNumber) ? undefined : potentialNumber;
}

export function isFloatWithMoreThanTwoDecimals(amount: number): boolean {
  return amount % 1 !== 0 && amount.toString().split(".")[1].length > 2;
}

export function isNumericValue(value: string): boolean {
  return NUMBER_REGEX.test(value);
}

export function roundToTwoDecimals(value: Maybe<number>): Maybe<string> {
  return value?.toFixed(2);
}
