import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTreeModule } from "@angular/material/tree";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { HighchartsChartModule } from "highcharts-angular";
import { ColorPickerModule } from "ngx-color-picker";
import { BrowsingModule } from "../browsing/browsing.module";
import { CoreModule } from "../core/core.module";
import { DateExpressionParser } from "../core/services/filter/date-expression-parser";
import { GlobalFilterViewModelDeserializer } from "../core/services/filter/global-filter-vm-deserializer";
import { IFilterSelector } from "../core/services/filter/i-filter.selector";
import { IGeneralSettingsSelector } from "../core/services/filter/i-general-settings.selector";
import { IDragDropService } from "../core/services/i-drag-drop.service";
import { IEntityUpdater } from "../core/services/i-entity-updater";
import { IFeedbackService } from "../core/services/i-feedback.service";
import { IReportInfoSelector } from "../core/services/i-report-info.selector";
import { IRuntimeSettingsSelector } from "../core/services/i-runtime-settings.selector";
import {
  DataSourceModifiers,
  dataSourceModifiersFactory
} from "../data-connectivity/services/data-source-modifiers";
import { StatusDisplayTypeDeserializer } from "../data-connectivity/services/deserializers/status-display-type.deserializer";
import { EquipmentToDataConnectorsConverter } from "../data-connectivity/services/equipment-to-data-connectors.converter";
import { IDataConnectorViewSelector } from "../data-connectivity/services/i-data-connector-view.selector";
import { IGenericDataSourceService } from "../data-connectivity/services/i-generic-data-source.service";
import { I18NModule } from "../i18n/i18n.module";
import { MaterialModule } from "../material/material.module";
import { MetaModule } from "../meta/meta.module";
import { PropertySheetModule } from "../property-sheet/property-sheet.module";
import { ClickOutsideDirectiveModule } from "../shared/directives/click-outside.directive";
import { SharedModule } from "../shared/shared.module";
import { DataConnectorModule } from "./../data-connectivity/data-connector.module";
import {
  HistoryViewComponent,
  HistoryViewPlaceholderComponent,
  LayoutBuilderMenuComponent
} from "./components";
import { AppBodyComponent } from "./components/app-body/app-body.component";
import { BaseComponent } from "./components/base/base.component";
import { ComponentConstructorParams } from "./components/base/component-constructor-params";
import { BasicCardComponent } from "./components/basic-card/basic-card.component";
import { BreadcrumbNavigationComponent } from "./components/breadcrumb-navigation/breadcrumb-navigation.component";
import { ButtonStripComponent } from "./components/button-strip/button-strip.component";
import { ButtonComponent } from "./components/button/button.component";
import { CardHeaderButtonComponent } from "./components/card-header-button/card-header-button.component";
import { CardComponent } from "./components/card/card.component";
import { CategoryComponent } from "./components/category/category.component";
import { ChartComponent } from "./components/chart/chart.component";
import { ClassifiedHeatmapChartComponent } from "./components/classified-heatmap-chart/classified-heatmap-chart.component";
import { ClusterChartComponent } from "./components/cluster-chart/cluster-chart.component";
import { ContainerComponent } from "./components/container/container.component";
import { DataExplorerComponent } from "./components/data-explorer/data-explorer.component";
import { EquipmentDataExplorerComponent } from "./components/equipment-data-explorer/equipment-data-explorer.component";
import { EquipmentModelBrowserDialogComponent } from "./components/equipment-model-browser-dialog/equipment-model-browser-dialog.component";
import { EquipmentModelEditorComponent } from "./components/equipment-model-editor/equipment-model-editor.component";
import { EquipmentPropertyBrowserComponent } from "./components/equipment-property-browser/equipment-property-browser.component";
import { GenericIframe } from "./components/generic-iframe/generic-iframe.component";
import { HeatmapChartComponent } from "./components/heatmap-chart/heatmap-chart.component";
import { ImageComponent } from "./components/image/image.component";
import { KmTrendComponent } from "./components/km-trend/km-trend.component";
import { LabelComponent } from "./components/label/label.component";
import { LayoutBuilderMenuCategoryComponent } from "./components/layout-builder-menu-category/layout-builder-menu-category.component";
import { LegendItemComponent } from "./components/legend-item/legend-item.component";
import { LegendComponent } from "./components/legend/legend.component";
import { MapCardComponent } from "./components/map/map-card.component";
import { MetaDataInspectorComponent } from "./components/meta-data-inspector/meta-data-inspector.component";
import { NavigationBarComponent } from "./components/navigation-bar/navigation-bar.component";
import { PageComponent } from "./components/page/page.component";
import { ReportHeaderComponent } from "./components/report-header/report-header.component";
import { ScatterChartComponent } from "./components/scatter-chart/scatter-chart.component";
import { HorizontalSeparatorComponent } from "./components/separator/horizontal-separator/horizontal-separator.component";
import { VerticalSeparatorComponent } from "./components/separator/vertical-separator/vertical-separator.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SignalBrowserDialogComponent } from "./components/signal-browser-dialog/signal-browser-dialog.component";
import { SignalEditorComponent } from "./components/signal-editor/signal-editor.component";
import { CategoryBarComponent } from "./components/simple-components/category-bar/category-bar.component";
import { GaugeComponent } from "./components/simple-components/gauge/gauge.component";
import { HistogramComponent } from "./components/simple-components/histogram/histogram.component";
import { PieComponent } from "./components/simple-components/pie/pie.component";
import { SeriesComponent } from "./components/simple-components/series/series.component";
import { SimpleChartComponent } from "./components/simple-components/simple-chart/simple-chart.component";
import { TableForConnectorsComponent } from "./components/simple-components/table-for-connectors/table-for-connectors.component";
import { TableComponent } from "./components/simple-components/table/table.component";
import { TextualTrendComponent } from "./components/simple-components/textual-trend/textual-trend.component";
import { TextualValueComponent } from "./components/simple-components/textual-value/textual-value.component";
import { SimpleStatusIndicatorComponent } from "./components/simple-status-indicator/simple-status-indicator.component";
import { SingleLineLabelComponent } from "./components/single-line-label/single-line-label.component";
import { SingleValueComponent } from "./components/single-value/single-value.component";
import { SpiderChartComponent } from "./components/spider-chart/spider-chart.component";
import { StaticNumericValueComponent } from "./components/static-numeric-value/static-numeric-value.component";
import { StatusIndicatorComponent } from "./components/status-indicator/status-indicator.component";
import { SystemOverviewComponent } from "./components/system-overview/system-overview.component";
import { TabContentComponent } from "./components/tab-content/tab-content.component";
import { TabGroupComponent } from "./components/tab-group/tab-group.component";
import { TimeSeriesComponent } from "./components/time-series/time-series.component";
import { AddAxisDialogComponent } from "./components/trend/dialogs/add-axis-dialog/add-axis-dialog.component";
import { AddPlotlineDialogComponent } from "./components/trend/dialogs/add-plotline-dialog/add-plotline-dialog.component";
import { AddSignalDialogComponent } from "./components/trend/dialogs/add-signal-dialog/add-signal-dialog.component";
import { OneLinePipe } from "./components/trend/dialogs/one-line.pipe";
import { RemoveAxisDialogComponent } from "./components/trend/dialogs/remove-axis-dialog/remove-axis-dialog.component";
import { RemovePlotlineDialogComponent } from "./components/trend/dialogs/remove-plotline-dialog/remove-plotline-dialog.component";
import { RemoveSignalsDialogComponent } from "./components/trend/dialogs/remove-signals-dialog/remove-signals-dialog.component";
import { TrendComponent } from "./components/trend/trend.component";
import { WaterfallChartComponent } from "./components/waterfall-chart/waterfall-chart.component";
import { ConditionValueDirective } from "./directives/condition-value.directive";
import { DynamicCellDirective } from "./directives/dynamic-cell.directive";
import { DynamicComponentsDirective } from "./directives/dynamic-components.directive";
import { EditableLayoutDirective } from "./directives/editable-layout.directive";
import { InlineEditDirective } from "./directives/inline-edit.directive";
import { LabelInlineEditDirective } from "./directives/label-inline-edit.directive";
import { ResizableComponentDirective } from "./directives/resizable-component.directive";
import { TableInlineEditDirective } from "./directives/table-inline-edit.directive";
import { WidgetsContainerDirective } from "./directives/widgets-container.directive";
import { DataServiceParams } from "./models/data-service-params";
import { ActiveContainersService } from "./services/active-containers.service";
import { ComponentDataAccessFactory } from "./services/component-data-access-factory.service";
import { ComponentEquipmentToDataConnectorsConverter } from "./services/component-equipment-to-data-connectors.converter";
import { ComponentPositioningService } from "./services/component-positioning.service";
import { DataConnectorQueryService } from "./services/data-connector-query.service";
import { DataConnectorReplacementService } from "./services/data-connector-replacement.service";
import { ComponentStateViewModelDeserializer } from "./services/deserializers/component-state-vm.deserializer";
import { DragDropService } from "./services/drag-drop.service";
import { DropHelper } from "./services/drop.helper";
import { DynamicDefaultsEvaluator } from "./services/dynamic-defaults-evaluator";
import { DynamicDefaultsEvaluatorCore } from "./services/dynamic-defaults-evaluator-core";
import { EntityInfoService } from "./services/entity-info.service";
import { ComponentStateSelector } from "./services/entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "./services/entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "./services/entity-selectors/data-connector.selector";
import { FilterSelector } from "./services/entity-selectors/filter.selector";
import { GeneralSettingsSelector } from "./services/entity-selectors/general-settings.selector";
import { ReportInfoSelector } from "./services/entity-selectors/report-info.selector";
import { RuntimeSettingsSelector } from "./services/entity-selectors/runtime-settings.selector";
import { EntityUpdater } from "./services/entity-updater";
import { FeatureSelector } from "./services/feature-selector";
import { FeedbackService } from "./services/feedback.service";
import { GenericDataSourceService } from "./services/generic-data-source.service";
import { HistoryViewService } from "./services/history-view.service";
import { InlineEditService } from "./services/inline-edit.service";
import { MockSidenavService } from "./services/mock-sidenav.service";
import { MockService } from "./services/mock.service";
import { PropertyInterpolationService } from "./services/property-interpolation.service";
import { QueryParamsResolverService } from "./services/query-params-resolver.service";
import { ComponentStateViewModelSerializer } from "./services/serializers/component-state-vm.serializer";
import { SidebarService } from "./services/sidebar.service";
import { ViewConfigProvider, viewConfigProviderFactory } from "./services/view-config-provider";
import { CommonEffects } from "./store/common/common.effects";
import { ComponentStateEffects } from "./store/component-state/component-state.effects";
import { DataConnectorEffects } from "./store/data-connector/data-connector.effects";
import { AxisDialogEffects } from "./store/dialogs/effects/axis-dialog.effects";
import { HistoryViewDialogEffects } from "./store/dialogs/effects/history-view-dialog.effects";
import { PlotlineDialogEffects } from "./store/dialogs/effects/plotline-dialog.effects";
import { SaveChangesDialogEffects } from "./store/dialogs/effects/save-changes-dialog.effects";
import { SignalDialogEffects } from "./store/dialogs/effects/signal-dialog.effects";
import { REPORT_FEATURE } from "./store/feature.selector";
import { FilterEffects } from "./store/filter/filter.effects";
import { GeneralSettingsEffects } from "./store/general-settings/general-settings.effects";
import { reducers } from "./store/index.reducer";
import { TemplateBuilderEffects } from "./store/template-builder/template-builder.effects";

export const DYNAMIC_COMPONENTS_ELEMENTS = [
  AddAxisDialogComponent,
  AddPlotlineDialogComponent,
  AddSignalDialogComponent,
  BaseComponent,
  BasicCardComponent,
  BreadcrumbNavigationComponent,
  ButtonComponent,
  ButtonStripComponent,
  // conflicting error for simple chart, in editor component should not be in components array, but build fails if it is not
  SimpleChartComponent,
  CardComponent,
  CardHeaderButtonComponent,
  ChartComponent,
  SeriesComponent,
  HistogramComponent,
  ClassifiedHeatmapChartComponent,
  ContainerComponent,
  EquipmentDataExplorerComponent,
  EquipmentModelBrowserDialogComponent,
  EquipmentModelEditorComponent,
  EquipmentPropertyBrowserComponent,
  KmTrendComponent,
  LayoutBuilderMenuComponent,
  LegendComponent,
  MapCardComponent,
  PageComponent,
  RemoveAxisDialogComponent,
  RemovePlotlineDialogComponent,
  RemoveSignalsDialogComponent,
  ReportHeaderComponent,
  SignalBrowserDialogComponent,
  SignalEditorComponent,
  SingleValueComponent,
  StaticNumericValueComponent,
  TimeSeriesComponent,
  CategoryComponent,
  StatusIndicatorComponent,
  SystemOverviewComponent,
  TabGroupComponent,
  TabContentComponent,
  TableComponent,
  TableForConnectorsComponent,
  NavigationBarComponent,
  TrendComponent,
  VerticalSeparatorComponent,
  HorizontalSeparatorComponent,
  LabelComponent,
  LegendItemComponent,
  MetaDataInspectorComponent,
  ImageComponent,
  GaugeComponent,
  TextualValueComponent,
  TextualTrendComponent,
  PieComponent,
  CategoryBarComponent,
  SidebarComponent,
  DataExplorerComponent,
  AppBodyComponent,
  SpiderChartComponent,
  SimpleStatusIndicatorComponent,
  ScatterChartComponent,
  HistoryViewComponent,
  HistoryViewPlaceholderComponent,
  SingleLineLabelComponent,
  ClusterChartComponent,
  HeatmapChartComponent,
  GenericIframe,
  WaterfallChartComponent,
  LayoutBuilderMenuCategoryComponent
];

const allDirectives = [
  ConditionValueDirective,
  DynamicCellDirective,
  DynamicComponentsDirective,
  EditableLayoutDirective,
  ResizableComponentDirective,
  WidgetsContainerDirective,
  InlineEditDirective,
  LabelInlineEditDirective,
  TableInlineEditDirective
];

@NgModule({
  imports: [
    CoreModule,
    MatTreeModule,
    CommonModule,
    FormsModule,
    ColorPickerModule,
    MaterialModule,
    RouterModule,
    MetaModule,
    I18NModule,
    ClickOutsideDirectiveModule,
    StoreModule.forFeature(REPORT_FEATURE, reducers),
    EffectsModule.forFeature([
      AxisDialogEffects,
      PlotlineDialogEffects,
      SignalDialogEffects,
      HistoryViewDialogEffects,
      SaveChangesDialogEffects
    ]),
    HighchartsChartModule,
    SharedModule,
    BrowsingModule,
    ReactiveFormsModule,
    DataConnectorModule,
    PropertySheetModule
  ],
  declarations: [DYNAMIC_COMPONENTS_ELEMENTS, allDirectives, OneLinePipe],
  exports: [DYNAMIC_COMPONENTS_ELEMENTS, allDirectives, OneLinePipe, HighchartsChartModule]
})
export class ElementsModule {
  static forRoot(): ModuleWithProviders<ElementsModule> {
    return {
      ngModule: ElementsModule,
      providers: [
        ComponentConstructorParams,
        DataServiceParams,
        DropHelper,
        MockService,
        ComponentStateSelector,
        DataConnectorSelector,
        FeatureSelector,
        FilterEffects,
        FilterSelector,
        GeneralSettingsEffects,
        CommonEffects,
        DataConnectorEffects,
        ComponentStateEffects,
        EntityInfoService,
        DataConnectorModule.forRoot().providers, // ?
        MockSidenavService,
        ActiveContainersService,
        ComponentPositioningService,
        EquipmentToDataConnectorsConverter, // PROVIDED TWICE
        ComponentDataAccessFactory,
        { provide: IGenericDataSourceService, useClass: GenericDataSourceService },
        {
          provide: EquipmentToDataConnectorsConverter,
          useClass: ComponentEquipmentToDataConnectorsConverter
        },
        { provide: IReportInfoSelector, useClass: ReportInfoSelector },
        { provide: IRuntimeSettingsSelector, useClass: RuntimeSettingsSelector },
        { provide: IEntityUpdater, useClass: EntityUpdater },
        { provide: IDragDropService, useClass: DragDropService },
        { provide: IFilterSelector, useClass: FilterSelector },
        { provide: IDataConnectorViewSelector, useClass: DataConnectorViewSelector },
        { provide: IGeneralSettingsSelector, useClass: GeneralSettingsSelector },
        { provide: IFeedbackService, useClass: FeedbackService },
        { provide: ViewConfigProvider, useFactory: viewConfigProviderFactory },
        { provide: DataSourceModifiers, useFactory: dataSourceModifiersFactory },
        ComponentStateViewModelDeserializer,
        ComponentStateViewModelSerializer,
        GeneralSettingsSelector, // IGeneralSettingsSelector provided already
        ReportInfoSelector,
        RuntimeSettingsSelector,
        DataConnectorQueryService,
        DynamicDefaultsEvaluator,
        DynamicDefaultsEvaluatorCore,
        DataConnectorReplacementService,
        QueryParamsResolverService,
        StatusDisplayTypeDeserializer,
        GlobalFilterViewModelDeserializer,
        HistoryViewService,
        DateExpressionParser,
        PropertyInterpolationService,
        InlineEditService,
        TemplateBuilderEffects,
        SidebarService
      ]
    };
  }
}
