import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CategoryInfo } from "../../core/models/component-category-info";
import { CoreComponentCategoryProvider } from "../../core/services/core-component-category-provider";

@Injectable()
export class WidgetExplorerItemsGenerator {
  constructor(protected componentCategoryInfoProvider: CoreComponentCategoryProvider) {}

  generateWidgetExplorerCategories$(): Observable<CategoryInfo[]> {
    const widgetExplorerCategories$: Observable<CategoryInfo[]> = this.componentCategoryInfoProvider
      .selectAllCategories()
      .pipe(map((categories) => categories.sort((a, b) => a.orderNo - b.orderNo)));

    return widgetExplorerCategories$;
  }
}
