import { AlignValue, Options } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { Maybe, first } from "../../../ts-utils";
import { tryConvertToNumber } from "../../../ts-utils/helpers/number.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { getLimitsDisplayOptions } from "../../helpers/limit-modes.helper";
import { DataStatus } from "../../models/data-status";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { mergeChartOptions } from "./base-highcharts-options.helper";
import { LinearGaugeDisplayService } from "./linear-gauge-display.service";
import { getBarColor, getHorizontalGaugePlotLineLabel } from "./single-value-display.helper";

export class HorizontalGaugeDisplayService extends LinearGaugeDisplayService {
  protected colorListService: ColorListService;
  constructor(protected valueFormatter: ValueFormatterService, colorListService: ColorListService) {
    super(valueFormatter, colorListService);
    this.chartType = "bar";
    this.colorListService = colorListService;
  }

  protected getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const min = tryConvertToNumber(viewConfig.min);
    const max = tryConvertToNumber(viewConfig.max);
    const normalizedValue = isDefined(min) ? value - min : value;
    const { showYAxisLabels, limitsMode } = viewConfig;
    const { showLimitValues } = getLimitsDisplayOptions(limitsMode);
    const spacingTop = showLimitValues || showYAxisLabels ? 30 : 10;

    const horizontalOptions: Options = {
      chart: {
        spacing: [spacingTop, 20, 15, 20]
      },
      title: {
        align: "left"
      },
      plotOptions: {
        bar: {
          groupPadding: 0,
          threshold: min
        }
      },
      series: [
        {
          type: undefined,
          data: isDefined(max) ? [max - value] : [value],
          enableMouseTracking: false,
          color: viewConfig.neutralColor || "#F0F0F0",
          dataLabels: {
            enabled: false
          }
        },
        {
          type: undefined,
          data: [normalizedValue],
          color: getBarColor(normalizedValue, viewConfig, this.colorListService)
        }
      ]
    };
    const linearGaugeOptions = super.getSpecificGaugeOptions(
      viewConfig,
      dataConnectorDescriptors,
      dataStatus
    );
    return mergeChartOptions(linearGaugeOptions, horizontalOptions);
  }

  protected getYOffset(): number {
    return 3;
  }

  protected getTextAlignStyle(): AlignValue {
    return "center";
  }

  protected getLimitPlotLineLabel(viewConfig: IGaugeDisplayConfig, value: number) {
    const { showLimitValues } = getLimitsDisplayOptions(viewConfig.limitsMode);
    return showLimitValues
      ? getHorizontalGaugePlotLineLabel(value, viewConfig, this.valueFormatter)
      : {};
  }

  protected getYAxisPlotLineLabel(viewConfig: IGaugeDisplayConfig, value: number) {
    return viewConfig.showYAxisLabels
      ? getHorizontalGaugePlotLineLabel(value, viewConfig, this.valueFormatter)
      : {};
  }
}
