<div class="layout-wrapper">
<div #searchInput class="browser__search-input search-bar">
  <i class="icon--small abb-icon signal-browser__header-icon Search search-bar-icon"></i>
  <input
    #widgetSearch
    focusVisualization
    class="browser__search-input-text"
    placeholder="{{ 'Sidebar.SearchWidgets' | translate }}"
    maxlength="100"
    [value]="searchPattern"
    (input)="searchPatternChanged$.next(widgetSearch.value)"
  />  
</div>
<div class="category-list">
  <div *ngFor="let category of categories">
    <layout-builder-menu-category
      [category]="category"
      (collapseSidebar)="collapseSidebarOnDragStart()"
      (expandSidebar)="expandSidebarOnDrop()"
      [searchPattern]="searchPattern"
    >
    </layout-builder-menu-category>
  </div>
</div>
</div>
