import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";
import { LinkResolver } from "../../../../browsing/services/link-resolver";
import { LinkDto, getDisplayValue } from "../../../../core/models/link";
import {
  ValueFormatterService,
  isValueHTML
} from "../../../../core/services/value-formatter.service";
import { DataConnectorDto, DataPointDto, DataPointValue } from "../../../../data-connectivity";
import { ColorListService } from "../../../../environment/services/color-list.service";
import { DateFormatterService } from "../../../../environment/services/date-formatter.service";
import { isEmptyOrNotDefined } from "../../../../ts-utils";
import { isDefined } from "../../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../../ts-utils/models/maybe.type";
import { getTextColorForNoDataStatus } from "../../../helpers/color.helper";
import {
  getSingleValue,
  getSingleValueDataPoint
} from "../../../helpers/component-data-accessor.helper";
import { calculateUnitFontSize } from "../../../helpers/component-font-size.helper";
import { buildLinkFromProperties } from "../../../helpers/link-resolution.helper";
import { DataStatus } from "../../../models/data-status";
import { LinkProperties } from "../../../models/link-properties";
import { getPrimaryColor } from "../../../services/highcharts/single-value-display.helper";
import { isWrap } from "../../../services/scalable-font-size.helper";
import { getSingleValueTooltip } from "../../../services/tooltip.helper";
import { ITextualValueViewConfig } from "./i-textual-value-view-config";

@Component({
  selector: "textual-value",
  templateUrl: "./textual-value.component.html",
  styleUrls: ["./textual-value.component.scss"],
  host: { class: "simple-component" },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextualValueComponent implements OnChanges {
  @HostBinding("attr.title")
  public tooltipText: string = "";

  @HostBinding("class.single-value__link") @Input() hasLink: boolean = false;

  @Input()
  public value: Maybe<DataConnectorDto>;

  @Input() viewConfig: Maybe<ITextualValueViewConfig>;

  @Input()
  public withCircle: boolean = false;

  @Input()
  public withTrend: boolean = false;

  @Input() dataStatus: DataStatus = DataStatus.NoDataDefined;

  @Output() openLink: EventEmitter<any> = new EventEmitter();

  public valueString: Maybe<string> = null;

  public color: Maybe<string>;

  public unitFontSizePx: number = 0;

  public DataStatus = DataStatus;

  valueLink?: LinkDto;

  constructor(
    protected dateFormatter: DateFormatterService,
    protected valueFormatter: ValueFormatterService,
    protected linkResolver: LinkResolver,
    protected colorListService: ColorListService
  ) {}

  public getUnit(): string | null {
    if (this.valueString == null || isEmptyOrNotDefined(this.viewConfig?.unit)) {
      return null;
    } else {
      return this.viewConfig!.unit;
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (isDefined(this.viewConfig)) {
      const dataPoint: Maybe<DataPointDto> = getSingleValueDataPoint(this.value);
      const singleValue: DataPointValue = getSingleValue(dataPoint);
      this.setValueStringAndColor(singleValue);
      this.unitFontSizePx = calculateUnitFontSize(this.viewConfig.fontSize);

      if (isDefined(dataPoint?.properties)) {
        const link = buildLinkFromProperties(dataPoint?.properties as LinkProperties);
        if (isDefined(link)) {
          this.valueLink = link;
        }
      }

      this.tooltipText = getSingleValueTooltip(
        this.value,
        dataPoint,
        {
          displayFormat: this.viewConfig.displayFormat,
          unit: this.viewConfig.unit
        },
        this.dateFormatter,
        this.valueFormatter,
        singleValue
      );
    } else {
      this.tooltipText = "";
      this.valueString = null;
    }
  }

  private setValueStringAndColor(yValue: Maybe<number>): void {
    if (isDefined(yValue)) {
      this.valueString = this.valueFormatter.formatValue(yValue, this.viewConfig!.displayFormat);
      this.color = getPrimaryColor(this.viewConfig, this.colorListService);
    } else {
      this.valueString = null;
    }
  }

  public getValueString(): string {
    return this.valueString;
  }

  public isTitleFormatWrap(): boolean {
    if (this.viewConfig && this.viewConfig.titleFormat) {
      return isWrap(this.viewConfig.titleFormat);
    }
    return false;
  }

  public get noDataColor(): string {
    return getTextColorForNoDataStatus(this.dataStatus, this.viewConfig?.foregroundColor);
  }

  public isHtmlFormattedValue(value: string): boolean {
    return isDefined(value) && isValueHTML(value);
  }

  public isValueLinked(): boolean {
    return isDefined(this.valueLink);
  }

  public getLink(): Maybe<string> {
    if (isDefined(this.valueLink)) {
      const value = getDisplayValue(this.valueLink);
      return value || null;
    }
    return null;
  }

  onClick(event: Event): void {
    if (isDefined(this.valueLink)) {
      this.linkResolver.resolveLink(this.valueLink);
      event.preventDefault();
    }
  }

  onOpenLink(): void {
    if (this.hasLink) {
      this.openLink.emit();
    }
  }
}
