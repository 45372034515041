import { isDevMode } from "@angular/core";
import { isEmpty } from "lodash";
import { Dispatcher } from "../../dispatcher";
import { TypeProvider } from "../../meta/services/type-provider";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { capitalizeText } from "../../ts-utils/helpers/string.helper";
import { DeepPartial } from "../../ts-utils/models/deep-partial.type";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { StrictDeepPartial, StrictPartial } from "../../ts-utils/models/strict-partial.type";
import { BasicCardViewConfig } from "../components/basic-card/view-config";
import { IframeViewConfig } from "../components/generic-iframe/view-config";
import { BaseViewConfigDto } from "../models/base-view-config";
import { BorderStyleDto } from "../models/border-style";
import { ComponentCssSize } from "../models/component-size";
import { cssSizeParser } from "../models/component-size.constants";
import { ComponentStateDto } from "../models/component-state";
import { ComponentStyleDto } from "../models/component-style";
import { GENERIC_IFRAME } from "../models/element-type.constants";
import { ComponentStateSelector } from "../services/entity-selectors/component-state.selector";
import { compareOrders } from "../services/order.helper";
import { StrategyDefaultsOverrideService } from "../services/strategy-defaults-override.service";
import { ComponentCounterActions, ComponentStateActions, ROOT_COMPONENT_ID } from "../store";
import { resolveBorderStyleFromElement, resolveElementBorderStyle } from "./border.helper";

export const KEEP_TOGETHER_CLASS = "keep-together";
const BODY_CONTENT_PLACEHOLDER = "BodyContentPlaceholder";
export const BORDER_SIDES_ATTRIBUTE = "data-border-sides";
export const BORDER_WIDTH_ATTRIBUTE = "data-border-width";
export function convertReportToTemplate(componentStateSelector: ComponentStateSelector): string {
  const card = componentStateSelector.getChildrenAsArray(ROOT_COMPONENT_ID)[0];
  const cardView = card.view as BasicCardViewConfig;
  const iframeWidgets = componentStateSelector
    .getManyById(card.childrenIds)
    .filter((component) => component.type === GENERIC_IFRAME)
    .sort((a, b) => {
      return compareOrders(a.view.css.order, b.view.css.order) ?? 0;
    });

  const bodyContent = iframeWidgets
    .reduce((acc, widget) => {
      const view = widget.view as IframeViewConfig;
      const element = createIframeElement(view);

      return acc.concat(
        element.outerHTML.replace('src=""', `src='${createTemplateSrc(view)}'`) + "\n"
      );
    }, "\n")
    .concat("\n<%=GetPopupDiv%>\n");
  const bodyHtml = getBodyHtml(componentStateSelector, cardView);
  return generateTemplateHtml(bodyHtml).replace(BODY_CONTENT_PLACEHOLDER, bodyContent);
}

export function refreshReportFromTemplate(
  templateText: string,
  componentSelector: ComponentStateSelector,
  dispatcher: Dispatcher,
  strategyOverrides: StrategyDefaultsOverrideService
): void {
  const cardId = componentSelector.getRoot().childrenIds[0];
  dispatcher.dispatch(
    ComponentStateActions.deleteMany({
      targetComponents: componentSelector.getChildrenAsArray(cardId)
    })
  );
  const templateDocument = new DOMParser().parseFromString(templateText, "text/html");
  dispatcher.dispatch(
    ComponentStateActions.updateOne({
      componentUpdate: { id: "Body", changes: { view: resolveCardViewUpdate(templateDocument) } }
    })
  );
  const iframes = templateDocument.querySelectorAll("iframe");
  const componentsCount = componentSelector.getComponentCount();
  iframes.forEach((iframe, index) => {
    const newComponent = createIframeComponent(iframe, componentsCount + index, strategyOverrides);
    dispatcher.dispatch(ComponentStateActions.addOne({ newComponent, parentId: cardId }));
  });
  const componentCounter = parseInt(templateDocument.body.getAttribute("bmi_id_max") ?? "0");
  dispatcher.dispatch(ComponentCounterActions.setCounter({ value: componentCounter }));
}

function createIframeElement(view: IframeViewConfig): HTMLIFrameElement {
  const convertedWidth = convertStyleProp(view.size.width);
  const convertedHeight = convertStyleProp(view.size.height);
  const convertedTop = convertStyleProp(view.css.top);
  const convertedLeft = convertStyleProp(view.css.left);

  const iframe = document.createElement("iframe");
  iframe.id = view.hostId;
  iframe.src = ""; //set src afterwards since this setter alters given src by encoding special characters
  iframe.frameBorder = "0";
  iframe.style.width = convertedWidth;
  iframe.style.height = convertedHeight;
  iframe.style.textAlign = view.textAlignment;
  iframe.style.overflow = view.overflow;
  iframe.style.padding = view.css.padding;
  iframe.style.margin = view.css.margin;
  iframe.style.clear = view.clear;
  iframe.style.float = view.dock;
  Object.assign(iframe.style, resolveElementBorderStyle(view.css));
  setBorderData(iframe, view.css.border);
  if (view.keepTogether) {
    iframe.classList.add(KEEP_TOGETHER_CLASS);
  }
  iframe.style.position = view.css.position;
  iframe.style.left = convertedLeft;
  iframe.style.top = convertedTop;

  return iframe;
}

function convertStyleProp(styleProp: string): string {
  const parsedProp = cssSizeParser(styleProp);
  // KBST doesn't suppot floating values
  return Math.floor(parsedProp.value) + parsedProp.unit;
}

function generateTemplateHtml(body: string) {
  return `<!DOCTYPE html>
          <!-- #include file="../scripts/TemplateMenus.inc" -->
          <html>
            <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
              <link href="../css/CIMSstyles.css" rel="stylesheet" type="text/css" media="screen" />
              <link href="../css/CIMSPrintStyles.css" rel="stylesheet" type="text/css" media="print" />
              <!-- #include file="../Scripts/KBSHeaderInclude.inc" -->
              <%=GetPopUpScripts%>
            </head>
            ${body}
          </html>`;
}

function createIframeComponent(
  iframe: HTMLIFrameElement,
  counter: number,
  strategyOverrides: StrategyDefaultsOverrideService
): ComponentStateDto {
  const component = ComponentStateDto.createWithCounter(
    GENERIC_IFRAME,
    counter,
    TypeProvider.getInstance(),
    { view: createIframeView(iframe) }
  );

  (component.view as IframeViewConfig).src = createDashboardsSrc(iframe.src);
  const displayStrategy = resolveDisplayStrategy(
    (component.view as IframeViewConfig).src,
    strategyOverrides
  );

  if (isDefined(displayStrategy)) {
    (component.view as IframeViewConfig).displayStrategy = displayStrategy;
  }
  return component;
}

function createIframeView(iframe: HTMLIFrameElement): DeepPartial<IframeViewConfig> {
  const view: StrictDeepPartial<IframeViewConfig, "css"> = {
    css: {
      top: iframe.style.top,
      left: iframe.style.left,
      padding: iframe.style.padding,
      margin: iframe.style.margin,
      border: resolveBorderStyleFromElement(iframe)
    } as ComponentStyleDto,
    size: new ComponentCssSize(iframe.style.width, iframe.style.height),
    hostId: iframe.id,
    keepTogether: iframe.classList.contains(KEEP_TOGETHER_CLASS)
  };
  if (!isEmpty(iframe.style.position)) {
    view.css.position = iframe.style.position;
  }
  if (!isEmpty(iframe.style.textAlign)) {
    view.textAlignment = capitalizeText(iframe.style.textAlign);
  }
  if (!isEmpty(iframe.style.overflow)) {
    view.overflow = capitalizeText(iframe.style.overflow);
  }
  if (!isEmpty(iframe.style.float)) {
    view.dock = capitalizeText(iframe.style.float);
  }
  if (!isEmpty(iframe.style.clear)) {
    view.clear = capitalizeText(iframe.style.clear);
  }
  return view;
}

function resolveDisplayStrategy(
  src: string,
  strategyOverrides: StrategyDefaultsOverrideService
): Maybe<string> {
  const originalUrl = getMinimalSrcUrl(src);
  return Object.keys(strategyOverrides.strategyDefaultsOverrideDict).find((key) => {
    const override = strategyOverrides.strategyDefaultsOverrideDict[key] as StrictPartial<
      IframeViewConfig,
      "src"
    >;
    return getMinimalSrcUrl(override.src) === originalUrl;
  });
}

function getMinimalSrcUrl(src: string): string {
  const urlParams = new URLSearchParams(src);
  urlParams.delete("ElementID");
  return createDashboardsSrc(decodeURIComponent(urlParams.toString()));
}

export function extractIframeConfig(htmlText: string): Partial<IframeViewConfig> {
  const htmlElement = new DOMParser().parseFromString(htmlText, "text/html");
  const iframeElement = htmlElement.querySelector("iframe");
  if (isNotDefined(iframeElement)) {
    return {};
  }

  return {
    size: new ComponentCssSize(iframeElement.style.width, iframeElement.style.height),
    src: createDashboardsSrc(iframeElement.src)
  };
}

function createDashboardsSrc(originalSrc: string): string {
  if (!isDevMode()) {
    return originalSrc.replace(location.protocol + "//" + location.host + "/km", "..");
  }

  return originalSrc.replace(
    location.protocol + "//" + location.host,
    location.protocol + "//" + location.hostname + "/km"
  );
}

function createTemplateSrc(view: IframeViewConfig): string {
  const urlParams = new URLSearchParams(view.src);
  urlParams.delete("ElementID");
  urlParams.set("ElementID", view.hostId);
  let decodedUrl = decodeURIComponent(urlParams.toString());
  if (isDevMode()) {
    decodedUrl = decodedUrl.replace(location.protocol + "//" + location.hostname + "/km", "..");
  }

  return decodeURI(decodedUrl.replace(/%(?![0-9][0-9a-fA-F]+)/g, "%25"));
}

function resolveCardViewUpdate(templateDocument: Document): DeepPartial<BaseViewConfigDto> {
  const body: HTMLBodyElement = templateDocument.querySelector("body");
  const cardBorderStyle = resolveBorderStyleFromElement(body);
  return {
    css: { border: cardBorderStyle, padding: body.style.paddingTop },
    backgroundImage: body.style.backgroundImage.match(/url\("(.+?)"\)/)?.[1] ?? ""
  };
}

function getBodyHtml(
  componentStateSelector: ComponentStateSelector,
  cardView: BasicCardViewConfig
): string {
  const body = document.createElement("body");
  body.setAttribute("bmi_id_max", componentStateSelector.getComponentCount().toString());
  body.innerText = BODY_CONTENT_PLACEHOLDER;
  if (!isEmpty(cardView.backgroundImage)) {
    body.style.backgroundImage = `url('${cardView.backgroundImage}')`;
  }
  body.style.paddingTop = cardView.css.padding;
  body.style.paddingLeft = cardView.css.padding;
  Object.assign(body.style, resolveElementBorderStyle(cardView.css));
  setBorderData(body, cardView.css.border);
  return body.outerHTML;
}

function setBorderData(element: HTMLIFrameElement | HTMLBodyElement, border: BorderStyleDto): void {
  const sidesData = border.sides.reduce(
    (acc, side) => acc.concat(side.charAt(0).toLowerCase()),
    ""
  );
  if (!isEmpty(sidesData)) {
    element.setAttribute(BORDER_SIDES_ATTRIBUTE, sidesData);
  }
  element.setAttribute(BORDER_WIDTH_ATTRIBUTE, border.borderWidth.toString());
}
