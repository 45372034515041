import {
  AxisPlotLinesOptions,
  Options,
  Point,
  PointOptionsObject,
  SeriesVariwideOptions,
  XAxisOptions,
  YAxisOptions
} from "highcharts";
import { Dictionary, isEmpty } from "lodash";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { DataConnectorDto } from "../../../data-connectivity";
import {
  SERIES_TYPE_BAND,
  SERIES_TYPE_X_PLOT_LINE,
  SERIES_TYPE_Y_PLOT_LINE
} from "../../../data-connectivity/models/series-type.strategies";
import { ColorListService } from "../../../environment/services/color-list.service";
import { getEntityTitle } from "../../../meta/helpers/get-title.helper";
import {
  getNumber,
  isDefined,
  isEmptyOrNotDefined2,
  isNotDefined,
  tryConvertToNumber
} from "../../../ts-utils/helpers";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { IHistogramViewConfig } from "../../components/simple-components/histogram/i-histogram-view-config";
import { DEFAULT_BINS_NUMBER } from "../../components/time-series/view-config";
import { DisplayFormatDto } from "../../models/display-format";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import {
  getSeriesAxisOptions,
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID,
  Y_AXIS_PREFIX
} from "./base-highcharts-options.helper";
import { PlotLineGenerator, PlotLineGeneratorTimeSeries } from "./plot-line-generator.service";
import { hideSeries, makeSeriesInvisible, showSeries } from "./series-visibility.helper";
import { getYAxisLabelStyle } from "./single-value-display.helper";

const MIN_BINS_NUMBER = 3;
const SHARED_BINS_OPACITY = 0.92;
export const BIN_PADDING_PERCENTAGE = 0.1;

interface HistogramTooltipData {
  fromX: number;
  toX: number;
  seriesName: string;
  value: number;
}

interface PlotLineSeriesCustomData {
  plotLineOptions: AxisPlotLinesOptions;
  isOnYAxis: boolean;
}
export class HistogramDisplayService {
  private plotLineGenerator: PlotLineGenerator = new PlotLineGeneratorTimeSeries();
  private invisibleSeriesIds: Set<string> = new Set<string>();

  constructor(
    private colorService: ColorListService,
    private valueFormatter: ValueFormatterService
  ) {}

  getChartOptions(
    viewConfig: IHistogramViewConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor[]>
  ): Options {
    const component = this;
    let histogramDescriptors: Maybe<DataConnectorDescriptor[]>;
    if (isDefined(dataConnectorDescriptors)) {
      histogramDescriptors = dataConnectorDescriptors.filter((descriptor) =>
        isSupportedSeriesType(descriptor)
      );
    }
    const histogramOptions: Options = {
      chart: { zooming: { type: "xy" } },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          title: { text: viewConfig.yAxisTitle ?? "" },
          endOnTick: true,
          startOnTick: true,
          type: "linear",
          labels: {
            formatter: function () {
              return component.valueFormatter.formatValue(
                Number(this.value),
                viewConfig.displayFormat
              );
            }
          }
        }
      ],
      yAxis: getYAxes(viewConfig, this.valueFormatter),
      plotOptions: {
        variwide: {
          opacity: !viewConfig.sharedBins ? SHARED_BINS_OPACITY : undefined,
          tooltip: {
            headerFormat: "",
            pointFormatter: function () {
              const point: Point = this;
              const histogramTooltipData: HistogramTooltipData = {
                // @ts-ignore
                fromX: point.custom.binStart,
                // @ts-ignore
                toX: parseFloat(point.custom.binEnd),
                value: point.y ?? 0,
                seriesName: point.series.name
              };
              return getHistogramSeriesTooltip(
                histogramTooltipData,
                viewConfig.displayFormat,
                component.valueFormatter
              );
            }
          }
        },
        series: {
          events: {
            hide: function (this: Highcharts.Series) {
              this.chart.xAxis[0].removePlotLine(this.options?.id);
              this.chart.yAxis[0].removePlotLine(this.options?.id);
              hideSeries(this.options.id, component.invisibleSeriesIds);
            },
            show: function (this: Highcharts.Series) {
              const customOptions = this.options.custom ?? {};
              if (isPlotBandCustomData(customOptions)) {
                (customOptions.isOnYAxis ? this.chart.yAxis : this.chart.xAxis)[0].addPlotLine(
                  customOptions.plotLineOptions
                );
              }
              showSeries(this.options.id, component.invisibleSeriesIds);
            }
          }
        }
      },
      legend: {
        enabled: viewConfig.showLegend
      }
    };

    histogramOptions.series = this.getSeries(viewConfig, histogramDescriptors);
    (histogramOptions.xAxis as XAxisOptions[])[0].tickPositions = getTickPositions(
      viewConfig,
      histogramOptions
    );
    let plotLineSeries: SeriesVariwideOptions[] = [];

    if (isDefined(dataConnectorDescriptors)) {
      this.plotLineGenerator.addPlotLines(
        histogramOptions,
        dataConnectorDescriptors,
        viewConfig.yAxes
      );
      plotLineSeries = this.getPlotLineSeries(histogramOptions, dataConnectorDescriptors);
    }
    histogramOptions.series = histogramOptions.series.concat(plotLineSeries);
    return histogramOptions;
  }

  getSeries(
    viewConfig: IHistogramViewConfig,
    descriptors: Maybe<DataConnectorDescriptor[]>
  ): SeriesVariwideOptions[] {
    if (isNotDefined(descriptors)) {
      return [];
    }
    const histogramData = this.getHistogramDataPerConnector(descriptors, viewConfig);

    const series = descriptors
      .map((descriptor, index) => {
        if (isNotDefined(descriptor?.connector)) {
          return;
        }
        const connId = descriptor.connector.id;
        const color: string = !isEmptyOrNotDefined2(descriptor.connectorView?.color)
          ? descriptor.connectorView?.color
          : this.colorService.getSeriesColorAtIndex(index);

        return {
          type: "variwide",
          name: getEntityTitle(descriptor.connector),
          zIndex: index,
          color: color,
          ...getSeriesAxisOptions(descriptor, viewConfig),
          data: histogramData[connId] ?? [],
          id: connId
        };
      })
      .filter(isDefined) as SeriesVariwideOptions[];
    makeSeriesInvisible(series, this.invisibleSeriesIds);
    return series;
  }

  getPlotLineSeries(
    options: Options,
    descriptors: DataConnectorDescriptor[]
  ): SeriesVariwideOptions[] {
    const seriesCount = options.series?.length ?? 0;
    const xAxisPlotLineSeries = (options.xAxis as XAxisOptions[])
      .map((axis) => axis.plotLines ?? [])
      .flat()
      .map((options, index) =>
        this.plotLineSeriesGenerator(options, descriptors, false, seriesCount + index)
      );
    const xPlotLinesLength = xAxisPlotLineSeries.length;
    const yAxisPlotLineSeries = (options.yAxis as YAxisOptions[])
      .map((axis) => axis.plotLines ?? [])
      .flat()
      .map((options, index) =>
        this.plotLineSeriesGenerator(
          options,
          descriptors,
          true,
          seriesCount + xPlotLinesLength + index
        )
      );
    return xAxisPlotLineSeries.concat(yAxisPlotLineSeries);
  }

  private plotLineSeriesGenerator(
    options: AxisPlotLinesOptions,
    descriptors: DataConnectorDescriptor[],
    isOnYAxis: boolean,
    colorIndex: number
  ): SeriesVariwideOptions {
    const color: string = !isEmptyOrNotDefined2(options.color)
      ? options.color
      : this.colorService.getSeriesColorAtIndex(colorIndex);
    return {
      type: "variwide",
      color,
      dashStyle: options?.dashStyle,
      id: options?.id,
      name:
        descriptors.find((desc) => desc.connector?.id === options?.id)?.connector?.title ??
        undefined,
      custom: { isOnYAxis, plotLineOptions: options } as PlotLineSeriesCustomData
    };
  }

  getHistogramDataPerConnector(
    descriptors: DataConnectorDescriptor[],
    viewConfig: IHistogramViewConfig
  ): Dictionary<PointOptionsObject[]> {
    const binCount = Math.max(viewConfig.numberOfBins ?? DEFAULT_BINS_NUMBER, MIN_BINS_NUMBER);
    const filteredValuesPerConnector: Dictionary<number[]> = {};
    descriptors.forEach((desc) => {
      if (isDefined(desc.connector)) {
        filteredValuesPerConnector[desc.connector.id] = filterConnectorData(
          desc.connector,
          viewConfig
        );
      }
    });
    return viewConfig.sharedBins
      ? this.getSharedBinsData(filteredValuesPerConnector, binCount)
      : this.getSeparateBinsData(filteredValuesPerConnector, binCount);
  }

  getSharedBinsData(
    valuesPerConnector: Dictionary<number[]>,
    binCount: number
  ): Dictionary<PointOptionsObject[]> {
    const dataPerConnector: Dictionary<PointOptionsObject[]> = {};
    const allData = Object.values(valuesPerConnector).flat();
    if (isEmpty(allData)) {
      return dataPerConnector;
    }
    const min = Math.min(...allData);
    const max = Math.max(...allData);
    const binWidth = (max - min) / binCount;
    const binTotalPadding = BIN_PADDING_PERCENTAGE * binWidth;
    Object.keys(valuesPerConnector).forEach((connectorId, connectorIndex) => {
      const filteredPoints = valuesPerConnector[connectorId];
      const binsDistribution = getBinsDistribution(filteredPoints, min, binWidth, binCount);
      dataPerConnector[connectorId] = binsDistribution.map((binY, index) => {
        return {
          x:
            min +
            binWidth * index +
            (connectorIndex * (binWidth - binTotalPadding)) /
              Object.keys(valuesPerConnector).length +
            binTotalPadding / 2,

          y: binY,
          z: (binWidth - binTotalPadding) / Object.keys(valuesPerConnector).length,
          custom: { binStart: min + binWidth * index, binEnd: min + binWidth * index + binWidth }
        };
      });
    });
    return dataPerConnector;
  }

  getSeparateBinsData(
    valuesPerConnector: Dictionary<number[]>,
    binCount: number
  ): Dictionary<PointOptionsObject[]> {
    const pointsPerConnector: Dictionary<PointOptionsObject[]> = {};
    Object.keys(valuesPerConnector).forEach((connectorId) => {
      const filteredPoints = valuesPerConnector[connectorId];
      if (isEmpty(filteredPoints)) {
        pointsPerConnector[connectorId] = [];
        return;
      }
      const min = Math.min(...filteredPoints);
      const max = Math.max(...filteredPoints);
      const binWidth = (max - min) / binCount;
      const binsDistribution = getBinsDistribution(filteredPoints, min, binWidth, binCount);

      pointsPerConnector[connectorId] = binsDistribution.map((binY, binIndex) => ({
        x: min + binIndex * binWidth,
        y: binY,
        z: binWidth,
        custom: {
          binStart: min + binWidth * binIndex,
          binEnd: min + binWidth * binIndex + binWidth
        }
      }));
    });
    return pointsPerConnector;
  }
}

function getBinsDistribution(
  values: number[],
  min: number,
  binWidth: number,
  binCount: number
): number[] {
  const binsInfo: number[] = new Array(binCount).fill(0);
  values.forEach((value) => {
    const binIndex = Math.min(Math.trunc((value - min) / binWidth), binCount - 1);
    binsInfo[binIndex]++;
  });
  return binsInfo;
}

function filterConnectorData(
  connector: DataConnectorDto,
  viewConfig: IHistogramViewConfig
): number[] {
  const dataPointValues = (connector.dataPoints ?? []).map((dp) =>
    getNumber(dp.evaluatedValue ?? dp.y)
  );
  const filteredPoints = filterNumberValues(
    dataPointValues,
    tryConvertToNumber(viewConfig.xAxisMin) ?? tryConvertToNumber(viewConfig.min),
    tryConvertToNumber(viewConfig.xAxisMax) ?? tryConvertToNumber(viewConfig.max)
  );
  /** We need to round the data because if number of decimal places is large, highcharts messes up the rounding and gets stuck in the infinite loop */
  return roundHistogramData(filteredPoints);
}

function isSupportedSeriesType(descriptor: DataConnectorDescriptor): boolean {
  const type = descriptor?.connectorView?.timeSeriesConfig.seriesType;
  return (
    type !== SERIES_TYPE_BAND &&
    type !== SERIES_TYPE_X_PLOT_LINE &&
    type !== SERIES_TYPE_Y_PLOT_LINE
  );
}

function isPlotBandCustomData(dict: Highcharts.Dictionary<any>): dict is PlotLineSeriesCustomData {
  return isDefined(dict.isOnYAxis) && isDefined(dict.plotLineOptions);
}

function getYAxes(
  viewConfig: IHistogramViewConfig,
  valueFormatter: ValueFormatterService
): Highcharts.YAxisOptions[] {
  return viewConfig.yAxes.map((axisConfig, index) => ({
    id: index === 0 ? PRIMARY_Y_AXIS_ID : Y_AXIS_PREFIX + index.toString(),
    title: {
      text: axisConfig.axisTitle,
      style: { color: axisConfig.color }
    },
    labels: {
      style: getYAxisLabelStyle(axisConfig.color),
      formatter: function () {
        return valueFormatter.formatValue(Number(this.value), viewConfig.displayFormat);
      }
    },
    min: tryConvertToNumber(axisConfig.min) ?? tryConvertToNumber(viewConfig.min),
    max: tryConvertToNumber(axisConfig.max) ?? tryConvertToNumber(viewConfig.max),
    opposite: index % 2 === 1,
    visible: !axisConfig.isHidden
  }));
}

function getTickPositions(
  viewConfig: IHistogramViewConfig,
  histogramOptions: Options
): Maybe<number[]> {
  if (viewConfig.sharedBins) {
    const data = (histogramOptions.series[0] as SeriesVariwideOptions)
      ?.data as PointOptionsObject[];

    const binStarts = data.map((point) => point.custom.binStart);
    const binEndsSorted = data.map((point) => point.custom.binEnd).sort();
    if (isEmpty(data)) {
      return undefined;
    }
    const d = [...binStarts, binEndsSorted[binEndsSorted.length - 1]];
    return roundHistogramData(d);
  }
  return undefined;
}

function filterNumberValues(
  values: Maybe<number>[],
  min: Maybe<number>,
  max: Maybe<number>
): number[] {
  const min2 = min == null ? -Infinity : min;
  const max2 = max == null ? Infinity : max;

  return values.filter((value) => isDefined(value) && value >= min2 && value <= max2);
}

function roundHistogramData(data: number[]): number[] {
  const precision = 6;
  return data.map((point) => Number(point.toPrecision(precision)));
}

function getHistogramSeriesTooltip(
  histogramTooltipData: HistogramTooltipData,
  displayFormat: Maybe<DisplayFormatDto>,
  valueFormatter: ValueFormatterService
): string {
  const { fromX, toX, value, seriesName } = histogramTooltipData;
  const tooltipHtml = `
    ${seriesName}:
    <br>
    ${valueFormatter.formatValue(fromX, displayFormat)}
    -
    ${valueFormatter.formatValue(toX, displayFormat)}
    <br>
    ${valueFormatter.formatValue(value, displayFormat)}
  `;
  return tooltipHtml;
}
