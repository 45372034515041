import { ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Subject, fromEvent } from "rxjs";
import { filter, take, takeUntil } from "rxjs/operators";
import { ReportBrowserSelector } from "../../../browsing/services/report-browser.selector";
import { Dispatcher } from "../../../dispatcher";
import { LocalizationService } from "../../../i18n/localization.service";
import { SPACE_KEY } from "../../../keyboard.constants";
import { TypeProvider } from "../../../meta";
import { OfType } from "../../../meta/decorators";
import { EditorType } from "../../../meta/models";
import { ReportBrowserDialogActions } from "../../../shared/dialogs/actions/report-browser-dialog.actions";
import { isEmpty, isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "link-editor",
  templateUrl: "link-editor.component.html",
  styleUrls: ["link-editor.component.scss"]
})
@OfType(EditorType.LinkEditor)
export class LinkEditorComponent extends BaseEditorComponent implements OnDestroy {
  reportName!: string;
  @HostBinding("attr.title")
  public get tooltipText(): string {
    return this.tooltip;
  }
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    protected cdr: ChangeDetectorRef,
    public localizer: LocalizationService,
    protected typeProvider: TypeProvider,
    private dispatcher: Dispatcher,
    private actions$: Actions,
    private hostElementRef: ElementRef,
    private reportBrowserSelector: ReportBrowserSelector
  ) {
    super(cdr, typeProvider, localizer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reportBrowserSelector.checkIfIdsCached();
    this.refreshReportName();
    this.subscribeToKeyboardEvent();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  refreshReportName(): void {
    if (isNotDefined(this.value) || isEmptyOrNotDefined(this.value.reportId)) {
      this.reportName = this.value.reportName ?? "";
    }
    this.reportBrowserSelector
      .selectAllReports()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((reports) => {
        const report = reports.find((report) => report.reportId === this.value?.reportId);
        this.reportName = report?.reportName ?? "";
        this.cdr.detectChanges();
      });
  }

  private subscribeToKeyboardEvent(): void {
    fromEvent<KeyboardEvent>(this.hostElementRef.nativeElement, "keydown")
      .pipe(
        filter((event) => event.key === SPACE_KEY),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe(() => this.openDialog());
  }

  openDialog(): void {
    this.subscribeToDialogClose();
    this.dispatcher.dispatch(ReportBrowserDialogActions.openReportBrowserDialog());
  }

  private subscribeToDialogClose(): void {
    this.actions$
      .pipe(ofType(ReportBrowserDialogActions.onReportBrowserDialogClosed), take(1))
      .subscribe(({ result }) => {
        if (isDefined(result)) {
          this.onValueChanged(result);
          this.reportName = result.reportName;
        }
      });
  }

  refreshValue(value): void {
    super.refreshValue(value);
    this.reportName = value?.reportName ?? "";
  }

  clearLink(): void {
    if (!isEmpty(this.reportName)) {
      this.reportName = "";
      this.onValueChanged(null);
    }
  }
}
