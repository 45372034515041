import {
  fontSize10,
  fontSize12,
  fontSize14,
  fontSize16,
  fontSize24,
  fontSize32,
  fontSize40
} from "../../../_typography";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { FontSizeParams } from "../models/font-size-params";
import { TitleFormat } from "../models/title-format";

export const MIN_SCALE_DOWN_FONT_SIZE = 5;

export function getScaledFontSizeByFormat(
  titleFormat: Maybe<string>,
  fontSizeParams: FontSizeParams
): number {
  if (isNotDefined(titleFormat)) {
    return 0;
  }
  if (isScaleDown(titleFormat)) {
    return getScaledFontSize(
      fontSizeParams.widthInPx,
      fontSizeParams.heightInPx,
      fontSizeParams.scalingFactor,
      MIN_SCALE_DOWN_FONT_SIZE,
      fontSizeParams.textLength ?? 0
    );
  }
  {
    return getScaledFontSize(
      fontSizeParams.widthInPx,
      fontSizeParams.heightInPx,
      fontSizeParams.scalingFactor,
      fontSizeParams.minSize,
      fontSizeParams.textLength
    );
  }
}

export function getScaledFontSize(
  widthInPx: number,
  heightInPx: number,
  scalingFactor: number,
  minSize: number,
  textLength: number = 0
): number {
  const smallerDimension = Math.min(heightInPx, widthInPx);
  const scaledSize = Math.max(minSize, smallerDimension * scalingFactor);
  const textWidth = getApproximateTextWidth(textLength, scaledSize);
  const fitFactor = textWidth > widthInPx ? widthInPx / textWidth : 1;
  const optimalSize = Math.max(minSize, scaledSize * fitFactor);
  return optimalSize;
}

function getApproximateTextWidth(textLength: number, fontSize: number): number {
  // NOTE: This solution proved to be most optimal regarding the performance. Previously tried approximation using DOM/canvas
  // https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/48172630#48172630
  const approximateAverageCharScalingFactor = 0.7;
  const textWidth = textLength * approximateAverageCharScalingFactor * fontSize;
  return textWidth;
}

export function roundToClosestFontSize(size: number): number {
  if (size <= fontSize10) {
    return fontSize10;
  } else if (size <= fontSize12) {
    return fontSize12;
  } else if (size <= fontSize14) {
    return fontSize14;
  } else if (size <= fontSize16) {
    return fontSize16;
  } else if (size <= fontSize24) {
    return fontSize24;
  } else if (size <= fontSize32) {
    return fontSize32;
  } else {
    return fontSize40;
  }
}

export function isWrap(titleFormat: Maybe<string>): boolean {
  return isDefined(titleFormat) && titleFormat === TitleFormat.Wrap;
}

export function isScaleDown(titleFormat: Maybe<string>): boolean {
  return isDefined(titleFormat) && titleFormat === TitleFormat.ScaleDown;
}
