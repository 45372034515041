<input
  *ngIf="!isReadOnly"
  #editorInput
  type="text"
  class="text-box-editor__input editor--discreet-input"
  [ngClass]="{
    'invalid-input': !isValid,
    'editor--value-configured': value !== propertyInfo.descriptor.defaultValue
  }"
  [value]="value ?? ''"
  (input)="onInputChange($event.target.value)"
  [placeholder]="generatePlaceholderText() | translate"
  [matAutocomplete]="autocompleteInterpolationComponent?.autocomplete"
  [matAutocompleteDisabled]="!autocompleteInterpolationComponent?.autocomplete"
  (keydown)="openHelp($event)"
/>
<label *ngIf="isReadOnly">{{ value }}</label>
<ng-container *ngIf="propertyInfo.descriptor.allowInterpolation">
  <autocomplete-interpolation
    #autocompleteInterpolation
    [value]="editorInput?.nativeElement.value"
    [ownerInfo]="parentInfo"
    (onValueChange)="onValueChange($event)"
  ></autocomplete-interpolation>
</ng-container>
