import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { CategoryInfo } from "../../../core";
import { WidgetExplorerItemsGenerator } from "../../../shared";
@Component({
  selector: "layout-builder-menu",
  templateUrl: "layout-builder-menu.component.html",
  styleUrls: ["./layout-builder-menu.component.scss"]
})
export class LayoutBuilderMenuComponent implements OnDestroy {
  categories: CategoryInfo[];
  @Output() collapseSidebar: EventEmitter<any> = new EventEmitter();
  @Output() expandSidebar: EventEmitter<any> = new EventEmitter();
  public searchPattern = "";
  public searchPatternChanged$: Subject<string> = new Subject<string>();
  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(private widgetExplorerItemsGenerator: WidgetExplorerItemsGenerator) {
    this.subscribeToSearchWidgets();
    this.widgetExplorerItemsGenerator
      .generateWidgetExplorerCategories$()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((widgetExplorerCategories) => {
        this.categories = widgetExplorerCategories;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  private subscribeToSearchWidgets(): void {
    this.searchPatternChanged$
      .pipe(debounceTime(200), takeUntil(this.unsubscribeSubject$))
      .subscribe((x) => {
        this.searchPattern = x;
      });
  }

  collapseSidebarOnDragStart(): void {
    this.collapseSidebar.emit();
  }

  expandSidebarOnDrop(): void {
    this.expandSidebar.emit();
  }
}
