import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { Observable, Subject, merge, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { DraggedItem } from "../../../core";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { SignalDragInfo } from "../../../core/models/drag/signal-drag-info";
import { DroppableElement } from "../../../core/models/droppable-element";
import { IDragDropService } from "../../../core/services/i-drag-drop.service";
import { DataService, SignalDataSourceDto } from "../../../data-connectivity";
import { Dispatcher } from "../../../dispatcher";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { LocalizationService } from "../../../i18n/localization.service";
import { EntityId } from "../../../meta/models/entity";
import { isDefined, isEmpty, isEmptyOrNotDefined } from "../../../ts-utils";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { DataExplorerSelector } from "../../services/data-explorer.selector";
import { DataExplorerActions } from "../../store/data-explorer";
import { ItemBrowserComponent } from "../item-browser/item-browser.component";

@Component({
  selector: "signal-browser",
  templateUrl: "signal-browser.component.html",
  styleUrls: ["signal-browser.component.scss"]
})
export class SignalBrowserComponent
  extends ItemBrowserComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() showCheckboxes: boolean;
  @Input() openInDialog: boolean;
  @Input() displayTitle: boolean = false;
  @Input() isDataExplorerTab: boolean = false;
  @Output() signalsChanged: EventEmitter<SignalDataSourceDto[]> = new EventEmitter<
    SignalDataSourceDto[]
  >();
  @Output() collapseSidebar: EventEmitter<any> = new EventEmitter();
  @Output() expandSidebar: EventEmitter<any> = new EventEmitter();

  searchPattern: Maybe<string> = "";
  showProgressBar: boolean = false;
  _isSignalTabSelected: boolean = true;
  searchInputChanged$: Subject<Maybe<string>> = new Subject<Maybe<string>>();
  signalQueryComplete$: Observable<Maybe<SignalDragInfo[]>>;
  selectedOptions: DroppableElement[];
  private lastSelectedSignal: Maybe<EntityId>;
  isSignalDescriptionVisible: boolean = true;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  constructor(
    dragDropService: IDragDropService,
    private dataExplorerSelector: DataExplorerSelector,
    private dispatcher: Dispatcher,
    private logDataService: DataService,
    private cdr: ChangeDetectorRef,
    public localizer: LocalizationService,
    protected environmentSelector: EnvironmentSelector
  ) {
    super(dragDropService, environmentSelector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.isDataExplorerTab) {
      this.subscribeToSignalBrowserMode();
      this.searchPattern = this.dataExplorerSelector.getSignalSearchPattern();
      this.initSelectedSignal();
    }
    this.signalQueryComplete$ = this.getSignalQueryObservable();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.shouldKeepSelectedSignal()) {
      this.dispatcher.dispatch(
        DataExplorerActions.preserveLastUsedSignal({
          signal: (this.selectedItem as SignalDragInfo)?.dataSource?.signal?.id ?? null
        })
      );
    }
  }

  private shouldKeepSelectedSignal(): boolean {
    return (
      this.isDataExplorerTab &&
      ((this.selectedItem as SignalDragInfo)?.dataSource?.signal?.id ?? null) !==
        this.lastSelectedSignal
    );
  }

  private subscribeToSignalBrowserMode(): void {
    this.dataExplorerSelector
      .selectSignalTabMode()
      .pipe(
        tap((isSignalTabSelected: boolean) => {
          this.showProgressBar = isSignalTabSelected;
        }),
        debounceTime(300),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((isSignalTabSelected: boolean) => {
        this.isSignalTabSelected = isSignalTabSelected;
      });
  }

  private initSelectedSignal(): void {
    this.lastSelectedSignal = this.dataExplorerSelector.getLastSelectedSignal();
    if (isDefined(this.lastSelectedSignal)) {
      this.selectedItem = {
        dataSource: {
          signal: {
            id: this.lastSelectedSignal
          }
        }
      };
    }
  }

  private getSignalQueryObservable(): Observable<Maybe<SignalDragInfo[]>> {
    return merge(this.getSearchInputObservable(), of(this.searchPattern)).pipe(
      withLatestFrom(this.dataExplorerSelector.selectSignalSearchPattern()),
      tap(([searchPatternFromInput, searchPatternFromStore]) => {
        this.showProgressBar = true;
        if (this.shouldUpdateSearchPatternInStore(searchPatternFromInput, searchPatternFromStore)) {
          this.updateSearchPatternInStore(searchPatternFromInput);
        }
        if (isDefined(searchPatternFromInput) && isEmpty(searchPatternFromInput)) {
          this.setSelectedItem(null);
        }
      }),
      switchMap(([signalSearchPattern]) => this.getSignalData(signalSearchPattern)),
      tap(() => {
        this.showProgressBar = false;
      })
    );
  }

  private getSearchInputObservable(): Observable<Maybe<string>> {
    return this.searchInputChanged$.pipe(debounceTime(500), distinctUntilChanged());
  }

  private shouldUpdateSearchPatternInStore(
    searchPattern: Maybe<string>,
    searchPatternFromStore: Maybe<string>
  ): boolean {
    return (
      this.isDataExplorerTab && isDefined(searchPattern) && searchPattern !== searchPatternFromStore
    );
  }

  private updateSearchPatternInStore(searchPattern: Maybe<string>): void {
    this.dispatcher.dispatch(DataExplorerActions.setSignalSearchPattern({ searchPattern }));
  }

  private getSignalData(signalSearchPattern: Maybe<string>): Observable<Maybe<SignalDragInfo[]>> {
    if (!isEmptyOrNotDefined(signalSearchPattern)) {
      const encodedSearchTerm: string = encodeURIComponent(signalSearchPattern);
      return this.logDataService.getFilteredSignalData(encodedSearchTerm, false);
    } else {
      this.searchPattern = "";
      return of(null);
    }
  }

  get isSignalTabSelected(): boolean {
    return this._isSignalTabSelected;
  }

  set isSignalTabSelected(isSignalTabSelected: boolean) {
    this._isSignalTabSelected = isSignalTabSelected;
    if (isSignalTabSelected) {
      this.viewport.checkViewportSize();
      this.showProgressBar = false;
    }
  }

  selectSignal(selectedSignal: SignalDataSourceDto): void {
    this.setSelectedItem(selectedSignal);
    this.selectedOptions = [selectedSignal];
    this.signalsChanged.emit(this.selectedOptions as SignalDataSourceDto[]);
  }

  getDragTarget(signalData: SignalDataSourceDto): DraggedItem {
    this.collapseSidebar.emit();
    return {
      type: DraggedItemType.Signal,
      item: signalData
    };
  }

  isSelectedItem(item: DroppableElement): boolean {
    return (
      (item as SignalDragInfo)?.dataSource?.signal?.id ===
      (this.selectedItem as SignalDragInfo)?.dataSource?.signal?.id
    );
  }

  showOrHideSignalDescription(): void {
    this.isSignalDescriptionVisible = !this.isSignalDescriptionVisible;
    this.viewport.checkViewportSize();
  }

  getTitleBasedOnAliasMode(signal: SignalDragInfo): string {
    const aliasName = signal.getAliasName();
    return this.aliasMode && isDefined(aliasName) ? aliasName : signal.getTitle();
  }

  dragEnd(): void {
    this.expandSidebar.emit();
    super.dragEnd();
  }
}
