import { ChangeDetectorRef, Component, ElementRef, ViewChild } from "@angular/core";
import { MatTabNav } from "@angular/material/tabs";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { take } from "rxjs/operators";
import { IconId } from "../../../browsing/models/icon-id";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { LinkDto, isReportLink } from "../../../core/models/link";
import { getConnectorViewId } from "../../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { ComponentCategory, LayoutBuilder } from "../../../meta";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { last } from "../../../ts-utils/helpers/array.helper";
import { isEmptyOrNotDefined2 } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { getConfiguredOrForegroundColor } from "../../helpers/color.helper";
import { getIndicatorStatus } from "../../helpers/status-indicator.helper";
import { NAVIGATION_BAR } from "../../models/element-type.constants";
import { NAVIGATION_BAR as NAVIGATION_BAR_HELP } from "../../models/help-constants";
import { NavLinkInfo } from "../../models/nav-link-info";
import { IndicatorDisplayStrategy } from "../../models/status-indicator/indicator-display-strategy";
import { IndicatorState } from "../../models/status-indicator/indicator-state";
import { NULL_VALUE } from "../../models/status-indicator/status-indicator-states.constants";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { ActiveContainersService } from "../../services/active-containers.service";
import { ComponentPositioningService } from "../../services/component-positioning.service";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { CardComponent } from "../card/card.component";
import { Roles } from "./roles";
import { NavigationBarViewConfig } from "./view-config";

interface LinkViewParams {
  title: string;
  link: Maybe<LinkDto>;
  disabled: boolean;
  status: IndicatorState;
  isActive: boolean;
  color: Maybe<string>;
  icon: Maybe<IconId>;
}

@Component({
  selector: "c-nav-bar",
  templateUrl: "./navigation-bar.component.html",
  styleUrls: ["./navigation-bar.component.scss"]
})
@LayoutBuilder(
  ComponentCategory.Container,
  NAVIGATION_BAR,
  "icon-Tab-links",
  "dashboard-icon",
  null,
  LOCALIZATION_DICTIONARY.layoutEditor.NavigationBar,
  NAVIGATION_BAR_HELP
)
@ConnectorRoles(Roles)
@MaxConnectors(20)
@EditableWidget({ fullName: NAVIGATION_BAR, title: "nav-bar" })
export class NavigationBarComponent extends CardComponent {
  @ViewChild(MatTabNav, { static: true }) matTabNav: MatTabNav;
  public links: LinkViewParams[] = [];

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    activeContainersService: ActiveContainersService,
    componentPositioningService: ComponentPositioningService,
    private activatedRoute: ActivatedRoute,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, activeContainersService, componentPositioningService, cdr);
  }

  @View(NavigationBarViewConfig)
  public get view(): NavigationBarViewConfig {
    return this.currentState.view as NavigationBarViewConfig;
  }

  protected updateDisplay(): void {
    this.onNavBarLinksChange(this.view.links);
  }

  onLinkClick(event: Event, typedLink: Maybe<LinkDto>): void {
    if (typedLink == null) {
      return;
    }
    event.preventDefault();
    this.linkResolver.resolveLink(typedLink);
  }

  protected onViewChange(view: NavigationBarViewConfig): void {
    super.onViewChange(view);
    this.matTabNav.updatePagination();
  }

  private onNavBarLinksChange(links: NavLinkInfo[]): void {
    this.links = links.map((linkInfo, linkIndex: number): LinkViewParams => {
      const { link, title, color, icon } = this.getInterpolatedLinkInfo(linkInfo, linkIndex);
      return {
        title,
        link,
        disabled: this.isLinkDisabled(link),
        status: this.getStatus(linkIndex),
        isActive: this.isActive(link),
        color,
        icon
      };
    });
  }

  private getInterpolatedLinkInfo(linkInfo: NavLinkInfo, linkIndex: number): NavLinkInfo {
    const allConnectors: DataConnectorDto[] = this.dataAccessor.getAllConnectors();
    const connector: DataConnectorDto = allConnectors[linkIndex];
    let connectorDescriptors: DataConnectorDescriptor[] = [];
    if (allConnectors.length > linkIndex) {
      connectorDescriptors = [
        {
          connector,
          connectorView: this.dataConnectorViewSelector.getById(getConnectorViewId(connector.id))
        }
      ];
    }
    return this.propertyInterpolationService.interpolatePropertiesForTarget(linkInfo, {
      componentViewModel: this.componentStateVmDeserializer.convert(this.currentState),
      connectorDescriptors
    }) as NavLinkInfo;
  }

  private isActive(reportLink: Maybe<LinkDto>): boolean {
    if (isNotDefined(reportLink) || !isReportLink(reportLink)) {
      return false;
    }
    let isActive: boolean = false;
    this.activatedRoute.paramMap.pipe(take(1)).subscribe((paramMapValue: ParamMap) => {
      const reportId: Maybe<string> = paramMapValue.get("id");
      if (
        isDefined(reportId) &&
        isDefined(reportLink.info.reportId) &&
        reportId === reportLink.info.reportId.toString()
      ) {
        isActive = true;
      }
    });
    return isActive;
  }

  private getStatus(tabIndex: number): IndicatorState {
    const allConnectors = this.dataAccessor.getAllConnectors();
    const statusConnectors: DataConnectorDto[] = allConnectors.filter(
      (connector) => connector.role === Roles.Status.name
    );
    if (isNotDefined(statusConnectors) || tabIndex >= allConnectors.length) {
      return getIndicatorStatus(NULL_VALUE, IndicatorDisplayStrategy.Binary);
    }
    const tabConnector: DataConnectorDto = allConnectors[tabIndex];
    const latestDataPoint = last(tabConnector.dataPoints);
    return getIndicatorStatus(
      latestDataPoint ? latestDataPoint.y : null,
      IndicatorDisplayStrategy.Binary
    );
  }

  private isLinkDisabled(typedLink: Maybe<LinkDto>): boolean {
    return (
      isNotDefined(typedLink) ||
      (isReportLink(typedLink) && isEmptyOrNotDefined2(typedLink.info.reportId))
    );
  }

  canAcceptDrop(): boolean {
    const targetType = this.draggedComponentService.target?.type;
    return targetType === DraggedItemType.Signal || targetType === DraggedItemType.Equipment;
  }

  protected makeConfigButtonsFullyVisible(): void {}

  protected hideParentsConfigButtons(event: MouseEvent): void {}

  protected revertOverlappingWidgetsState(): void {}

  protected unhideParentsConfigButtons(event: MouseEvent): void {}

  resolveLinkColor(configuredLinkColor: Maybe<string>): string {
    return getConfiguredOrForegroundColor(configuredLinkColor, this.view.foregroundColor);
  }
}
