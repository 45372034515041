import { TimeUnit } from "../../core/models/time-unit";

export class LiveModeFilter {
  public amount: number;
  public unit: TimeUnit;

  constructor(params: Partial<LiveModeFilter> = {}) {
    this.amount = params.amount ?? 0;
    this.unit = params.unit ?? TimeUnit.Hours;
  }
}
