import { Access } from "../models/access";
import { EditorSize } from "../models/editor-size";
import { EditorType } from "../models/editor-type";
import { defaultValidationFunction, PropertyDescriptor } from "../models/property-descriptor";
import { TypeProvider } from "../services/type-provider";
import { ConfigurableDecoratorParams } from "./configurable.decorator";

const TYPE_PROVIDER = TypeProvider.getInstance();

export class ConfigurableArrayParams extends ConfigurableDecoratorParams {
  typeConstructor: Function;
  arrayItemEditorType?: EditorType = null;
  arrayEditorSize?: EditorSize = EditorSize.Large;
  canBeHidden?: boolean;
}

export function ConfigurableArray(params: ConfigurableArrayParams) {
  return function (proto: Object, key: string) {
    const typeDescriptor = TYPE_PROVIDER.addType({
      constructorFunction: proto.constructor as new () => any
    });

    const partialPropertyDescriptor: Partial<PropertyDescriptor> = {
      displayName: params.displayName || key,
      editorType: params.editorType || EditorType.Array,
      access: params.access || Access.Write,
      tooltipKey: params.tooltipKey || "",
      validationFunction: params.validationFunction || defaultValidationFunction,
      arrayItemEditorType: params.arrayItemEditorType,
      arrayEditorSize: params.arrayEditorSize == null ? EditorSize.Large : params.arrayEditorSize,
      isHidden: false,
      isArray: true,
      isGroupable: params.isGroupable ?? false,
      canBeHidden: params.canBeHidden ?? false,
      showLabel: false
    };

    typeDescriptor.upsertProperty(key, params.typeConstructor, partialPropertyDescriptor);
  };
}
